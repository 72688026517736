// truncate.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: any, trail?: string): string {
    limit = limit ? parseInt(limit, 0) : 10;
    trail = !trail ? '...' : trail;
    return value && value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

