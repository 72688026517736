import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { TableFilterModule } from 'src/app/core/feature-modules/table-filter/table-filter.module';
import { GbpQaReplyComponent } from './components/gbp-qa-reply/gbp-qa-reply.component';
import { GbpTabQaComponent } from './gbp-tab-qa.component';
import { GbpQaCustomerAnswersComponent } from './components/gbp-qa-customer-answers/gbp-qa-customer-answers.component';
import { GbpQaCustomerViewComponent } from './components/gbp-qa-customer-view/gbp-qa-customer-view.component';
import { GbpQaOwnerViewComponent } from './components/gbp-qa-owner-view/gbp-qa-owner-view.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivityStatusModule } from '../pipes/activity-status.module';
import { GbpQaAddQuestionComponent } from './components/gbp-qa-add-question/gbp-qa-add-question.component';
import { ProgressrModule } from 'src/app/core/feature-modules/progress/progress.module';
import { GbpQaAddLocationComponent } from './components/gbp-qa-add-location/gbp-qa-add-location.component';
import { GbpQaEditComponent } from './components/gbp-qa-edit/gbp-qa-edit.component';
import { GbpQaDeleteComponent } from './components/gbp-qa-delete/gbp-qa-delete.component';
import { GetStatusModule } from '../pipes/get-status.module';
import { GbpQaOwnerViewAnswersComponent } from './components/gbp-qa-owner-view-answers/gbp-qa-owner-view-answers.component';

export const MODULES = [TableFilterModule, ActivityStatusModule, ProgressrModule, GetStatusModule];
export const COMPONENTS = [
  GbpQaReplyComponent,
  GbpQaCustomerAnswersComponent,
  GbpQaCustomerViewComponent,
  GbpQaOwnerViewComponent,
  GbpQaAddQuestionComponent,
  GbpQaAddLocationComponent,
  GbpQaEditComponent,
  GbpQaDeleteComponent,
  GbpQaOwnerViewAnswersComponent,
];

@NgModule({
  imports: [RSCommonModule, ...MODULES],
  declarations: [GbpTabQaComponent, ...COMPONENTS],
  exports: [GbpTabQaComponent],
  providers: [DialogService],
})
export class GbpQaModule {}
