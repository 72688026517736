import {Component, Input, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GBPService} from '@app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-photo-delete',
  templateUrl: './gbp-photo-delete.component.html',
  styleUrls: ['./gbp-photo-delete.component.scss'],
})
export class GbpPhotoDeleteComponent implements OnInit {
  constructor(public dynamicDialogConfig: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private gbpService: GBPService) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.ref.close();
  }

  deleteItem(): void {
    this.gbpService.deletePhotoBatch(this.dynamicDialogConfig.data);
    this.ref.close();
  }
}
