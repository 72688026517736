<div class="header-parent-title">
  <div class="header-child-title">Photo - Locations</div>
  <div class="header-child-title">.</div>
</div>
<div class="gbp-photo-locations-table">
  <app-locations-list [tableData]="tableData" (searchKeyword)="updateTableData($event)"></app-locations-list>
  <p-paginator
    styleClass="multiselect-paginator"
    [rows]="paginatorRows"
    [totalRecords]="paginatorLength"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} locations.'"
    (onPageChange)="onPageChange($event)"
  >
  </p-paginator>
</div>
