const all = `
SELECT _id AS "DB ID", storeCode AS "Store Code", businessname as "Business Name",
city AS "City", state AS "State",
vendor->'yelp'->>'url' as "YELP URL",
vendor->'mapquest'->>'url' as "MAPQUEST URL",
vendor->'pandio'->>'urls' as "YP/DEX/SP URLs",
'https://maps.apple.com/place?auid=' || (vendor->'applemaps'->>'mapId') as "APPLEMAPS URL",
vendor->'bing'->>'url' as "Bing URL",
vendor->'gmb'->>'mapsUri' as "GMB URL",
vendor->'nextdoor'->>'url' as "NEXTDOOR URL"
FROM location
WHERE  _status = 'A' AND _pubstatus = 'Y' ORDER BY storeCode
`;
export const LISTINGS_SQL = { all };
