import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class GBPPhotoConfigService {

  private mediaRequirementsTitle: string[] = [
    'Logo requirements',
    'Cover photo requirements',
    'Photo requirements',
    'Video requirements'
  ];

  private mediaRequirementsDescription = [
    {
      id: 'VIDEO',
      category: 'ADDITIONAL',
      details: [
        'File format: MPEG4/MP4',
        'Duration: Up to 30 seconds long',
        'File size: Up to 75 MB',
        'Resolution: 720p or higher',
      ]
    },
    {
      id: 'PHOTO',
      category: 'ADDITIONAL',
      details: [
        'Image format: JPG or PNG',
        'Minimum 250px width and 250px height. Recommended 720px by 720px.',
        'Longer dimension should be no more than twice the shorter dimension.',
        'Maximum 10,000px for either dimension.',
        'Maximum file size: 5MB',
        'Minimum file size: 10k',
        'Multiframe, Live, and Motion photos are not allowed. Disable advanced photo options (such as HDR+) before taking photos. You can also avoid this issue by converting the image to PNG format before submitting.'
      ]
    },
    {
      id: 'COVER PHOTO',
      category: 'COVER',
      details: [
        'Image format: JPG or PNG',
        'Image must have 16:9 aspect ratio. For example,  1600px by 900px.',
        'Minimum 420px width and 270px height.',
        'Maximum 10,000px for either dimension.',
        'Maximum file size: 5MB',
        'Minimum file size: 10k',
        'Multiframe, Live, and Motion photos are not allowed. Disable advanced photo options (such as HDR+) before taking photos. You can also avoid this issue by converting the image to PNG format before submitting.'
      ]
    },
    {
      id: 'LOGO',
      category: 'LOGO',
      details: [
        'Image format: JPG or PNG',
        'Image must be exactly square.',
        'Minimum 250px width by 250px height.',
        'Maximum 10,000px for either dimension.',
        'Maximum file size: 5MB',
        'Minimum file size: 10k',
        'Multiframe, Live, and Motion photos are not allowed. Disable advanced photo options (such as HDR+) before taking photos. You can also avoid this issue by converting the image to PNG format before submitting.'
      ]
    }
  ];


  public setGBPMediaRequirements(category: string) {
    switch (category) {
      case 'LOGO':
        return {
          title: this.mediaRequirementsTitle[0],
          description: this.mediaRequirementsDescription.find(v => v.id === category).details
        }
      case 'COVER PHOTO':
        return {
          title: this.mediaRequirementsTitle[1],
          description: this.mediaRequirementsDescription.find(v => v.id === category).details
        }
      case 'PHOTO':
        return {
          title: this.mediaRequirementsTitle[2],
          description: this.mediaRequirementsDescription.find(v => v.id === category).details
        }
      case 'VIDEO':
        return {
          title: this.mediaRequirementsTitle[3],
          description: this.mediaRequirementsDescription.find(v => v.id === category).details
        }
    }
  }

}
