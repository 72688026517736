import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ACTIVITY_STATUS } from 'src/app/gbp-management/gbp-tabs.constants';
import { GbpPhotoLocationsComponent } from '../gbp-photo-locations/gbp-photo-locations.component';
import { GbpSelectLocationsComponent } from '../gbp-select-locations/gbp-select-locations.component';

@Component({
  selector: 'app-gbp-photo-details',
  templateUrl: './gbp-photo-details.component.html',
  styleUrls: ['./gbp-photo-details.component.scss'],
})
export class GbpPhotoDetailsComponent implements OnInit {
  isEdit = false;
  imageUrl = '';
  status = '';
  activityStatus: typeof ACTIVITY_STATUS = ACTIVITY_STATUS;
  isOptimized: boolean;
  scheduleGroup = new FormGroup({
    date: new FormControl('', Validators.required),
    time: new FormControl('', Validators.required),
    timeZone: new FormControl('', Validators.required),
    location: new FormControl(''),
    isSelectedAll: new FormControl(''),
  });

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.imageUrl = this.dynamicDialogConfig.data.internalURL || this.dynamicDialogConfig.data.sourceURL;
    this.status = this.dynamicDialogConfig.data?._status;
    this.isOptimized = this.dynamicDialogConfig.data?.optimized || false;
  }

  closeDialog(): void {
    this.ref.close();
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
  }

  openLocations(): void {
    this.ref.close();
    this.dialogService.open(GbpPhotoLocationsComponent, {
      width: '80%',
      data: { ...this.dynamicDialogConfig.data },
    });
  }

  scheduleOrPublish(): void {
    this.ref.close();
    this.dialogService.open(GbpSelectLocationsComponent, {
      width: '80%',
      data: { ...this.dynamicDialogConfig.data },
    });
  }

  saveChanges(): void {
    // save info
    this.toggleEdit();
  }
}
