<div class="reviews-page-container">
  <!-- Main page header -->
  <div class="flex">
    <div class="header-parent-title">
      <div class="header-child-title">Your Reviews.</div>
      <div>
        <p-splitButton #actions label="EXPORT" styleClass="export-button" [model]="actionItems"
          (onClick)="actions.onDropdownButtonClick(null)"></p-splitButton>
      </div>
    </div>
  </div>

  <!-- Tab menu-->
  <!-- <p-tabMenu class="tabs-menu" [model]="tabItems" [activeItem]="activeItem">
    <ng-template pTemplate="item" let-item let-i="index">
      <div class="tabs-style">{{ item.label }}</div>
    </ng-template>
  </p-tabMenu> -->
  <ng-container *ngIf="tabItems.length">
    <rs-page-tabs [tabItems]="tabItems" (tabSelected)="activeItem = $event"> </rs-page-tabs>
    <!-- Google Reviews Tab -->
    <div *ngIf="activeItem.label == reviewTabs.GOOGLE"><app-google-reviews-tab></app-google-reviews-tab></div>

    <!-- Yelp Reviews Tab -->
    <div *ngIf="activeItem.label == reviewTabs.YELP"><app-yelp-reviews-tab></app-yelp-reviews-tab></div>

    <!-- Pending Responses Tab -->
    <!-- <div *ngIf="activeItem.label == reviewTabs.PENDING"><app-pending-responses-tab></app-pending-responses-tab></div> -->

    <!-- Pandio reviews tab -->
    <div *ngIf="activeItem.label == reviewTabs.OTHER_REVIEWS"><app-pandio-reviews-tab></app-pandio-reviews-tab></div>
  </ng-container>

</div>
