import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {DBService} from 'src/app/core/backend-adapter/db.service';
import {FILTER_TYPES, IFilterData} from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';
import {TableFilterService} from 'src/app/core/feature-modules/table-filter/table-filter.service';
import {NotifyService} from 'src/app/core/layouts/notifications/notify/notify.service';
import {ACTIVITY_STATUS} from '../gbp-tabs.constants';
import {GBPService} from '../gbp.service';
import {SelectPostTypeComponent} from './components/gbp-post-create/select-post-type/select-post-type.component';
import {SuccessComponent} from './components/gbp-post-create/success/success.component';
import {GbpPostDeleteComponent} from './components/gbp-post-delete/gbp-post-delete.component';
import {GbpPostEditComponent} from './components/gbp-post-edit/gbp-post-edit.component';
import {GbpPostLocationsComponent} from './components/gbp-post-locations/gbp-post-locations.component';
import {BroadcastService} from '@app/core/backend-adapter/broadcast.service';
import {LazyLoadEvent} from 'primeng/api';
import { SYSTEM_COLORS } from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import {SessionService} from '@app/core/backend-adapter/session.service';
import {IAccount} from '@app/acct-comps/accounts.interfaces';
import { IFitersObject } from 'src/app/review-management/review-management.interfaces';
import { GBPFiltersDateService } from '../services/gbp-filters-date-service/gbp-filters-date-service';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';

@Component({
  selector: 'app-gbp-post',
  templateUrl: './gbp-tab-post.component.html',
  styleUrls: ['./gbp-tab-post.component.scss'],
  providers: [DialogService],
})
export class GbpTabPostComponent implements OnInit, OnDestroy {
  SYSTEM_COLORS = SYSTEM_COLORS;
  keyword = '';
  postData = [];
  tableData = [];
  selectedRows = [];
  filterData: IFilterData[];
  chipsArray: string[] = [];
  tooltipText = 'Coming soon. Please reach out to support if you need to edit a post.';
  activityStatus: typeof ACTIVITY_STATUS = ACTIVITY_STATUS;
  totalLocationsCount;
  allLocations;
  allPostsData;
  initialPostsData;
  postTrends;
  postLoading = true;
  errorImg = '';
  createPostRef: DynamicDialogRef;
  totalPostsCount: number;
  offset = 0;
  pageSize = 25;
  sortOrder = '';
  filters: IFitersObject = { };

  private unsubscribe$ = new Subject<void>();
  private account: IAccount;

  constructor(
    private dialogService: DialogService,
    private tableFilterService: TableFilterService,
    public dbService: DBService,
    private gbpService: GBPService,
    private notifyService: NotifyService,
    private broadcastService: BroadcastService,
    private sessionService: SessionService,
    private gbpFiltersDateService: GBPFiltersDateService,
    private socketService: SocketService
  ) {
  }

  ngOnInit(): void {
    this.account = this.dbService.Account.findByIdent(this.sessionService.currAccount);

    this.filterData = [
      {
        type: FILTER_TYPES.MULTI,
        title: 'ATTRIBUTES',
        options: ['Scheduled', 'Published', 'Unpublished'],
      },
      {
        type: FILTER_TYPES.MULTI,
        title: 'POST',
        options: ['STANDARD', 'EVENT', 'OFFER', 'ALERT'],
      },
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: ['This month', 'Last month'],
      },
    ];

    this.setSubscriptions();
  }

  lazyLoadData(event: LazyLoadEvent) {
    this.offset = event.first;
    this.pageSize = event.rows;
    if (event.sortField) {
      this.sortOrder = `${event.sortField}${event.sortOrder < 0 ? ' DESC' : ''}`;
    }
    return this.loadData();
  }

  loadData() {
    this.dbService.LocalPostGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      order: this.sortOrder,
      where: this.buildWhereClause()
    }).then(
      (repl) => {
        if (repl.collection.length > 0) {
          this.allPostsData = repl.collection;
          this.totalPostsCount = repl.totalEntries;
          this.initialPostsData = repl.collection;
        } else {
          this.initialPostsData = [];
          this.allPostsData = [];
          this.totalPostsCount = 0;
          this.notifyService.error('There is no data for this account');
        }
        this.postLoading = false;

      },
      (err) => {
        console.warn('Error loading posts: %j', err);
      }
    );
  }

  setSubscriptions() {
    this.broadcastService.subscribe('localpostStarted', (repl) => {
      this.loadData();
    });

    this.broadcastService.subscribe('localpostProcessed', (repl) => {
      this.loadData();
    });
  }

  accountIsNotLeaf() {
    return !this.account?.isLeaf;
  }

  createPostTooltip() {
    return (!this.account?.isLeaf) ? 'Please choose a sub-account before creating a post.' : '';
  }

  openPostDelete(rowData) {
    this.dialogService.open(GbpPostDeleteComponent, {
      data: {...rowData},
      width: '40%',
    });
  }

  deleteBtnClass(data) {
    return ( ['DP', 'UR', 'AR'].includes(data._status) ) ? 'disabled' : '';
  }

  openPostCreate() {
    this.createPostRef = this.dialogService.open(SelectPostTypeComponent, {
      width: '90%',
    });

    // update posts list after creating the new post
    this.createPostRef.onClose.subscribe(() => {
      this.dbService.LocalPostGMB.loadObjects({}).then(
        (repl) => {
          if (repl.collection.length > 0) {
            this.allPostsData = repl.collection;
          } else {
            this.allPostsData = [];
          }
        },
        (err) => {
          console.warn('Error loading posts: %j', err);
        }
      );
    });
  }

  openLocations(postId: number) {
    this.dialogService.open(GbpPostLocationsComponent, {
      width: '50%',
      data: {
        isPagination: true,
        id: postId,
      },
    });
  }

  duplicatePost(rowData) {
    const tempObj = {...rowData};
    tempObj._id = null;
    tempObj._createdAt = null;
    tempObj.status = 'AP';

    this.dialogService.open(SuccessComponent, {
      width: '20%',
    });
    this.gbpService.updateLocalPost(tempObj);
  }

  unpublishPost(rowData) {
    // temporarily disabled
    // const tempObj = { ...rowData, _status: 'UP' };
    // this.dialogService.open(SuccessComponent, {
    //   width: '20%',
    // });
    // this.gbpService.updateLocalPost(tempObj);
  }

  editPost(rowData) {
    this.dialogService.open(GbpPostEditComponent, {
      width: '90%',
      data: {...rowData},
    });

  }

  onChipsRefresh(event) {
    this.chipsArray = [].concat.apply([], Object.values(event)); // merge all checkbox values into one array
    this.chipsArray = this.chipsArray.filter((e) => e); // remove all possible undefined
    this.filters = event;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.tableFilterService.clearFilters(); // clear table filters
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildWhereClause(): Array<string> {
    const filterClauses: string[][] = [];
    const tempFilterArr: string[] = [];
    const isFiltersApplied = this.chipsArray.length > 0;

    const pushQueriesToMainArray = () => {
      let filterClause: Array<string> = ['-or'];
      filterClause = filterClause.concat(tempFilterArr);
      filterClauses.push(filterClause);
      tempFilterArr.length = 0;
    }

    if (isFiltersApplied) {
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.length > 0 && value[0] != undefined) {
          switch (key) {
            case 'ATTRIBUTES':
              value.forEach((element: string) => {
                switch (element) {
                  case 'Scheduled':
                    tempFilterArr.push("_status IN ('AP', 'UP','DP')");
                    break;
                  case 'Published':
                    tempFilterArr.push("_status = 'A'");
                    break;
                  case 'Unpublished':
                    tempFilterArr.push("_status = 'U'");
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'POST':
              value.forEach((element: string) => tempFilterArr.push(`topicType = '${element}'`));
              pushQueriesToMainArray();
              break;
            case 'DATE':
              value.forEach((element: string) => {
                switch (element) {
                  case 'This month':
                    tempFilterArr.push(this.gbpFiltersDateService.inThisMonth('(SELECT min(localPostUpdatedAt) FROM localpostdetailgmb d WHERE d.post_id = localpostgmb._id)'));
                    break;
                  case 'Last month':
                    tempFilterArr.push(this.gbpFiltersDateService.inLastMonth('(SELECT min(localPostUpdatedAt) FROM localpostdetailgmb d WHERE d.post_id = localpostgmb._id)'));
                    break;
                  default:
                    const dateArray: string[] = element.split(' - ');
                    tempFilterArr.push(`localpostcreatetime >= '${dateArray[0]}' AND localpostcreatetime < '${dateArray[1]}'`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
          }
        }
      }
    }

    if (this.keyword) {
      tempFilterArr.push(`b._id::text ~* '\\y${this.keyword}'`);
      tempFilterArr.push(`b.summary::text ~* '\\y${this.keyword}'`);
      tempFilterArr.push(`b.topicType::text ~* '\\y${this.keyword}'`);
      tempFilterArr.push(`l.storeCode::text ~* '\\y${this.keyword}'`);
      pushQueriesToMainArray();
    }

    let whereClauseArray: Array<any> = ['-and'];
    whereClauseArray = whereClauseArray.concat(filterClauses);
    return whereClauseArray;
  }

}
