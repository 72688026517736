// truncate.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCategory',
})
export class DirectoryFormatCategoryPipe implements PipeTransform {
  transform(value: string): string {
    try {
      return value.split('_').join(' ');
    } catch (e) {
      console.warn(value, e);
      return 'bad category';
    }
  }
}
