<ng-container [formGroup]="offerInfoGroup">
  <div class="details-wrapper">
    <div class="details-row">
      <div class="details-title">
        * Offer Title<br />
        <div class="subtext">(Max 58 chars)</div>
      </div>
      <div class="details-input">
        <input
          class="details-input-field"
          type="text"
          pInputText
          formControlName="offerTitle"
          placeholder="Type Here..."
          maxlength="58"
        />
        <span class="charac_limit" [class.highlight]="offerTitleCharLimit === 58">{{ offerTitleCharLimit }} / 58 </span>
      </div>
    </div>
    <div class="details-row">
      <div class="details-title">Coupon Code</div>
      <div class="details-input">
        <input
          class="details-input-field"
          type="text"
          pInputText
          formControlName="couponCode"
          placeholder="Type Here..."
        />
      </div>
    </div>
    <div class="details-row">
      <div class="details-title">Link to Redeem Offer</div>
      <div class="details-input">
        <div class="details-splitted-row">
          <input
            class="details-splitted-row-field"
            type="text"
            pInputText
            formControlName="linkToRedeemOffer"
            placeholder="Type Here..."
          />
          <button
            *ngIf="!showGbpUtmForm"
            pButton
            (click)="onAddUtmCodes($event)"
            class="details-cta"
            iconPos="right"
            icon="pi pi-angle-down"
          >
            UTM Codes
          </button>
          <button
            *ngIf="showGbpUtmForm"
            pButton
            (click)="onAddUtmCodes($event)"
            class="details-cta"
            iconPos="right"
            icon="pi pi-angle-up"
          >
            UTM Codes
          </button>
        </div>
      </div>
    </div>
    <div class="details-row" *ngIf="showGbpUtmForm">
      <div class="details-title">UTM Codes</div>
      <gbp-utm-form
        class="details-input"
        [ctaURL]="this.linkToRedeemOfferValue"
        (onCtaUrlWithUtmChange)="handleLinkToRedeemOfferValueWithUtmChange($event)"
        [postInfoGroup]="offerInfoGroup"
      ></gbp-utm-form>
    </div>
    <div class="details-row">
      <div class="details-title">Terms and Conditions</div>
      <div class="details-input">
        <input
          class="details-input-field"
          type="text"
          pInputText
          formControlName="termsAndConditions"
          placeholder="Type Here..."
          maxlength="5000"
        />
        <span class="charac_limit" [class.highlight]="termsConditionCharLimit === 5000">{{ termsConditionCharLimit }} / 5000 </span>
      </div>
    </div>
    <!-- <div class="details-row">
      <div class="details-title">Add Button</div>
      <div class="details-input">
        <p-dropdown
          [style]="{ minWidth: '100%' }"
          [options]="buttons"
          appendTo="body"
          formControlName="addButton"
          placeholder="Select Button..."
        >
        </p-dropdown>
      </div>
    </div> -->
  </div>
</ng-container>
