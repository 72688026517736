import { Injectable } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, forkJoin, from, Subject } from "rxjs";
import { SocketService } from "src/app/core/backend-adapter/socket.service";

@Injectable({
  providedIn: 'root'
})

export class AppleMapsInsightsDataService {
  public showReviews: boolean = true;
  public dialogRefComponent: DynamicDialogRef | undefined;
  public a$: Subject<string[]> = new Subject<string[]>();
  public graphData = [];

  private updateFilterState = new BehaviorSubject([]);
  public currentUpdatedFilterState = this.updateFilterState.asObservable();

  private filterOperandType = new BehaviorSubject('-and');
  public currentFilterOperandType = this.filterOperandType.asObservable();

  constructor(private socketService: SocketService,) { }

  getMonthName(month: number): string {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'][month - 1];
  }

  generatetGraphData(currData, prevData?) {
    const labels = [];
    const viewsMaps = [];
    const viewsSrch = [];
    const actionsWeb = [];
    const actionsPhone = [];
    const actionsDirs = [];
    const viewsMapsDesktop = [];
    const viewsMapsMobile = [];
    const viewsSearchDesktop = [];
    const viewsSearchMobile = [];
    const conversations = [];
    const actionsDrivingDirections = [];
    const actionsWebsite = [];
    const bookings = [];
    const foodOrders = [];


    for (const item of currData) {
      if (prevData) {
        // Monthly calls eg: 3 and 6 months.
        const label = this.getMonthName(item.month) + ' ' + item.year.toString();
        labels.push(label);
      } else {
        // Quarterly calls
        const label = 'Q' + item.qtr + ' ' + item.year.toString();
        labels.push(label);
      }

      viewsMaps.push(item.viewsMaps);
      viewsSrch.push(item.viewsSearch);

      actionsWeb.push(item.actionsWebsite);
      actionsPhone.push(item.actionsPhone);
      actionsDirs.push(item.actionsDrivingDirections);

      viewsMapsDesktop.push(item.viewsMapsDesktop);
      viewsMapsMobile.push(item.viewsMapsMobile);
      viewsSearchDesktop.push(item.viewsSearchDesktop);
      viewsSearchMobile.push(item.viewsSearchMobile);
      conversations.push(item?.conversations);
      actionsDrivingDirections.push(item?.actionsDrivingDirections);
      actionsWebsite.push(item?.actionsWebsite);
      bookings.push(item?.bookings);
      foodOrders.push(item?.foodOrders);
    }

    return {
      labels,
      viewsMaps,
      viewsSrch,
      actionsWeb,
      actionsPhone,
      actionsDirs,
      viewsMapsDesktop,
      viewsMapsMobile,
      viewsSearchDesktop,
      viewsSearchMobile,
      conversations,
      actionsDrivingDirections,
      actionsWebsite,
      bookings,
      foodOrders,
    };
  }

  // Zip codes code...
  public getZipCodes() {
    const data = new Array(20).fill({
      city: 'Hoover',
      zipcode: '35244',
      state: 'Alabama',
      score: Math.floor(Math.random() * 100)
    });

    return data;
  };


  public getDialogRef(ref: DynamicDialogRef): void {
    this.dialogRefComponent = ref;
  }

  public setBasicFiltersData() {
    return [
      {
        id: 0,
        label: 'Store code',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter Store code',
        toolTipText: 'Please press \'Enter\' to add this item'
      }
    ];
  };

  public setAdvanceFiltersData(): any[] {
    return [
      {
        id: 1,
        label: 'Business Name',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter Business name',
        toolTipText: 'Please press \'Enter\' to add this item'
      },
      {
        id: 2,
        label: 'City',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter City, State name. Eg:- Montreal,QC',
        toolTipText: 'Please press \'Enter\' to add this item'
      },
      {
        id: 3,
        label: 'State',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter State name',
        toolTipText: 'Please press \'Enter\' to add this item'
      },
      {
        id: 4,
        label: 'Country',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter Country name',
        toolTipText: 'Please press \'Enter\' to add this item'
      },
      {
        id: 5,
        label: 'Tag',
        fieldType: 'checkbox',
        checkboxList: [
          // {label: 'Business', value: 'business'},
          // {label: 'abd', value: 'business'},
          // {label: 'cbd', value: 'business'}
        ]
      },
      {
        id: 6,
        label: 'Google Label',
        fieldType: 'checkbox',
        checkboxList: []
      },
      {
        id: 7,
        label: 'Business Category',
        fieldType: 'checkbox',
        checkboxList: []
      },
      // {
      //   id: 7,
      //   label: 'Google Attributes',
      //   fieldType: 'checkbox',
      //   checkboxList: []
      // }
    ];
  };

  getSelectedFiltersList(list) {
    this.a$.next(list);

  }

  public mapInsightsData(coll) {

    const schlocationtap = [];
    // const schlocationtapcategory = [];
    // const schlocationtapother = [];
    const pcview = [];
    const pctapdirection = [];
    const pctapwebsite = [];
    const pctapcall = [];
    const pctapshare = [];
    const pctapshowcase = [];
    const pcviewmenu = [];
    const pctaporder = [];
    const pcorderdelivery = [];
    const pcorderfood = [];
    const pcordertakeout = [];
    const pcpickup = [];
    const pcreserveparking = [];
    const pcreservetable = [];
    const pcjoinwaitlist = [];
    const pcscheduleappt = [];
    const labels = [];

    if (coll && coll?.length) {
      coll.forEach(item => {
        const label = this.getMonthName(item.month) + ' ' + item.year.toString();
        labels.push(label);
        schlocationtap.push(item.schlocationtap);
        // schlocationtapcategory.push(item.schlocationtapcategory);
        // schlocationtapother.push(item.schlocationtapother);
        pcview.push(item.pcview);
        pctapdirection.push(item.pctapdirection);
        pctapwebsite.push(item.pctapwebsite);
        pctapcall.push(item.pctapcall);
        pctapshare.push(item.pctapshare);
        pctapshowcase.push(item.pctapshowcase);
        pcscheduleappt.push(item.pcscheduleappt);
        pcviewmenu.push(item.pcviewmenu);
        pctaporder.push(item.pctaporder);
        pcorderdelivery.push(item.pcorderdelivery);
        pcorderfood.push(item.pcorderfood);
        pcordertakeout.push(item.pcordertakeout);
        pcpickup.push(item.pcpickup);
        pcreserveparking.push(item.pcreserveparking);
        pcreservetable.push(item.pcreservetable);
        pcjoinwaitlist.push(item.pcjoinwaitlist);
      });
    };

    return {
      schlocationtap,
      // schlocationtapcategory,
      // schlocationtapother,
      pcview,
      pctapdirection,
      pctapwebsite,
      pctapcall,
      pctapshare,
      pctapshowcase,
      pcviewmenu,
      pctaporder,
      pcorderdelivery,
      pcorderfood,
      pcordertakeout,
      pcpickup,
      pcreserveparking,
      pcreservetable,
      pcjoinwaitlist,
      pcscheduleappt,
      labels
    }
  };

  getDynamicFilters(): any {
    const tags$ = from(this.socketService.sendRequest('get-location-tags-and-counts', {}));
    const googleLabels$ = from(this.socketService.sendRequest('get-location-labels-and-counts', {}));
    const businessCategories$ = from(this.socketService.sendRequest('get-location-categories-and-counts', {}));
    // const googleAttributes$ = from(this.socketService.sendRequest('get-location-attributes-and-counts', {}));
    return forkJoin([tags$, googleLabels$, businessCategories$]);
  }

  public setCurrentFilterState(currFilters: any): void {
    this.updateFilterState.next(currFilters);
  }

  public buildWhereClaus(finalFilter: any[] = []): any[] {
    const filterClauses: string[][] = [];
    const tempFilterArr: string[] = [];

    const pushQueriesToMainArray = () => {
      let filterClause: Array<string> = ['-or'];
      filterClause = filterClause.concat(tempFilterArr);
      filterClauses.push(filterClause);
      tempFilterArr.length = 0;
    };

    finalFilter.forEach((fl: { key: string, value: any }) => {
      for (const [key, value] of Object.entries(fl)) {
        switch (key) {
          case 'Business Name': // Business Name
            value.forEach(v => { tempFilterArr.push(`businessName::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'Store code': // Store code
            value.forEach(v => { tempFilterArr.push(`storeCode::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'City': // City
            value.forEach(v => { tempFilterArr.push(`city::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'State': // State
            value.forEach(v => { tempFilterArr.push(`state::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'Country': // Country
            value.forEach(v => { tempFilterArr.push(`country::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'Tag': // Tag
            value.forEach(v => { tempFilterArr.push(`tags::text LIKE '%${v}%'`) })
            pushQueriesToMainArray();
            break;
          case 'Google Label': // Google label
            value.forEach(v => { tempFilterArr.push(`labels::text LIKE '%${v}%'`) })
            pushQueriesToMainArray();
            break;
          case 'Business Category': // Business Category
            value.forEach(v => { tempFilterArr.push(`categories::text LIKE '%${v}%'`) })
            pushQueriesToMainArray();
            break;
        }
      }
    });

    let whereClauseArray: Array<any> = [this.filterOperandType.getValue()];
    whereClauseArray = whereClauseArray.concat(filterClauses);

    return whereClauseArray;
  }

  public setFilterOperandType(operandType: string): void {
    this.filterOperandType.next(operandType);
  }
}
