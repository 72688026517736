import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  THREE_CHART_PALETTE,
  TWO_RED_CHART_PALETTE,
} from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import { IReviewsMetrics } from '../../review-management.interfaces';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
})
export class OverviewCardComponent implements OnChanges {
  @Input() reviewsData: IReviewsMetrics;
  @Output() hide: EventEmitter<boolean> = new EventEmitter();
  @Input() dailyAvgRatings: any;
  @Input() dailyTotalReviews: any;

  private previousWidth: string = '98%';

  public totalReviewsWithComma: string;
  public unrespondedPct: number;
  public respondedPct: number;
  public averageRating: string;
  public percentageLowRating: number;
  public percentageMediumRating: number;
  public percentageHighRating: number;
  public reviewResponses = {
    labels: ['unresponded', 'responded'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: TWO_RED_CHART_PALETTE,
        borderColor: TWO_RED_CHART_PALETTE,
      },
    ],
  };
  public doughnutOptions = {
    layout: {
      padding: 10, // overall padding
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    events: [],
  };
  public ratings = {
    labels: ['good', 'neutral', 'poor'],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: THREE_CHART_PALETTE,
        borderColor: THREE_CHART_PALETTE,
      },
    ],
  };
  public ratingsGraph = [
    {
      label: '5',
      icon: 'pi-star-fill',
      ratingValue: null,
      ratingLabel: 'fiveStarCount',
      responseLabel: 'fiveStarRepliedToCount',
      responseValue: null,
    },
    {
      label: '4',
      icon: 'pi-star-fill',
      ratingValue: null,
      ratingLabel: 'fourStarCount',
      responseLabel: 'fourStarRepliedToCount',
      responseValue: null,
    },
    {
      label: '3',
      icon: 'pi-star-fill',
      ratingValue: null,
      ratingLabel: 'threeStarCount',
      responseLabel: 'threeStarRepliedToCount',
      responseValue: null,
    },
    {
      label: '2',
      icon: 'pi-star-fill',
      ratingValue: null,
      ratingLabel: 'twoStarCount',
      responseLabel: 'twoStarRepliedToCount',
      responseValue: null,
    },
    {
      label: '1',
      icon: 'pi-star-fill',
      ratingValue: null,
      ratingLabel: 'oneStarCount',
      responseLabel: 'oneStarRepliedToCount',
      responseValue: null,
    },
  ];
  public dailyAvgRatingsData: any = {
    labels: [],
    datasets: [
      {
        label: 'Average Rating per Day ',
        data: [],
        fill: false,
        borderColor: '#2EC330',
        tension: 0.4,
      },
    ],
  };
  public dailyReviewsData: any = {
    labels: [],
    datasets: [
      {
        label: 'Total Reviews per Day',
        data: [],
        fill: false,
        borderColor: '#F59B00',
        tension: 0.4,
      },
    ],
  };
  public options: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: 'black',
        },
      },
    },
    scales: {
      x: {
        // padding: '50px',
        ticks: {
          color: 'textColorSecondary',
        },
        grid: {
          padding: '50px',
          color: 'transparent',
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          color: 'textColorSecondary',
        },
        grid: {
          color: 'transparent',
          drawBorder: false,
        },
      },
    },
  };
  public totalReviewsRepliedTo: number;
  public displayCards: boolean = true;
  public width: string = '100%';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue } = changes.reviewsData;
    if (currentValue && Object.keys(currentValue).length) {
      this.totalReviewsWithComma = currentValue.totalReviews.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.averageRating = currentValue.avgStarRating;
      this.percentageLowRating = parseFloat(
        ((currentValue.lowStarsCount / currentValue.totalReviews) * 100).toFixed(1)
      );
      this.percentageMediumRating = parseFloat(
        ((currentValue.medStarsCount / currentValue.totalReviews) * 100).toFixed(1)
      );
      this.percentageHighRating = parseFloat(
        ((currentValue.highStarsCount / currentValue.totalReviews) * 100).toFixed(1)
      );
      this.respondedPct = parseFloat(((currentValue.totalRepliedTo / currentValue.totalReviews) * 100).toFixed(1));
      this.totalReviewsRepliedTo = currentValue.totalRepliedTo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.unrespondedPct = +(100 - this.respondedPct).toFixed(1);
      this.calcReviewResponses();
      this.calcRatings();
      this.calcRatingsPercent(currentValue);
    };

    this.generateLineGraphData(changes);
  }

  /**
   * @description Calculate unresponded and responded review responses for the doughnut chart.
   * @returns void
   */
  private calcReviewResponses(): void {
    this.reviewResponses = {
      labels: ['unresponded', 'responded'],
      datasets: [
        {
          data: [this.unrespondedPct, this.respondedPct],
          backgroundColor: TWO_RED_CHART_PALETTE,
          borderColor: TWO_RED_CHART_PALETTE,
        },
      ],
    };
  }

  /**
   * @description Calculate the ratings for the doughnut chart Good, Neutral, Poor.
   * @returns void
   */
  private calcRatings(): void {
    this.ratings = {
      labels: ['good', 'neutral', 'poor'],
      datasets: [
        {
          data: [this.percentageLowRating, this.percentageMediumRating, this.percentageHighRating],
          backgroundColor: THREE_CHART_PALETTE,
          borderColor: THREE_CHART_PALETTE,
        },
      ],
    };
  }

  private calcRatingsPercent(currentValue): void {
    Object.keys(currentValue).forEach((key, i) => {
      this.ratingsGraph.forEach((rating) => {
        if (key === rating.ratingLabel) {
          rating.ratingValue =
            (currentValue[key] && ((currentValue[key] / currentValue.totalReviews) * 100).toFixed()) || 0;
        }

        if (key === rating.responseLabel) {
          rating.responseValue =
            (currentValue[rating.responseLabel] &&
              ((currentValue[rating.responseLabel] / currentValue[rating.ratingLabel]) * 100).toFixed()) ||
            0;
        }
      });
    });
  }

  public hideOverview(): void {
    this.hide.emit(true);
    this.displayCards = !this.displayCards;
  }

  private resetLineGraphs(): void {
    if (this.dailyAvgRatingsData.labels.length || this.dailyAvgRatingsData.datasets[0].data.length) {
      this.dailyAvgRatingsData.labels = [];
      this.dailyAvgRatingsData.datasets[0].data = [];
    }

    if (this.dailyReviewsData.labels.length || this.dailyReviewsData.datasets[0].data.length) {
      this.dailyReviewsData.labels = [];
      this.dailyReviewsData.datasets[0].data = [];
    }
  }

  private generateLineGraphData(changes): any {
    const { dailyAvgRatings, dailyTotalReviews } = changes;
    if (
      dailyAvgRatings?.currentValue && dailyAvgRatings.currentValue?.length !==
      dailyAvgRatings?.previousValue && dailyAvgRatings.previousValue?.length
    ) {
      let randomNum = (100 - Math.floor(Math.random() * 2));
      this.width = this.width === `${randomNum}%` ? this.previousWidth : `${randomNum}%`;
    }

    this.resetLineGraphs();

    if(this.dailyAvgRatings && this.dailyAvgRatings?.length) {
      this.dailyAvgRatings.forEach(rating => {
        this.dailyAvgRatingsData.labels.push(rating.date);
        this.dailyAvgRatingsData.datasets[0].data.push(rating.value);
      })
    };

    if(this.dailyTotalReviews && this.dailyTotalReviews?.length) {
      this.dailyTotalReviews.forEach(review => {
        this.dailyReviewsData.labels.push(review.date);
        this.dailyReviewsData.datasets[0].data.push(review.value);
      })
    };
  };

}
