<form ngNoForm name="export" id="export-form">
  <div class="export-dropdown">
    <p-dropdown
      [options]="downloadTypes"
      [(ngModel)]="downloadTypeField"
      [optionLabel]="'label'"
      [optionValue]="'value'"
      appendTo="body"
    ></p-dropdown>

    <button
      class="ttu m-l-0 w-20"
      style="width: 240px"
      type="button"
      pButton
      [disabled]="loading"
      [icon]="loading ? 'pi pi-spin pi-spinner' : ''"
      [label]="loading ? '' : 'Export'"
      (click)="export($event)"
    ></button>
  </div>
  <p-checkbox label="Include sub-locations" binary="true" (onChange)="selectSublocationsInQuery($event)"></p-checkbox>
</form>
