import { RsThemeConfigColors } from '../../theme-config.interfaces';
import { lightenDarkenColor } from '../colors.helper';
import { _PALETTE } from './colors.palette';

const toRGB = (color: string) => {
  return `rgb(${color})`;
};

export const COMPUTED_COLORS = (colors: RsThemeConfigColors): string =>
  `
  :root {
    /* project colors */
    --dark-background-color: ${_PALETTE.$darkGray.rgb.join()}; /* charcoal for menus */ /*#f4f4f4;*/
    --light-background-color: ${_PALETTE.$lightGray.rgb.join()}; /* should be just RAW numbers for rgba ability */
    --green-color: ${_PALETTE.$mainGreen.rgb.join()};
    --red-color: ${_PALETTE.$red.rgb.join()};

    /* main colors */
    --button-color:${colors.button}; /* should be just RAW numbers for rgba ability */
    --primary-color:${colors.primary}; /* should be just RAW numbers for rgba ability */
    --secondary-color: ${colors.secondary}; /* should be just RAW numbers for rgba ability */

    /* NOT USED AROUND THE PROJECT */
    /* --primary-color-text: #ffffff; */
    /* --surface-a:#ffffff;*/
    /* --surface-b:#f8f9fa;*/
    /* --surface-c:#e9ecef;*/
    /* --surface-d:#dee2e6;*/
    /* --surface-e:#ffffff;*/
    /* --surface-f:#ffffff;*/
    /* --text-color: #414042;*/
    /* --text-color-secondary: #A5A1A1;*/
  }

  .p-button {
    color: #ffffff;
    background: ${toRGB(colors.button)};
    border: 1px solid ${toRGB(colors.button)};
  }

  .p-button.p-button-outlined, .p-button.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: ${toRGB(colors.button)};
    border: 1px solid ${toRGB(colors.button)};
  }

  .p-button:enabled:hover {
    color: #ffffff;
    background: ${lightenDarkenColor(colors.button, -10)};
    border-color: ${lightenDarkenColor(colors.button, -10)};
  }

  .c-primary {
    color: ${toRGB(colors.primary)}
  }

  .c-secondary {
    color: ${toRGB(colors.secondary)}
  }

  .c-primary-l-10 {
    color: ${lightenDarkenColor(colors.primary, 10)};
  }

  .c-primary-d-10 {
    color: ${lightenDarkenColor(colors.primary, -10)};
  }

` +
  iterateColorClasses('c', colors.primary, 'primary', 'lighten') +
  iterateColorClasses('c', colors.primary, 'primary', 'darken') +
  iterateColorClasses('c', colors.secondary, 'secondary', 'lighten') +
  iterateColorClasses('c', colors.secondary, 'secondary', 'darken') +
  //
  iterateColorClasses('bgc', colors.primary, 'primary', 'lighten') +
  iterateColorClasses('bgc', colors.primary, 'primary', 'darken') +
  iterateColorClasses('bgc', colors.secondary, 'secondary', 'lighten') +
  iterateColorClasses('bgc', colors.secondary, 'secondary', 'darken');

const iterateColorClasses = (classPrefix: string, color: string, type: string, action: 'darken' | 'lighten') => {
  let str = '';

  for (let i = 10; i <= 100; i += 10) {
    let index = i;
    index = action === 'lighten' ? index : -index; // negative = darken. positive - lighten
    str += `
    .${classPrefix}-${type}-${action.slice(0, 1)}-${+i} {
      ${classPrefix === 'bgc' ? 'background-' : ''}color: ${lightenDarkenColor(color, index)};
    }

    `;
  }
  return str;
};
