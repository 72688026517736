import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private subjectsCollection: any = {};

  constructor() {}

  getSubject(subjectName: string): BehaviorSubject<any> {
    let subj = this.subjectsCollection[subjectName];
    if (!subj) {
      subj = this.subjectsCollection[subjectName] = new BehaviorSubject<any>(undefined);
    }
    return subj;
  }

  public subscribe(ident: string, ...callbacks): Subscription {
    const subj = this.getSubject(ident);
    const sub = subj
      .asObservable()
      .pipe(filter((v) => v !== undefined))
      .subscribe(...callbacks);
    return sub;
  }

  public update(subjectName: string, payload: any) {
    // tslint:disable-next-line: quotemark
    console.log(`Broadcasting update '%s': %j`, subjectName, payload);
    const subj = this.getSubject(subjectName);
    subj.next(payload);
  }
}
