import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { GbpQaAddLocationComponent } from 'src/app/gbp-management/gbp-tab-qa/components/gbp-qa-add-location/gbp-qa-add-location.component';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
})
export class LocationsListComponent implements OnInit {
  @Input() tableData;
  @Input() addLocation;
  @Output() searchKeyword: EventEmitter<string> = new EventEmitter<string>(null);
  keyword: string = '';
  constructor(public ref: DynamicDialogRef, public dialogService: DialogService) {}

  ngOnInit(): void {}

  openAddLocation() {
    this.ref.close();
    this.dialogService.open(GbpQaAddLocationComponent, {
      width: '50%',
      data: {
        locations: [...this.tableData],
        openPopup: true,
      },
    });
  }

  lazyLoadLocations(event) {
    this.searchKeyword.emit(event);
  }
}
