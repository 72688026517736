import {RsLogos} from '../theme-config.interfaces';

export const COMPUTED_LOGOS = (logos: RsLogos): string => {
  return `
  :root {
    /* logos */
    --large-logo:url('${logos.large}'); /* should be 200px-height PNG */
    --square-logo:url('${logos.square}'); /* should be 100px square PNG */
  }

  .login-logo {
    background-image: url('${logos.large}');
    height: 120px;
    position: relative;
    top: -60px;
    left: 100px;
    max-width: 400px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    border-radius: 10px;
    padding: 5px;
  }

`;
};
