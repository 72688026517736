import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieService {
  public COOKIE_ID_CONSTANT = 'auth-id';

  // abstract child service for localStorage service without any state.
  // if it will be not work - use next solution
  // https://stackoverflow.com/a/34308089/9026103
  constructor() {}

  set(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  get(name: string): string | null {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      // tslint:disable-next-line:triple-equals
      // tslint:disable-next-line: curly
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      // tslint:disable-next-line:triple-equals
      // tslint:disable-next-line: curly
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  delete(name: string): void {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  setAuthCookie(authId: string): void {
    if (authId) {
      document.cookie = this.COOKIE_ID_CONSTANT + '=' + authId + '; path=/';
    } else {
      document.cookie = this.COOKIE_ID_CONSTANT + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
  }
}
