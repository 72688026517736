<div class="loc-change">
  <div class="date-range">
    <p-dropdown
      [options]="dateRanges"
      [(ngModel)]="selectedRange"
      optionLabel="name"
      optionValue="code"
      (onChange)="dateRangeChanged($event)">
    </p-dropdown>
    <p-calendar
      class="month-picker"
      [(ngModel)]="customDate"
      view="month"
      dateFormat="mm/yy"
      [readonlyInput]="true"
      [showIcon]="true">
    </p-calendar>
  </div>

  <p-table
    #dt2
    id="loginsTable"
    [value]="data"
    [scrollable]="true"
    [resizableColumns]="true"
    [globalFilterFields]="['storecode', 'updatedat', 'updatedby', 'fieldname']"
    selectionMode="single"
    sortMode="multiple"
    [paginator]="true"
    >

    <ng-template pTemplate="caption">
      <div class="search">
        <input class="filter_logins" pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
          placeholder="Search keyword" />
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of tableHeaderData" pSortableColumn="{{col.enableSorting ? col.sortingLable : null}}"
          class="{{ col.className }}">
          {{col.label}}
          <p-sortIcon *ngIf="col.enableSorting" field="col.fieldName"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <!-- <ng-template pTemplate="body" let-login>
    <tr [pSelectableRow]="login" (dblclick)="editLogin(login, 'editUser')">
      <td pResizableColumn class="db-id">{{ login._id }}</td>
      <td pResizableColumn class="login-id">{{ login.loginId }}</td>
      <td pResizableColumn class="password">
        {{ login.password || 'N/A' }}
      </td>
      <td pResizableColumn class="pwd-reset">
        {{ login.lastPwReset || 'Never' }}
      </td>
      <td pResizableColumn class="note">{{ login.note }}</td>
      <td pResizableColumn class="account">
        {{ login.account || 'NONE' }}
      </td>
      <td pResizableColumn class="auth-types">
        {{ authTypeList(login.authTypes) || 'N/A' }}
      </td>
      <td pResizableColumn class="tags">
        {{ login.tags || 'N/A' }}
      </td>
      <td pResizableColumn class="status">
        {{ statuses[login._status]?.name }}
      </td>
    </tr>
  </ng-template> -->

  </p-table>

  <div>
    <p>No data available.</p>
  </div>

</div>
