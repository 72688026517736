<!-- @format -->

<h4 *ngIf="loading">Loading, please wait</h4>

<div class="main-container" *ngIf="parties && parties.length > 0; else noSubs">
  <p-table
    [value]="parties"
    selectionMode="single"
    [(selection)]="selectedParty"
    dataKey="_id"
    [scrollable]="true"
    [resizableColumns]="true"
    [style]="{ width: tableWidth }"
    scrollHeight="tableHeight"
  >
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col class="name" style="width: 250px" />
        <col class="ident" style="width: 190px" />
        <col class="status" style="width: 70px" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="name" pResizableColumn><span>Account Name</span></th>
        <th class="ident" pResizableColumn>API Ident</th>
        <th class="status">Status</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr
        [pSelectableRow]="rowData"
        (click)="selectParty(rowData)"
        (dblclick)="editParty(rowData)"
      >
        <td class="name">{{ rowData.name }}</td>
        <td class="ident">{{ rowData.ident }}</td>
        <td
          class="status"
          [pTooltip]="buildTooltipText(rowData)"
          tooltipPosition="top"
        >
          {{ rowData._status }}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <button
        pButton
        (click)="addParty()"
        label="Add"
        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only p-button-success"
      ></button>
      <button
        pButton
        [disabled]="!selectedParty"
        (click)="getAndEdit(selectedParty)"
        label="Edit"
      ></button>
    </ng-template>
  </p-table>
</div>
<ng-template #noSubs>
  <div class="no-subaccounts">
    <h4 *ngIf="!loading">No subaccounts to load</h4>
    <button
      pButton
      (click)="addParty()"
      label="Add"
      class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only p-button-success"
    ></button>
  </div>
</ng-template>

<p-dialog
  *ngIf="showDialog"
  [(visible)]="showDialog"
  header="{{ subAccCopy.name }} || Subaccount of {{ account.name }}"
  [modal]="true"
>
  <div
    *ngIf="haveErr"
    style="
      background-color: #f8f8f8;
      border: 1px solid #dddddd;
      padding: 10px;
      margin-bottom: 10px;
    "
  >
    <h3 class="error">
      Error saving, please contact dev department. <br />
      {{ message }}
    </h3>
  </div>
  <div
    style="background-color: #f8f8f8; border: 1px solid #dddddd; padding: 10px"
  >
    <span class="p-float-label">
      <input
        autofocus
        id="acct-name"
        type="text"
        pInputText
        [(ngModel)]="subAccCopy.name"
        style="width: 300px"
      />
      <label for="acct-name">Account Name</label>
    </span>

    <span class="inline-input-label">
      <label>Account Prefix</label>
      <input
        id="acct-prefix"
        type="text"
        pInputText
        [(ngModel)]="subAccCopy?.properties.storeCodePrefix"
        style="width: 90px"
      />
    </span>

    <span class="inline-input-label">
      <label>Status</label>
      <p-dropdown
        [(ngModel)]="subAccCopy._status"
        [options]="dbStatusTypes"
        optionValue="code"
        optionLabel="name"
        appendTo="body"
      ></p-dropdown>
    </span>

    <div style="height: 10px"></div>

    <span class="p-float-label">
      <input
        id="acct-ident"
        type="text"
        pInputText
        [(ngModel)]="subAccCopy.ident"
        style="width: 155px"
      />
      <label for="acct-ident">API Account Identifier</label>
    </span>
  </div>

  <div style="height: 20px"></div>

  <div
    id="accountTabs"
    style="background-color: #f8f8f8; border: 1px solid #dddddd; padding: 10px"
  >
    <!-- Categories Tab Panel -->
    <div>
      <h3>Categories</h3>
      <div style="height: 10px"></div>

      <!-- Apple Maps -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['appleMaps.publish']"
            binary="true"
            label="Apple Maps"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Apple Maps Categories</label>
          <!--<p-chips inputId="loc-categories" [(ngModel)]="flatGlobal['appleMaps.categories']" separator=","></p-chips>-->
          <p-autoComplete
            [(ngModel)]="flatGlobal['appleMaps.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'appleMaps')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Factual -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['factual.publish']"
            binary="true"
            label="Factual"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Chain ID/Name</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="flatGlobal['factual.chainId']"
            style="width: 320px"
          />
        </span>

        <span class="input-label">
          <label>Factual Categories (up to 3)</label>
          <!--<p-chips inputId="loc-categories" [(ngModel)]="flatGlobal['factual.categories']" separator=","></p-chips>-->
          <p-autoComplete
            [(ngModel)]="flatGlobal['factual.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'factual')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Bing -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['bing.publish']"
            binary="true"
            label="Bing"
          ></p-checkbox>
        </legend>

        <span class="inline-input-label">
          <label>Registered Chain Name</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="flatGlobal['bing.chainId']"
            style="width: 320px"
          />
        </span>

        <span style="font-size: 110%">or&nbsp;&nbsp;</span>

        <span class="inline-input-label">
          <label>Verification Email</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="flatGlobal['bing.verificationEmail']"
            style="width: 320px"
          />
        </span>

        <span class="input-label">
          <label>Bing Categories (up tp 10)</label>
          <!--<p-chips inputId="loc-categories" [(ngModel)]="flatGlobal['bing.categories']" separator=","></p-chips>-->
          <p-autoComplete
            [(ngModel)]="flatGlobal['bing.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'bing')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Yelp -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['yelp.publish']"
            binary="true"
            label="Yelp"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Yelp Categories</label>
          <p-autoComplete
            [(ngModel)]="flatGlobal['yelp.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'yelp')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Nextdoor -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['nextdoor.publish']"
            binary="true"
            label="Nextdoor"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Nextdoor Categories</label>
          <!--<p-chips inputId="loc-categories" [(ngModel)]="flatGlobal['nextdoor.categories']" separator=","></p-chips>-->
          <p-autoComplete
            [(ngModel)]="flatGlobal['nextdoor.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'nextdoor')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- TomTom -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['tomtom.publish']"
            binary="true"
            label="TomTom"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>TomTom Categories</label>
          <p-autoComplete
            [(ngModel)]="flatGlobal['tomtom.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'tomtom')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Infogroup -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['infogroup.publish']"
            binary="true"
            label="Infogroup"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Infogroup Categories (up to 5)</label>
          <p-autoComplete
            [(ngModel)]="flatGlobal['infogroup.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'infogroup')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Localeze -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['localeze.publish']"
            binary="true"
            label="Localeze"
          ></p-checkbox>
        </legend>

        <a
          href="https://www.neustarlocaleze.biz/manage/#/category"
          target="_blank"
          >Localeze Category Selection</a
        >

        <span class="input-label">
          <label>Account Categories</label>
          <p-chips
            [(ngModel)]="flatGlobal['localeze.categories']"
            separator=","
          ></p-chips>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Here -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['here.publish']"
            binary="true"
            label="Here"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Account Categories</label>
          <p-chips
            [(ngModel)]="flatGlobal['here.categories']"
            separator=","
          ></p-chips>
        </span>
      </fieldset>

      <div style="height: 10px"></div>

      <!-- Hotfrog -->
      <!-- <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['hotfrog.publish']"
            binary="true"
            label="Hotfrog"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Hotfrog Categories</label>
          <p-autoComplete
            [(ngModel)]="flatGlobal['hotfrog.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'hotfrog')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset> -->

      <!-- <div style="height: 10px"></div> -->

      <!-- Pandio -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['pandio.publish']"
            binary="true"
            label="Pandio"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Pandio Categories</label>
          <p-autoComplete
            [(ngModel)]="flatGlobal['pandio.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'pandio')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>

      <!-- Amazon Alexa -->
      <fieldset style="border: 1px solid black">
        <legend>
          <p-checkbox
            class="for-fieldset"
            [(ngModel)]="flatGlobal['amazonalexa.publish']"
            binary="true"
            label="Amazon Alexa"
          ></p-checkbox>
        </legend>

        <span class="input-label">
          <label>Amazon Alexa Categories</label>
          <p-autoComplete
            [(ngModel)]="flatGlobal['amazonalexa.categories']"
            [suggestions]="categoryMatches"
            (completeMethod)="searchCats($event, 'amazonalexa')"
            [multiple]="true"
            [dropdown]="true"
            field="name"
            appendTo="body"
            scrollHeight="400px"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>
        </span>
      </fieldset>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      icon="pi pi-times"
      (click)="showDialog = false"
      label="Cancel"
      class="p-button-secondary p-button-outlined"
    ></button>
    <button
      pButton
      icon="pi pi-check"
      (click)="saveParty()"
      label="Save"
      class=""
    ></button>
  </ng-template>
</p-dialog>
