import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-rs-table-behaviors',
  template: `<p-progressBar [ngStyle]="{ opacity: searching ? 1 : 0 }" mode="indeterminate"></p-progressBar>
    <div class="p-table-no-items" *ngIf="items?.length === 0"><span class="fz-18 p-20">No results found</span></div>
    <div class="p-table-no-items" *ngIf="!items">
      <app-rs-spinner></app-rs-spinner>
    </div>`,
  styles: [
    `
      .p-table-no-items {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsTableBehaviorsComponent {
  @Input() searching: boolean;
  @Input() items: any[];
}
