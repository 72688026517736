import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';
import { ProgressComponent } from 'src/app/core/feature-modules/progress/progress.component';
import { GBPService } from 'src/app/gbp-management/gbp.service';
import { GBPPhotoConfigService } from 'src/app/gbp-management/services/gbp-photo-config-service/gbp-photo-config-service';
import { SuccessComponent } from '../success/success.component';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';

export interface IEventSaveObject {
  account_id: number;
  summary: string;
  languageCode: string;
  topicType: string;
  sourceURL: string;
  location_ids: any[] | string;
  scheduleTime: any;
  eventStartDate: any;
  eventEndDate: any;
  eventTitle: string;
  scheduleTimeZone: number;
  ctaActionType: string;
  eventStartTime?: any;
  eventEndTime?: any;
  ctaURL: string;
  _status: string;
  _id?: number;
}

@Component({
  selector: 'app-event-post',
  templateUrl: './event-post.component.html',
  styleUrls: ['./event-post.component.scss'],
})
export class EventPostComponent implements OnInit {
  @Input() rowData;

  stepsLabels = ['Add Photo', 'Event Info', 'Schedule and Select Location', 'Review and Submit'];
  activeIndex = 0;
  eventForm = new FormGroup({
    upload: new FormGroup({
      imageUrl: new FormControl(''),
    }),
    postDetails: new FormGroup({
      postDescription: new FormControl('', Validators.required),
      isAddTime: new FormControl(false),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      startTime: new FormControl(''),
      endTime: new FormControl(''),
      title: new FormControl('', Validators.required),
      addButton: new FormControl(''),
      ctaURL: new FormControl(
        '',
        Validators.pattern(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]{}@!\$&'\(\)\*\+,;=]*(\{\{[A-Za-z0-9.]+\}\}|[\w\-\._~:/?#[\]{}@!\$&'\(\)\*\+,;=])*\/?$/
        )
      ),
      languageCode: new FormControl('', Validators.required),
    }),
    schedule: new FormGroup({
      checked: new FormControl(false),
      date: new FormControl(''),
      time: new FormControl(''),
      timeZone: new FormControl(''),
      location: new FormControl([]),
      isSelectedAll: new FormControl(''),
    }),
  });
  uploadGroup: FormGroup;
  postInfoGroup: FormGroup;
  scheduleGroup: FormGroup;
  formGroupName = 'upload';
  saveObj;
  closeGbpUtmFormEvent = new EventEmitter<void>();
  preSelectedLocation: any[] = [];

  /**
   * @description To display validations rules to upload an Image.
   */
  public photoRequirements: string[] = [];
  /**
   * @description Validation rules Title.
   */
  public photoRequirementsTitle: string;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private gbpService: GBPService,
    private gbpPhotoConfigService: GBPPhotoConfigService,
    private socketService: SocketService,
  ) {}

  ngOnInit(): void {
    this.postInfoGroup = this.eventForm.controls.postDetails as FormGroup;
    this.scheduleGroup = this.eventForm.controls.schedule as FormGroup;
    this.uploadGroup = this.eventForm.controls.upload as FormGroup;
    this.setPhotoValidationRules();

    if (this.rowData) {
      this.setInitialFormValues();
    }
  }

  /**
   * @description Method set's validation rules on Add Photo screen for Media type PHOTO.
   * @returns void
   */
  private setPhotoValidationRules(): void {
    this.photoRequirements = this.gbpPhotoConfigService.setGBPMediaRequirements('PHOTO').description;
    this.photoRequirementsTitle = this.gbpPhotoConfigService.setGBPMediaRequirements('PHOTO').title;
  }

  private setInitialFormValues() {
    this.uploadGroup.setValue({
      imageUrl: this.rowData?.internalURL || this.rowData?.sourceURL,
    });

    const today = new Date();

    this.postInfoGroup.patchValue({
      postDescription: this.rowData?.summary,
      isAddTime: this.rowData?.eventStartTime ? true : false,
      startDate: new Date(this.rowData?.eventStartDate.toString()),
      endDate: new Date(this.rowData?.eventEndDate.toString()),
      startTime: this.rowData?.eventStartTime
        ? new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            this.rowData.eventStartTime.hour,
            this.rowData.eventStartTime.minute,
            0
          )
        : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
      endTime: this.rowData?.eventEndTime
        ? new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            this.rowData.eventEndTime.hour,
            this.rowData.eventEndTime.minute,
            0
          )
        : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
      title: this.rowData?.eventTitle,
      addButton: this.rowData?.ctaActionType || '',
      ctaURL: this.rowData?.ctaURL,
      languageCode: this.gbpService.preparePostLanguage(this.rowData?.languageCode),
    });

    // Set schedule Date, Time and Timezone.
    const scheduledTime = this.rowData.scheduleTime !== null ?
      (new Date(new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), this.rowData.scheduleTime?._hour_, this.rowData.scheduleTime?._minute_, this.rowData.scheduleTime?._second_)) : '';
    const scheduledDate = this.rowData.scheduleTime !== null ?
      (new Date(`${this.rowData.scheduleTime?._month_}/${this.rowData.scheduleTime?._day_}/${this.rowData.scheduleTime?._year_}`)) : '';
    this.scheduleGroup.patchValue({
      date: scheduledDate,
      time: scheduledTime,
      timeZone: this.rowData?.scheduleTimeZone,
    });

    // Add a new control if Post status === A (Active).
    if (this.rowData._status === 'A') {
      this.scheduleGroup.addControl('postStatus', new FormControl(true));
    }


    this.socketService.sendRequest('get-localpost-detail-coll', { 'detailLevel': 3, 'where': ['-and', `post_id=${this.rowData._id}`] })
    .then(res => {
      res['collection'].forEach(l => {
        this.preSelectedLocation.push({
          _id: l.location_id,
          storeCode: l.storeCode,
          businessName: l.businessName,
          city: l.city,
          state: l.state,
          disabled: true
        });
      });

      // Added a new control to store pre-selected locations.
      this.scheduleGroup.addControl('preSelectedLocation', new FormControl(
        [...this.preSelectedLocation]
      ));

    })

  }

  private prepareEventSaveObject(postDetailsGroup, scheduleGroup, uploadGroup) {
    const tempObj: IEventSaveObject = {
      account_id: 0,
      summary: '',
      languageCode: '',
      topicType: '',
      sourceURL: '',
      location_ids: [],
      scheduleTime: new RZLib.Timestamp().setNow(),
      eventStartDate: new RZLib.Timestamp().setNow(),
      eventEndDate: new RZLib.Timestamp().setNow(),
      eventTitle: '',
      scheduleTimeZone: 0,
      ctaActionType: '',
      ctaURL: '',
      _status: 'AP',
    };

    tempObj.account_id = this.gbpService.account_id;
    tempObj.summary = postDetailsGroup.postDescription;
    tempObj.languageCode = postDetailsGroup.languageCode;
    tempObj.topicType = 'EVENT';
    const urlFieldName = new RegExp(/^https:\/\/rseo-platform(-dev)?.s3.amazonaws.com\/media\/.+/i).test(
      uploadGroup.imageUrl
    )
      ? 'internalURL'
      : 'sourceURL';
    tempObj[urlFieldName] = uploadGroup.imageUrl;

    tempObj.eventStartDate = this.gbpService.prepareDate(postDetailsGroup.startDate, tempObj.eventStartDate);
    tempObj.eventEndDate = this.gbpService.prepareDate(postDetailsGroup.endDate, tempObj.eventEndDate);

    tempObj.eventTitle = postDetailsGroup.title;
    tempObj.ctaActionType = postDetailsGroup.addButton.toUpperCase();
    tempObj.ctaURL = postDetailsGroup.ctaURL || '';

    tempObj.location_ids = scheduleGroup.isSelectedAll
      ? '*'
      : scheduleGroup.location?.length > 0
      ? this.gbpService.mapAllLocations(scheduleGroup.location, this.preSelectedLocation)
      : null;
    tempObj.scheduleTime = scheduleGroup.checked ? this.gbpService.prepareDate(scheduleGroup.date, tempObj.scheduleTime, scheduleGroup.time) : '';
    tempObj.scheduleTimeZone = scheduleGroup.checked ? scheduleGroup.timeZone : null;

    if (postDetailsGroup.startTime && postDetailsGroup.endTime) {
      tempObj.eventStartTime = new RZLib.Timestamp().setNow();
      tempObj.eventEndTime = new RZLib.Timestamp().setNow();
      tempObj.eventStartTime = this.gbpService.prepareTime(tempObj.eventStartTime, postDetailsGroup.startTime);
      tempObj.eventEndTime = this.gbpService.prepareTime(tempObj.eventEndTime, postDetailsGroup.endTime);
    }

    // Edit mode
    if (this.rowData) {
      tempObj._id = this.rowData._id;
    }

    return tempObj;
  }

  public goNext(progress: ProgressComponent): void {
    if (this.closeGbpUtmFormEvent) this.closeGbpUtmFormEvent.emit();
    progress.next();
  }

  onStateChange(event) {
    if (event.status == 'completed') {
      // send data to backend
      this.saveObj = this.prepareEventSaveObject(
        this.postInfoGroup.value,
        this.scheduleGroup.value,
        this.uploadGroup.value
      );

      this.gbpService.updateLocalPost(this.saveObj);
      this.ref.close();
      this.dialogService.open(SuccessComponent, {
        width: '20%',
      });
    }
    this.activeIndex = event.activeIndex;
    switch (this.activeIndex) {
      case 0: {
        this.formGroupName = 'upload';
        break;
      }
      case 1: {
        this.formGroupName = 'postDetails';
        break;
      }
      case 2: {
        this.formGroupName = 'schedule';
        break;
      }
    }
  }

  closeDialog() {
    this.ref.close();
  }

  imageChanged(url) {
    this.eventForm.patchValue({ upload: { imageUrl: url[0] } });
  }

  public get checkDateAndLocStatus(): boolean {
    const isChecked = this.scheduleGroup.controls;
    let dateSelection: boolean;
    if (this.formGroupName === 'schedule') {
      if (!isChecked['checked'].value) {
        return (dateSelection =
          !isChecked['checked'].value && (isChecked['location'].value.length || isChecked['isSelectedAll'].value || isChecked['preSelectedLocation']?.value?.length)
            ? false
            : true);
      } else {
        return (dateSelection =
          isChecked['date'].value &&
          isChecked['time'].value &&
          isChecked['timeZone'].value &&
          (isChecked['location'].value.length || isChecked['isSelectedAll'].value || isChecked['preSelectedLocation']?.value?.length)
            ? false
            : true);
      }
    } else {
      false;
    }
  }

  public get checkAddButtonStatus(): boolean {
    let status: boolean = false;
    if (this.postInfoGroup.controls['addButton'].value && this.postInfoGroup.controls['addButton'].value !== 'CALL') {
      if (!this.postInfoGroup.controls['ctaURL'].value) {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  }

}
