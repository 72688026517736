<div class="utm-form-container">
  <!-- Wrapper for left and right columns -->
  <div class="columns-wrapper">
    <!-- Left column for the form fields -->
    <div class="left-column">
      <div *ngFor="let field of campaignFields; let i = index" class="form-row">
        <div class="flex-container">
          <div class="label-container">
            <label [for]="field.id" class="label flex-item">{{ field.label }}</label>
          </div>
          <div class="input-container">
            <input
              type="text"
              [id]="field.id"
              class="input-field flex-item"
              [(ngModel)]="field.value"
              (ngModelChange)="onFieldChange(field.id, $event)"
              placeholder="{{ campaignFieldsDefaults[field.id] }}"
              maxlength="50"
              [required]="
                (providerName == 'default' && field.isRequired) ||
                (providerName != 'default' && campaignFieldsDefaults[field.id] == '')
              "
            />
            <span
              *ngIf="
                (providerName == 'default' && field.isRequired && !field.value) ||
                (providerName != 'default' &&
                  field.isRequired &&
                  campaignFieldsDefaults[field.id] == '' &&
                  !field.value)
              "
              >This field is required.</span
            >
          </div>
          <div class="icon-container">
            <div *ngIf="field.fieldChooser" class="db-icon-container flex-item">
              <img src="assets/database-2.svg" class="db-icon" (click)="openFieldChooser(field.id)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right column for the UTM code link preview -->
    <div class="right-column">
      <div class="preview-container">
        <p *ngIf="providerName == 'default'">Default UTM Code Link:</p>
        <p *ngIf="providerName != 'default'">UTM Code Link for: {{ providerLabel }}</p>
        <!-- Dynamically add UTM preview links -->
        <!-- for each preview location, we combine the websiteurl and the corresponding provider utm link-->
        <div *ngIf="!sampleUtmLinks || (sampleUtmLinks | objectKeysCount) == 0" class="spinner-container">
          <br />
          <p>Enter codes in the left columns to see link previews...</p>
        </div>
        <div *ngFor="let link of sampleUtmLinks" class="preview-row">
          <h3>Store code: {{ link.storeCode }}</h3>
          <div class="preview-link">
            <a [href]="link.url" target="_blank" rel="noopener noreferrer">{{ link.url }}</a>
          </div>
          <br />
        </div>
      </div>
    </div>

    <!-- Modal dialog component -->
    <app-modal-dialog
      [(showUtmFieldChooser)]="showUtmFieldChooser"
      (closeModal)="showUtmFieldChooser = false"
      [(providerName)]="providerName"
      [campaignField]="selectedFieldId"
    ></app-modal-dialog>
  </div>
  <!-- Buttons below the columns -->
  <div class="button-container">
    <button
      [ngClass]="canSave() ? 'enabled-button save-preview-btn' : 'disabled-button save-preview-btn'"
      (click)="saveCampaign()"
      [disabled]="!canSave()"
    >
      Save
    </button>
  </div>
</div>
