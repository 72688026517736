import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { IDirectory, IDirectoryCategory } from '../../directory.interfaces';
import { DirectoryService } from '../../directory.service';

@Component({
  selector: 'app-directory-card-edit',
  templateUrl: './directory-card-edit.component.html',
  styleUrls: ['./directory-card-edit.component.scss'],
})
export class DirectoryCardEditComponent implements OnInit {
  @Input() d: IDirectory;
  @Output() cancelEdit = new EventEmitter<void>();
  @Output() saveEvent = new EventEmitter<IDirectory>();
  selectedCategory: IDirectoryCategory;
  categoriesSlots: any[] = new Array(6);
  categoryMatches: any[] = [];
  showAutocomplete: boolean;
  tooltipUTMText = `Do not include the "?" in this field. \n
    For location-based parameter values, enclose the custom column name in double curly braces.
    <b>Ex:</b> <i>utm_source=gmb&utm_medium=maps&utm_content={{utm_content_column}}</i>\n

    Please confirm that the URL including the UTM code works before saving in Console.`;

  constructor(private notifyService: NotifyService, private directoryService: DirectoryService) {}

  ngOnInit(): void {
    this.updateSlots();
  }

  public handleSave(): void {
    // debugger
    this.saveEvent.emit(this.d);
  }

  public handleSearchCategories(event, vendor: string) {
    this.directoryService.searchCats(event, vendor, (res) => {
      this.categoryMatches = res.collection;
    });
  }

  public handleCategorySelection(cat: IDirectoryCategory, d: IDirectory) {
    const exist = this.d.categories.some((v) => v === cat.code);
    if (!exist) {
      if (this.d.categories.length === 6) {
        this.notifyService.info('Limit of tags is reached. Please delete some tag to add another.');
        return;
      }
      this.d.categories.push(cat.code);
      // TODO: do here the names of categories, not codes
      this.updateSlots();
    }

    this.selectedCategory = null;
  }

  updateSlots() {
    this.categoriesSlots = this.d.categories ? [...this.d.categories] : [];
    this.categoriesSlots.length = 6;
  }

  public handleRemoveCategory(c: string): void {
    this.d.categories = this.d.categories.filter((v) => v !== c);
    this.updateSlots();
  }

  public handleCancel(): void {
    this.cancelEdit.emit();
  }
}
