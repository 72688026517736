import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getLocationHash } from './location.helpers';
import { IPageTabItem } from './page-tabs.interfaces';

@Component({
  selector: 'rs-page-tabs',
  templateUrl: './page-tabs.component.html',
  styles: [''],
})
export class RsPageTabsComponent {
  public readonly COMING_SOON_TEXT: string = 'Coming soon!';
  public activeItem: IPageTabItem;
  public tabs: IPageTabItem[];
  private tabName: string;

  @Input() set tabItems(v: IPageTabItem[]) {
    this.tabs = v.map((t) => {
      t.command = (evt) => this.setActiveTab(evt.item);
      return t;
    });

    const tabName = this.getTabNameInUrl();
    const currentTab = v.find((t) => t.hash === tabName);

    this.setActiveTab(currentTab || v[0]);
  }

  @Output() tabSelected = new EventEmitter<IPageTabItem>();

  constructor() {}

  public setActiveTab(item): void {
    this.activeItem = item;
    this.setTabName(item.hash);
    this.tabSelected.emit(item);
  }

  public setTabName(hash: string): void {
    this.tabName = hash;
    this.setTabNameInUrl();
  }

  public setTabNameInUrl() {
    location.hash = `${this.tabName}`;
  }

  public getTabNameInUrl(): string {
    const tabName = getLocationHash();
    return tabName;
  }
}
