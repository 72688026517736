import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class NotifyService {
  private options: Message = { key: 'bc' };

  constructor(private messageService: MessageService) {}

  info(summary: string, delay?: number): void {
    this.buildMessage('info', summary, delay);
  }

  success(summary: string, delay?: number): void {
    this.buildMessage('success', summary, delay);
  }

  warning(summary: string, delay?: number): void {
    this.buildMessage('warning', summary, delay);
  }

  error(summary: string, delay?: number): void {
    this.buildMessage('error', summary, delay);
  }

  add(options: Message): void {
    this.messageService.add({ ...this.options, ...options });
  }

  private buildMessage(severity: string, summary: string, delay = 2000): void {
    const data: Message = { ...this.options, severity, summary, life: delay };
    this.messageService.add(data);
  }
}
