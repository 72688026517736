<div class="table_cards">
  <h2 class="table_cards_title">{{ title }}</h2>
  <div class="table_cards_block">
    <h3 class="table_card_desc">{{ cardDescription }}</h3>
  </div>

  <h4 class="table_header">Zip Codes</h4>
  <div class="list">
    <ul class="block" *ngFor="let zipCode of zipCodes; let i = index">
      <li class="table_list">
        <span class="table_list_number">{{ i + 1 }}.</span>
        <span class="table_list_zipcode">{{ zipCode.zipcode}},</span>
        <span class="table_list_city">{{ zipCode.city }},</span>
        <span class="table_list_state">{{ zipCode.state }}</span>
        <span class="table_list_score">{{ zipCode.score }}%</span>
      </li>
    </ul>
    <div class="table_nodata" *ngIf="!zipCodes.length">
      <h4>No data available.</h4>
    </div>
  </div>
</div>
