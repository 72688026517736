import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DBService } from './db.service';
import { BroadcastService } from './broadcast.service';
import { SessionService } from './session.service';
import { SocketService } from './socket.service';
import { ApiService } from './api/api.service';
import { CookieService } from './cookie.service';
import { StorageService } from './storage.service';

@NgModule({
  imports: [CommonModule],
})
export class BackendAdapterModule {
  static forRoot(): ModuleWithProviders<BackendAdapterModule> {
    return {
      ngModule: BackendAdapterModule,
      providers: [ApiService, DBService, BroadcastService, SessionService, SocketService, CookieService, StorageService],
    };
  }
}
