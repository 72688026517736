import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GBPService } from '../../../../gbp.service';

@Component({
  selector: 'app-post-info',
  templateUrl: './post-info.component.html',
  styleUrls: ['./post-info.component.scss'],
})
export class PostInfoComponent implements OnInit, OnDestroy {
  @Input() postInfoGroup: FormGroup;
  @Input() closeGbpUtmFormEvent: EventEmitter<void>;
  @Input() type: string | undefined;
  @ViewChild('desc') desc: ElementRef;
  buttons = [
    { value: 'BOOK', label: 'BOOK' },
    { value: 'ORDER', label: 'ORDER' },
    { value: 'SHOP', label: 'BUY' },
    { value: 'LEARN_MORE', label: 'LEARN MORE' },
    { value: 'SIGN_UP', label: 'SIGN UP' },
    { value: 'CALL', label: 'CALL' },
  ];
  languages = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'es', label: 'Español' },
  ];
  chipsArray = ['city', 'full address', 'state', 'location name'];
  showCtaURLField = false;
  unsubscribe$ = new Subject<void>();
  showGbpUtmForm = false;
  public ctaURL: string;
  // Disable past date, done for Event end date.
  public minDate= new Date();
  public urlToolTip: string = `Must be a valid URL.`;

  constructor(private gbpService: GBPService) {}

  ngOnInit(): void {
    if (this.closeGbpUtmFormEvent) this.closeGbpUtmFormEvent.subscribe(() => this.onCloseGbpUtmForm());
    this.showGbpUtmForm = false;
    this.postInfoGroup?.controls['ctaURL']?.enable();
    if (this.type != 'offer') {
      if (this.postInfoGroup.controls.addButton.value !== 'CALL') {
        this.showCtaURLField = !!this.postInfoGroup.controls.addButton.value;
      }
      this.postInfoGroup.controls.addButton.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
        this.showCtaURLField = value === 'CALL' ? false : !!value;
      });
    }

    // Set language if it's value is there and is in format of `en-US / fr-CA / es-ES`
    if ((this.postInfoGroup.controls['languageCode']?.value &&
        (this.postInfoGroup.controls['languageCode']?.value === 'en-US') ||
        (this.postInfoGroup.controls['languageCode']?.value === 'fr-CA') ||
        (this.postInfoGroup.controls['languageCode']?.value === 'es-ES'))
    ) {
      this.postInfoGroup.controls['languageCode'].setValue(
        this.gbpService.preparePostLanguage(this.postInfoGroup.controls['languageCode'].value)
      );

    }

  }

  onCloseGbpUtmForm(): void {
    this.showGbpUtmForm = false;
    this.postInfoGroup?.controls['ctaURL']?.enable();
  }

  onChipClick(text: string) {
    this.desc.nativeElement.value =
      this.desc.nativeElement.value.substr(0, this.desc.nativeElement.selectionStart) +
      `{${text}}` +
      this.desc.nativeElement.value.substr(
        this.desc.nativeElement.selectionStart,
        this.desc.nativeElement.value.length
      );
    this.postInfoGroup.patchValue({
      postDescription: this.desc.nativeElement.value,
    });
    this.desc.nativeElement.focus();
  }

  public handleInputSwitchChange(event): void {
    if (event?.checked) {
      this.postInfoGroup.controls['startTime'].addValidators(Validators.required);
      this.postInfoGroup.controls['endTime'].addValidators(Validators.required);
    }
  }

  handleInputCtaURLChange(event): void {
    const value = event.target.value;
    this.ctaURL = value;
    this.postInfoGroup.patchValue({
      ctaURL: value,
    });
  }

  public handleDropdownChange(event): void {
    if (event?.value) {
      this.postInfoGroup.controls['ctaURL'].addValidators(Validators.required);
    }
  }

  public get descriptionCharacterLimit(): number {
    return this.desc?.nativeElement?.value?.length;
  }

  public get titleCharacterLimit(): number {
    return this.postInfoGroup.controls['title']?.value.length;
  }

  onAddUtmCodes(event: Event): void {
    event.preventDefault(); // This prevents the default link navigation
    this.showGbpUtmForm = !this.showGbpUtmForm;
  }

  handleCtaUrlWithUtmChange(event: Event): void {
    this.postInfoGroup.patchValue({
      ctaURL: event,
    });
    this.ctaURL = event as unknown as string;
  }

  public onDropdownChange(): void {
    if(this.postInfoGroup.controls['addButton'].value === 'CALL') {
      this.postInfoGroup.controls['ctaURL'].setValue('');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
