import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GBPService } from 'src/app/gbp-management/gbp.service';
import { SuccessComponent } from '../gbp-post-create/success/success.component';

@Component({
  selector: 'app-gbp-post-delete',
  templateUrl: './gbp-post-delete.component.html',
  styleUrls: ['./gbp-post-delete.component.scss'],
})
export class GbpPostDeleteComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dialogService: DialogService,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {}

  deleteItem() {
    const tempObj = { ...this.dynamicDialogConfig.data, _status: 'DP' };
    this.dialogService.open(SuccessComponent, {
      width: '20%',
    });
    this.gbpService.updateLocalPost(tempObj);
    this.ref.close();
  }

  unpublishItem() {
    const tempObj = { ...this.dynamicDialogConfig.data, _status: 'UP' };
    this.dialogService.open(SuccessComponent, {
      width: '20%',
    });
    this.gbpService.updateLocalPost(tempObj);
    this.ref.close();
  }
}
