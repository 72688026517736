import { Injectable } from "@angular/core";
import { IReviewData } from "../review-management.interfaces";
import * as XLSX from 'xlsx';
import { Table } from 'primeng/table';
import { DBService } from '../../core/backend-adapter/db.service';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

@Injectable({
  providedIn: 'root'
})


export class ExportDataToFile {

  private static readonly fileName: string = 'ExcelSheet.xlsx';

  constructor(private dBService: DBService) { }

  private mapData(data: any[]): any[] {
    const JSONData = data?.map(el => {
      return {
        'STORE CODE': this.getStoreCode(el),
        'LOCATION': this.getLocationName(el),
        'STREET ADDRESS': this.getStreetAddress(el),
        'CITY': this.getCity(el),
        'STATE': this.getState(el),
        'POSTAL CODE': this.getPostalCode(el),
        'TAGS': this.getTags(el),
        'REVIEW DATE': this.getReviewDate(el),
        'REVIEW': this.getReviewerComment(el),
        'REVIEWER NAME': this.getReviewerName(el),
        'RATING': this.getRatings(el),
        'RESPONSE DATE': this.getResponseDate(el),
        'RESPONSE': this.getResponseComment(el)
      };
    });
    return JSONData;
  };

  private setSheetColumnWidth(): any[] {
    return [
      { 'width': 15 }, // store code
      { 'width': 30 }, // location
      { 'width': 30 }, // street address
      { 'width': 15 }, // city
      { 'width': 10 }, // state
      { 'width': 15 }, // postal code
      { 'width': 30 }, // tags
      { 'width': 15 }, // review date
      // { 'width': 30 }, // review comment
      { 'width': 20 }, // reviewer name
      { 'width': 15 }, // rating
      { 'width': 15 }, // response date
      { 'width': 30 }, // response comment
    ];
  };

  private getStoreCode(loc: IReviewData): string {
    return loc?.storeCode ? loc?.storeCode : 'N/A';
  };

  private getLocationName(loc: IReviewData): string {
    return loc?.businessName ? loc?.businessName : 'N/A';
  };

  private getStreetAddress(loc: any): string {
    return loc?.addressLines.length ? loc.addressLines.join(',') : 'N/A';
  }

  private getCity(loc: any): string {
    return loc?.city ? loc?.city : 'N/A';
  }

  private getState(loc: any): string {
    return loc?.state ? loc?.state : 'N/A';
  }

  private getPostalCode(loc: any): string {
    return loc?.postalCode ? loc?.postalCode : 'N/A';
  }

  private getTags(loc: any): string[] {
    return loc?.tags && loc?.tags?.length && loc.tags.join(', ').replace(/_/g,' ').split(',') || ['N/A'];
  }

  private getReviewDate(loc: any): string {
    return loc?.reviewCreatedAt === null ? 'N/A' : `${loc?.reviewCreatedAt._year_}/${loc?.reviewCreatedAt._month_}/${loc?.reviewCreatedAt._day_}`;
  }

  private getReviewerComment(loc: any) {
    if (loc.vendorIdent === 'yelp-yelp') {
      loc.reviewerComment = 'Yelp Review text only available in console.';
    }
    return loc?.reviewerComment ? loc.reviewerComment : 'N/A'
  };

  private getReviewerName(loc: any): string {
    return loc?.reviewerName ? loc.reviewerName : 'N/A';
  }

  private getRatings(loc: any): string {
    return loc?.starRating ? loc?.starRating : 'N/A';
  };

  private getResponseDate(loc: any): string {
    return loc?.replyUpdatedAt === null ? 'N/A' : `${loc?.replyUpdatedAt?._year_}/${loc?.replyUpdatedAt?._month_}/${loc?.replyUpdatedAt?._day_}`;
  }

  private getResponseComment(loc: any): string {
    return loc?.replyComment ? loc?.replyComment : 'N/A';
  };

  private setSheetPasswordProtection() {
    return {
      password: '*RenderSEO-console2*',
      selectLockedCells: false,
      selectUnlockedCells: false,
    };
  };

  public generateXLSXFile(table: any[]): void {
    const data = this.mapData(table);

    // generate xlsx worksheet.
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = [...this.setSheetColumnWidth()];
    // ws['!protect'] = this.setSheetPasswordProtection();

    // generate xlsx workbook.
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, ExportDataToFile.fileName);
  };

  public generateCSVFile(table: Table, config?: {[key: string]: any}): void {
    table.exportCSV(config);
  }


  public getReviews(params): void {
    const { order, where } = params;
    this.dBService.ReviewGMB.loadObjects({
      offset: 0,
      limit: 25000,
      order: order,
      where: where,
    }).then(res => {
      const data = this.mapData(res?.collection);
      const unparsedData = Papa?.unparse(data);
      const blob = new Blob([unparsedData], {
        type: 'text/plain;charset=utf-8',
      });
      saveAs(blob, `reviews-all.csv`);
    })
  }

}
