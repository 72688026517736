<div class="package-wrapper">
  <div class="package-title">
    <span>{{ packageTitle }}</span> <span>({{ count }})</span>
  </div>
  <button
    pButton
    type="button"
    label="{{ showAllListings ? 'Back to directories' : 'See all listings' }}"
    class="p-button-text"
    [icon]="showAllListings ? 'pi pi-chevron-left' : ''"
    (click)="showAllListings = !showAllListings"
  ></button>

  <p *ngIf="count === 0" class="tac">No active directories for selected location</p>
  <div *ngIf="!showAllListings" class="package-container">
    <ng-content select="[directories]"></ng-content>
  </div>
  <div *ngIf="showAllListings">
    <ng-content select="[listings]"></ng-content>
  </div>
</div>
