import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GbpPhotoLocationsComponent} from '@app/gbp-management/gbp-tab-photo/components/gbp-photo-locations/gbp-photo-locations.component';

@Component({
  selector: 'app-gbp-photo-submit',
  templateUrl: './gbp-photo-submit.component.html',
  styleUrls: ['./gbp-photo-submit.component.scss'],
})
export class GbpPhotoSubmitComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() mediaCategory: string;
  @Input() preSelectedLocs: number = 0;
  uploadGroup: FormGroup;
  photoAttrsGroup: FormGroup;
  scheduleGroup: FormGroup;
  public selectedLocationsCount: number | string;
  public isFileVideo: boolean = false;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {
  }

  ngOnInit(): void {
    this.uploadGroup = this.form.controls.upload as FormGroup;
    this.isFileVideo = this.uploadGroup.controls['imageUrl'].value.endsWith('.mp4');
    this.photoAttrsGroup = this.form.controls.photoAttrs as FormGroup;
    this.scheduleGroup = this.form.controls.schedule as FormGroup;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mediaCategory = changes?.mediaCategory.currentValue
    const { isSelectedAll, location,  totalLocationsCount} = changes?.form.currentValue['controls']['schedule']['controls'];
    const setLocValue: boolean = (location.value.length + this.preSelectedLocs) === totalLocationsCount.value;
    this.selectedLocationsCount = (isSelectedAll.value ||  setLocValue) ? 'All' : ( location.value.length + this.preSelectedLocs);
  }

  openLocations(): void {
    this.dialogService.open(GbpPhotoLocationsComponent, {
      width: '90%',
      height: '90%',
      data: {
        locations: [...this.scheduleGroup.value.location],
      },
    });
  }
}
