import { Injectable } from "@angular/core";
import { GraphCount } from "src/app/core/feature-modules/table-filter/table-filter.interfaces";
import { PandioReviews } from "../model/pandio-reviews";
import { IReviewData } from "../review-management.interfaces";
import { PandioReviewsConfigService } from './pandio-reviews-config-service';

@Injectable({
  providedIn: 'root'
})

export class PandioReviewsDataService {
  public data: PandioReviews;
  public mockReviewsData: IReviewData[] = [];

  constructor(private pandioReviewsConfigService: PandioReviewsConfigService) {
  }

  public filterDataPerDirectory(res: any): GraphCount {
    const graphObj: GraphCount = {
      // Total reviews...
      tripadvisorTotalReviews: Object.keys(res[0]).length && res[0] && res[0]?.collection && res[0]?.collection?.totalReviews || 0,
      zocDocTotalReviews: Object.keys(res[1]).length && res[1] && res[1]?.collection && res[1]?.collection?.totalReviews || 0,
      vitalsTotalReviews: Object.keys(res[2]).length && res[2] && res[2]?.collection && res[2]?.collection?.totalReviews || 0,
      healthGradesTotalReviews: Object.keys(res[3]).length && res[3] && res[3]?.collection && res[3]?.collection?.totalReviews || 0,
      indeedTotalReviews: Object.keys(res[4]).length && res[4] && res[4]?.collection && res[4]?.collection?.totalReviews || 0,
      glassdoorTotalReviews: Object.keys(res[5]).length && res[5] && res[5]?.collection && res[5]?.collection?.totalReviews || 0,
      // Last 30 days reviews...
      tripdavisorReviews: res[0] && res[0]?.collection_prior30 && res[0]?.collection_prior30?.totalReviews || 0,
      zocDocReviews: res[1] && res[1]?.collection_prior30 && res[1]?.collection_prior30?.totalReviews || 0,
      vitalsReviews: res[2] && res[2]?.collection_prior30 && res[2]?.collection_prior30?.totalReviews || 0,
      healthGradesReviews: res[3] && res[3]?.collection_prior30 && res[3]?.collection_prior30?.totalReviews || 0,
      indeedReviews: res[4] && res[4]?.collection_prior30 && res[4]?.collection_prior30?.totalReviews || 0,
      glassdoorReviews: res[5] && res[5]?.collection_prior30 && res[5]?.collection_prior30?.totalReviews || 0,
      // Average ratings...
      tripdavisorAvgRatings: res[0]['collection']?.avgStarRating || 0,
      zocDocAvgRatings: res[1]['collection']?.avgStarRating || 0,
      vitalsAvgRatings: res[2]['collection']?.avgStarRating || 0,
      healthGradesAvgRatings: res[3]['collection']?.avgStarRating || 0,
      indeedAvgRatings: res[4]['collection']?.avgStarRating || 0,
      glassdoorAvgRatings: res[5]['collection']?.avgStarRating || 0,
      // sentiment report high count
      tripadvisorHighCount: res[0]['collection']?.highStarsCount || 0,
      zocDocHighCount: res[1]['collection']?.highStarsCount || 0,
      vitalsHighCount: res[2]['collection']?.highStarsCount || 0,
      healthGradesHighCount: res[3]['collection']?.highStarsCount || 0,
      indeedHighCount: res[4]['collection']?.highStarsCount || 0,
      glassdoorHighCount: res[5]['collection']?.highStarsCount || 0,
      // sentiment report neutral count
      tripadvisorNeutralCount: res[0]['collection']?.medStarsCount || 0,
      zocDocNeutralCount: res[1]['collection']?.medStarsCount || 0,
      vitalsNeutralCount: res[2]['collection']?.medStarsCount || 0,
      healthGradesNeutralCount: res[3]['collection']?.medStarsCount || 0,
      indeedNeutralCount: res[4]['collection']?.medStarsCount || 0,
      glassdoorNeutralCount: res[5]['collection']?.medStarsCount || 0,
      // sentiment report poor count
      tripadvisorPoorCount: res[0]['collection']?.lowStarsCount || 0,
      zocDocPoorCount: res[1]['collection']?.lowStarsCount || 0,
      vitalsPoorCount: res[2]['collection']?.lowStarsCount || 0,
      healthGradesPoorCount: res[3]['collection']?.lowStarsCount || 0,
      indeedPoorCount: res[4]['collection']?.lowStarsCount || 0,
      glassdoorPoorCount: res[5]['collection']?.lowStarsCount || 0,
    };
    return graphObj;
  }

  public generateOverviewCardsData(result): GraphCount {
    const filtersList: string[] = [];
    const dataSet1 = {}, dataSet2 = {}, dataSet3 = {}, dataSet4 = {}, dataSet5 = {}, dataSet6 ={};
    result.collection?.forEach((col: any) => {
      switch (col['vendorIdent']) {
        case 'vitals.com-lde':
          filtersList.push('Vitals');
          dataSet1['collection'] = col;
          break;
        case 'zocdoc.com-lde':
          filtersList.push('ZocDoc');
          dataSet2['collection'] = col;
          break;
        case 'tripadvisor.com-lde':
          filtersList.push('Tripadvisor');
          dataSet3['collection'] = col;
          break;
        case 'healthgrades.com-lde':
          filtersList.push('Healthgrades');
          dataSet4['collection'] = col;
          break;
        case 'indeed.com-lde':
          filtersList.push('Indeed.com');
          dataSet5['collection'] = col;
          break;
        case 'glassdoor.com-lde':
          filtersList.push('Glassdoor.com');
          dataSet6['collection'] = col;
          break;
      }
    });

    this.pandioReviewsConfigService.setPandioReviewsFilters(filtersList);

    result.collection_prior30?.forEach((col: any) => {
      switch (col['vendorIdent']) {
        case 'vitals.com-lde':
          dataSet1['collection_prior30'] = col;
          break;
        case 'zocdoc.com-lde':
          dataSet2['collection_prior30'] = col;
          break;
        case 'tripadvisor.com-lde':
          dataSet3['collection_prior30'] = col;
          break;
        case 'healthgrades.com-lde':
          dataSet4['collection_prior30'] = col;
          break;
        case 'indeed.com-lde':
          dataSet5['collection_prior30'] = col;
          break;
        case 'glassdoor.com-lde':
          dataSet6['collection_prior30'] = col;
          break;
      }
    });

    const res = [];
    res.push(dataSet3, dataSet2, dataSet1, dataSet4, dataSet5, dataSet6);
    return this.filterDataPerDirectory(res);
  }

  public calcTotalReviews(data): number {
    return (
      data.tripadvisorTotalReviews +
      data.zocDocTotalReviews +
      data.vitalsTotalReviews +
      data.healthGradesTotalReviews +
      data.indeedTotalReviews +
      data.glassdoorTotalReviews)
  }

}
