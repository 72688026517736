// tslint:disable: curly
import { ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AcctHelpers } from 'src/app/acct-comps/helpers';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { IDirectory } from '../../directory.interfaces';
import { DirectoryFlatListingsRow } from '../directory-listings.interfaces';
import { DirectoryListingsService } from '../directory-listings.service';
import { ILocation } from './../../../../../loc-comps/locations.interfaces';
import { ChangeDetectorRef } from '@angular/core';
import { timer } from 'rxjs';
import { Table } from 'primeng/table';
import { getVendorName } from '../../directory.helpers';

@Component({
  selector: 'app-directory-account-listings',
  templateUrl: './directory-account-listings.component.html',
  styleUrls: ['./directory-account-listings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryAccountListingsComponent implements OnInit, OnDestroy {
  public getVendorName = getVendorName;
  loading = true;
  VENDOR_IMAGES = this.acctHelpers.VENDOR_IMAGES;
  listings: DirectoryFlatListingsRow[];
  filteredListings: DirectoryFlatListingsRow[];
  prevSearch = {};
  copyToClipboard: string;

  first = 1;
  offset = 0;
  pageSize = 4;
  totalEntries: number;
  @ViewChild('sqlTbl', { static: false }) table: Table;

  constructor(
    private notifyService: NotifyService,
    private acctHelpers: AcctHelpers,
    private directoryListingsService: DirectoryListingsService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.getListings();
  }

  getListings() {
    this.directoryListingsService.getListings().subscribe((v) => {
      this.listings = v;
      this.totalEntries = v.length;
      this.filteredListings = v;
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  handleCopyToClipboard(link) {
    this.copyToClipboard = link;
    this.notifyService.info('Link was successfully copied to clipboard');
  }

  public handleSearch(k: string): void {
    this.loading = true;
    const searchKey = `search-${k}`;
    // this.filteredListings = null;
    // if (this.prevSearch[searchKey]) {
    //   this.filteredListings = this.prevSearch[k];
    //   this.loading = false;
    //   this.cdr.markForCheck();
    //   return;
    // }
    let res;
    this.zone.runOutsideAngular(() => {
      res = this.listings.filter((v) => {
        return `${v.location}${v.storeCode}`.toLowerCase().includes(k);
      });
    });

    timer(100).subscribe((_) => {
      this.filteredListings = res;
      this.totalEntries = res.length;
      this.table.first = 0;
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.prevSearch = null;
  }
}
