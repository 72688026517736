import { Component, OnDestroy, OnInit } from "@angular/core";
import { DateRange } from "../shared/models/date-range";
import { GraphConfigService } from '../shared/services/graph-config.service';
import { MapsInsightsConfigService } from '../shared/services/apple-maps-graph-config.service';
import { AppleMapsInsightsDataService } from "../shared/services/apple-maps-insights-data.service";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterButtonComponent } from "../shared/components/filter-buttons/filter-buttons.component";
import { Observable, Subject, from } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SocketService } from "src/app/core/backend-adapter/socket.service";
import { AppleMapsSpatialInsightsService } from "../shared/services/apple-maps-spatial-insights.service";
import { SpatialData } from "../shared/models/spatial-data-model";

interface Location {
  name: string;
  value: string;
  id: number;
  ownerId: number;

}

@Component({
  selector: 'app-apple-dashboards-maps-tab',
  templateUrl: './apple-dashboard-tab.component.html',
  styleUrls: ['./apple-dashboard-tab.component.scss'],
})

export class AppAppleDashboardMapsTabComponent implements OnInit, OnDestroy {
  private previousSelectedInsights: string[] = []; // unsued
  private ngUnsubscribe$ = new Subject();
  private allAdvanceFilters: any[] = [];
  private operandType: string = '-or';


  public readonly CardDescription: string = 'Where people were when they got directions to your location.';
  public readonly cardTitle1: string = 'Search clicks Location';
  public readonly cardTitle2: string = 'Directions Location';
  public readonly filterButtonTitle: string = 'Filter by';
  public tags = [
    {
      label: 'Store code filter',
      disabled: false,
      command: () => {
        this.primaryFilters();
      }
    },
    {
      label: 'Advanced filters',
      disabled: false,
      command: () => {
        this.advanceFilters();
      }
    }
  ];
  public selectedRange: number = 3;
  public dateRanges: DateRange[];
  public insightsGraphConfig: unknown;
  public data: unknown;
  public showReviews: boolean;
  public demoData = [];
  public displayInsightsPopup: boolean = false;
  public selectedInsightPrefs: string[] = ['searchCard', 'views', 'directions', 'websiteClicks', 'calls', 'shares'];
  public popupData = [];
  public searchLocationsZipCodes: SpatialData[] = [];
  public directionsLocationsZipCodes: SpatialData[] = [];
  public date: Date = new Date();
  public componentRef: DynamicDialogRef | undefined;
  public displayCardInsights: boolean = true;
  public displaySpatialInsights: boolean = false;
  public locations: any[] = [];
  public selectedLocation: any;
  public whereClauss: any[] = [];
  public fullWidth: boolean = false;

  constructor(
    private graphConfigService: GraphConfigService,
    private appleMapsInsightsConfigService: MapsInsightsConfigService,
    private appleMapsInsightsDataService: AppleMapsInsightsDataService,
    private dialogService: DialogService,
    private socketService: SocketService,
    private appleMapsSpatialInsightsService: AppleMapsSpatialInsightsService,
  ) { }

  ngOnInit(): void {
    this.dateRanges = this.graphConfigService.dateRanges;
    this.insightsGraphConfig = this.appleMapsInsightsConfigService.stackOptions;
    this.showReviews = this.appleMapsInsightsDataService.showReviews;
    this.popupData = this.graphConfigService.applePopupData;
    this.getFiltersOperandStatus();
    this.setFilters();
    this.getAllLocations();
    this.getMetricsDataOnFilterUpdates();
  }

  public dateRangeChanged(): void {
    this.fullWidth = (this.selectedRange === 12 || this.selectedRange === 24) ? true : false;
    this.getInsightsData(this.selectedRange);
  }

  public editInsights(): void {
    this.previousSelectedInsights = [...this.selectedInsightPrefs];
    this.displayInsightsPopup = true;
  }

  public closeInsightsPopup(): void {
    this.setInsightsCardVisibility();
    this.displayInsightsPopup = false;
  }

  public prefsChanged(): void {
    this.setInsightsCardVisibility();
  };

  private setInsightsCardVisibility(): void {
    this.demoData.forEach((card) => {
      card['visibility'] = this.selectedInsightPrefs.includes(card.id) ? true : false;
    });
  };

  public saveInsightsPrefs(): void {
    this.displayInsightsPopup = false;
  }

  public primaryFilters() {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Filter by Store Code',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.appleMapsInsightsDataService.setBasicFiltersData()],
        isAdvanceFilterActive: false
      }
    });
    this.appleMapsInsightsDataService.getDialogRef(this.componentRef);
  };

  public advanceFilters() {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Advance filters',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.allAdvanceFilters],
        isAdvanceFilterActive: true
      }
    });

    this.appleMapsInsightsDataService.getDialogRef(this.componentRef);
  };

  private checkInsightsCardsVisibility(): void {
    this.demoData.forEach((item) => {
      item['visibility'] = this.selectedInsightPrefs.includes(item.id) ? true : false;
      return item;
    });
  }

  /**
   * @description Function to switch between Card insights and Spatial insights view.
   * @returns void
   */
  public openTab(): void {
    this.displayCardInsights = !this.displayCardInsights;
    this.displaySpatialInsights = !this.displaySpatialInsights;

    // Get Spatial insights data is Spatial Insights tab is active.
    if (this.displaySpatialInsights) {
      this.initSpatialDataCall();
    }
  }

  private setFilters(): void {
    const advanceFiltersObs$ = this.appleMapsInsightsDataService.getDynamicFilters();
    advanceFiltersObs$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([tags, googleLabels, businessCategories]) => {
        this.allAdvanceFilters = this.appleMapsInsightsDataService.setAdvanceFiltersData()
          .map(filter => {
            if (filter.label === 'Tag') {
              tags.collection?.forEach(tag => {
                filter.checkboxList.push({
                  label: tag.tagName?.replace(/_/g, ' ')
                })
              });
              return filter;
            };

            if (filter.label === 'Google Label') {
              googleLabels?.collection.forEach(tag => {
                filter.checkboxList.push({
                  label: tag.labelName?.replace(/_/g, ' ')
                })
              });
              return filter;
            }

            if (filter.label === 'Business Category') {
              businessCategories?.collection.forEach(tag => {
                filter.checkboxList.push({
                  label: tag.categoryName?.replace(/_/g, ' ')
                })
              });
              return filter;
            }

            // if (filter.label === 'Google Attributes') {
            //   googleAttributes?.collection.forEach(tag => {
            //     filter.checkboxList.push({
            //       label: tag.labelName?.replace(/_/g, ' ')
            //     })
            //   });
            //   return filter;
            // }

            return filter;
          });
      });
  }

  getInsightsData(range: number): void {
    const params = {
      publisher: 'applemaps',
      numPeriods: range,
      where: this.whereClauss
    };

    this.socketService.sendRequest('get-monthly-metrics', params)
      .then((res: any) => {
        const collections = res && res?.collection;
        const {
          schlocationtap,
          // schlocationtapcategory,
          // schlocationtapother,
          pcview,
          pctapdirection,
          pctapwebsite,
          pctapcall,
          pctapshare,
          pctapshowcase,
          pcviewmenu,
          pctaporder,
          pcorderdelivery,
          pcorderfood,
          pcordertakeout,
          pcpickup,
          pcreserveparking,
          pcreservetable,
          pcjoinwaitlist,
          pcscheduleappt,
          labels
        } = this.appleMapsInsightsDataService.mapInsightsData(collections);


        this.demoData = [
          {
            id: 'searchCard',
            title: 'Search Clicks Total',
            visibility: true,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Search clicks',
                  backgroundColor: '#791e6c',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: schlocationtap,
                },
                // {
                //   type: 'bar',
                //   label: 'Category search',
                //   backgroundColor: '#d51ba0',
                //   barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                //   maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                //   data: schlocationtapcategory,
                // },
                // {
                //   type: 'bar',
                //   label: 'Other',
                //   backgroundColor: '#fa6bc1',
                //   barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                //   maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                //   data: schlocationtapother,
                // },
              ],
            }
          },
          {
            id: 'views',
            title: 'Views',
            visibility: true,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Views',
                  backgroundColor: '#491d8b',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcview
                }
              ],
            }
          },
          {
            id: 'directions',
            title: 'Directions',
            visibility: true,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Directions',
                  backgroundColor: '#F68A8A',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pctapdirection,
                },
              ],
            }
          },
          {
            id: 'websiteClicks',
            title: 'Website clicks',
            visibility: true,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Website clicks',
                  backgroundColor: '#e8e52e',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pctapwebsite,
                },
              ],
            }
          },
          {
            id: 'calls',
            title: 'Calls',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Calls',
                  backgroundColor: '#6e44ad',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pctapcall,
                },
              ],
            }
          },
          {
            id: 'shares',
            title: 'Shares',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Shares',
                  backgroundColor: '#8a3ffc',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pctapshare,
                },
              ],
            }
          },
          {
            id: 'showCaseClicks',
            title: 'Showcase Clicks',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Show case',
                  backgroundColor: '#651e7d',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pctapshowcase,
                },
              ],
            }
          },
          {
            id: 'appointments',
            title: 'Appointments',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Appointments',
                  backgroundColor: '#cf44be',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcscheduleappt,
                },
              ],
            }
          },
          {
            id: 'menuCard',
            title: 'Menu',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Menu',
                  backgroundColor: '#d1548c',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcviewmenu,
                },
              ],
            }
          },
          {
            id: 'ordering',
            title: 'Ordering',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Order',
                  backgroundColor: '#206A02',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pctaporder,
                },
                {
                  type: 'bar',
                  label: 'Delivery',
                  backgroundColor: '#609c51',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcorderdelivery,
                },
                {
                  type: 'bar',
                  label: 'Order food',
                  backgroundColor: '#266317',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcorderfood,
                },
                {
                  type: 'bar',
                  label: 'Order takeout',
                  backgroundColor: '#64f241',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcordertakeout,
                },
                {
                  type: 'bar',
                  label: 'Order pickup',
                  backgroundColor: '#317d1e',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcpickup,
                },
              ],
            }
          },
          {
            id: 'reservations',
            title: 'Reservations',
            visibility: false,
            graphData: {
              labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Reserve parking',
                  backgroundColor: '#abdadb',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcreserveparking,
                },
                {
                  type: 'bar',
                  label: 'Reserve table',
                  backgroundColor: '#4b797a',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcreservetable,
                },
                {
                  type: 'bar',
                  label: 'Join waitlist',
                  backgroundColor: '#1cc6c9',
                  barThickness: this.appleMapsInsightsConfigService.setBarThickness(range, this.showReviews),
                  maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                  data: pcjoinwaitlist,
                },
              ],
            }
          }
        ];

        this.checkInsightsCardsVisibility();
      });
  }

  /**
   * @description Function runs when date is changed.
   * @returns void
   */
  public changeMonth(): void {
    this.initSpatialDataCall();
  };

  /**
   * @description Function runs when location is changed.
   * @returns void
   */
  public changeLocation(): void {
    this.initSpatialDataCall();
  };

  /**
   * @description Returns the list of locations connected to the owner.
   * @returns void
   */
  private getAllLocations(): void {
    const locations$: Observable<unknown> = from(this.socketService.sendRequest('get-locations-summary', {
      columns: ['_id', 'storeCode', 'businessName', 'city', 'state', 'addressLines'],
      where: ['-and', '_parent_id is null']
    }));
    locations$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        this.locations = res['collection']
          ?.length && res['collection'].map(loc => {
            return {
              id: loc[0],
              storeCode: loc[1],
              businessName: `${loc[2]} - ${loc[1]} - ${loc[3]}, ${loc[4]} - ${loc[5]?.join(', ')}`,
              city: loc[3],
              state: loc[4],
              addressLines: loc[5]?.join(', ')
            }
          });
          this.selectedLocation = this.locations[0];
      });
  }

  public getMetricsDataOnFilterUpdates(): void {
    this.appleMapsInsightsDataService.currentUpdatedFilterState
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        if (res) {
          this.whereClauss = this.appleMapsInsightsDataService.buildWhereClaus(res);
          this.getInsightsData(this.selectedRange);
        }
      });
  }

  private getFiltersOperandStatus(): void {
    this.appleMapsInsightsDataService.currentFilterOperandType
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((openationType: string) => {
        this.operandType = openationType;
      })
  }

  /**
   * @description Gets Spatial insights data for selected year, month and location.
   * @param year - number
   * @param month - number
   * @param location_id - number
   * @returns void
   */
  private getSpatialInsightsData(year: number, month: number, location_id: number): void {
    this.appleMapsSpatialInsightsService.getSpatialReport(year, month, location_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        res && res['collection'].length && res['collection'].forEach(location => {
          if (location['key'] === "SPATIAL_SEARCH_LOCATION_TAP") {
            this.searchLocationsZipCodes.push({
              zipcode: location['location'].length ? location['location'].split(',')[0] : 'N/A',
              city: location['location'].length ? location['location'].split(',')[1] : 'N/A',
              state: location['location'].length ? location['location'].split(',')[2] : 'N/A',
              score: location['metrics'] || 'N/A'
            })
          }
          if (location['key'] === "SPATIAL_DIRECTION_LOCATION_TAP") {
            this.directionsLocationsZipCodes.push({
              zipcode: location['location'].length ? location['location'].split(',')[0] : 'N/A',
              city: location['location'].length ? location['location'].split(',')[1] : 'N/A',
              state: location['location'].length ? location['location'].split(',')[2] : 'N/A',
              score: location['metrics'] || 'N/A'
            });
          }
        });
      }, (err) => {
        this.searchLocationsZipCodes = [];
        this.directionsLocationsZipCodes = [];
      })
  }

  /**
   * @description To set params for Spatial insights endpoint.
   * @param none
   * @returns void
   */
  private initSpatialDataCall(): void {
    const year = this.date.getFullYear();
    const month = this.date.getMonth() + 1;
    const location_id = this.selectedLocation?.id;
    this.getSpatialInsightsData(year, month, location_id);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
