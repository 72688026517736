<div *ngIf="showUtmFieldChooser" class="modal">
  <div class="modal-content">
    <div class="modal-header">
      <h2>Select a field to use for this parameter</h2>
      <span class="close" (click)="onClose()">&times;</span>
    </div>
    <ul class="field-list">
      <li
        *ngFor="let field of customFields"
        [class.selected]="selectedField === field.ident"
        (click)="onFieldSelect(field.ident)"
      >
        {{ field.label }}
      </li>
    </ul>
    <div class="modal-footer">
      <button (click)="onConfirm()" class="confirm-btn">OK</button>
    </div>
  </div>
</div>
