import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DBService } from '../core/backend-adapter/db.service';
import { SessionService } from '../core/backend-adapter/session.service';
import { SocketService } from '../core/backend-adapter/socket.service';
import { TIME_ZONES } from './account-settings.data';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLess } from '@fortawesome/free-brands-svg-icons';

interface ZoneGroup {
  label: string;
  items: Zone[];
}

interface Zone {
  name: string;
  value: string;
}
interface Clock {
  hours: string;
}

enum TABS {
  accountInfo = 'USER SETTINGS',
  preferences = 'PREFERENCES',
}
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  DB;
  login: any;
  login1: any;
  attrs: any = {};
  prefs: any = {};
  passwordConfirmation: string;

  public TABS = TABS;

  tabItems: MenuItem[] = [
    {
      label: TABS.accountInfo,
      command: (event) => {
        this.setActiveTab(event.item);
      },
    },
    {
      label: TABS.preferences,
      command: (event) => {
        this.setActiveTab(event.item);
      },
    },
  ];

  selectedValues: string[] = ['val1', 'val2'];
  editingInfo = false;
  editingPassword = false;
  timeZones: ZoneGroup[];
  selectedZone: Zone;

  clock: Clock[];
  selectedClock: Clock;

  // password
  oldPass = '';
  newPass = '';
  currentUser: string;
  showDialog: boolean;
  makeDialog: boolean;
  errMsg: string;
  passTypeOld = 'password';
  passTypeNew = 'password';
  passTypeConfirm = 'password';
  eyeIconOld = 'pi pi-eye';
  eyeIconNew = 'pi pi-eye';
  confirmedPassword: string;
  buttonDisabled = true;

  activeItem: MenuItem = this.tabItems[1];
  private subs = new Subscription();
  private subjInited: boolean;

  @Input() set showDialogEvent(_) {
    if (this.subjInited) {
      this.showChangePwd();
    }
    this.subjInited = true;
  }
  constructor(
    public dbService: DBService,
    private socketService: SocketService,
    private sessionService: SessionService,
    private messageService: MessageService
  ) {
    this.timeZones = TIME_ZONES;
    this.clock = [{ hours: '12 hours' }, { hours: '24 hours' }];
  }

  ngOnInit(): any {
    this.subs.add(
      this.sessionService
        .getCurrentUser$()
        .pipe(filter((v) => v != null))
        .subscribe((user) => {
          this.login = user.login;
          this.currentUser = user.login.loginId;
          console.log(this.currentUser, 'this.currentUser');
          this.login1 = this.sessionService.loginObj;
          console.log(this.login1, 'login1');
          if (this.login1.attrs == 'null') {
            this.login.attrs = {
              prefs: {
                email: '',
                office: '',
                mobile: '',
                position: '',
                permission: '',
                location: '',
                timezone: '',
                clock: '',
              },
            };
          }
        })
    );
  }

  setActiveTab(item) {
    this.activeItem = item;
  }
  editing() {
    this.editingInfo = true;
  }
  save() {
    this.editingInfo = false;
  }
  editingPw() {
    this.editingPassword = true;
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  private showChangePwd() {
    this.login = this.sessionService.loginObj;
    this.passTypeOld = 'password';
    this.eyeIconOld = 'pi pi-eye';
    this.passTypeNew = 'password';
    this.eyeIconNew = 'pi pi-eye';
    this.passTypeConfirm = 'password';
  }

  closeChangePwdDialog() {
    this.oldPass = '';
    this.newPass = '';
  }

  changePassword() {
    this.editingPassword = false;
    this.socketService
      .sendRequest('change-password', {
        loginId: this.currentUser,
        oldPassword: this.oldPass,
        newPassword: this.newPass,
      })

      .then(
        (args) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Password changed',
            detail: 'Your password has been successfully updated.',
            life: 3000,
            closable: true,
          });

          this.closeChangePwdDialog();
        },
        (errArgs) => {
          this.errMsg = errArgs.message;
        }
      );
  }

  toggleShowHide(inputName) {
    if (inputName == 'old') {
      if (this.passTypeOld == 'password') {
        this.passTypeOld = 'text';
        this.eyeIconOld = 'pi pi-eye-slash';
      } else {
        this.passTypeOld = 'password';
        this.eyeIconOld = 'pi pi-eye';
      }
    }
    if (inputName == 'new') {
      if (this.passTypeNew == 'password') {
        this.passTypeNew = 'text';
        this.eyeIconNew = 'pi pi-eye-slash';
      } else {
        this.passTypeNew = 'password';
        this.eyeIconNew = 'pi pi-eye';
      }
    }
    if (inputName == 'confirm') {
      if (this.passTypeConfirm == 'password') {
        this.passTypeConfirm = 'text';
        this.eyeIconNew = 'pi pi-eye-slash';
      } else {
        this.passTypeConfirm = 'password';
        this.eyeIconNew = 'pi pi-eye';
      }
    }
  }

  resetErr() {
    this.errMsg = null;
  }

  styleForRule(ruleFunc, input?) {
    if (typeof ruleFunc != 'function') {
      const val = ruleFunc;
      ruleFunc = () => {
        return val;
      };
    }

    return ruleFunc(input) ? 'passedRule' : 'failedRule';
  }

  iconForRule(ruleFunc, input?) {
    if (typeof ruleFunc != 'function') {
      const val = ruleFunc;
      ruleFunc = () => {
        return val;
      };
    }

    return ruleFunc(input) ? faCheck : faTimes;
  }

  // validations
  hasRequiredLength(input) {
    return input.length >= 8;
  }

  requiresLetter(input) {
    return input.match(/[A-Z]/i);
  }

  requiresDigit(input) {
    return input.match(/[0-9]/i);
  }

  validateInput(input) {
    this.passwordConfirmation = input;
    this.hasRequiredLength(input);
    this.requiresLetter(input);
    this.requiresDigit(input);
  }

  validatePasswordMatch(input) {
    this.confirmedPassword = input;
    if (this.confirmedPassword == this.passwordConfirmation) {
      this.buttonDisabled = false;
    }
  }

  //// toast
  showToast() {
    this.socketService.signal('toastMessage', { title: 'Hey!', message: 'Hello there.' });
  }

  clearToast() {
    this.messageService.clear();
  }
}
