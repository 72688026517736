import { NgModule } from '@angular/core';
import { FormatFileSizePipe } from '../../pipes/format-file-size.pipe';
import { PrimengModule } from '../primeng.module';
import { UploadMediaComponent } from './upload-media.component';

@NgModule({
  imports: [PrimengModule],
  declarations: [UploadMediaComponent, FormatFileSizePipe],
  exports: [UploadMediaComponent],
})
export class UploadMediaModule {}
