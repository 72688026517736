import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICollectionWrapper } from '../core/backend-adapter/api/api.interfaces';
import { ApiService } from '../core/backend-adapter/api/api.service';
import { IAccount } from './accounts.interfaces';

@Injectable({ providedIn: 'root' })
export class AccountsService {
  constructor(public apiService: ApiService) {}

  getAccounts(): Observable<ICollectionWrapper<IAccount[]>> {
    return this.apiService.get('Account');
  }

  getActiveAccounts(): Observable<ICollectionWrapper<IAccount[]>> {
    return this.apiService.get('Account', {where: `_status IN ('A', 'P')`});
  }

  public mapAccountsWithSubaccounts(collection): IAccount[] {
    const myCollection = collection.map(x => Object.assign({}, x));
    const accountsTree = myCollection.reduce((acc, v: IAccount, i, array) => {
      const hasParentAccount = v._parent_id;
      if (hasParentAccount) {
        const currentlyAdded = acc.find((a) => a._id === v._parent_id);
        const parentAccount = array.find((a) => a._id === v._parent_id) || {};
        if (!parentAccount?.hasOwnProperty('_subAccounts')) parentAccount['_subAccounts'] = [];

        if (currentlyAdded) {
          currentlyAdded._subAccounts.push(v);
          currentlyAdded.hasSubAccts = true;
        } else {
          parentAccount['_subAccounts'].push(v);
          parentAccount['hasSubAccts'] = true;
          acc.push(parentAccount);
        }
      } else {
        const currentlyAdded = acc.find((a) => a._id === v._id);
        if (!currentlyAdded) {
          acc.push(v);
        }
      }
      return acc;
    }, []);

    const flattenAccounts = accountsTree.reduce((acc, v) => {
      if (v.hasOwnProperty('_subAccounts')) {
        acc.push(v);
        acc.push(...v._subAccounts.sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        acc.push(v);
      }
      return acc;
    }, []);
    return flattenAccounts;
  }


}


