import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient, @Inject('API_URL') private API_URL: string) {}

  public login({ loginId, password }): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}/authenticate`,
      { loginId, password },
      {
        headers: new HttpHeaders({
          'Cache-Control': 'private, no-cache',
          'content-type': 'application/json',
        }),
      }
    );
  }
}
