import {
  Component,
  Input,
  ElementRef,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { timeStamp } from 'console';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'rs-title',
  template: `<h1 class="rs-title">
    <span [innerHTML]="trimmedTitle | safeHtml"></span><span class="text-green">{{ sign }}</span>
  </h1>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .rs-title {
        font-size: 40px;
        font-weight: bolder;
        margin: 10px 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsTitleComponent implements OnInit, OnDestroy {
  public trimmedTitle: string;
  @Input() sign = '.';
  @Input() private title: string;
  private resizeSubscription = new Subscription();

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(startWith(true))
      .subscribe((e: any) => this.rerenderTitle());
  }

  rerenderTitle() {
    const titleRect: DOMRectReadOnly = this.elementRef.nativeElement.getBoundingClientRect();
    const letters = Math.ceil(titleRect.width / 20);

    if (letters != 0 && this.title.length > letters) {
      this.trimmedTitle = this.title.substring(0, letters) + '..';
    } else {
      this.trimmedTitle = this.title;
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
}
