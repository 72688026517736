import {Component, Input, OnInit} from '@angular/core';
import {AcctHelpers} from 'src/app/acct-comps/helpers';
import {DBService} from 'src/app/core/backend-adapter/db.service';
import {IDirectory} from '../directory.interfaces';
import {IAccount} from './../../../../acct-comps/accounts.interfaces';
import {NotifyService} from './../../../layouts/notifications/notify/notify.service';
import {DirectoryService} from './../directory.service';
import {ILocation} from './../../../../loc-comps/locations.interfaces';
import {SessionService} from '@app/core/backend-adapter/session.service';

const accuracyFullMatch = 'fa fa-check-circle accuracy-full-match';
const accuracyPartialMatch = 'fa fa-info-circle accuracy-partial-match';
const accuracyNoMatch = 'fa fa-times-circle accuracy-no-match';
const accuracyNoData = 'fa fa-exclamation-circle accuracy-no-data';

@Component({
  selector: 'app-directory-card',
  templateUrl: './directory-card.component.html',
  styleUrls: ['./directory-card.component.scss'],
})
export class DirectoryCardComponent implements OnInit {
  VENDOR_IMAGES = this.acctHelpers.VENDOR_IMAGES;
  mode: 'view' | 'edit' = 'view';

  isOpenModal: boolean;

  // INFO: this.sessionService.hasPerm('admin.account', 'r');
  public d: IDirectory;
  @Input() user = {role: 'admin'};
  @Input() isStub: boolean;
  @Input() account: IAccount;
  @Input() location: ILocation;
  @Input() disableEdit: boolean;

  // tslint:disable-next-line: no-input-rename
  @Input('directory') set directory(v: IDirectory) {
    v.categories = v.categories !== undefined ? v.categories?.filter((category) => !!category) : [];
    this.d = v;
  }


  constructor(
    private acctHelpers: AcctHelpers,
    private dbService: DBService,
    private notifyService: NotifyService,
    private directoryService: DirectoryService,
    public sessionService: SessionService
  ) {
  }

  ngOnInit(): void {
    if (this.d && this.location) {
      this.d.accuracy = this.location?.vendorStatus[this.d.vendor]?.accuracy != undefined ? this.location.vendorStatus[this.d.vendor].accuracy : {};
    }

    this.getActiveCats();
  }

  saveDirectory(d: IDirectory) {
    this.mode = 'view';
    this.notifyService.info('will be soon');
  }

  public handleExternalLinkOpening(d: IDirectory): void {
    // if (d.url) {
    //   window.open(d.url, '_blank').focus();
    // } else {
    // }
    this.isOpenModal = true;
  }

  public handleSaveChanges(): void {
    const existedAccount = this.dbService.Account.coll.findIndex((a) => a._id === this.account._id);

    this.dbService.Account.saveObject(this.account)
      .then((account: IAccount) => {
        this.account.global[this.d.vendor] = this.d;
        this.mode = 'view';
        this.dbService.Account.coll.splice(existedAccount, 1, this.account);
        this.notifyService.success('Successfully updated');
      })
      .catch((e) => this.notifyService.error('Error while update'));
  }

  public handleEdit(): void {
    this.mode = 'edit';
  }

  public accuracyNameIcon() {
    return this.accuracyIcon(this.d.accuracy?.businessName?.score);
  }

  public accuracyAddressIcon() {
    return this.accuracyIcon(1); // this.d.accuracy?.address?.score);
  }

  public accuracyPhoneIcon() {
    return this.accuracyIcon(this.d.accuracy?.phoneNumber?.score);
  }

  public accuracyWebsiteIcon() {
    return this.accuracyIcon(this.d.accuracy?.websiteURL?.score);
  }

  public accuracyHoursIcon() {
    return this.accuracyIcon(this.d.accuracy?.businessHours?.score);
  }

  public accuracyNameTip(): string {
    return this.accuracyTip(this.d.accuracy?.businessName?.score, 'businessName');
  }

  public accuracyAddressTip(): string {
    return this.accuracyTip(1/*this.d.accuracy?.address?.score*/, 'address');
  }

  public accuracyPhoneTip(): string {
    return this.accuracyTip(this.d.accuracy?.phoneNumber?.score, 'businessPhone');
  }

  public accuracyWebsiteTip(): string {
    return this.accuracyTip(this.d.accuracy?.websiteURL?.score, 'websiteURL');
  }

  public accuracyHoursTip(): string {
    return this.accuracyTip(this.d.accuracy?.businessHours?.score, 'businessHours');
  }

  private accuracyIcon(score) {
    if (score == 1) {
      return accuracyFullMatch;
    } else if (score > 0) {
      return accuracyPartialMatch;
    } else if (score == 0) {
      return accuracyNoMatch;
    } else if (!score) {
      return accuracyNoData;
    }
  }

  private accuracyTip(score, field): string {
    if (score == 1) {
      return 'Exact Match'; // (' + this.location[field] + ')';
    } else if (score > 0) {
      return 'Partial Match'; // (' + this.location[field] + ')';
    } else if (score == 0) {
      return 'Discrepancy'; // (' + this.location[field] + ')';
    } else {
      return 'No data found on publisher';
    }
  }

  // get current categories by ident
  private getActiveCats(): void {
    this.dbService.CategoryType.loadObjects({where: this.buildWhereClause()}).then(
      () => {
        this.dbService.CategoryType.fillCatsByVendor();

        // this.selectedCategories = this.d.categories.reduce((acc, categoryKey) => {
        //   acc.push(this.dbService.CategoryType.findByCodeForVendor(categoryKey, this.d.vendor));
        //   return acc;
        // }, []);
      },
      (err) => console.log('Categ objects load fail', err)
    );
  }

  // Populate categories (on load + search)) for backend filtering
  private buildWhereClause(): string {
    const whereClauses = [];
    const categories = this.d?.categories || [];

    if (categories && categories.length > 0) {
      const catsWquotes = [];

      for (const cat of categories) {
        catsWquotes.push(`'${cat}'`);
      }

      whereClauses.push(`vendorIdent = '${this.d.vendor}' AND code IN (${catsWquotes.join(',')})`);
    }

    // if no clauses were built, create a where clause that returns nothing (vs everthing)
    if (whereClauses.length == 0) {
      whereClauses.push('false');
    }

    return whereClauses.join(' OR ');
  }
}
