import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SessionService } from '../../backend-adapter/session.service';
import { ILoginData } from 'src/app/core/backend-adapter/session.interfaces';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { IAuthenticateData } from './../../backend-adapter/session.interfaces';
import { MessageService } from 'primeng/api';
import { NotifyService } from '../notifications/notify/notify.service';
import { AccountsService } from 'src/app/acct-comps/accounts.service';

@Component({
  selector: 'app-private-wrapper',
  templateUrl: './private-wrapper.component.html',
  styleUrls: ['./private-wrapper.component.scss'],
})
export class PrivateWrapperComponent implements OnInit, OnDestroy {
  public showDialogEvent$$ = new Subject<void>();
  public refreshBreadcrumbEvent$$ = new Subject<void>();

  currentUser: IAuthenticateData;
  collapsed = true;
  account: IAccount;
  mainAccount; // TODO: delete it?

  private subs = new Subscription();

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.subs.add(
      this.sessionService
        .getCurrentUser$()
        .pipe(filter((v) => v != null))
        .subscribe((user) => (this.currentUser = user))
    );
    this.subs.add(
      this.sessionService
        .getSelectedAccount$()
        .pipe(filter((v) => v != null))
        .subscribe((a) => (this.account = a))
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.showDialogEvent$$.complete();
    this.refreshBreadcrumbEvent$$.complete();
  }

  notInIframe(): boolean {
    try {
      return window.location === window.parent.location ;
    } catch (e) {
      return false;
    }
  }
}
