import { IPaletteType } from '../../theme-config.interfaces';

export const _PALETTE: IPaletteType = {
  // default theme colors
  $defaultThemeParakeetGreen: { rgb: [0, 128, 0], hex: '#008000' },
  $defaultThemeCharcoalGrey: { rgb: [65, 64, 66], hex: '#414042' },
  $whitelabelDeepBlue: { rgb: [20, 113, 185], hex: '#1471b9' },

  // text colors
  $darkGray: { rgb: [65, 64, 66], hex: '#414042' },
  $lightGray: { rgb: [244, 244, 244], hex: '#F4F4F4' },

  // renderseo colors
  $mainGreen: { rgb: [51, 177, 0], hex: '#33b100' },
  $mainOrange: { rgb: [255, 165, 0], hex: '#ffa500' },
  $red: { rgb: [251, 36, 36], hex: '#FB2424' },
  $lightGreen: { rgb: null, hex: '#c4e9bf' },
};
