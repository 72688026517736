import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DBService } from '../db.service';
import { ICollectionWrapper, ILoadParams } from './api.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private dbService: DBService) {}

  get(collectionName: string, params?: ILoadParams, postLoadedSig?): Observable<ICollectionWrapper<any>> {
    return from(this.dbService[collectionName].loadObjects(params, postLoadedSig)) as any;
  }

  post(collectionName: string, obj: any): Observable<any> {
    return from(this.dbService[collectionName].saveObject(obj));
  }
}
