import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FILTER_TYPES, IDates, IFilterData } from './table-filter.interfaces';
import { formatDate } from '@angular/common';
import { TableFilterService } from './table-filter.service';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit, OnDestroy {
  selected;
  dateValue: IDates = {
    start: null,
    end: null,
  };
  isCustomDates = false;
  chipsValues = [];
  regExp = /[a-zA-Z]/g;

  filterTypes: typeof FILTER_TYPES = FILTER_TYPES;
  @Input() data: IFilterData[];
  @Output() filterData: EventEmitter<any> = new EventEmitter();
  public isCustomDateCalendarOpen: boolean = false;

  constructor(private tableFilterService: TableFilterService) {}

  ngOnInit(): void {
    this.selected = { ...this.tableFilterService.savedFilters };
    if (this.selected.DATE != undefined && !this.regExp.test(this.selected.DATE[0])) {
      // check if date is custom date
      this.isCustomDates = true;
      const dateArr = this.selected.DATE[0].split(' - ');
      this.dateValue.start = new Date(dateArr[0]);
      this.dateValue.end = new Date(dateArr[1]);
    }
    this.transformChips();
  }

  onCheckboxChange(title: string, type: FILTER_TYPES) {
    if (type == FILTER_TYPES.DATE) {
      this.clearCustomDates();
    }

    if (type === FILTER_TYPES.SINGLE || type === FILTER_TYPES.DATE) {
      const latestItem = this.selected[title][this.selected[title].length - 1];
      this.selected[title].length = 0;
      if (latestItem) {
        this.selected[title].push(latestItem);
      }
    }

    this.transformChips();
    this.filterData.emit(this.selected); // emit array of filters to parent component
    this.isCustomDateCalendarOpen = this.selected.DATE?.length ? true : false;
  }

  onCustomChange(title: string) {
    this.selected[title] = [];
    this.transformChips();
    this.isCustomDateCalendarOpen = this.selected.DATE.length ? true : false;
    if(!this.isCustomDates && !this.selected[title].length) {
      this.filterData.emit(this.selected); // emit array of filters to parent component
    }
  }

  transformChips() {
    if (this.isCustomDates) {
      const dateChipLabel =
        this.dateValue?.start?.toISOString().slice(0, 10) + ' - ' + this.dateValue?.end?.toISOString().slice(0, 10);
      const newObj = Object.keys(this.selected)
        .map((k, i) => {
          if (k !== 'DATE') {
            return {
              [k]: this.selected[k],
            };
          }
        })
        .filter((e) => e);
      const updatedChips = (this.dateValue?.start || this.dateValue?.end) ?
       Object.assign({}, ...newObj, { DATE: [dateChipLabel] }) : Object.assign({}, ...newObj);
      this.chipsValues = [].concat.apply([], Object.values(updatedChips)); // merge all checkbox values into one array
      this.chipsValues = this.chipsValues.filter((e) => e); // remove all possible undefined
    } else {
      this.chipsValues = [].concat.apply([], Object.values(this.selected)); // merge all checkbox values into one array
      this.chipsValues = this.chipsValues.filter((e) => e); // remove all possible undefined
    }
  }

  clearCustomDates() {
    this.isCustomDates = false;
    this.dateValue.start = this.dateValue.end = null;
  }

  onChipRemove(text: string) {
    Object.entries(this.selected).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        this.selected[key] = value.filter((e) => e != text);
      }
    });
    if (!this.regExp.test(text)) {
      this.clearCustomDates();
    }
    this.transformChips();
    this.filterData.emit(this.selected); // emit array of filters to parent component
  }

  onCalendarSelect(title: string, event: any) {
    if (this.dateValue.end !== null) {
      var followingDay = new Date(this.dateValue.end.getTime() + 86400000);
    }
    if (this.dateValue.start != null && this.dateValue.end != null) {
      const dateChipLabel =
        this.dateValue?.start.toISOString().slice(0, 10) + ' - ' + followingDay.toISOString().slice(0, 10);
      this.selected[title][0] = dateChipLabel;
      this.transformChips();
    }
  }

  public submitCustomDate(): void {
    if (this.selected.DATE.length && this.isCustomDates) {
      this.filterData.emit(this.selected); // emit array of filters to parent component
    }
    const dateChipLabel =
    this.dateValue?.start?.toISOString().slice(0, 10) + ' - ' + this.dateValue?.end?.toISOString().slice(0, 10);
  }

  public openCustomDate(event: any): void {
    this.isCustomDateCalendarOpen = this.selected.DATE.length ? true : false;
  }

  public closeCustomDate(event: any): void {
    this.isCustomDateCalendarOpen = this.selected.DATE.length ? true : false;
  }

  ngOnDestroy(): void {
    this.tableFilterService.savedFilters = { ...this.selected };
  }
}
