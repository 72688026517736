import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-graph',
  templateUrl: './card-graph.component.html',
  styleUrls: ['./card-graph.component.scss'],
})
export class CardGraphComponent {
  @HostBinding('class.rs-card-container') componentClass = true;
  @Input() headerTitle: string;
  @Input() doughnutData: any[];

  @Input() doughnutOptions = {
    layout: {
      padding: 10, // overall padding
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    // disable hover, not needed
    events: [],
  };
}
