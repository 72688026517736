import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SuccessComponent } from 'src/app/gbp-management/gbp-tab-post/components/gbp-post-create/success/success.component';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-qa-delete',
  templateUrl: './gbp-qa-delete.component.html',
  styleUrls: ['./gbp-qa-delete.component.scss'],
})
export class GbpQaDeleteComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dialogService: DialogService,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {}

  deleteItem() {
    const tempObj = { ...this.dynamicDialogConfig.data, _status: 'DP' };
    this.dialogService.open(SuccessComponent, {
      width: '20%',
    });
    this.gbpService.updateBulkOwnerQa(tempObj);
    this.ref.close();
  }

  closeDialog() {
    this.ref.close();
  }
}
