import { NgModule } from '@angular/core';
import { RSCommonModule } from '../core/feature-modules/facade.module';
import { UtmManagementComponent } from './utm-management.component';
import { UtmTabModule } from './utm-tab/utm-tab.module';
import { UTMService } from './services/utm.service';

@NgModule({
  declarations: [UtmManagementComponent],
  exports: [UtmManagementComponent],
  imports: [RSCommonModule, UtmTabModule],
  providers: [UTMService],
})
export class UtmManagementModule {}
