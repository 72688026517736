import {Pipe, PipeTransform} from '@angular/core';
import {ACTIVITY_STATUS} from '../gbp-tabs.constants';

@Pipe({
  name: 'getStatus',
})
export class GetStatusPipe implements PipeTransform {
  activityStatus: typeof ACTIVITY_STATUS = ACTIVITY_STATUS;

  transform(status: string): string {
    const returnStr =
      Object.keys(ACTIVITY_STATUS)[Object.values(ACTIVITY_STATUS).indexOf(status as unknown as ACTIVITY_STATUS)];
    console.log(returnStr);
    switch (returnStr) {
      case 'DELETE_SCHEDULED':
      case 'DELETE_PROCESSING':
        return 'DELETING';
      case 'UNPUBLISH_SCHEDULED':
      case 'UNPUBLISH_PROCESSING':
        return 'UNPUBLISHING';
      case 'PROCESSING':
        return 'PUBLISHING';
      default:
        return returnStr;
    }
  }
}
