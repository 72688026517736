import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { PandioReviews } from "../model/pandio-reviews";
import { ICols, IFitersObject, IReviewData, IReviewDataResponse, IReviewsMetrics, IReviewsMetricsResponse } from "../review-management.interfaces";
import { PandioReviewsDataService } from "../services/pandio-reviews-data-service";
import { Table } from 'primeng/table';
import { IFilterData, GraphCount } from "src/app/core/feature-modules/table-filter/table-filter.interfaces";
import { DBService } from "src/app/core/backend-adapter/db.service";
import { SessionService } from "src/app/core/backend-adapter/session.service";
import { SocketService } from "src/app/core/backend-adapter/socket.service";
import { NotifyService } from "src/app/core/layouts/notifications/notify/notify.service";
import { PandioReviewsConfigService } from '../services/pandio-reviews-config-service';
import { ExportDataToFile } from "../services/export-reviews-file.service";
import { DeletePopupComponent } from "../components/delete-popup/delete-popup.component";
import { DialogService } from "primeng/dynamicdialog";
import { FILTER_TYPES  } from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';

@Component({
  selector: 'app-pandio-reviews-tab',
  templateUrl: './pandio-reviews-tab.component.html',
  styleUrls: ['./pandio-reviews-tab.component.scss'],
})

export class PandioReviewsTabComponent implements OnInit, OnDestroy {
  @ViewChild('reviewTbl', { static: false }) reviewTbl: Table;
  /** To unsubscribe when component gets destroyed */
  private ngSubscribe$ = new Subject();
  private sortOrder: string = '';
  /** Store all reviews data */
  private allReviewsData: IReviewData[] = [];
  private offset: number = 0;
  /** To allow user to download all reviews available in the view. */
  private enableSelectAllReviews: boolean = false;
  /** Property to set pandio reviews count */
  public reviewsCountGraphData: PandioReviews;
  /** Options object for horizontal bar graph */
  public options: unknown;
  /** Property to hide/display the accordian/reviews component */
  public displayReviewsCard: boolean = true;
  /** Prop to store pandios reviews data for table */
  public reviewsTableData: IReviewData[] = [];
  /** Table page size */
  public pageSize: number = 50;
  /** Total number of reviews returned by API */
  public totalReviewsCount: number;
  /** Reviews table column's title */
  public cols: ICols[] = [];
  /**To highlight the selected reviews by user in the UI */
  public selectedReviews: IReviewData[] = [];
  /** Set table columns header dynamically */
  public tableColumnsHeader;
  /** Total reviews */
  public totalReviews: string = '';
  /** Filter table by Directories  */
  public filterData: IFilterData[] = [];
  /** Action itmes to download reviews report */
  public actionItems = [
    // {
    //   label: 'Selected (none)',
    //   command: () => {
    //     this.exportReviews(this.reviewTbl);
    //   },
    //   disabled: true,
    // },
    {
      label: `This page (0 items)`,
      command: () => {
        this.exportAllReviews(this.reviewTbl);
      },
      disabled: false,
      id: 'selectAll'
    },
    {
      label: 'All items (up to 25,000)',
      command: () => {
        this.exportFilteredReviews();
      }
    }
  ];
  /** Average ratings for directories. */
  public averageDirectoriesRating = [];
  /** Set graph options for stacked bar graph. */
  public stackedGraphOptions: unknown;
  /** Sentiment reports for Directories porp */
  public sentimentReportsData: PandioReviews;
  public keyword: string;
  public reviewData;
  chipsArray: string[] = [];
  reviewsData: IReviewsMetrics;
  filters: IFitersObject = {};
  // Refactor this later........
  totalReviewsTP: number;
  last30DaysReviewsTP: number;
  public selectAllReviews: boolean = false;
  /** Stores user's search */
  public search: string = '';
  public startDate: string;
  public endDate: string;

  private selectedDateRange: string = 'DefaultDate';
  private dateRangeSet = new Set(['This month', 'Last 30 days', 'Last month', 'Year to date', 'Last 12 months', 'All time']);

  constructor(
    private pandioReviewsdataService: PandioReviewsDataService,
    public dbService: DBService,
    public sessionService: SessionService,
    public socketService: SocketService,
    private notifyService: NotifyService,
    private pandioReviewsConfigService: PandioReviewsConfigService,
    private exportDataToFile: ExportDataToFile,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.tableColumnsHeader = this.pandioReviewsConfigService.generateTableHeaderData();
    this.options = this.pandioReviewsConfigService.initReviewsGraph();
    this.cols = this.pandioReviewsConfigService.tableColsHeader;
    this.stackedGraphOptions = this.pandioReviewsConfigService.generateStackedSentimentReportsGraph();
    this.getAllMetricsData();
    this.loadReviews();

    this.filterData.push(
      {
        type: FILTER_TYPES.MULTI,
        title: 'REVIEWS',
        options: ['Responded to', 'Not yet responded to'],
      },
      {
        type: FILTER_TYPES.MULTI,
        title: 'RATINGS',
        options: ['5', '4', '3', '2', '1'],
      },
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: ['This month', 'Last 30 days', 'Last month', 'Year to date', 'Last 12 months', 'All time'],
      },
      {
        type: FILTER_TYPES.SINGLE,
        title: 'REVIEW TEXT',
        options: ['Review includes text', 'Review does not include text'],
      }
    );

  }

  /**
  * @description To hide/display the overviews component.
  * @returns void
  */
  public toggleReviewsCardView(): void {
    this.displayReviewsCard = !this.displayReviewsCard;
  }

  public lazyLoadLocations(event: any): void {
    this.offset = event.first;
    this.reviewsTableData = this.allReviewsData.slice(event.first, event.rows * 2);
    if (event.sortField) {
      this.sortOrder = `${event.sortField}${event.sortOrder < 0 ? ' DESC' : ''}`;
    }
    this.loadReviews();
  }

  /**
  * To select a review from the table's list and can be used to download CSV.
  * @param event event is the selected row/review from the table.
  */
  public onRowSelect(event): void {
    // if (this.selectedReviews.length > 0) {
    //   this.actionItems[1].disabled = false;
    //   this.actionItems[0].label = `Selected (${this.selectedReviews.length})`;
    // } else {
    //   this.actionItems[1].disabled = true;
    //   this.actionItems[0].label = `Selected (none)`;
    // }
  }

  /**
  * @description To filter table data based on selection.
  * @param event
  * @returns void
  */
  public filterTableData(event): void {
    this.chipsArray = [].concat.apply([], Object.values(event)); // merge all checkbox values into one array
    this.chipsArray = this.chipsArray.filter((e) => e); // remove all possible undefined

    if(!event.hasOwnProperty('DATE')) {
      event = { ...event, DATE: ['DefaultDate'] };
    } else if(event.hasOwnProperty('DATE') && !event['DATE'].length) {
      event = { ...event, DATE: ['DefaultDate'] };
    }

    this.filters = event;
    this.getAllMetricsData();
    this.loadReviews();
  }

  /**
  * @description To download individual reviews report.
  * @param table
  * @param all
  */
  private exportReviews(table: Table, all?: boolean) {
    table.exportCSV(all ? '' : { selectionOnly: true });
  }

  /**
  * @description To download single/multiple/all reviews report.
  * @param table
  */
  public exportAllReviews(table: Table): void {
    this.enableSelectAllReviews = !this.enableSelectAllReviews;
    if (this.enableSelectAllReviews) {
      this.selectedReviews = this.reviewData
      table.exportCSV();
    }
  }

  /**
  * @description To open a new browser tab to enable reviews
  * @param review: IReviewData
  */
  public openExternalResponseLink(review: IReviewData): void {
    window.open(review.attrs?.url, '_blank');
  }

  loadReviews() {
    this.dbService.ReviewGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      order: this.sortOrder,
      where: this.buildWhereClause(),
    })
      .then(
        (repl: IReviewDataResponse) => {
          this.setDirectoriesByVendorIdent(repl);
          this.totalReviewsCount = repl.totalEntries;

          this.actionItems.forEach(item => {
            if(item.id === 'selectAll') {
              item.label = `This page (${this.reviewData.length} items)`
            }
          });

          for (const obj of this.reviewData) {
            if (obj.reviewerComment != null && obj.reviewerComment.length > 55) {
              obj.reviewTooltip = `<span style="font-style: italic; padding-bottom: 15px">"${obj.reviewerComment}"</span>
                           <p style="text-align: left; margin-top: 12px"><b>Posted by</b> ${obj.reviewerName}</p>
                           <p style="text-align: left; margin: 0">on <b>${obj.reviewCreatedAt.day}/${obj.reviewCreatedAt.month}/${obj.reviewCreatedAt.year} ${obj.reviewCreatedAt.hour}:${obj.reviewCreatedAt.minute}</b></p>`;
            }
          }
        },
        (err: Error) => {
          this.reviewData = [];
          console.warn('Error loading ReviewGMB: %j', err);
        }
      )
  }

  private buildWhereClause(): string[] {
    const filterClauses: string[][] = [];
    const tempFilterArr: string[] = [];
    const isFiltersApplied = this.chipsArray?.length > 0;

    const pushQueriesToMainArray = () => {
      let filterClause: Array<string> = ['-or'];
      filterClause = filterClause.concat(tempFilterArr);
      filterClauses.push(filterClause);
      tempFilterArr.length = 0;
    };

    if (this.chipsArray?.length) {
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.length > 0 && value[0] != undefined) {
          switch (key) {
            case 'DIRECTORIES':
              value.forEach((tag: string) => {
                switch (tag) {
                  case 'Tripadvisor':
                    tempFilterArr.push(`vendorIdent = 'tripadvisor.com-lde'`);
                    break;
                  case 'ZocDoc':
                    tempFilterArr.push(`vendorIdent = 'zocdoc.com-lde'`);
                    break;
                  case 'Vitals':
                    tempFilterArr.push(`vendorIdent = 'vitals.com-lde'`);
                    break;
                  case 'Healthgrades':
                    tempFilterArr.push(`vendorIdent = 'healthgrades.com-lde'`);
                    break;
                  case 'Indeed.com':
                    tempFilterArr.push(`vendorIdent = 'indeed.com-lde'`);
                    break;
                  case 'Glassdoor.com':
                    tempFilterArr.push(`vendorIdent = 'glassdoor.com-lde'`);
                    break;
                  // healthgrades.com-lde and ratemds.com-lde for Healthgrades and RateMDs
                }
              });
              pushQueriesToMainArray();
              break;
            case 'DATE':
              value.forEach((element: string) => {
                this.selectedDateRange = element;
                switch (element) {
                  case 'This month':
                    tempFilterArr.push(`extract(month from r.date) = extract(month from current_date) and extract(year from r.date) = extract(year from current_date)`);
                    break;
                  case 'Last 30 days':
                    tempFilterArr.push(`r.date >= (now() - interval '30 day')`);
                    break;
                  case 'Last month':
                    tempFilterArr.push(
                      `extract(month from r.date) = extract(month from current_date) - 1 and extract(year from r.date) = extract(year from current_date)`
                    );
                    break;
                  case 'Year to date':
                    tempFilterArr.push(`extract(year from r.date) = extract(year from current_date)`);
                    break;
                  case 'Last 12 months':
                    tempFilterArr.push(`r.date > (now() - interval '12 month')`);
                    break;
                  case 'All time':
                    break;
                  case 'DefaultDate':
                    this.startDate = new Date().toISOString().slice(0, 10);
                    this.endDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
                    tempFilterArr.push(`r.date > (now() - interval '30 day')`);
                    break;
                  default:
                    const dateArray: string[] = element.split(' - ');
                    this.startDate = dateArray[0];
                    this.endDate = dateArray[1];
                    tempFilterArr.push(`reviewCreatedAt >= '${dateArray[0]}' AND reviewCreatedAt <= '${dateArray[1]}'`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'REVIEW TEXT':
              value.forEach((element: string) => {
                switch (element) {
                  case 'Review includes text':
                    tempFilterArr.push(`reviewercomment IS NOT NULL`);
                    break;
                  case 'Review does not include text':
                    tempFilterArr.push(`reviewercomment IS NULL`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
          }
        }
      }
    } else {
      this.selectedDateRange = 'DefaultDate';
      this.startDate = new Date().toISOString().slice(0, 10);
      this.endDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
      // Adding default lst 30 days option if no date filter is selected.
      tempFilterArr.push(`r.date >= (now() - interval '30 day')`);
      pushQueriesToMainArray();
      this.startDate = new Date().toISOString().slice(0, 10);
      this.endDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
    }

    if (this.keyword) {
      tempFilterArr.push(`storeCode::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`businessName::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`reviewerComment::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`starRating::text ILIKE '${this.keyword}%'`);
      pushQueriesToMainArray()
    }

    let whereClauseArray: Array<any> = ['-and', "r._status IN ('A', 'UA', 'UP')",];
    whereClauseArray.push(`vendorIdent LIKE '%-lde'`);
    whereClauseArray = whereClauseArray.concat(filterClauses);

    return whereClauseArray;
  }

  /**
   * @description Set's reviews count graph data.
   * @param graphObj: GraphCount
   */
  private setReviewsCountData(graphObj: GraphCount): void {
    if (graphObj.tripdavisorReviews || graphObj.tripadvisorTotalReviews) {
      this.reviewsCountGraphData['labels'].push('Tripadvisor');
      this.reviewsCountGraphData['datasets'][0].data.push(graphObj.tripadvisorTotalReviews);
      this.reviewsCountGraphData['datasets'][1].data.push(graphObj.tripdavisorReviews);
    }
    if (graphObj.zocDocTotalReviews || graphObj.zocDocReviews) {
      this.reviewsCountGraphData['labels'].push('ZocDoc');
      this.reviewsCountGraphData['datasets'][0].data.push(graphObj.zocDocTotalReviews);
      this.reviewsCountGraphData['datasets'][1].data.push(graphObj.zocDocReviews);
    }
    if (graphObj.vitalsTotalReviews || graphObj.vitalsReviews) {
      this.reviewsCountGraphData['labels'].push('Vitals');
      this.reviewsCountGraphData['datasets'][0].data.push(graphObj.vitalsTotalReviews);
      this.reviewsCountGraphData['datasets'][1].data.push(graphObj.vitalsReviews);
    }
    if (graphObj.healthGradesTotalReviews || graphObj.healthGradesReviews) {
      this.reviewsCountGraphData['labels'].push('Healthgrade');
      this.reviewsCountGraphData['datasets'][0].data.push(graphObj.healthGradesTotalReviews);
      this.reviewsCountGraphData['datasets'][1].data.push(graphObj.healthGradesReviews);
    }
    if (graphObj.indeedTotalReviews || graphObj.indeedReviews) {
      this.reviewsCountGraphData['labels'].push('Indeed.com');
      this.reviewsCountGraphData['datasets'][0].data.push(graphObj.indeedTotalReviews);
      this.reviewsCountGraphData['datasets'][1].data.push(graphObj.indeedReviews);
    }
    if (graphObj.glassdoorTotalReviews || graphObj.glassdoorReviews) {
      this.reviewsCountGraphData['labels'].push('Glassdoor.com');
      this.reviewsCountGraphData['datasets'][0].data.push(graphObj.glassdoorTotalReviews);
      this.reviewsCountGraphData['datasets'][1].data.push(graphObj.glassdoorReviews);
    }
  }

  /**
   * @description To set average rating on the overviews card.
   * @param graphObj: GraphCount
   */
  private setAvgRating(graphObj: GraphCount): void {
    this.averageDirectoriesRating = [
      {
        name: graphObj.tripdavisorAvgRatings ? 'Tripadvisor' : '',
        rating: graphObj.tripdavisorAvgRatings || null
      },
      {
        name: graphObj.zocDocAvgRatings ? 'ZocDoc' : '',
        rating: graphObj.zocDocAvgRatings || null
      },
      {
        name: graphObj.vitalsAvgRatings ? 'Vitals' : '',
        rating: graphObj.vitalsAvgRatings || null
      },
      {
        name: graphObj.healthGradesAvgRatings ? 'Healthgrade' : '',
        rating: graphObj.healthGradesAvgRatings || null
      },
      {
        name: graphObj.indeedAvgRatings ? 'Indeed.com' : '',
        rating: graphObj.indeedAvgRatings || null
      },
      {
        name: graphObj.glassdoorAvgRatings ? 'Glassdoor.com' : '',
        rating: graphObj.glassdoorAvgRatings || null
      },
    ];
  }

  /**
   * @description Set's sentiments graph data.
   * @param graphObj: GraphCount
   */
  private setSentimentsGraphData(graphObj: GraphCount): void {
    if (graphObj.tripadvisorHighCount || graphObj.tripadvisorNeutralCount || graphObj.tripadvisorPoorCount) {
      this.sentimentReportsData['labels'].push('Tripadvisor');
      this.sentimentReportsData['datasets'][0].data.push(graphObj.tripadvisorHighCount);
      this.sentimentReportsData['datasets'][1].data.push(graphObj.tripadvisorNeutralCount);
      this.sentimentReportsData['datasets'][2].data.push(graphObj.tripadvisorPoorCount);

    }
    if (graphObj.zocDocHighCount || graphObj.zocDocNeutralCount || graphObj.zocDocPoorCount) {
      this.sentimentReportsData['labels'].push('ZocDoc');
      this.sentimentReportsData['datasets'][0].data.push(graphObj.zocDocHighCount);
      this.sentimentReportsData['datasets'][1].data.push(graphObj.zocDocNeutralCount);
      this.sentimentReportsData['datasets'][2].data.push(graphObj.zocDocPoorCount);

    }
    if (graphObj.vitalsHighCount || graphObj.vitalsNeutralCount || graphObj.vitalsPoorCount) {
      this.sentimentReportsData['labels'].push('Vitals');
      this.sentimentReportsData['datasets'][0].data.push(graphObj.vitalsHighCount);
      this.sentimentReportsData['datasets'][1].data.push(graphObj.vitalsNeutralCount);
      this.sentimentReportsData['datasets'][2].data.push(graphObj.vitalsPoorCount);

    }
    if (graphObj.healthGradesHighCount || graphObj.healthGradesNeutralCount || graphObj.healthGradesPoorCount) {
      this.sentimentReportsData['labels'].push('Healthgrade');
      this.sentimentReportsData['datasets'][0].data.push(graphObj.healthGradesHighCount);
      this.sentimentReportsData['datasets'][1].data.push(graphObj.healthGradesNeutralCount);
      this.sentimentReportsData['datasets'][2].data.push(graphObj.healthGradesPoorCount);
    }
    if (graphObj.indeedHighCount || graphObj.indeedNeutralCount || graphObj.indeedPoorCount) {
      this.sentimentReportsData['labels'].push('Indeed.com');
      this.sentimentReportsData['datasets'][0].data.push(graphObj.indeedHighCount);
      this.sentimentReportsData['datasets'][1].data.push(graphObj.indeedNeutralCount);
      this.sentimentReportsData['datasets'][2].data.push(graphObj.indeedPoorCount);
    }
    if (graphObj.glassdoorHighCount || graphObj.glassdoorNeutralCount || graphObj.glassdoorPoorCount) {
      this.sentimentReportsData['labels'].push('Glassdoor.com');
      this.sentimentReportsData['datasets'][0].data.push(graphObj.glassdoorHighCount);
      this.sentimentReportsData['datasets'][1].data.push(graphObj.glassdoorNeutralCount);
      this.sentimentReportsData['datasets'][2].data.push(graphObj.glassdoorPoorCount);
    }
  }

  /**
   * @description Method to set Direectory name based on the vendorIdent.
   * @param repl: IReviewDataResponse
   */
  private setDirectoriesByVendorIdent(repl: IReviewDataResponse): void {
    this.reviewData = repl.collection?.map(el => {
      switch (el.vendorIdent) {
        case 'tripadvisor.com-lde':
          return {
            ...el,
            directory: 'Tripadvisor',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
        case 'zocdoc.com-lde':
          return {
            ...el,
            directory: 'ZocDoc',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
        case 'vitals.com-lde':
          return {
            ...el,
            directory: 'Vitals',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
        case 'healthgrades.com-lde':
          return {
            ...el,
            directory: 'Healthgrades',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
        case 'indeed.com-lde':
          return {
            ...el,
            directory: 'Indeed.com',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
        case 'glassdoor.com-lde':
          return {
            ...el,
            directory: 'Glassdoor.com',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
        default:
          return {
            ...el,
            directory: '',
            tags: el?.tags && el?.tags.length && el.tags.join(', ').replace(/_/g, ' ').split(',')
          }
      }
    });
  }

  /**
   * @description Method is called to get all review metrics data for Other reviews page.
   */
  private getAllMetricsData(): void {
    this.socketService.sendRequest('get-review-metrics',
      {
        excludePast30Days: true,
        where: this.buildWhereClause()
      }
    ).then(
      (repl: IReviewsMetricsResponse) => {
        const graphObj: any = this.pandioReviewsdataService.generateOverviewCardsData(repl);
        this.reviewsCountGraphData = this.pandioReviewsConfigService.setReviewCountGraphConfig();
        this.sentimentReportsData = this.pandioReviewsConfigService.setRatingDistributionGraphConfig();
        this.setReviewsCountData(graphObj);
        this.setAvgRating(graphObj);
        this.setSentimentsGraphData(graphObj);
        this.filterData = [...this.pandioReviewsConfigService.otherReviewsGlobalFilters];

        this.totalReviewsTP = this.pandioReviewsdataService.calcTotalReviews(graphObj);

        this.totalReviews = this.totalReviewsTP.toLocaleString();
        const currData = repl.collection[0];
        if (this.dateRangeSet.has(this.selectedDateRange)) {
          this.startDate = repl['collection'][0]?.dateStart;
          this.endDate = repl['collection'][0]?.dateEnd;
        }
        if (currData) {
          this.reviewsData = currData;
          this.totalReviewsCount = currData.totalReviews;
          // this.loadReviews();
        } else {
          this.notifyService.error('There is no data for this account');
        }
      },
      (err: Error) => {
        this.notifyService.error('There is no data for this account');
        console.warn('Review metrics error:', err);
      }
    );
  }

  public exportFilteredReviews(): void {
    const params = {
      order: this.sortOrder,
      where: this.buildWhereClause()
    };

    this.exportDataToFile.getReviews(params);
  }

  handleSelectAllReviews(event) {

  }

  public deleteReview(review): void {
    this.dialogService.open(DeletePopupComponent, {
      data: { ...review, actionType: 'Review', },
      closable: false,
      width: '40%',
    }).onClose.subscribe(res => {
      if(res) {
        this.notifyService.success('Success: Review deleted successfully.');
        this.loadReviews();
      } else {
        this.notifyService.error('Error: Error in deleting the Review. Please try again!');
      }
    });
  }

  public userSearch(): void {
    this.getAllMetricsData();
    this.loadReviews();
  }

  ngOnDestroy() {
    this.ngSubscribe$.next();
    this.ngSubscribe$.complete();
  }

}
