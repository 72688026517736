import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-question-mark',
  template: `<div
    class="question-mark"
    [pTooltip]="tooltip"
    tooltipStyleClass="appFixTooltipWidth"
    tooltipPosition="top"
  >
    ?
  </div> `,
  styles: [
    `
      .question-mark {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border: solid 1px #cac8c8;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background-color: #cac8c8;
        font-size: 12px;
        font-weight: bolder;
        margin: 3px;
      }
    `,
  ],
})
export class RsQuestionMarkComponent {
  @Input() tooltip: string;
}
