import { NgModule } from '@angular/core';
import { SidebarMenuComponent } from './menus/sidebar-menu/sidebar-menu.component';
import { SidebarMenuAccountSelectorComponent } from './menus/sidebar-menu-account-selector/sidebar-menu-account-selector.component';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { ClickOutsideModule } from '../feature-modules/click-outside/click-outside.module';
import { PublicWrapperComponent } from './public-wrapper/public-wrapper.component';
import { PrivateWrapperComponent } from './private-wrapper/private-wrapper.component';
import { BreadcrumbsComponent } from './menus/breadcrumbs/breadcrumbs.component';
import { TopRightMenuBlockComponent } from './menus/top-right-menu-block/top-right-menu-block.component';
import { ChangePassDialogComponent } from './change-pass-dialog/change-pass-dialog.component';
import { NoticebarModule } from './notifications/noticebar/noticebar.module';
import { NotifyModule } from './notifications/notify/notify.module';
import { GetParentNamePipe } from './menus/sidebar-menu-account-selector/get-parent-name.pipe';

const LAYOUT_COMPONENTS = [PublicWrapperComponent, PrivateWrapperComponent];

@NgModule({
  imports: [RSCommonModule, ClickOutsideModule, NoticebarModule, NotifyModule],

  declarations: [
    ...LAYOUT_COMPONENTS,
    ChangePassDialogComponent,
    BreadcrumbsComponent,
    TopRightMenuBlockComponent,
    SidebarMenuComponent,
    SidebarMenuAccountSelectorComponent,
    GetParentNamePipe,
  ],
  providers: [],
  exports: [...LAYOUT_COMPONENTS],
})
export class LayoutsModule {}
