<div class="review-submit-wrapper">
  <div *ngIf="uploadGroup?.value?.imageUrl" class="review-submit-row">
    <div class="review-submit-title">Media</div>
    <div class="review-submit-text">
      <div class="review-submit-img">
        <img *ngIf="!isFileVideo" [src]="uploadGroup.value.imageUrl" height="300px" />
        <video *ngIf="isFileVideo" width="100%" height="100%" controls>
          <source [src]="uploadGroup.value.imageUrl" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <div *ngIf="mediaCategory" class="review-submit-row">
    <div class="review-submit-title">Category</div>
    <div class="review-submit-text">
      {{ photoAttrsGroup.value.category }}
    </div>
  </div>
  <div class="review-submit-row">
    <div class="review-submit-title">Schedule Media For</div>
    <div *ngIf="!scheduleGroup.value.checked" class="review-submit-text">Now</div>
    <div *ngIf="scheduleGroup.value.checked" class="review-submit-text">
      {{ scheduleGroup.value.date | date: 'MM/dd/yyyy' }} {{ scheduleGroup.value.time | date: 'shortTime' }}
      {{ scheduleGroup.value.timeZone }}
    </div>
  </div>
  <div class="review-submit-row">
    <div class="review-submit-title">Location</div>
    <div class="review-submit-text">
      <span>{{ selectedLocationsCount }} Locations Selected</span>
    </div>
  </div>
</div>
