import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import { GbpPhotoOptimizeComponent } from '../gbp-photo-optimize/gbp-photo-optimize.component';

@Component({
  selector: 'app-gbp-select-locations',
  templateUrl: './gbp-select-locations.component.html',
  styleUrls: ['./gbp-select-locations.component.scss'],
})
export class GbpSelectLocationsComponent implements OnInit {
  timeZones = [];
  isScheduled = false;
  isPublished = false;
  scheduleSuccess = false;
  formValue = {};

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.timeZones = TIME_ZONES;
  }

  closeDialog(): void {
    this.ref.close();
  }

  publishNow(): void {
    // call to backend will be here
    this.isPublished = true;
  }

  optimizeNow(): void {
    // call to backend will be here
    this.ref.close();
    this.dialogService.open(GbpPhotoOptimizeComponent, {
      width: '80%',
      data: { ...this.dynamicDialogConfig.data },
    });
  }

  schedule(): void {
    // call to backend will be here
    this.scheduleSuccess = true;
  }
}
