<div class="header-parent-title">
  <div class="header-child-title">Delete Item</div>
  <div class="header-child-title">.</div>
</div>

<div class="gbp-photo-delete-wrapper">Are you sure you want to delete this photo? This action cannot be undone.</div>

<div class="bottom-buttons">
  <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
  <button pButton label="DELETE" class="p-button-primary" (click)="deleteItem()"></button>
</div>
