<div class="login-page" *ngIf="!sessionService.isLoggedIn()">
  <div class="logo">
    <!-- <img alt="Logo" src="/assets/login/RenderSEO_Logo_Full_Light.png" /> -->
  </div>

  <div class="login-body">
    <div class="filler"></div>
    <div class="right">
      <div class="Modal">
        <div class="login-modal auth-modal-container" *ngIf="!resetPwdFlow">
          <h1 class="titlebar">{{ wasRecentlyLogged ? 'Welcome back' : 'Hello, there' }}<span>!</span></h1>
          <h3 class="title-two">Log in to your account</h3>
          <form id="loginForm" name="loginForm" class="css-form" novalidate>
            <div class="form-content">
              <div class="error" [style.visibility]="errMessage ? 'visible' : 'hidden'">
                <fa-icon [icon]="exclamation"></fa-icon>
                {{ errMessage }}
              </div>
              <span class="inline-input-label p-input-filled">
                <input
                  pInputText
                  type="text"
                  id="login-email"
                  name="loginId"
                  autocapitalize="off"
                  placeholder="Your email"
                  [(ngModel)]="loginId"
                  (input)="errMessage = null"
                />
              </span>
              <span class="inline-input-label p-input-filled">
                <input
                  pInputText
                  [type]="passwordType"
                  id="password-email"
                  name="password"
                  autocapitalize="off"
                  placeholder="Password"
                  [(ngModel)]="password"
                  (input)="errMessage = null"
                />
                <button pButton type="button" class="p-button-text eye" (click)="toggleShowHide()">
                  <span *ngIf="passwordType == 'text'" class="material-icons-outlined">visibility</span>
                  <span *ngIf="passwordType !== 'text'" class="material-icons-outlined">visibility_off</span>
                </button>
              </span>
            </div>

            <!-- (click)="resetPwdFlow = true" -->
            <span pTooltip="Contact Support to reset your password." tooltipPosition="top" class="pwd-reset on-login">
              Forgot your password?
            </span>

            <div class="footer">
              <!-- <span
              class="ng-cloak"
              style="
                float: left;
                font-size: 85%;
                font-style: italic;
                margin-top: 19px;
              "
              >{{ serverName }}</span
            > -->
              <!-- <button
              pButton
              id="resetButton"
              tabindex="4"
              class="p-button-rounded p-button-outlined"
              type="button"
              (click)="reset()"
              label="Reset"
            ></button> -->

              <!-- [disabled]="!inputsValid()" -->

              <button
                pButton
                class="login-button p-button-primary"
                id="authButton"
                tabindex="3"
                [disabled]="logging"
                [icon]="logging ? 'pi pi-spin pi-spinner' : ''"
                [label]="logging ? '' : 'LOG IN'"
                (click)="login()"
              ></button>
            </div>
          </form>
          <span class="row-flex">
            <p-checkbox
              name="Keep me logged in"
              value="val1"
              [(ngModel)]="selectedValues"
              [style]="{ marginRight: '20px' }"
            ></p-checkbox>
            <div class="labels">Keep me logged in</div></span
          >
        </div>
        <div class="pwd-reset-modal auth-modal-container" *ngIf="resetPwdFlow">
          <div class="pwd-change-content" [ngSwitch]="resetStep">
            <div class="input-email" *ngSwitchCase="'input-email'">
              <h1 class="titlebar">Password Reset<span>.</span></h1>
              <p>A link to reset your password will be sent to your email, if an account exists.</p>
              <input
                pInputText
                type="text"
                id="reset-email"
                name="pwdResetEmail"
                autocapitalize="off"
                placeholder="Email address"
                [(ngModel)]="pwdResetEmail"
              />

              <button
                pButton
                id="pwdReset"
                class="reset-button p-button-rounded p-button-primary"
                (click)="sendResetEmail()"
                [disabled]="!pwdResetEmail.length"
                label="SEND EMAIL"
              ></button>
            </div>

            <div class="email-sent" *ngSwitchCase="'email-sent'">
              <h1 class="titlebar">Password Reset Sent<span>!</span></h1>
              <p>
                Keep an eye on your inbox. If you have not received an email within 5 minutes, check your spam folder
                and contact support or your account manager.
              </p>
            </div>

            <div class="email-reset" *ngSwitchCase="'email-reset'">
              <h1 class="titlebar">Password Reset<span>.</span></h1>

              <input
                pInputText
                id="new-pass"
                name="newPass"
                type="text"
                autocapitalize="off"
                placeholder="New password"
                [(ngModel)]="newPass"
                (input)="validateInput($event.target.value)"
              />

              <input
                pInputText
                id="confirm-pass"
                name="confirmPass"
                type="text"
                autocapitalize="off"
                autocomplete="off"
                placeholder="Confirm new password"
                [(ngModel)]="confirmPass"
                (input)="passwordsMatch()"
              />

              <div class="rules">
                <label>Your password must:</label>
                <span class="rule">
                  <fa-icon
                    [ngClass]="styleForRule(hasRequiredLength, newPass)"
                    [icon]="iconForRule(hasRequiredLength, newPass)"
                  ></fa-icon>
                  <label>Contain at least 8 characters</label>
                </span>

                <span class="rule">
                  <fa-icon
                    [ngClass]="styleForRule(requiresLetter, newPass)"
                    [icon]="iconForRule(requiresLetter, newPass)"
                  ></fa-icon>
                  <label>Have at least one (1) letter</label>
                </span>

                <span class="rule">
                  <fa-icon
                    [ngClass]="styleForRule(requiresDigit, newPass)"
                    [icon]="iconForRule(requiresDigit, newPass)"
                  ></fa-icon>
                  <label>Have at least one (1) number</label>
                </span>

                <span class="rule">
                  <fa-icon
                    [ngClass]="passStyle(newPass, confirmPass)"
                    [icon]="passIcon(newPass, confirmPass)"
                  ></fa-icon>
                  <label>Must match both entries</label>
                </span>

                <span class="rule err" *ngIf="errMsg">
                  <fa-icon [ngClass]="styleForRule(false)" [icon]="iconForRule(false)"></fa-icon>
                  <label>{{ errMsg }}</label>
                </span>
              </div>

              <button
                pButton
                id="pwdReset"
                class="p-button-rounded p-button-primary"
                (click)="changePassword()"
                [disabled]="haveErr"
                label="RESET PASSWORD"
              ></button>
            </div>

            <div class="req-sent" *ngSwitchCase="'req-sent'">
              <h1 class="titlebar">Success<span>!</span></h1>
              <p>Your password has been reset. You can now log in to your account with your new password.</p>

              <!-- [disabled]="!inputsValid()" -->
              <button
                pButton
                id="authButton"
                tabindex="3"
                class="p-button-primary"
                (click)="backToLogin()"
                label="LOGIN"
              ></button>
            </div>
          </div>

          <p
            class="pwd-reset back"
            *ngIf="resetStep == 'input-email' || resetStep == 'email-sent'"
            (click)="backToLogin()"
          >
            Back to login
          </p>
        </div>
      </div>
    </div>
  </div>
  <ng-template *ngTemplateOutlet="footer"></ng-template>
</div>

<ng-template #footer>
  <div class="footer-bottom">
    <div class="flex" *ngIf="!isWhitelabeled">
      <span class="left-logos">
        <a href="https://renderseo.com/" target="_blank" class="website link-text">
          renderseo.com
          <!-- <img class="render-icon" alt="RenderSEOLogo" src="/assets/login/RenderSEO.com.png"/> -->
        </a>
        <a href="https://www.facebook.com/RenderSEO" target="_blank" class="social-icon"
          ><img class="social-icons" alt="Facebook Logo" src="/assets/login/facebook-icon.png" />
        </a>
        <a href="https://www.linkedin.com/company/renderseo" target="_blank" class="social-icon">
          <img class="social-icons" alt="LinkedIn Logo" src="/assets/login/linked-in-icon.png"
        /></a>
        <a href="https://www.instagram.com/renderseo/?hl=en" target="_blank" class="social-icon">
          <img class="social-icons" alt="Instagram" src="/assets/login/instagram-icon.png" />
        </a>
        <a href="https://twitter.com/renderseo?lang=en" target="_blank" class="social-icon">
          <img class="social-icons" alt="Twitter Logo" src="/assets/login/twitter-icon.png" /></a
      ></span>
      <button
        pButton
        type="button"
        class="p-button-outlined p-button-rounded contact-btn"
        label="CONTACT SUPPORT"
        (click)="navigateToForm()"
      ></button>
    </div>
    <div class="login-logo" *ngIf="isWhitelabeled"></div>
  </div>
</ng-template>
