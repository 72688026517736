import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RsStyleManager {
  private STYLE_ID = 'rs-changer';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public createStyle(style: string): void {
    this.deleteStyle();

    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(style));

    styleElement.setAttribute('id', this.STYLE_ID);
    this.document.head.append(styleElement);
  }

  private deleteStyle(): void {
    const styleElement = this.document.getElementById(this.STYLE_ID);
    styleElement && styleElement.remove();
  }
}
