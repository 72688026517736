import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import { GbpPostLocationsComponent } from 'src/app/gbp-management/gbp-tab-post/components/gbp-post-locations/gbp-post-locations.component';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-qa-add-location',
  templateUrl: './gbp-qa-add-location.component.html',
  styleUrls: ['./gbp-qa-add-location.component.scss'],
})
export class GbpQaAddLocationComponent implements OnInit, OnDestroy {
  isSuccess = false;
  locations = [];
  location = [];
  currentLocations = [];
  alllocations$: Observable<any>;
  filtered = [];
  filterValue = '';
  paginatorRows = 5;
  paginatorLength = 0;
  buttonLabel = 'SAVE AND NEXT';
  saveObj;
  rowData;
  unsubscribe$ = new Subject<void>();

  constructor(
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public dynamicDialogConfig: DynamicDialogConfig,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {
    this.gbpService
      .getAllLocations(['_id', 'storeCode', 'businessName', 'city', 'state', 'tags', 'labels'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locations) => {
        this.locations = locations;
        this.currentLocations = this.locations.slice(0, this.paginatorRows);
        this.paginatorLength = this.locations.length;
        this.filtered = locations;
      });

    this.gbpService
      .getBulkOwnerQuestionById(this.gbpService.currentBulkOwnerId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((post) => {
        this.rowData = post;
      });
  }

  saveLocation() {
    // call to backend will be here
    this.saveObj = {
      ...this.rowData,
      location_ids: this.currentLocations,
    };

    this.gbpService.updateBulkOwnerQa(this.saveObj);
    this.isSuccess = true;
  }

  onPanelHide() {
    this.filterValue = '';
    this.currentLocations = this.locations.slice(0, this.paginatorRows);
  }

  onPageChange(event) {
    this.currentLocations = this.filtered.slice(event.first, +event.first + +event.rows);
  }

  onFilter(event) {
    this.filtered = [...this.locations.filter((obj) => obj?.businessName.includes(event.target.value))];
    this.paginatorLength = this.filtered.length;
    this.currentLocations = this.filtered.slice(0, this.paginatorRows);
  }

  openCurrentLocations() {
    //if (!this.dynamicDialogConfig.data?.openPopup) {
    this.gbpService
      .getAllQALocations(this.gbpService.currentBulkOwnerId)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((locations) => {
          this.dialogService.open(GbpPostLocationsComponent, {
            width: '70%',
            data: {
              locations,
              openAddLocation: false,
            },
          });
        })
      )
      .subscribe();
    // }
  }

  closeDialog(): void {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // if (this.dynamicDialogConfig.data && this.dynamicDialogConfig.data?.openPopup) {
    //   this.dialogService.open(GbpPostLocationsComponent, {
    //     width: '70%',
    //     data: {
    //       locations: this.alllocations$,
    //       openPopup: true,
    //     },
    //   });
    // }
  }
}
