import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any | string, type?: 'url') {
    switch (type) {
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}
