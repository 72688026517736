import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DirectoryListingsService } from './../directory-listings/directory-listings.service';

@Component({
  selector: 'app-directory-wrapper',
  templateUrl: './directory-wrapper.component.html',
  styleUrls: ['./directory-wrapper.component.scss'],
  providers: [DirectoryListingsService],
})
export class DirectoryWrapperComponent implements OnInit, OnDestroy {
  public showAllListings: boolean;
  // tslint:disable-next-line: no-input-rename
  @Input('title') packageTitle: string;
  @Input() count: number;

  constructor(private directoryListingsService: DirectoryListingsService) {
  }

  ngOnInit() {
    this.directoryListingsService.initListings();
  }

  ngOnDestroy() {
    console.log('destroyed wrapper');
  }
}
