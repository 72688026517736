<div class="main-screen-container">
  <app-sidebar-menu
    [collapsed]="collapsed"
    [account]="account"
    [mainAccount]="mainAccount"
    (changeCollapsed)="collapsed = $event"
    (refreshBreadcrumb)="refreshBreadcrumbEvent$$.next()"
  ></app-sidebar-menu>

  <div
    class="stack-vertically"
    [ngClass]="{
      collapsed: collapsed,
      expanded: !collapsed
    }"
  >
  <app-breadcrumbs [collapsed]="collapsed" [refreshBreadcrumbEvent]="refreshBreadcrumbEvent$$ | async">
      <app-noticebar></app-noticebar>
      <app-notify></app-notify>
      <app-top-right-menu-block
        *ngIf="notInIframe()"
        [currentUser]="currentUser"
        (showChangePwdDialog)="showDialogEvent$$.next()"
      ></app-top-right-menu-block>
    </app-breadcrumbs>

    <div class="main-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-change-pass-dialog [showDialogEvent]="showDialogEvent$$ | async"></app-change-pass-dialog>
