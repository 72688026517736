import { Routes, RouterModule } from '@angular/router';
import { GbpManagementComponent } from './gbp-management.component';

const routes: Routes = [
  {
    path: '',
    component: GbpManagementComponent,
  },
];

export const GbpManagementRoutingModule = RouterModule.forChild(routes);
