import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SessionService } from '../../backend-adapter/session.service';
import { SocketService } from '../../backend-adapter/socket.service';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-change-pass-dialog',
  templateUrl: './change-pass-dialog.component.html',
  styleUrls: ['./change-pass-dialog.component.scss'],
})
export class ChangePassDialogComponent implements OnInit, OnDestroy {
  currentUser: string;

  // Pass change vars
  showDialog: boolean;
  makeDialog: boolean;
  login: any;
  oldPass = '';
  newPass = '';
  errMsg: string;
  passTypeOld = 'password';
  passTypeNew = 'password';
  eyeIconOld = 'pi pi-eye';
  eyeIconNew = 'pi pi-eye';

  private subs = new Subscription();
  private subjInited: boolean;

  @Input() set showDialogEvent(_) {
    if (this.subjInited) {
      this.showChangePwdDialog();
    }
    this.subjInited = true;
  }

  constructor(
    private socketService: SocketService,
    private sessionService: SessionService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.subs.add(
      this.sessionService
        .getCurrentUser$()
        .pipe(filter((v) => v != null))
        .subscribe((user) => {
          this.login = user.login;
          this.currentUser = user.login.loginId;
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private showChangePwdDialog() {
    this.login = this.sessionService.loginObj;

    this.passTypeOld = 'password';
    this.eyeIconOld = 'pi pi-eye';
    this.passTypeNew = 'password';
    this.eyeIconNew = 'pi pi-eye';

    this.showDialog = true;
    this.makeDialog = true;
  }

  closeChangePwdDialog() {
    this.oldPass = '';
    this.newPass = '';

    this.showDialog = false;
    this.makeDialog = false;
  }

  changePassword() {
    this.socketService
      .sendRequest('change-password', {
        loginId: this.currentUser,
        oldPassword: this.oldPass,
        newPassword: this.newPass,
      })
      .then(
        (args) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Password changed',
            detail: 'Your password has been successfully updated.',
            life: 3000,
            closable: true,
          });
          this.closeChangePwdDialog();
        },
        (errArgs) => {
          this.errMsg = errArgs.message;
        }
      );
  }

  toggleShowHide(inputName) {
    if (inputName == 'old') {
      if (this.passTypeOld == 'password') {
        this.passTypeOld = 'text';
        this.eyeIconOld = 'pi pi-eye-slash';
      } else {
        this.passTypeOld = 'password';
        this.eyeIconOld = 'pi pi-eye';
      }
    } else {
      if (this.passTypeNew == 'password') {
        this.passTypeNew = 'text';
        this.eyeIconNew = 'pi pi-eye-slash';
      } else {
        this.passTypeNew = 'password';
        this.eyeIconNew = 'pi pi-eye';
      }
    }
  }

  resetErr() {
    this.errMsg = null;
  }

  styleForRule(ruleFunc, input?) {
    if (typeof ruleFunc != 'function') {
      const val = ruleFunc;
      ruleFunc = () => {
        return val;
      };
    }

    return ruleFunc(input) ? 'passedRule' : 'failedRule';
  }

  iconForRule(ruleFunc, input?) {
    if (typeof ruleFunc != 'function') {
      const val = ruleFunc;
      ruleFunc = () => {
        return val;
      };
    }

    return ruleFunc(input) ? faCheck : faTimes;
  }

  // validations
  hasRequiredLength(input) {
    return input.length >= 8;
  }

  requiresLetter(input) {
    return input.match(/[A-Z]/i);
  }

  requiresDigit(input) {
    return input.match(/[0-9]/i);
  }

  validateInput(input) {
    this.hasRequiredLength(input);
    this.requiresLetter(input);
    this.requiresDigit(input);
  }

  //// toast
  showToast() {
    this.socketService.signal('toastMessage', { title: 'Hey!', message: 'Hello there.' });
  }

  clearToast() {
    this.messageService.clear();
  }
}
