<div *ngIf="headerTitle" class="header">
  <h3>{{ headerTitle }}</h3>
  <ng-content select="[headerAction]"></ng-content>
</div>

<div class="flex-between flex-1">
  <div class="rs-card-numbers" *ngFor="let num of numbers">
    <h2 [class]="num.color"><span [countUp]="num.total">0</span>{{ num.totalEnding }}</h2>
    <p>{{ num.text }}</p>
  </div>
</div>

<!-- Display sub locations here -->
<div *ngIf="subNumbers && subNumbers?.length" class="sub_locs">
  <div class="rs-card-numbers" *ngFor="let subLocs of subNumbers">
    <h2 [class]="subLocs?.color"><span [countUp]="subLocs?.total">0</span>{{ subLocs.totalEnding }}</h2>
    <p>{{ subLocs.text }}</p>
  </div>
</div>
