import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { GbpTabPostComponent } from './gbp-tab-post.component';
import { GbpPostDeleteComponent } from './components/gbp-post-delete/gbp-post-delete.component';
import { GbpPostLocationsComponent } from './components/gbp-post-locations/gbp-post-locations.component';
import { LocationsListModule } from 'src/app/core/feature-modules/locations-list/locations-list.module';
import { ProgressrModule } from 'src/app/core/feature-modules/progress/progress.module';
import { components as postCreateComponents } from './components/gbp-post-create';
import { UploadMediaModule } from 'src/app/core/feature-modules/upload-media/upload-media.module';
import { GbpDateLocationsFormModule } from '../components/gbp-date-locations-form/gbp-date-locations-form.module';
import { TableFilterModule } from 'src/app/core/feature-modules/table-filter/table-filter.module';
import { ActivityStatusModule } from '../pipes/activity-status.module';
import { GetStatusModule } from '../pipes/get-status.module';
import { GbpPostEditComponent } from './components/gbp-post-edit/gbp-post-edit.component';
import { SubmitComponent } from '@app/gbp-management/gbp-tab-post/components/gbp-post-create/submit/submit.component';
import { GbpUtmFormModule } from 'src/app/gbp-management/components/gbp-utm-form/gbp-utm-form.module';

export const MODULES = [
  LocationsListModule,
  ProgressrModule,
  UploadMediaModule,
  GbpDateLocationsFormModule,
  TableFilterModule,
  ActivityStatusModule,
  GetStatusModule,
  GbpUtmFormModule,
];
export const COMPONENTS = [GbpPostDeleteComponent, GbpPostLocationsComponent, ...postCreateComponents];

@NgModule({
  imports: [RSCommonModule, ...MODULES],
  declarations: [GbpTabPostComponent, ...COMPONENTS, GbpPostEditComponent],
  exports: [GbpTabPostComponent, SubmitComponent],
})
export class GbpPostModule {}
