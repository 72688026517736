export const COMPUTED_FONTS = (currentFont: string): string => {
  return `

  :root {
    --font-family: '${currentFont}';
  }

  p, h1, h2, h3, h4, h5, h6 {
    font-family: '${currentFont}';
  }

  body {
    font-family: '${currentFont}';
  }

  .p-datatable *,
  .p-breadcrumb *,
  .p-menuitem-link,
  .p-menuitem-text,
  .p-listbox,
  .p-fileupload {
    font-family: '${currentFont}';
  }

  button span:not(.pi):not(.fa):not(.material-icons-outlined) {
    font-family: '${currentFont}' !important;
  }

  /* ICONS FONTS */
  i, .pi {
    font-family: 'primeicons' !important;
  }

  .fa {
    font-family: 'Font Awesome 5 Free' !important;
  }

  .material-icons-outlined {
    font-family: 'Material Icons' !important;
  }

`;
};
