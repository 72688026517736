import { IAuthenticateData } from '../backend-adapter/session.interfaces';

export const isUserAdmin = (user: IAuthenticateData) => {
  return user && user.permissions._roles.some((v) => ['sql-admin', 'super-admin'].includes(v));
};

export const isUserSingleAccounted = (user: IAuthenticateData) => {
  console.log(user);
  return user && user.authorizedAccts && user.authorizedAccts.length === 1;
};
