import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { IPublish } from '../../review-management.interfaces';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss'],
})
export class SuccessPopupComponent implements OnInit {
  publishObject: IPublish<any>;
  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.publishObject = this.dynamicDialogConfig.data;
  }

  closeSuccessPopup() {
    this.ref.close();
  }
}
