<div class="header-parent-title">
  <div class="header-child-title">Photo Details</div>
  <div class="header-child-title">.</div>
</div>

<div class="gbp-photo-details-wrapper">
  <div class="details-main">
    <div *ngIf="(status != activityStatus.UNPUBLISHED || isEdit) && !isOptimized" class="details-image-wrapper">
      <img src="{{ imageUrl }}" alt="photo" class="details-image" />
    </div>

    <div *ngIf="status == activityStatus.UNPUBLISHED" class="details-unpublished">
      <ng-container *ngIf="!isEdit; else edit">
        <img src="{{ imageUrl }}" alt="photo" class="details-image-unpublished" />
        <div class="details-text-header">Unpublished Photo.</div>
        <div class="details-text">Would you like to schedule or publish this photo?</div>
      </ng-container>
    </div>

    <ng-container *ngIf="(status === activityStatus.PUBLISHED || status === activityStatus.SCHEDULED) && !isOptimized">
      <div *ngIf="!isEdit; else edit" class="details-main-text">
        <div class="details-row">
          <div class="grid-item">Date & Time</div>
          <div class="grid-item">14/02/2022 05:32 PM GMT+2</div>
        </div>
        <div class="details-row">
          <div class="grid-item">Locations</div>
          <div class="grid-item-link" (click)="openLocations()">23 locations selected</div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(status === activityStatus.PUBLISHED || status === activityStatus.SCHEDULED) && isOptimized">
      <div class="details-main-optimized">
        <app-gbp-photo-scheduled-optimized [edit]="isEdit" [detailsOptimize]="true"></app-gbp-photo-scheduled-optimized>
      </div>
    </ng-container>
  </div>
</div>

<div class="bottom-buttons">
  <button pButton label="CLOSE" class="p-button-outlined" (click)="closeDialog()"></button>
  <button
    *ngIf="status == activityStatus.UNPUBLISHED && !isEdit"
    pButton
    label="SCHEDULE/PUBLISH"
    class="p-button-primary"
    (click)="scheduleOrPublish()"
  ></button>
  <button
    *ngIf="
      (status === activityStatus.SCHEDULED && !isEdit) || (status === activityStatus.PUBLISHED && isOptimized && !isEdit)
    "
    pButton
    label="EDIT"
    class="p-button-primary"
    (click)="toggleEdit()"
  ></button>
  <button *ngIf="isEdit" pButton label="SAVE CHANGES" class="p-button-primary" (click)="saveChanges()"></button>
</div>

<ng-template #edit>
  <app-gbp-date-locations-form
    class="date-locations-form-wrapper"
    [scheduleGroup]="scheduleGroup"
    [rows]="1"
  ></app-gbp-date-locations-form>
</ng-template>
