import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-select-post-type',
  templateUrl: './select-post-type.component.html',
  styleUrls: ['./select-post-type.component.scss'],
})
export class SelectPostTypeComponent implements OnInit {
  selectedType: string;
  showSteps = false;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  saveAndNext() {
    // call to backend for saving data will be here
    this.showSteps = true;
  }

  closeDialog() {
    this.ref.close();
  }
}
