import {Component, OnInit} from '@angular/core';
import {DBService} from '@app/core/backend-adapter/db.service';
import {NotifyService} from '@app/core/layouts/notifications/notify/notify.service';
import {SessionService} from '@app/core/backend-adapter/session.service';
import {AcctHelpers} from '@app/acct-comps/helpers';

@Component({
  selector: 'app-accuracy-reports',
  templateUrl: './accuracy-reports.component.html',
  styleUrls: ['./accuracy-reports.component.scss']
})
export class AccuracyReportsComponent implements OnInit {
  VENDOR_IMAGES = this.acctHelpers.VENDOR_IMAGES;
  private locations: any;
  private totalEntries = 0;
  private loading = true;

  public publishers = [
    'gmb',
    'bing',
    'yelp',
    'appleMaps'
  ];
  public publisherStatistics: object;
  public fields = [
    'businessName',
    'address',
    'phoneNumber',
    'websiteURL',
    'businessHours'
  ];
  public fieldMap = {
    businessName: 'Business Name',
    address: 'Address',
    phoneNumber: 'Phone Number',
    websiteURL: 'Website',
    businessHours: 'Business Hours'
  };
  public publisherMap = {
    gmb: 'Google',
    appleMaps: 'Apple Maps',
    amazonalexa: 'Alexa',
    bing: 'Bing',
    facebook: 'Facebook',
    factual: 'Factual',
    here: 'Here',
    infogroup: 'Infogroup',
    localeze: 'Localeze',
    mapquest: 'Mapquest',
    nextdoor: 'Nextdoor',
    pandio: 'YP Package',
    tomtom: 'TomTom',
    uber: 'Uber',
    yelp: 'Yelp'
  };

  constructor(
    public dbService: DBService,
    public notifyService: NotifyService,
    public sessionService: SessionService,
    private acctHelpers: AcctHelpers
  ) {
  }

  ngOnInit(): void {
    this.loadLocationData();
  }


  loadLocationData(): void {
    this.dbService.Location.loadObjects({
      offset: 0,
      limit: 2500,
      where: ['-and', '_parent_id is  null', "_status = 'A'"] // Remove sublocations, as we don't collect accuracy data for them.
    })
      .then(
        (repl) => {
          this.locations = repl.collection;
          this.totalEntries = repl.totalEntries;

          this.getPublisherStatistics();
        },
        (err) => {
          this.locations = [];
          this.notifyService.error('No locations for this account');
          console.warn('Error loading locations: %j', err);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  getPublisherStatistics() {
    // iterate through locations to determine publishers and remove undefined values
    const publisherStatistics = this.publishers.map(p =>
      this.locations.map(l => l.vendorStatus[p]?.accuracy)
    ).map(p => p.filter(e => e != undefined));

    // zip publisher keys to accuracy values
    this.publisherStatistics = this.publishers.reduce((accumulator, value, i) => {
      return {...accumulator, [value]: publisherStatistics[i]};
    }, {});
  }

  getPublisherFieldMatchPercentage(publisher, field): number {
    if (field == 'address') {
      field = 'businessName';
    }
    const numerator = this.getPublisherFieldMatchCount(publisher, field);
    const denominator = this.totalEntries;

    return numerator / denominator * 100;
  }

  getPublisherFieldTooltip(publisher, field): string {
    const matchCount = this.getPublisherFieldMatchCount(publisher, field);
    return matchCount + ' of ' + this.totalEntries + ' locations match on ' + field;
  }

  private getPublisherFieldMatchCount(publisher, field): number {
    if (field == 'address') {
      field = 'businessName';
    }
    return this.publisherStatistics[publisher].filter(locationAccuracy => locationAccuracy[field]?.score > 0).length;
  }

}
