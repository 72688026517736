import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { GbpDateLocationsFormComponent } from './gbp-date-locations-form.component';

@NgModule({
  imports: [RSCommonModule],
  declarations: [GbpDateLocationsFormComponent],
  exports: [GbpDateLocationsFormComponent],
})
export class GbpDateLocationsFormModule {}
