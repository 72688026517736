import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-gbp-photo-optimize',
  templateUrl: './gbp-photo-optimize.component.html',
  styleUrls: ['./gbp-photo-optimize.component.scss'],
})
export class GbpPhotoOptimizeComponent implements OnInit {
  receivedData = {};
  isOptimized = false;
  isEdit = false;

  constructor(public dynamicDialogConfig: DynamicDialogConfig, public ref: DynamicDialogRef) {}

  ngOnInit(): void {
    this.isEdit = this.dynamicDialogConfig.data.optimized;
  }

  closeDialog(): void {
    this.ref.close();
  }

  optimize(): void {
    // request to backend
    this.isOptimized = true;
  }
}
