import { ModuleWithProviders, NgModule } from '@angular/core';
import { RSCommonModule } from '../../../feature-modules/facade.module';
import { NoticebarComponent } from './noticebar.component';
import { NoticebarService } from './noticebar.service';

@NgModule({
  imports: [RSCommonModule],
  declarations: [NoticebarComponent],
  providers: [],
  exports: [NoticebarComponent],
})
export class NoticebarModule {
  static forRoot(): ModuleWithProviders<NoticebarModule> {
    return {
      ngModule: NoticebarModule,
      providers: [NoticebarService],
    };
  }
}
