import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TypeDefs } from './acct-comps/type-defs';
import { AcctHelpers } from './acct-comps/helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ClientsComponent } from './clients/clients.component';
import { UsersComponent } from './users/users.component';
import { AgingReportComponent } from './aging-report/aging-report.component';
import { PartiesComponent } from './parties/parties.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { SocialManagementComponent } from './social-management/social-management.component';
import { LocalAdsComponent } from './local-ads/local-ads.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';

import { RsElementsModule } from './core/elements/rs-elements.module';
import { LoginModule } from './login/login.module';
import { API_URL, WSS_Url } from 'src/config';
import { LayoutsModule } from './core/layouts/layouts.module';
import { BackendAdapterModule } from './core/backend-adapter/backend-adapter.module';
import { PrimengModule } from './core/feature-modules/primeng.module';
import { authLoaderFactory, AuthLoaderService } from './auth-loader.service';
import { MessageService } from 'primeng/api';
import { LoginsComponent } from './admin/logins/logins.component';
import { GbpManagementModule } from './gbp-management/gbp-managment.module';
import { UtmManagementModule } from './utm-management/utm-management.module';
import { RSDirectoryPackagesModule } from './core/feature-modules/directory-packages/directory-packages.module';
import { ReviewManagementModule } from './review-management/review-management.module';
import { ReportsModule } from './reports/reports.module';
import { StyleChangerModule } from './core/feature-modules/whitelabel/style-changer/style-changer.module';
import { LocChangeLogModule } from './loc-change-log/loc-change-log.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BackendAdapterModule,
    FontAwesomeModule,
    PrimengModule,
    ClipboardModule,
    LoginModule,
    LayoutsModule,
    GbpManagementModule,
    UtmManagementModule,
    RsElementsModule,
    RSDirectoryPackagesModule,
    ReviewManagementModule,
    ReportsModule,
    StyleChangerModule,
    LocChangeLogModule,
  ],
  declarations: [
    AppComponent,
    ClientsComponent,
    UsersComponent,
    AgingReportComponent,
    PartiesComponent,
    PermissionsComponent,
    SocialManagementComponent,
    LocalAdsComponent,
    AccountSettingsComponent,
    LoginsComponent,
  ],
  providers: [
    AuthLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: authLoaderFactory,
      deps: [AuthLoaderService],
      multi: true,
    },
    {
      provide: 'API_URL',
      useValue: API_URL,
    },
    {
      provide: 'WSS_Url',
      useValue: WSS_Url,
    },
    MessageService,
    TypeDefs,
    AcctHelpers,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
