import { NgModule } from '@angular/core';
import { LocChangeLogComponent } from './loc-change-log.component';
import { RSCommonModule } from '../core/feature-modules/facade.module';

@NgModule({
  imports: [RSCommonModule],
  declarations: [LocChangeLogComponent],
  exports: [LocChangeLogComponent],
  providers: [],
})

export class LocChangeLogModule {}
