export const VENDORS_KEYS: VendorKeysType[] = [
  'gmb', // old one
  // 'gbp', // refactored gmb deprecated, on backend we have gmb
  'appleMaps',
  'amazonalexa',
  'bing',
  'facebook',
  'factual',
  'here',
  'infogroup',
  'localeze',
  'mapquest',
  'nextdoor',
  'pandio',
  'tomtom',
  'uber',
  'yelp',
  'ypca',
];

export type VendorKeysType =
  | 'gmb' // old one
  // 'gbp', // refactored gmb deprecated, on backend we have gmb
  | 'appleMaps'
  | 'amazonalexa'
  | 'bing'
  | 'facebook'
  | 'factual'
  | 'here'
  | 'infogroup'
  | 'localeze'
  | 'mapquest'
  | 'nextdoor'
  | 'pandio'
  | 'tomtom'
  | 'uber'
  | 'yelp'
  | 'ypca';

export type AccuracyKeysType = 'businessName' | 'address' | 'phoneNumber' | 'websiteURL' | 'businessHours';
