// tslint:disable: object-literal-shorthand
// tslint:disable: prefer-const
// tslint:disable: space-before-function-paren
// tslint:disable: curly
// tslint:disable: no-var-keyword
// tslint:disable: only-arrow-functions
// tslint:disable: variable-name
// tslint:disable: jsdoc-format
// tslint:disable: forin
// tslint:disable: no-bitwise
// tslint:disable: no-eval
// tslint:disable: quotemark
// tslint:disable: no-unused-expression
// tslint:disable: radix
// tslint:disable: no-conditional-assignment

import RZLib from './RZ-lib.helpers';
import { isEqual as _isEqual } from 'lodash-es';
import { SocketService } from '../socket.service';
import { BroadcastService } from '../broadcast.service';
import { SessionService } from '../session.service';
import { ILoadParams } from '../api/api.interfaces';

export const DB_initialize = (
  sessionService: SessionService,
  socketService: SocketService,
  broadcastService: BroadcastService
) => {
  const DBLib = RZLib.subclass(RZLib.Object, 'DBLib', {
    DBLib: function () {
      DBLib.superConstructor.call(this);
    },
  });

  (window as any).DBLib = DBLib;

  DBLib.cbSignal = {};

  DBLib.findById = function (key) {
    return this.coll && this.idxBy_id ? this.coll[this.idxBy_id[key]] : null;
  };

  DBLib.findByCode = function (key) {
    return this.coll && this.idxByCode ? this.coll[this.idxByCode[key]] : null;
  };

  DBLib.findByIdent = function (key): null | any {
    return this.coll && this.idxByIdent ? this.coll[this.idxByIdent[key]] : null;
  };

  DBLib.findByLoginId = function (key) {
    return this.coll && this.idxByLoginId ? this.coll[this.idxByLoginId[key]] : null;
  };

  DBLib.findByStoreCode = function (key) {
    return this.coll && this.idxByStoreCode ? this.coll[this.idxByStoreCode[key]] : null;
  };

  DBLib.ensureIndexes = function () {
    if (!this.indexes) {
      // debugger
      throw new Error('CUSTOM ERROR IN DB2 - no indexes!');
    }
    for (let key of this.indexes) {
      var idxName = 'idxBy' + key.substr(0, 1).toUpperCase() + key.substr(1);
      if (!this[idxName]) {
        this[idxName] = {};
      }
    }
  };

  DBLib.updateObject = function (objHash) {
    if (!objHash) {
      return null;
    }

    if (!this.coll) {
      this.coll = [];
    }

    this.ensureIndexes();

    var obj = this.importFromHash(objHash);
    var idx = this.idxBy_id[obj._id];

    if (idx != undefined) {
      var postSig = !this.isEqual(this.coll[idx], obj, ['_lastUpdatedBy', '_lastUpdatedAt']);

      Object.assign(this.coll[idx], obj);
      return [this.coll[idx], postSig];
    } else {
      idx = this.coll.length;
      for (let key of this.indexes) {
        var idxName = 'idxBy' + key.substr(0, 1).toUpperCase() + key.substr(1);
        this[idxName][obj[key]] = idx;
      }
      this.coll.push(obj);
      return [obj, true];
    }
  };

  DBLib.indexCollection = function (startIdx) {
    var objColl = this.coll;
    if (!startIdx) {
      startIdx = 0;
    }

    for (var k = 1; k < arguments.length; ++k) {
      var key = arguments[k];
      var idxKey = 'idxBy' + key.substr(0, 1).toUpperCase() + key.substr(1);

      if (!this[idxKey]) {
        this[idxKey] = {};
      }

      var idxByKey = this[idxKey];
      for (var i = startIdx; i < objColl.length; ++i) {
        idxByKey[objColl[i][key]] = i;
      }
    }
  };

  DBLib.importCollection = function (hashColl) {
    var objColl = [];

    if (!hashColl) {
      return [];
    }

    var dbClass = this;
    for (let hash of hashColl) {
      var [obj, postSig] = dbClass.updateObject(hash);
      objColl.push(obj);
    }
    // hashColl.forEach(function(hash) {
    //   objColl.push(dbClass.importFromHash(hash));
    // });
    //
    // dbClass.indexCollection(0, '_id');
    // for (var i = 1; i < arguments.length; ++i)
    //   this.indexCollection(0, arguments[i]);

    return objColl;
  };

  DBLib.preImportMapping = function (obj, hash, cls) {};
  DBLib.postImportMapping = function (obj, hash, cls) {};

  DBLib.importFromHash = function (hash) {
    var obj = new this();

    this.preImportMapping(obj, hash, this);
    this.applyImportMappings(obj, hash, this);
    if (this.finishImport) {
      this.finishImport(obj, hash);
    }
    this.postImportMapping(obj, hash, this);

    return obj;
  };

  DBLib.applyImportMappings = function (obj, hash, cls) {
    if (!cls) {
      return;
    }

    this.applyImportMappings(obj, hash, cls.superConstructor);

    if (!cls.hasOwnProperty('mappings')) {
      return obj;
    }

    for (let [attr, m, key, mapKey] of cls.mappings) {
      if (!key) {
        key = attr;
      }

      var val = hash[key];

      if (!hash.hasOwnProperty(key)) {
        continue;
      }

      if (Array.isArray(m)) {
        m = m[0];
        obj[attr] = [];

        if (Array.isArray(val)) {
          let ary = obj[attr];
          for (let hv of val) {
            ary.push(this.applyImportMap(hv, m, key, mapKey));
          }
        }
      } else {
        obj[attr] = this.applyImportMap(val, m, key, mapKey);
      }
    }

    return obj;
  };

  DBLib.applyImportMap = function (val, m, attr, mapKey) {
    if (val == null) {
      switch (m) {
        case '[]':
          return [];
        case '{}':
          return {};
      }
      return null;
    }

    switch (m) {
      case 'bool':
        return !!val;
      case 'int':
        return parseInt(val, 10);
      case 'float':
        return parseFloat(val);
      case 'str':
        return val.toString();
      case 'json':
        return typeof val == 'string' ? JSON.parse(val) : typeof val == 'object' ? val : null;
      case RZLib.Date:
        return RZLib.Date.newForStringValue(val);
      case RZLib.Time:
        return RZLib.Time.newForStringValue(val);
      case RZLib.Timestamp:
        return RZLib.Timestamp.newForStringValue(val);
      case '[]':
        return typeof val == 'string' ? JSON.parse(val) : Array.isArray(val) ? val : [];
      case '{}':
        if (typeof val == 'string') {
          val = JSON.parse(val);
        }

        if (val == null) {
          return {};
        }

        return val.toString() === '[object Object]' ? val : {};

      default:
        if (!mapKey) {
          mapKey = 'idxBy_id';
        }

        if (!m) {
          // debugger
          throw new Error('CUSTOM ERROR IN DB2 - no map m!');
        }

        if (m.hasOwnProperty(mapKey)) {
          var idx = m[mapKey][val];
          return idx != undefined ? m.coll[idx] : null;
        }
    }
  };

  DBLib.preExportMapping = function (hash, obj, cls) {};
  DBLib.postExportMapping = function (hash, obj, cls) {};

  DBLib.exportToHash = function (obj) {
    var hash = {};

    this.preExportMapping(hash, obj, this);
    this.applyExportMappings(hash, obj, this);
    if (this.finishExport) {
      this.finishExport(hash, obj);
    }
    this.postExportMapping(hash, obj, this);

    return hash;
  };

  DBLib.applyExportMappings = function (hash, obj, cls) {
    if (!cls) {
      return;
    }

    this.applyExportMappings(hash, obj, cls.superConstructor);

    if (!cls.hasOwnProperty('mappings')) {
      return obj;
    }

    for (let [attr, m, key, map] of cls.mappings) {
      if (!key) {
        key = attr;
        delete hash[key];
      }

      var val = obj[attr];

      if (!obj.hasOwnProperty(attr)) {
        continue;
      }

      if (Array.isArray(m) && Array.isArray(val)) {
        m = m[0];
        let ary = (hash[key] = []);
        for (let ov of val) {
          ary.push(this.applyExportMap(ov, m, key, map));
        }
      } else {
        hash[key] = this.applyExportMap(val, m, key, map);
      }
    }

    return DBLib.cleanTemps(hash);
  };

  DBLib.applyExportMap = function (val, m, key, mapKey) {
    if (val == null) {
      return null;
    }

    switch (m) {
      case 'bool':
        return !!val;
        break;
      case 'int':
        return parseInt(val, 10);
        break;
      case 'float':
        return parseFloat(val);
        break;
      case 'str':
        return val.toString();
        break;
      case 'json':
        return JSON.stringify(val);
        //   return val;
        break;
      case RZLib.Date:
        return typeof val == 'string' ? val : typeof val == 'object' ? val.stringValue() : null;
        break;
      case RZLib.Time:
        return typeof val == 'string' ? val : typeof val == 'object' ? val.stringValue() : null;
        break;
      case RZLib.Timestamp:
        return typeof val == 'string' ? val : typeof val == 'object' ? val.stringValue() : null;
        break;
      case '[]':
        if (Array.isArray(val)) {
          if (val.length == 0) {
            return null;
          }
          return JSON.stringify(val);
        }
        return null;
      case '{}':
        if (val.toString() === '[object Object]' && val.constructor == {}.constructor) {
          if (Object.keys(val).length == 0) {
            return null;
          }
          return JSON.stringify(val);
        }
        return null;
      default:
        if (!mapKey) {
          mapKey = 'idxBy_id';
        }

        if (m.hasOwnProperty(mapKey)) {
          var keyAttr = mapKey == 'idxByCode' ? 'code' : '_id';
          return val && val[keyAttr] ? val[keyAttr] : null;
        }
    }
  };

  DBLib.makeLoadRequest = function (reqId, args: ILoadParams, postLoadedSig) {
    var dbClass = this;
    // debugger;
    return (dbClass.promise = socketService
      .sendRequest(reqId, args)
      .then(
        (res: { collection: any[]; status: string; totalEntries?: any }) => {
          // delete dbClass.promise;
          var coll = dbClass.importCollection(res.collection);

          if (postLoadedSig) {
            var baseName = dbClass.name.substr(dbClass.name.lastIndexOf('.') + 1);
            var sigName = baseName.substr(0, 1).toLowerCase() + baseName.substr(1) + 'CollLoaded';
            DBLib.postSignal(sigName, { coll: coll });
          }
          return { collection: coll, totalEntries: res.totalEntries };
        },
        (err) => {
          ////      if (err.category == 'session' && err.type == 'no-account') {
          dbClass.coll = null;
          var baseName = dbClass.name.substr(dbClass.name.lastIndexOf('.') + 1);
          var sigName = baseName.substr(0, 1).toLowerCase() + baseName.substr(1) + 'CollNotLoaded';
          DBLib.postSignal(sigName, err);
          return Promise.reject(err);
          ////      }
        }
      )
      .finally(function () {
        delete dbClass.promise;
      }));
  };

  DBLib.loadObjects = function (args: ILoadParams, postLoadedSig) {
    var dbClass = this;
    if (dbClass.promise) {
      return dbClass.promise;
      ////    else if (dbClass.hasOwnProperty('coll')) {
      ////      return dbClass.coll;
    } else {
      return dbClass.makeLoadRequest(dbClass.load_reqId, args, postLoadedSig);
    }
  };

  DBLib.makeSaveRequest = function (hItem, type, args) {
    var dbClass = this;

    if (!args) {
      args = {};
    }

    args.item = hItem;

    if (type) {
      args.type = type;
    }

    return socketService.sendRequest(dbClass.save_reqId, args).then((args: any) => {
      var baseName = dbClass.name.substr(dbClass.name.lastIndexOf('.') + 1);
      var sigName = baseName.substr(0, 1).toLowerCase() + baseName.substr(1) + 'Updated';
      var [obj, postSig] = dbClass.updateObject(args.item);
      if (postSig) {
        DBLib.postSignal(sigName, { item: obj });
      }

      return obj;
    });
  };

  DBLib.saveObject = function (obj, args) {
    var dbClass = this;
    var hash = dbClass.exportToHash(obj);
    return dbClass.makeSaveRequest(hash, null, args);
  };

  DBLib.addSigHandler = function () {
    var dbClass = this;
    var baseName = dbClass.name.substr(dbClass.name.lastIndexOf('.') + 1);
    var sigName = baseName.substr(0, 1).toLowerCase() + baseName.substr(1) + 'Updated';

    socketService.onSignal(sigName, (sigName, sigArgs: any) => {
      var [obj, postSig] = dbClass.updateObject(sigArgs.item);
      if (postSig) {
        DBLib.postSignal(sigName, { item: obj });
      }
    });
  };

  DBLib.isEqual = function (t1, t2, ignoreAttrs) {
    ignoreAttrs = ignoreAttrs || [];

    for (let m of this.mappings) {
      var attr = m[0];

      if (attr.match(/^(\$\$|__)/) || ignoreAttrs.includes(attr)) {
        continue;
      }

      var v1 = t1[attr];
      var v2 = t2[attr];

      if (v1 == null || v2 == null) {
        if (v1 != v2) {
          return false;
        } else {
          continue;
        }
      }

      switch (m[1]) {
        case 'json':
          if (!_isEqual(v1, v2)) {
            return false;
          }
          break;
        case RZLib.Date:
        case RZLib.Time:
        case RZLib.Timestamp:
          if (v1.valueOf() != v2.valueOf()) {
            return false;
          }
          break;
        default:
          if (RZLib.isKindOf(v1, DBLib) && RZLib.isKindOf(v2, DBLib)) {
            if (v1._id != v2._id) {
              return false;
            } else {
              continue;
            }
          }

          if (!RZLib.eqv(v1, v2)) {
            return false;
          }
          break;
      }
    }
    return true;
  };

  DBLib.onSignal = function (s, cb) {
    var cbList = DBLib.cbSignal[s];
    if (!cbList) {
      cbList = DBLib.cbSignal[s] = [];
    }

    if (cb instanceof Function) {
      cbList.push(cb);
    }
  };

  DBLib.offSignal = function (s, cb) {
    var cbList = DBLib.cbSignal[s];
    for (var i = cbList.length - 1; i >= 0; --i) {
      if (cbList[i] == cb) {
        cbList.splice(i, 1);
      }
    }
  };

  DBLib.signal = function (sigName, sigArgs) {
    broadcastService.update(sigName, sigArgs);

    var cbList = DBLib.cbSignal[sigName];
    if (Array.isArray(cbList)) {
      cbList.forEach(function (cb) {
        cb(sigName, sigArgs);
      });
    }
  };

  DBLib.postSignal = function (sigName, sigArgs) {
    console.log(`DB: Posting signal: ${sigName}`);
    setTimeout(function () {
      DBLib.signal(sigName, sigArgs);
    }, 0);
  };

  DBLib.cleanTemps = function (h) {
    var k;
    for (k in h) {
      if (k.match(/^(?:__|\$\$)/)) {
        delete h[k];
      } else if (typeof h[k] == 'object') {
        DBLib.cleanTemps(h[k]);
      }
    }
    return h;
  };

  DBLib.loadAll = function () {
    return (
      sessionService
        .authenticateWSS()
        .then(function () {
          DBLib.LoginSummary.loadObjects({}, true);
        })
        .then(function () {
          DBLib.Account.loadObjects({ where: `_status IN ('A', 'P')` }, true);
        })
        .then(function () {
          DBLib.AuthType.loadObjects({}, true);
        })
        .then(function () {
          DBLib.LocStatusType.loadObjects({}, true);
        })
        .then(function () {
          return socketService.sendRequest('get-vendor-attrs-coll', { vendorIdent: 'gmb' });
        })
        //          DBLib.CategoryType.loadObjects({}, true),
        //          DBLib.EnumType.loadObjects({}, true),
        //          DBLib.Entity.loadObjects({}, true),
        .catch(function (err) {
          // debugger;
          console.log('DBLib.loadAll() failed.');
        })
        .then(function (repl: any) {
          //        DBLib.CategoryType.fillCatsByVendor();
          (DBLib.schema = repl?.schema), socketService.on('deauthenticated', DBLib.purgeAll);
          return repl;
        })
    );
  };

  DBLib.clearCache = function () {
    delete this.coll;
    for (let key of this.indexes) {
      var idxName = 'idxBy' + key.substr(0, 1).toUpperCase() + key.substr(1);
      delete this[idxName];
    }
  };

  DBLib.purgeAll = function () {
    socketService.off('deauthenticated', DBLib.purgeAll);
    DBLib.clearPublicCaches();
    DBLib.clearAccountCaches();
  };

  DBLib.clearPublicCaches = function () {
    DBLib.AuthType.clearCache();
    DBLib.LocStatusType.clearCache();
    DBLib.CategoryType.clearCache();
    DBLib.LoginSummary.clearCache();
    DBLib.Login.clearCache();
    DBLib.Permission.clearCache();
    DBLib.Account.clearCache();
    DBLib.SqlReports.clearCache();
  };

  DBLib.clearAccountCaches = function () {
    DBLib.Entity.clearCache();
    DBLib.Party.clearCache();
    DBLib.Address.clearCache();
    DBLib.Phone.clearCache();
    DBLib.EMail.clearCache();
    DBLib.Location.clearCache();
    DBLib.UploadLog.clearCache();
    DBLib.ReviewGMB.clearCache();
    DBLib.LocalPostGMB.clearCache();
    DBLib.LocalPostDetailGMB.clearCache();
    DBLib.BulkOwnerQuestionGMB.clearCache();
    DBLib.QuestionGMB.clearCache();
    DBLib.AnswerDetailGMB.clearCache();
    DBLib.MarkAsRead.clearCache();
  };

  ////////////////////////////////////////////////////////////////////

  //
  // EnumType model
  //
  DBLib.EnumType = RZLib.subclass(DBLib, 'DBLib.EnumType', {
    'DBLib.EnumType': function () {
      DBLib.EnumType.superConstructor.call(this);
      this.tags = [];
      this.attrs = {};
    },
    _code: function () {
      return this.attrs && this.attrs.dispCode && this.attrs.dispCode.length ? this.attrs.dispCode : this.code;
    },
    _codeWithName: function (e) {
      if (!e) {
        e = this;
      }
      return e._code() + ' - ' + e.name;
    },
    _codeWithDescr: function () {
      return this._code() + ' - ' + this.descr;
    },
  });

  DBLib.EnumType.load_reqId = 'get-enum-type-coll';
  DBLib.EnumType.save_reqId = 'save-enum-type';
  DBLib.EnumType.enumType = 'all';
  DBLib.EnumType.indexes = ['_id', 'code'];
  DBLib.EnumType.mappings = [
    ['_id', 'int'],
    ['_status', 'str'],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_updatedBy', DBLib.Login, '_updatedBy_id'],

    ['seq', 'int'],
    ['code', 'str'],
    ['name', 'str'],
    ['descr', 'str'],

    ['attrs', 'json'],
    ['tags', ['str']],
  ];

  DBLib.EnumType.codeWithName = function (e) {
    if (e) {
      return e._codeWithName();
    }
    return '?';
  };

  DBLib.EnumType.codeWithDescr = function (e) {
    if (RZLib.isKindOf(e, DBLib.EnumType)) {
      return e._codeWithDescr();
    }
    return '?';
  };

  DBLib.EnumType.makeLoadRequest = function (reqId, args, postLoadedSig) {
    args = args || {};
    args.type = this.enumType;

    return DBLib.EnumType.superConstructor.makeLoadRequest.call(this, reqId, args, postLoadedSig);
  };

  DBLib.EnumType.makeSaveRequest = function (hItem) {
    return DBLib.EnumType.superConstructor.makeSaveRequest.call(this, hItem, this.enumType);
  };

  DBLib.EnumType.activeColl = function (currVal) {
    if (this.hasOwnProperty('coll')) {
      return this.coll.filter((et) => et.status == 'A' || et == currVal);
    }

    return [];
  };

  //
  // AuthType model
  //
  DBLib.AuthType = RZLib.subclass(DBLib.EnumType, 'DBLib.AuthType', {
    'DBLib.AuthType': function () {
      DBLib.AuthType.superConstructor.call(this);
    },
  });
  DBLib.AuthType.enumType = 'Auth';

  DBLib.AuthType.addSigHandler();

  //
  // LocStatusType model
  //
  DBLib.LocStatusType = RZLib.subclass(DBLib.EnumType, 'DBLib.LocStatusType', {
    'DBLib.LocStatusType': function () {
      DBLib.LocStatusType.superConstructor.call(this);
    },
  });
  DBLib.LocStatusType.enumType = 'LocStatus';

  DBLib.LocStatusType.addSigHandler();

  //
  // CategoryType model
  //
  DBLib.CategoryType = RZLib.subclass(DBLib.EnumType, 'DBLib.CategoryType', {
    'DBLib.CategoryType': function () {
      DBLib.CategoryType.superConstructor.call(this);
    },
  });
  DBLib.CategoryType.enumType = 'Category';
  DBLib.CategoryType.mappings = [['vendorIdent', 'str']];

  DBLib.CategoryType.addSigHandler();

  DBLib.CategoryType.catsByVendor = {};
  DBLib.CategoryType.catByVendorByCode = {};

  DBLib.CategoryType.fillCatsByVendor = function () {
    let catByVendorByCode = DBLib.CategoryType.catByVendorByCode;
    let catsByVendor;

    if (this.coll) {
      for (let c of this.coll) {
        // tslint:disable-next-line: no-conditional-assignment
        if (!(catsByVendor = this.catsByVendor[c.vendorIdent])) {
          catsByVendor = this.catsByVendor[c.vendorIdent] = [];
          catByVendorByCode[c.vendorIdent] = {};
        }

        catsByVendor.push(c);
        catByVendorByCode[c.vendorIdent][c.code] = c;
      }
    }
  };

  DBLib.CategoryType.findCatsByVendor = function (vendor) {
    let cats = this.catsByVendor[vendor];
    if (!cats) {
      cats = this.catsByVendor[vendor] = this.coll?.filter((c) => c.vendorIdent === vendor);
    }
    return cats;
  };

  DBLib.CategoryType.findByCodeForVendor = function (code, vendor) {
    if (!vendor) {
      return null;
    }

    let index = this.catByVendorByCode[vendor];

    if (!index) {
      let coll = [];
      coll = this.findCatsByVendor(vendor);
      index = this.catByVendorByCode[vendor] = {};
      if (coll?.length) {
        for (let c of coll) {
          index[c.code] = c;
        }
      }
    }

    let c = index[code];
    if (c) {
      return c;
    }

    return null;
  };

  //
  // LoginSummary model
  //
  DBLib.LoginSummary = RZLib.subclass(DBLib, 'DBLib.LoginSummary', {});
  DBLib.LoginSummary.load_reqId = 'get-login-summary-coll';
  DBLib.LoginSummary.indexes = ['_id', 'loginId'];
  DBLib.LoginSummary.mappings = [
    ['_id', 'int'],
    ['loginId', 'str'],
    ['note', 'str'],
    ['account', 'str'],
  ];

  //
  // Permission model
  //
  DBLib.Permission = RZLib.subclass(DBLib, 'DBLib.Permission', {
    'DBLib.Permission': function () {
      DBLib.Permission.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Permission.load_reqId = 'get-permission-coll';
  DBLib.Permission.save_reqId = 'save-permission';
  DBLib.Permission.indexes = ['_id'];
  DBLib.Permission.mappings = [
    ['_id', 'int'],
    ['_status', 'str'],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_updatedBy', DBLib.Login, '_updatedBy_id'],

    ['role', 'str'],
    ['seq', 'int'],
    ['code', 'str'],
    ['perms', 'str'],
    ['descr', 'str'],
    ['attrs', 'json'],
    ['tags', ['str']],
  ];

  DBLib.Permission.addSigHandler();

  //
  // Account model
  //
  DBLib.Account = RZLib.subclass(DBLib, 'DBLib.Account', {
    'DBLib.Account': function () {
      DBLib.Account.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Account.load_reqId = 'get-account-coll';
  DBLib.Account.save_reqId = 'save-account';
  DBLib.Account.indexes = ['_id', 'ident'];
  DBLib.Account.mappings = [
    ['_id', 'int'],
    ['_parent_id', 'int'],
    ['_status', 'str'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_lastUpdatedBy', DBLib.LoginSummary, '_lastUpdatedBy_id'],

    ['ident', 'str'],
    ['name', 'str'],
    ['type', 'str'],
    ['properties', '{}'],
    ['global', '{}'],

    ['isLeaf', 'bool'],
    ['hashKey', 'str'],
    ['attrs', 'json'],
    ['tags', ['str']],
  ];

  DBLib.Account.addSigHandler();

  DBLib.Account.findByIdent = function (ident) {
    if (!this.coll || !this.idxByIdent) {
      return null;
    }

    var idx = this.idxByIdent[ident];
    if (idx == undefined) {
      return null;
    }

    return this.coll[idx];
  };

  //
  // Entity model
  //
  DBLib.Entity = RZLib.subclass(DBLib, 'DBLib.Entity', {
    'DBLib.Entity': function () {
      DBLib.Entity.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Entity.load_reqId = 'get-entity-coll';
  DBLib.Entity.save_reqId = 'save-entity';
  DBLib.Entity.indexes = ['_id', 'ident'];
  DBLib.Entity.mappings = [
    ['_id', 'int'],
    ['_owner', 'int'],
    ['_status', 'str'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_updatedBy', DBLib.Login, '_updatedBy_id'],

    ['type', 'str'],
    ['ident', 'str'],
    ['isPrimary', 'bool'],
    ['hashKey', 'str'],

    ['attrs', 'json'],
    ['tags', ['str']],
  ];

  DBLib.Entity.addSigHandler();

  //
  // Party model
  //
  DBLib.Party = RZLib.subclass(DBLib.Entity, 'DBLib.Party', {
    'DBLib.Party': function () {
      DBLib.Party.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Party.load_reqId = 'get-party-coll';
  DBLib.Party.save_reqId = 'save-party';
  DBLib.Party.mappings = [
    ['name', 'str'],
    ['properties', '{}'],
    ['global', '{}'],
  ];

  DBLib.Party.addSigHandler();

  //
  // Address model
  //
  DBLib.Address = RZLib.subclass(DBLib.Entity, 'DBLib.Address', {
    'DBLib.Address': function () {
      DBLib.Address.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Address.load_reqId = 'get-address-coll';
  DBLib.Address.save_reqId = 'save-address';
  DBLib.Address.mappings = [
    ['addressLines', ['str']],
    ['city', 'str'],
    ['state', 'str'],
    ['postalCode', 'str'],
  ];

  DBLib.Address.addSigHandler();

  //
  // Phone model
  //
  DBLib.Phone = RZLib.subclass(DBLib.Entity, 'DBLib.Phone', {
    'DBLib.Phone': function () {
      DBLib.Phone.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Phone.load_reqId = 'get-phone-coll';
  DBLib.Phone.save_reqId = 'save-phone';
  DBLib.Phone.mappings = [['number', 'str']];

  DBLib.Phone.addSigHandler();

  //
  // EMail model
  //
  DBLib.EMail = RZLib.subclass(DBLib.Entity, 'DBLib.EMail', {
    'DBLib.EMail': function () {
      DBLib.EMail.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.EMail.load_reqId = 'get-email-coll';
  DBLib.EMail.save_reqId = 'save-email';
  DBLib.EMail.mappings = [['email', 'str']];

  DBLib.EMail.addSigHandler();

  //
  // Location model
  //
  DBLib.Location = RZLib.subclass(DBLib, 'DBLib.Location', {
    'DBLib.Location': function () {
      DBLib.Location.superConstructor.call(this);
    },
    _initialize: function () {
      this._status = DBLib.LocStatusType.findByCode('A');
      this.businessName = '';
      this.addressLines = [''];
      //      this.city = '';
      //      this.state = '';
      //      this.country = '';
      //      this.postalCode = '';
      this.phoneNumbers = [''];
      this.websiteURL = '';
      this.categories = [];
      (this.businessHours = [[], [], [], [], [], [], []]), (this.businessDescr = '');
      this.photoURLs = [];
      this.labels = [];
      this.attributes = {};
      this.sabType = 'P';

      return this;
    },
  });

  DBLib.Location.load_reqId = 'get-location-coll';
  DBLib.Location.save_reqId = 'save-location';
  DBLib.Location.indexes = ['_id', 'storeCode'];
  DBLib.Location.mappings = [
    ['_id', 'int'],
    ['_owner_id', 'int'],
    ['_parent_id', 'int'],
    ['_type', 'str'],
    ['_status', DBLib.LocStatusType, '_status', 'idxByCode'],
    ['_pubStatus', 'str'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_lastUpdatedBy_id', 'int'],

    ['storeCode', 'str'],
    ['chainName', 'str'],
    ['businessName', 'str'],
    ['addressLines', ['str']],
    ['city', 'str'],
    ['state', 'str'],
    ['country', 'str'],
    ['postalCode', 'str'],
    ['serviceArea', 'str'],
    ['sabType', 'str'],

    ['phoneNumbers', ['str']],
    ['email', 'str'],
    ['websiteURL', 'str'],
    ['vanityURL', 'str'],
    ['slug', 'str'],
    ['logoURL', 'str'],
    ['coverPhotoURL', 'str'],
    ['photoURLs', ['str']],
    ['labels', ['str']],

    ['businessHours', 'json'],
    ['otherHours', '{}'],
    ['specialHours', '[]'],
    ['businessDescr', 'str'],
    ['openingDate', RZLib.Date],
    ['closingDate', RZLib.Date],

    ['profID', 'str'],
    ['profCred', 'str'],
    ['specialties', ['str']],
    ['categories', ['str']],
    ['services', ['str']],
    ['attributes', '{}'],
    ['custom', '{}'],
    ['i18n', '{}'],
    ['meta', '{}'],

    ['latitude', 'float'],
    ['longitude', 'float'],
    ['otherGeom', '{}'],
    ['territory', 'str'],

    ['vendor', '{}'],
    ['vendorStatus', '{}'],

    ['attrs', 'json'],
    ['tags', ['str']],
    ['mediumBusinessDescr', 'str'],
    ['shortBusinessDescr', 'str'],
  ];

  DBLib.Location.addSigHandler();

  // UploadLog model
  //
  DBLib.UploadLog = RZLib.subclass(DBLib, 'DBLib.UploadLog', {
    'DBLib.UploadLog': function () {
      DBLib.UploadLog.superConstructor.call(this);
    },
    _initialize: function () {
      this.logMessages = [];
      this.attrs = {};

      return this;
    },
  });

  DBLib.UploadLog.load_reqId = 'get-upload-log-coll';
  DBLib.UploadLog.save_reqId = 'save-upload-log';
  DBLib.UploadLog.indexes = ['_id'];
  DBLib.UploadLog.mappings = [
    ['_id', 'int'],
    ['_initiatedBy_id', 'int'],

    ['type', 'str'],
    ['source', 'str'],
    ['jobStartTime', RZLib.Timestamp],
    ['jobEndTime', RZLib.Timestamp],
    ['jobState', 'str'],

    ['entriesIn', 'int'],
    ['entriesUpdated', 'int'],
    ['entriesAdded', 'int'],
    ['entriesDropped', 'int'],
    ['logMessages', 'str'],

    ['attrs', 'json'],
  ];

  DBLib.UploadLog.addSigHandler();

  DBLib.SqlReports = RZLib.subclass(DBLib, 'DBLib.SqlReports', {
    'DBLib.SqlReports': function () {
      DBLib.SqlReports.superConstructor.call(this);
    },
  });

  DBLib.SqlReports.loadObjects = function () {
    var dbClass = this;

    if (dbClass.promise) {
      return dbClass.promise;
    }

    if (dbClass.coll) {
      return Promise.resolve(dbClass.coll);
    }

    return (dbClass.promise = socketService.sendRequest('get-sql-query-coll').then((res: any) => {
      this.coll = res.coll;
      DBLib.SqlReports.indexCollection(0, 'ident');
      delete dbClass.promise;
      return res.coll;
    }));
  };
  //
  // ReviewGMB model
  //

  DBLib.ReviewGMB = RZLib.subclass(DBLib, 'DBLib.ReviewGMB', {
    'DBLib.ReviewGMB': function () {
      DBLib.ReviewGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.ReviewGMB.load_reqId = 'get-review-coll';
  DBLib.ReviewGMB.save_reqId = 'save-review';
  DBLib.ReviewGMB.indexes = ['_id'];
  DBLib.ReviewGMB.mappings = [
    ['_id', 'int'],
    ['date', RZLib.Date],
    ['date_id', 'int'],
    ['account_id', 'int'],
    ['location_id', 'int'],

    ['reviewId', 'str'],
    ['reviewIdent', 'str'],
    ['reviewCreatedAt', RZLib.Timestamp],
    ['reviewUpdatedAt', RZLib.Timestamp],

    ['reviewerName', 'str'],
    ['reviewerProfileUrl', 'str'],
    ['reviewerIsAnonymous', 'bool'],
    ['reviewerComment', 'str'],
    ['starRating', 'int'],

    ['replyUpdatedAt', RZLib.Timestamp],
    ['replyComment', 'str'],

    ['account', 'str'],
    ['storeCode', 'str'],
    ['businessName', 'str'],
    ['addressLines', ['str']],
    ['city', 'str'],
    ['state', 'str'],
    ['postalCode', 'str'],
    ['attrs', 'json'],
    ['vendorIdent', 'str'],
    ['tags', ['str']],
    ['country', 'str'],
    ['_status', 'str']
  ];
  //
  // Login model
  //
  DBLib.Login = RZLib.subclass(DBLib, 'DBLib.Login', {
    'DBLib.Login': function () {
      DBLib.Login.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.Login.load_reqId = 'get-login-coll';
  DBLib.Login.save_reqId = 'save-login';
  DBLib.Login.indexes = ['_id', 'idxByLoginId'];
  DBLib.Login.mappings = [
    ['_id', 'int'],
    ['_status', 'str'],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_updatedBy', DBLib.Login, '_updatedBy_id'],

    ['account', 'str'],
    ['loginId', 'str'],
    ['password', 'str'],
    ['note', 'str'],
    ['entity', DBLib.Entity, 'entity_id'],
    ['authTypes', [DBLib.AuthType], 'authType_ids'],

    ['lastPwReset', RZLib.Date],
    ['attrs', 'json'],
    ['tags', ['str']],
    ['prefs', 'json'],
    ['authz', '{}']
  ];

  //
  // LocalPostGMB model
  //
  DBLib.LocalPostGMB = RZLib.subclass(DBLib, 'DBLib.LocalPostGMB', {
    'DBLib.LocalPostGMB': function () {
      DBLib.LocalPostGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.LocalPostGMB.load_reqId = 'get-localpost-coll';
  DBLib.LocalPostGMB.save_reqId = 'save-localpost';
  DBLib.LocalPostGMB.indexes = ['_id'];
  DBLib.LocalPostGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_status', 'str'],

    ['account_id', 'int'],

    ['createTime', RZLib.Timestamp],

    ['scheduleTime', RZLib.Timestamp],
    ['scheduleTimeZone', 'str'],

    ['languageCode', 'str'],

    ['topicType', 'str'],
    ['alertType', 'str'],

    ['eventTitle', 'str'],
    ['eventStartDate', RZLib.Date],
    ['eventStartTime', RZLib.Time],
    ['eventEndDate', RZLib.Date],
    ['eventEndTime', RZLib.Time],

    ['offerCouponCode', 'str'],
    ['offerRedeemURL', 'str'],
    ['offerTerms', 'str'],

    ['ctaActionType', 'str'],
    ['ctaURL', 'str'],

    ['sourceURL', 'str'],
    ['internalURL', 'str'],

    ['summary', 'str'],

    ['loc_count', 'int'], // read-only
    ['loc_count_live', 'int'], // read-only

    ['attrs', 'json'],
  ];

  //
  // LocalPostDetailGMB model
  //
  DBLib.LocalPostDetailGMB = RZLib.subclass(DBLib, 'DBLib.LocalPostDetailGMB', {
    'DBLib.LocalPostDetailGMB': function () {
      DBLib.LocalPostDetailGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.LocalPostDetailGMB.load_reqId = 'get-localpost-detail-coll';
  DBLib.LocalPostDetailGMB.save_reqId = 'save-localpost-detail';
  DBLib.LocalPostDetailGMB.indexes = ['_id'];
  DBLib.LocalPostDetailGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_status', 'str'],

    ['account_id', 'int'],
    ['post_id', 'int'],
    ['location_id', 'int'],

    ['localPostId', 'str'],
    ['state', 'str'],

    ['localPostCreatedAt', RZLib.Timestamp],
    ['localPostUpdatedAt', RZLib.Timestamp],

    ['mediaKey', 'str'],
    ['mediaItem', 'json'],

    ['searchURL', 'str'],

    ['storeCode', 'str'], // read-only
    ['businessName', 'str'], // read-only

    ['attrs', 'json'],
  ];

  //Mark notifications as read

  DBLib.MarkAsRead = RZLib.subclass(DBLib, 'DBLib.MarkAsRead', {
    'DBLib.MarkAsRead': function () {
      DBLib.MarkAsRead.superConstructor.call(this);
      this.attrs = {};
    },
  });
  DBLib.MarkAsRead.load_reqId = 'get-notification-coll';
  DBLib.MarkAsRead.save_reqId = 'save-notification';
  DBLib.MarkAsRead.indexes = ['_id'];
  DBLib.MarkAsRead.mappings = [
    ['_id', 'int'],
    ['is_read', 'bool'],
  ];

  //
  // BulkOwnerQuestionGMB model
  //
  DBLib.BulkOwnerQuestionGMB = RZLib.subclass(DBLib, 'DBLib.BulkOwnerQuestionGMB', {
    'DBLib.BulkOwnerQuestionGMB': function () {
      DBLib.BulkOwnerQuestionGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.BulkOwnerQuestionGMB.load_reqId = 'get-bulk-owner-question-coll';
  DBLib.BulkOwnerQuestionGMB.save_reqId = 'save-bulk-owner-question';
  DBLib.BulkOwnerQuestionGMB.indexes = ['_id'];
  DBLib.BulkOwnerQuestionGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_status', 'str'],

    ['account_id', 'int'],

    ['createTime', RZLib.Timestamp],

    ['scheduleTime', RZLib.Timestamp],
    ['scheduleTimeZone', 'str'],

    ['languageCode', 'str'],
    ['questionText', 'str'],
    ['answerText', 'str'],

    ['ques_count', 'int'], // read-only
    ['ques_count_live', 'int'], // read-only
    ['ans_count_cust', 'int'], // read-only
    ['ans_owner', 'bool'], // read-only

    ['attrs', 'json'],
  ];

  //
  // QuestionGMB model
  //
  DBLib.QuestionGMB = RZLib.subclass(DBLib, 'DBLib.QuestionGMB', {
    'DBLib.QuestionGMB': function () {
      DBLib.QuestionGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.QuestionGMB.load_reqId = 'get-question-coll';
  DBLib.QuestionGMB.save_reqId = 'save-question';
  DBLib.QuestionGMB.indexes = ['_id'];
  DBLib.QuestionGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_status', 'str'],

    ['account_id', 'int'],
    ['bulkOwner_id', 'int'],
    ['location_id', 'int'],

    ['questionId', 'str'],

    ['authorName', 'str'],
    ['authorPhotoURL', 'str'],
    ['authorType', 'str'],

    ['upvoteCount', 'int'],

    ['languageCode', 'str'],
    ['questionText', 'str'],
    ['ownerAnswerText', 'str'],

    ['topAnswers', 'jsonb'],
    ['totalAnswersCount', 'int'],

    ['createTime', RZLib.Timestamp],
    ['updateTime', RZLib.Timestamp],

    ['storeCode', 'str'], // read-only
    ['businessName', 'str'], // read-only
    ['ans_count', 'int'], // read-only
    ['ans_count_live', 'int'], // read-only
    ['ans_count_cust', 'int'], // read-only
    ['ans_owner', 'bool'], // read-only

    ['attrs', 'json'],
  ];

  //
  // AnswerDetailGMB model
  //
  DBLib.AnswerDetailGMB = RZLib.subclass(DBLib, 'DBLib.AnswerDetailGMB', {
    'DBLib.AnswerDetailGMB': function () {
      DBLib.AnswerDetailGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.AnswerDetailGMB.load_reqId = 'get-answer-coll';
  DBLib.AnswerDetailGMB.save_reqId = 'save-answer';
  DBLib.AnswerDetailGMB.indexes = ['_id'];
  DBLib.AnswerDetailGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_status', 'str'],
    ['account_id', 'int'],

    ['question_id', 'str'],

    ['answerId', 'str'],

    ['authorName', 'str'],
    ['authorPhotoURL', 'str'],
    ['authorType', 'str'],

    ['upvoteCount', 'int'],

    ['answerText', 'str'],
    ['createTime', RZLib.Timestamp],
    ['updateTime', RZLib.Timestamp],

    ['attrs', 'json'],
  ];

  //
  // MediaBatchGMB model
  //
  DBLib.MediaBatchGMB = RZLib.subclass(DBLib, 'DBLib.MediaBatchGMB', {
    'DBLib.MediaBatchGMB': function () {
      DBLib.MediaBatchGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.MediaBatchGMB.load_reqId = 'get-media-batch-coll';
  DBLib.MediaBatchGMB.save_reqId = 'save-media-batch';
  DBLib.MediaBatchGMB.indexes = ['_id'];
  DBLib.MediaBatchGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', RZLib.Timestamp],
    ['_status', 'str'],

    ['account_id', 'int'],

    ['createTime', RZLib.Timestamp],

    ['scheduleTime', RZLib.Timestamp],
    ['scheduleTimeZone', 'str'],

    ['sourceURL', 'str'],
    ['internalURL', 'str'],

    ['mediaFormat', 'str'],
    ['category', 'str'],
    ['description', 'str'],
    ['attribution', 'json'],

    ['width', 'int'],
    ['height', 'int'],
    ['exifData', 'json'],

    ['loc_count', 'int'], // read-only
    ['loc_count_live', 'int'], // read-only

    ['labels', ['str']],
    ['attrs', 'json'],
  ];

  //
  // MediaDetailGMB model
  //
  DBLib.MediaDetailGMB = RZLib.subclass(DBLib, 'DBLib.MediaDetailGMB', {
    'DBLib.MediaDetailGMB': function () {
      DBLib.MediaDetailGMB.superConstructor.call(this);
      this.attrs = {};
    },
  });

  DBLib.MediaDetailGMB.load_reqId = 'get-media-detail-coll';
  DBLib.MediaDetailGMB.save_reqId = 'save-media-detail';
  DBLib.MediaDetailGMB.indexes = ['_id'];
  DBLib.MediaDetailGMB.mappings = [
    ['_id', 'int'],
    ['_createdAt', RZLib.Timestamp],
    ['_lastUpdatedAt', 'str'],
    ['_status', 'str'],

    ['account_id', 'int'],
    ['batch_id', 'int'],
    ['location_id', 'int'],

    ['mediaIdent', 'str'],
    ['createdTime', RZLib.Timestamp],

    ['sourceURL', 'str'],
    ['internalURL', 'str'],
    ['googleURL', 'str'],
    ['thumbnailURL', 'str'],

    ['mediaFormat', 'str'],
    ['category', 'str'],
    ['description', 'str'],
    ['attribution', 'json'],

    ['width', 'int'],
    ['height', 'int'],
    ['exifData', 'json'],

    ['storeCode', 'str'],
    ['businessName', 'str'],

    ['viewCount', 'int'],
    ['labels', ['str']],
    ['attrs', 'json'],
  ];

  return DBLib;
};

// vim:ft=javascript:ts=2:sw=2:expandtab:
