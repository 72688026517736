import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateTimePipe' })

export class DateTimePipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      const year = value.substring(0, 4);
      const month = value.substring(5, 7);
      const day = value.substring(8, 10);
      const time = value.substring(11, 16);
      return `${day} ${this.setMonthName(month)}, ${year} | ${time}`;
    } else {
      return 'N/A';
    }
  }

  private setMonthName(month: string): string {
    const monthObj = [
      { id: '01', value: 'Jan', _id: '1' },
      { id: '02', value: 'Feb', _id: '2' },
      { id: '03', value: 'Mar', _id: '3' },
      { id: '04', value: 'Apr', _id: '4' },
      { id: '05', value: 'May', _id: '5' },
      { id: '06', value: 'Jun', _id: '6' },
      { id: '07', value: 'Jul', _id: '7' },
      { id: '08', value: 'Aug', _id: '8' },
      { id: '09', value: 'Sep', _id: '9' },
      { id: '10', value: 'Oct', _id: '10' },
      { id: '11', value: 'Nov', _id: '11' },
      { id: '12', value: 'Dec', _id: '12' }
    ];

    const monthName = monthObj.find(m => m.id === month || m._id === month);
    return monthName.value;
  }

}
