import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../core/backend-adapter/api/api.service';
import { GBP_TABS } from './gbp-tabs.constants';

@Component({
  selector: 'app-gbp-management',
  templateUrl: './gbp-management.component.html',
  styleUrls: ['./gbp-management.component.scss'],
})
export class GbpManagementComponent implements OnInit, OnDestroy {
  gbpTabs: typeof GBP_TABS = GBP_TABS;

  // tab menu
  tabItems: MenuItem[];

  activeItem: MenuItem;

  private unsubscribe$ = new Subject<void>();

  constructor(private apiService: ApiService) {
    this.tabItems = Object.values(this.gbpTabs).map((tabName) => {
      return {
        label: tabName,
        // disabled: tabName == this.gbpTabs.PHOTO || tabName == this.gbpTabs.QA,
        hash: tabName.split(' ').join('-').toLowerCase(),
        command: (event) => {
          this.setActiveTab(event.item);
        },
      };
    });
    this.activeItem = this.tabItems[0];
  }

  ngOnInit(): void {
    this.apiService
      .get('Account')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        // console.log(res);
      });
  }

  // tab menu navigation
  setActiveTab(item) {
    this.activeItem = item;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
