import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StyleChangerComponent } from './style-changer.component';
import { StyleChangerService } from './style-changer.service';
import { RsStyleManager } from './style-manager.worker';

@NgModule({
  imports: [CommonModule],
  declarations: [StyleChangerComponent],
  exports: [StyleChangerComponent],
  providers: [StyleChangerService, RsStyleManager],
})
export class StyleChangerModule {}
