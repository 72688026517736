import { VENDORS_KEYS } from '../core/feature-modules/directory-packages/directory.constants';

type IVendorImagesType = { [vendorKey: string]: string };

export class AcctHelpers {
  public VENDOR_IMAGES: IVendorImagesType;
  public vendors = VENDORS_KEYS;

  constructor() {
    this.prebuildVendorImages();
  }

  public flattenGlobal(glObj: any) {
    const flatObj = {};

    for (const vendor of this.vendors) {
      // if global has publish flag set, push it to flatGlobal; else default to false
      flatObj[`${vendor}.publish`] = glObj[vendor]?.publish ?? false;

      // if global has categories set, push the array to flatGlobal; else default to empty array
      flatObj[`${vendor}.categories`] = glObj[vendor]?.categories?.slice() ?? [];

      // check for set categories, set mapping type accordingly; default to acctLevel if no mapping set
      if (!glObj[vendor]?.catMapLevel) {
        if (glObj[vendor]?.categories?.length > 0) {
          flatObj[`${vendor}.catMapLevel`] = 'acctLevel';
        } else {
          flatObj[`${vendor}.catMapLevel`] = null;
        }
      } else {
        flatObj[`${vendor}.catMapLevel`] = glObj[vendor]?.catMapLevel;
      }

      // default utm
      if (['gbp', 'bing', 'facebook', 'factual'].includes(vendor)) {
        flatObj[`${vendor}.utm`] = glObj[vendor]?.utm ?? null;
      }

      // only some vendors have fields below, no need to default all of them
      if (glObj[vendor]?.chainId) {
        flatObj[`${vendor}.chainId`] = glObj[vendor].chainId;
      }

      // Bing extra fields
      if (glObj[vendor]?.chainName) {
        flatObj[`${vendor}.chainName`] = glObj[vendor].chainName;
      }

      if (glObj[vendor]?.website) {
        flatObj[`${vendor}.website`] = glObj[vendor].website;
      }

      if (glObj[vendor]?.clientContactName) {
        flatObj[`${vendor}.clientContactName`] = glObj[vendor].clientContactName;
      }

      if (glObj[vendor]?.clientContactEmail) {
        flatObj[`${vendor}.clientContactEmail`] = glObj[vendor].clientContactEmail;
      }

      if (glObj[vendor]?.chainFlag) {
        flatObj[`${vendor}.chainFlag`] = glObj[vendor].chainFlag;
      }

      // Yelp has isSMB flag
      if (glObj[vendor]?.isSMB) {
        flatObj[`${vendor}.isSMB`] = glObj[vendor].isSMB;
      } else {
        flatObj[`${vendor}.isSMB`] = false;
      }
    }

    return flatObj;
  }

  buildGlObj(flatObj: any) {
    const treeObj = {};

    for (const vendor of this.vendors) {
      treeObj[vendor] = {};
      treeObj[vendor].publish = flatObj[`${vendor}.publish`];
      treeObj[vendor].categories = [];
      treeObj[vendor].catMapLevel = flatObj[`${vendor}.catMapLevel`];

      // localeze and here only use codes for categories
      if (vendor == 'localeze' || vendor == 'here') {
        treeObj[vendor].categories = flatObj[`${vendor}.categories`].slice();
      } else {
        // for all others extract codes
        for (const cat of flatObj[`${vendor}.categories`]) {
          if (!cat) {
            continue;
          }

          treeObj[vendor].categories.push(cat.code);
        }
      }

      if (flatObj[`${vendor}.utm`]) {
        treeObj[vendor].utm = flatObj[`${vendor}.utm`];
      }

      if (flatObj[`${vendor}.chainId`]) {
        treeObj[vendor].chainId = flatObj[`${vendor}.chainId`];
      }

      if (flatObj[`${vendor}.chainName`]) {
        treeObj[vendor].chainName = flatObj[`${vendor}.chainName`];
      }

      if (flatObj[`${vendor}.website`]) {
        treeObj[vendor].website = flatObj[`${vendor}.website`];
      }

      if (flatObj[`${vendor}.clientContactName`]) {
        treeObj[vendor].clientContactName = flatObj[`${vendor}.clientContactName`];
      }

      if (flatObj[`${vendor}.clientContactEmail`]) {
        treeObj[vendor].clientContactEmail = flatObj[`${vendor}.clientContactEmail`];
      }

      if (flatObj[`${vendor}.chainFlag`]) {
        treeObj[vendor].chainFlag = flatObj[`${vendor}.chainFlag`];
      }

      if (flatObj[`${vendor}.isSMB`]) {
        treeObj[vendor].isSMB = flatObj[`${vendor}.isSMB`];
      }
    }

    return treeObj;
  }

  private prebuildVendorImages(): void {
    const directoryUrl = '/assets/directories/';

    this.VENDOR_IMAGES = VENDORS_KEYS.reduce((acc, vendorKey) => {
      acc[vendorKey] = `${directoryUrl}${vendorKey}.png`;
      return acc;
    }, {});
  }
}
