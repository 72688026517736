<div
  *ngIf="sessionService.isAuthenticated('app')"
  class="breadcrumb"
  [ngClass]="{
    collapsed: collapsed,
    expanded: !collapsed
  }"
>
  <div class="upper-header">
    <div class="breadcrumb-left">
      <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>

      <!-- <div class="breadcrumbs-container">
        <span class="row-item" *ngFor="let row of breadcrumbItems; index as i">
          <a
            class="crumb"
            [innerHTML]="row.label"
            [routerLink]="row.url"
            [class.disabled]="!row.url"
          ></a>

          <span class="sepr" [innerHTML]="checkSeparatorInserting(i)"></span>
        </span>
      </div> -->

      <button
        *ngIf="showReportsButton"
        pButton
        type="button"
        label="Back to table of contents"
        class="p-button-raised p-button-info p-button-text"
        (click)="backToContents()"
      ></button>
      <!-- <p class="sub-account">&nbsp;&nbsp;&nbsp;&nbsp;{{ selectedAccount?.name }}</p> -->
      <!-- <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ sessionService.currAccount }}</p> -->
    </div>

    <!-- Notifications on menu bar -->

    <div class="breadcrumb-right">
      <div *ngIf="overlayOpen" #notifOverlay class="overlay">
        <span class="notif-header">
          <h3>Notifications</h3>
          <h2 class="notif-options" (click)="toggleSettings()">. . .</h2>
        </span>
        <hr />
        <ul>
          <li *ngFor="let notif of notifs; index as i; trackBy: trackIndex">
            <div class="new-dot">
              <img src="../assets/notifications/blue-ellipse.svg" alt="new notification" *ngIf="notif.new" />
            </div>
            <span>
              <span class="apply-hover">
                <p class="title">{{ notif.title }}</p>
                <p class="content">{{ notif.content }}</p>
              </span>
              <span class="footer">
                <small>{{ notif.date }}</small>
                <span class="buttons">
                  <img
                    [ngClass]="{ 'mark-read': true, read: !notif.new }"
                    src="../assets/notifications/{{ notif.new ? 'empty-ellipse' : 'green-ellipse' }}.svg"
                    alt="mark notification as read"
                    (click)="notif.new = false"
                  />
                  <img
                    class="delete"
                    src="../assets/notifications/delete.png"
                    alt="delete notification"
                    (click)="deleteEntry(i)"
                  />
                </span>
              </span>
              <hr *ngIf="i < notifs.length - 1" />
            </span>
          </li>
        </ul>
      </div>

      <div #notifSettings class="notif-settings" *ngIf="openSettings == true">
        <p (click)="markAllRead()">Mark all as read</p>
        <p (click)="deleteAllNotifs()">Delete all</p>
        <p (click)="goToSettings()">Settings</p>
      </div>

      <i *ngIf="false" #notifBell [class.p-overlay-badge]="checkForNewNotifs()" class="notifications-bell" severity="success">
        <span
          id="pr_id_2_badge"
          class="p-badge p-component p-badge-success p-badge-dot"
          *ngIf="checkForNewNotifs()"
        ></span>
      </i>

      <ng-content></ng-content>
    </div>
  </div>
  <div class="divider"></div>
</div>
