<div class="main-logins-container">
  <div class="table-container">
    <p-table
      #dt2
      id="loginsTable"
      [value]="logins"
      [scrollable]="true"
      [resizableColumns]="true"
      [globalFilterFields]="['loginId', 'name', 'account', 'authTypes', 'tags', '_status']"
      [style.maxWidth]="tableWidth"
      [scrollHeight]="tableHeight"
      selectionMode="single"
      sortMode="multiple"
      [(selection)]="selLogin"
      [loading]="loading"
      (onRowSelect)="selectedUserLogin($event)"
      [paginator]="true"
      [rows]="20"
      (onPage)="pageChange($event)"
      [showCurrentPageReport]="true"
      [first]="first"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

      <ng-template pTemplate="caption">
        <div class="flex">
          <input class="filter_logins" pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword" />
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of tableHeaderData" pSortableColumn="{{col.enableSorting ? col.sortingLable : null}}"
            class="{{ col.className }}">
            {{col.label}}
            <p-sortIcon *ngIf="col.enableSorting" field="col.fieldName"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-login>
        <tr [pSelectableRow]="login" (dblclick)="editLogin(login, 'editUser')">
          <td pResizableColumn class="db-id">{{ login._id }}</td>
          <td pResizableColumn class="login-id">{{ login.loginId }}</td>
          <td pResizableColumn class="password">
            {{ login.password || 'N/A' }}
          </td>
          <td pResizableColumn class="pwd-reset">
            {{ login.lastPwReset || 'Never' }}
          </td>
          <td pResizableColumn class="note">{{ login.note }}</td>
          <td pResizableColumn class="account">
            {{ login.account || 'NONE' }}
          </td>
          <td pResizableColumn class="auth-types">
            {{ authTypeList(login.authTypes) || 'N/A' }}
          </td>
          <td pResizableColumn class="tags">
            {{ login.tags || 'N/A' }}
          </td>
          <td pResizableColumn class="status">
            {{ statuses[login._status]?.name }}
          </td>
        </tr>
      </ng-template>

    </p-table>

    <div class="buttons">
      <button pButton (click)="addLogin('addUser')" label="Add" class="p-button-success"
        *ngIf="sessionService.hasPerm('admin.logins', 'a')"></button>
      <button pButton [disabled]="!selLogin" (click)="editLogin(selLogin, 'editUser')" label="Edit"
        *ngIf="sessionService.hasPerm('admin.logins', 'e')"></button>
    </div>
  </div>
</div>

<p-dialog id="logins" [(visible)]="showDialog" [modal]="true" header="Heaader" (onHide)="closePopup()"
  *ngIf="makeDialog">
  <ng-template pTemplate="header">
    <p class="dialog_title">{{ newLogin ? 'Add login' : 'Edit login: ' + loginCopy._id + ' - ' + loginCopy.note }}</p>
  </ng-template>
  <div class="popup-main">
    <div class="add_user">

      <div class="add_user">
        <div class="add_user__block">
          <label class="add_user__label" for="login-id">Login ID</label>
          <input class="add_user__input" pInputText id="login-id" type="text" [(ngModel)]="loginCopy.loginId" />
        </div>

        <div class="add_user__block">
          <label class="add_user__label" for="login-id">Password</label>
          <input class="add_user__input" pInputText id="password" type="text" [(ngModel)]="loginCopy.password" />
          <button pButton icon="pi pi-plus-circle" class="p-button-text cta_btn" pTooltip="Generate random password"
            tooltipPosition="top" (click)="suggestPassword()"></button>
        </div>

        <div class="add_user__block">
          <label class="add_user__label" for="login-id">Name</label>
          <input class="add_user__input" pInputText id="note" type="text" [(ngModel)]="loginCopy.note" />
        </div>

        <div class="add_user__block">
          <label class="add_user__label" for="login-id">Default account</label>
          <p-dropdown placeholder="Select an Account" [(ngModel)]="loginCopy.account" [options]="accounts"
            optionValue="name" optionLabel="name"></p-dropdown>
        </div>

        <div class="add_user__block">
          <label class="add_user__label">Status</label>
          <p-dropdown class="add_user__input" [(ngModel)]="loginCopy._status" [options]="statuses" optionValue="code"
            optionLabel="name" appendTo="body"></p-dropdown>
        </div>

        <div class="add_user__block">
          <label class="add_user__label">Tags</label>
          <p-checkbox class="checkboxex" name="tags-list" value="console" label="Console"
            [(ngModel)]="selectedTags"></p-checkbox>
          <p-checkbox class="checkboxex" name="tags-list" value="api" label="API"
            [(ngModel)]="selectedTags"></p-checkbox>
        </div>

        <div class="add_user__block">
          <label class="add_user__label">Account authorization</label>
          <div class="auth_roles" *ngFor="let auth of authAccount; index as i">
            <ng-container *ngIf="ctaActionType === 'addUser'">
              <p-dropdown placeholder="Select an Account" [options]="accounts" [(ngModel)]="authAccount[i]"
                optionLabel="name" (onChange)="accountChanged($event)" appendTo="body" [filter]="true" filterBy="name">
              </p-dropdown>
            </ng-container>
            <ng-container *ngIf="ctaActionType === 'editUser'">
              <p-dropdown placeholder="Select an Account" [options]="accounts" [(ngModel)]="editAuthAccountData[i]"
                optionLabel="name" (onChange)="accountChanged($event)" appendTo="body" [filter]="true" filterBy="name">
              </p-dropdown>
            </ng-container>

            <button *ngIf="authAccount[i]" pButton icon="pi pi-minus" class="p-button-text cta_btn"
              (click)="removeAccountAuth(i)"></button>
            <button *ngIf="authAccount[i]" pButton icon="pi pi-plus" class="p-button-text cta_btn"
              (click)="addAccountAuth(i)"></button>
            <div class="auth_roles__btn">
              <button [class.active]="authAccount[i].activeTab === 'locations'"
                [class.inactive]="authAccount[i].activeTab !== 'locations'" class="auth_btn"
                (click)="selectAuthAttribute('locations', auth, i)">
                Locations
              </button>
              <button [class.active]="authAccount[i].activeTab === 'tags'"
                [class.inactive]="authAccount[i].activeTab !== 'tags'" class="auth_btn"
                (click)="selectAuthAttribute('tags', auth, i)">
                Tags
              </button>
            </div>
            <div>
              <p-multiSelect
                [options]="authAccount[i].activeTab === 'locations' ? authAccount[i].allLocs : authAccount[i].allTags"
                [(ngModel)]="authAccount[i].activeTab === 'locations' ? authAccount[i].locations : authAccount[i].tags"
                display="chip" showClear="true" optionLabel="name" (onChange)="selectLocOrTag($event, i)"
                [showToggleAll]="false" optionDisabled="disabled" [displaySelectedLabel]="true">
              </p-multiSelect>
            </div>

          </div>
        </div>

        <div class="add_user__block">
          <label class="add_user__label">Auth roles</label>
          <div *ngFor="let auth of loginCopy.authTypes; index as i; trackBy: trackIndex" class="auth_roles">
            <p-dropdown [(ngModel)]="loginCopy.authTypes[i]" [options]="authList" optionLabel="name"
              optionDisabled="inactive" appendTo="body" [style]="{ width: '250px' }">
            </p-dropdown>
            <button pButton icon="pi pi-minus" class="p-button-text cta_btn" (click)="removeAuth(i)"></button>
            <button pButton icon="pi pi-plus" class="p-button-text cta_btn" (click)="addAuth(i)"></button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Cancel" icon="pi pi-times" class="p-button-outlined p-button-secondary dialog"
      (click)="closePopup()"></button>
    <button pButton label="Save" icon="pi pi-check" class="dialog" (click)="saveLogin()"></button>
  </ng-template>
</p-dialog>
