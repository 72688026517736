<div class="card-reviews">
  <header>
    <p>METRICS</p>
    <span>
      <button pButton type="button" icon="pi pi-eye-slash" class="hide-graphs"
        [pTooltip]="displayCards ? 'Hide Metrics' : 'Show Metrics'" tooltipPosition="bottom"
        tooltipStyleClass="hide-tooltip" (click)="hideOverview()"></button>
    </span>
  </header>
  <hr *ngIf="displayCards" />
  <div class="cards" *ngIf="displayCards">
    <!-- First Card -->
    <div class="cards_card">
      <p class="cards_card__title">Average Rating (<span *ngIf="totalReviewsWithComma" class="cards_card__data">{{ totalReviewsWithComma }}</span> Reviews )</p>
      <div class="cards_block">
        <div class="cards_block__one">
          <!-- <p class="cards_title">All Reviews</p> -->
          <div class="cards_div">
            <div class="ratings_rating">
              <h2>{{ averageRating }} <i class="pi pi-star ratings_star"></i></h2>
            </div>
            <div class="ratings_stats">
              <p-chart class="cards_graph" type="doughnut" [data]="ratings" [options]="doughnutOptions" width="100%"
                height="72%"></p-chart>
              <div>
                <p class="ratings_stats__info">
                  <i class="p-badge-success p-badge p-badge-dot"></i><span class="ratings_dot_one">{{
                    percentageHighRating }}% Good Ratings</span>
                </p>
                <p class="ratings_stats__info">
                  <i class="p-badge p-badge-warning p-badge-dot"></i><span class="ratings_dot_two">{{
                    percentageMediumRating }}% Neutral Ratings</span>
                </p>
                <p class="ratings_stats__info">
                  <i class="p-badge p-badge-danger p-badge-dot"></i><span class="ratings_dot_three">{{
                    percentageLowRating }}% Poor Ratings</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="cards_block__two">
          <p class="cards_title">Rating distribution</p>
          <div class="ratings_div">
            <ng-container *ngFor="let ratings of ratingsGraph">
              <div class="ratings_card">
                <span class="ratings_label">{{ratings.label}} <i class="pi pi-star ratings_icon"></i> </span>
                <p-progressBar class="progress_bar" [value]="ratings.ratingValue" [showValue]="false"></p-progressBar>
                <span class="ratings_percent">{{ratings.ratingValue}} %</span>
              </div>
            </ng-container>
          </div>
        </div>

      </div>
    </div>

    <!-- Second Card -->
    <div class="cards_card">
      <p class="cards_card__title">Review Responses (<span *ngIf="totalReviewsRepliedTo" class="cards_card__data">{{ totalReviewsRepliedTo }}</span> Reviews )</p>
      <div class="cards_block">
        <div class="cards_block__one">
          <!-- <p class="cards_title">All Reviews</p> -->
          <div class="cards_div">
            <p-chart class="cards_graph" type="doughnut" [data]="reviewResponses" [options]="doughnutOptions"
              width="100%" height="100%">
            </p-chart>
            <div class="cards_info">
              <!-- <p>Review Responses</p> -->
              <p>
                <i class="p-badge-success p-badge p-badge-dot"></i>
                {{ respondedPct }}% responded to
              </p>
              <p>
                <i class="p-badge p-badge-danger p-badge-dot"></i>
                {{ unrespondedPct }}% not responded to
              </p>
            </div>
          </div>
        </div>

        <div class="cards_block__two">
          <p class="cards_title">Response rate by Rating</p>
          <div class="ratings_div">
            <ng-container *ngFor="let ratings of ratingsGraph">
              <div class="ratings_card">
                <span class="ratings_label">{{ratings.label}} <i class="pi pi-star ratings_icon"></i> </span>
                <p-progressBar class="progress_bar" [value]="ratings.responseValue" [showValue]="false"></p-progressBar>
                <span class="ratings_percent">{{ratings.responseValue}} %</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="line_card" *ngIf="dailyAvgRatings">
      <p class="cards_card__title">Average Rating per Day (<span class="cards_card__data">{{ totalReviewsWithComma }}</span> Reviews )</p>
      <div class="cards_block line_graph">
        <p-chart class="line_graph__graph" type="line" [data]="dailyAvgRatingsData" [options]="options" [width]="width" height="90%"></p-chart>
      </div>
    </div>

    <div class="cards_card" *ngIf="false">
      <p class="cards_card__title">Word frequency (last 30 days) x,xxx Reviews</p>
      <div class="cards_block line_graph">
        <!-- Add the table here.
         -->
      </div>
    </div>

    <div class="line_card" *ngIf="dailyTotalReviews">
      <p class="cards_card__title">Total Reviews per Day <span class="cards_card__data">{{ totalReviewsWithComma }}</span> Reviews</p>
      <div class="cards_block line_graph">
        <p-chart class="line_graph__graph" type="line" [data]="dailyReviewsData" [options]="options" [width]="width" height="90%"></p-chart>
      </div>
    </div>

  </div>



</div>
