import { Component, OnInit } from '@angular/core';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationPopupComponent } from '../components/notification-popup/notification-popup.component';

@Component({
  selector: 'app-manage-notifications-tab',
  templateUrl: './manage-notifications-tab.component.html',
  styleUrls: ['./manage-notifications-tab.component.scss'],
})
export class ManageNotificationsTabComponent implements OnInit {
  // New updates with previousValue and nextValue for email notifications.......
  public email = {
    previousValue: false,
    currentValue: false
  }
  public emailAllReviews = {
    previousValue: false,
    currentValue: null
  }
  public emailPositiveReviews = {
    previousValue: false,
    currentValue: null
  }
  public emailNeutralReviews = {
    previousValue: false,
    currentValue: null
  }
  public emailNegativeReviews = {
    previousValue: false,
    currentValue: null
  }
  public emailBtnStat: boolean = true;

  // weekly digest ----------------- USED.
  public weeklyOpt = {
    previousValue: false,
    currentValue: false
  }
  public weeklyOptDay = {
    previousValue: 'Monday',
    currentValue: 'Monday'
  }
  public weeklyOptTime = {
    previousValue: '9:00am',
    currentValue: '9:00am'
  }
  public weeklyOptTimeZone = {
    previousValue: 'US/Eastern',
    currentValue: 'US/Eastern'
  }

  //Daily digest ----------------- USED
  public dailyOpt = {
    previousValue: false,
    currentValue: false
  }
  public dailyOptTime = {
    previousValue: '9:00am',
    currentValue: '9:00am'
  }
  public dailyOptTimeZone = {
    previousValue: 'US/Eastern',
    currentValue: 'US/Eastern'
  }
  // ----------------- USED
  public instantNotification = {
    previousValue: false,
    currentValue: false
  }
  // ----------------- USED
  public inConsole = {
    previousValue: false,
    currentValue: false
  }
  public inConsoleAllReviews = {
    previousValue: false,
    currentValue: null
  }
  public inConsolePositiveReviews = {
    previousValue: false,
    currentValue: null
  }
  public inConsoleNeutralReviews = {
    previousValue: false,
    currentValue: null
  }
  public inConsoleNegativeReviews = {
    previousValue: false,
    currentValue: null
  }
  weekDays: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  hours = [
    '00:00am',
    '1:00am',
    '2:00am',
    '3:00am',
    '4:00am',
    '5:00am',
    '6:00am',
    '7:00am',
    '8:00am',
    '9:00am',
    '10:00am',
    '11:00am',
    '12:00pm',
    '1:00pm',
    '2:00pm',
    '3:00pm',
    '4:00pm',
    '5:00pm',
    '6:00pm',
    '7:00pm',
    '8:00pm',
    '9:00pm',
    '10:00pm',
    '11:00pm',
  ];
  selWeeklyTime = '9:00am';
  selDailyTime = '9:00am';
  filteredHrs: string[] = [];
  public selectWeeklyTimeZone: string = 'US/Eastern';
  public timeZones = [];
  public selectDailyTimeZone: string = 'US/Eastern';
  public emailNotifications: any[] = [
    {
      id: 0,
      name: 'All Reviews',
      value: 'allReviews',
      checked: false
    },
    {
      id: 1,
      name: 'Positive Reviews (4 to 5 stars)',
      value: 'positiveReviews',
      checked: false
    },
    {
      id: 2,
      name: 'Neutral Reviews (3 stars)',
      value: 'neutralReviews',
      checked: false
    },
    {
      id: 3,
      name: 'Negative Reviews (1 to 2 stars)',
      value: 'negativeReviews',
      checked: false
    }
  ];
  public selectedEmailNotifications: string[] = [];
  public selectedConsoleNotifications = [];
  private id: string;
  public locationName: string = '';
  public hasUnsavedData: boolean = false;
  private globalConfig: any = {};

  public dialogRef: DynamicDialogRef;


  emailOptions: any;

  constructor(
    private socketService: SocketService,
    private sessionService: SessionService,
    private notifyService: NotifyService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getUserDetails();
    this.initNotifications(); /// call to get notifications details...
    this.timeZones = TIME_ZONES;
  }

  private getUserDetails(): void {
    this.sessionService.getCurrentUser$()
      .subscribe(res => {
        if(res && res?.account) {
          this.locationName = res.account;
        }
      })
  }

  private initNotifications(): void {
    const notification_type = "review";
    this.socketService.sendRequest('get-notification-configuration-coll', { notification_type })
      .then(res => {

        const notifications = res['collection'] && res['collection']?.length &&  res['collection'];

        if(notifications.length) {
          const { weeklyDigest, dailyDigest, instantNotifications, emailOpt, byEmail, inConsole, consoleOpt, _id } = JSON.parse(notifications[0]?.configuration);
          this.globalConfig = JSON.parse(notifications[0]?.configuration);
          this.id = res['collection'] && res['collection']?.length && res['collection'][0]?._id;
          // weekly
          this.weeklyOpt.previousValue = weeklyDigest?.value;
          this.weeklyOpt.currentValue = weeklyDigest?.value;
          this.weeklyOptDay.previousValue = weeklyDigest?.weeklySelectedDay;
          this.weeklyOptDay.currentValue = weeklyDigest?.weeklySelectedDay;
          this.weeklyOptTime.previousValue = weeklyDigest?.weeklySelectedTime;
          this.weeklyOptTime.currentValue = weeklyDigest?.weeklySelectedTime;
          this.weeklyOptTimeZone.previousValue = weeklyDigest?.weeklySelectedTimezone;
          this.weeklyOptTimeZone.currentValue = weeklyDigest?.weeklySelectedTimezone;


          // daily
          this.dailyOpt.previousValue = dailyDigest?.value || false;
          this.dailyOpt.currentValue = dailyDigest?.value || false;
          this.dailyOptTime.previousValue = dailyDigest?.dailySelectedTime || '9:00am';
          this.dailyOptTime.currentValue = dailyDigest?.dailySelectedTime || '9:00am';
          this.dailyOptTimeZone.previousValue = dailyDigest?.dailySelectedTimezone || 'US/Eastern';
          this.dailyOptTimeZone.currentValue = dailyDigest?.dailySelectedTimezone || 'US/Eastern';

          this.instantNotification.currentValue = instantNotifications || false;
          this.instantNotification.previousValue = instantNotifications || false;
          this.email.currentValue = byEmail;
          this.email.previousValue = byEmail;
          this.emailOptions = emailOpt;
          this.setDropdpownOptions(emailOpt, this.selectedEmailNotifications); // Initialize Email notifications dropdown.
          this.setDropdpownOptions(consoleOpt, this.selectedConsoleNotifications);  // Initialize InConsole notifications dropdown.
          this.inConsole.currentValue = inConsole;
          this.inConsole.previousValue = inConsole;

          if(this.email.currentValue) {
            this.instantNotification.currentValue = (weeklyDigest.value || dailyDigest.value) ? false : (instantNotifications ? instantNotifications : true)
          }
        }

      });
  }

  private setDropdpownOptions(obj, arr): void {
    Object.keys(obj).forEach(k => {
      if (k === 'all' && obj['all'] === true) {
        arr.push('allReviews');
      }
      if (k === 'negative' && obj['negative'] === true) {
        arr.push('negativeReviews');
      }
      if (k === 'neutral' && obj['neutral'] === true) {
        arr.push('neutralReviews'); //neutralReviews
      }
      if (k === 'positive' && obj['positive'] === true) {
        arr.push('positiveReviews');
      }
    })
  }

  public handleWeeklyUpdates(event: any): void {
    this.hasUnsavedData = (this.weeklyOpt.currentValue !== this.globalConfig.weeklyDigest.value) ? true : false;
    if(!this.weeklyOpt.currentValue || !this.dailyOpt.currentValue) {
      this.instantNotification.currentValue = true;
    }
  }

  public handleWeekDayUpdates(): void {
    this.hasUnsavedData = (this.weeklyOptDay.currentValue !== this.globalConfig.weeklyDigest.weeklySelectedDay) ? true : false;
  }

  public handleInstantNotifications(event): void {
    this.instantNotification.currentValue = event?.checked;
    this.hasUnsavedData = (this.globalConfig?.instantNotifications !== this.instantNotification.currentValue) ? true : false;
  }

  handleDailyDigestUpdated(event): void {
    this.dailyOpt.currentValue = event?.checked;
    this.hasUnsavedData = (this.globalConfig?.dailyDigest.value !== this.dailyOpt.currentValue) ? true : false;
    if(!this.weeklyOpt.currentValue || !this.dailyOpt.currentValue) {
      this.instantNotification.currentValue = true;
    }
  }

  filterHrs(event) {
    this.filteredHrs = this.hours.slice();

    if (event.query.length > 0) {
      this.filteredHrs = [];
      for (const entry of this.hours) {
        if (entry.includes(event.query)) {
          this.filteredHrs.push(entry);
        }
      }
      this.filteredHrs = this.filteredHrs.slice();
    }
  }

  public setDigestHrs(event, frequency): void {
    this.hasUnsavedData = frequency === 'daily' ?
      ((this.globalConfig?.dailyDigest.dailySelectedTime !== this.dailyOptTime.currentValue) ? true : false) :
      ((this.globalConfig?.weeklyDigest.weeklySelectedTime !== this.weeklyOptTime.currentValue) ? true : false);
  }


  public handleTimeZone(event, frequency: string): void {
    this.hasUnsavedData = frequency === 'daily' ?
      ((this.globalConfig?.dailyDigest.dailySelectedTimezone !== this.dailyOptTimeZone.currentValue) ? true : false) :
      ((this.globalConfig?.weeklyDigest.weeklySelectedTimezone !== this.weeklyOptTimeZone.currentValue) ? true : false);
  }

  public handleEmailNotificationsChange(event): void {
    this.mapSelectedItems(event, this.selectedEmailNotifications);
    if (event.value.includes('positiveReviews') && event.value.includes('neutralReviews') && event.value.includes('negativeReviews')) {
      this.selectedEmailNotifications = ['allReviews', 'positiveReviews', 'neutralReviews', 'negativeReviews'];
    }

    if(event?.itemValue === 'allReviews' && !event?.value.includes('allReviews')){
      this.selectedEmailNotifications = [];
    }

    if(event?.itemValue === 'allReviews' && event?.value.includes('allReviews')){
      this.selectedEmailNotifications = ['allReviews', 'positiveReviews', 'neutralReviews', 'negativeReviews'];
    }
    this.email.currentValue = this.selectedEmailNotifications.length ? true : false;
    this.emailAllReviews.currentValue = this.selectedEmailNotifications.includes('allReviews');
    this.emailPositiveReviews.currentValue = this.selectedEmailNotifications.includes('positiveReviews');
    this.emailNeutralReviews.currentValue = this.selectedEmailNotifications.includes('neutralReviews');
    this.emailNegativeReviews.currentValue = this.selectedEmailNotifications.includes('negativeReviews');

    if(this.selectedEmailNotifications.length) {
      this.instantNotification.currentValue = !this.instantNotification.currentValue ? true : this.instantNotification.currentValue;
    }

    this.hasUnsavedData = (
      (this.globalConfig?.byEmail !== this.email.currentValue) ||
      (this.globalConfig?.emailOpt?.all !== this.emailAllReviews.currentValue) ||
      (this.globalConfig?.emailOpt?.positive !== this.emailPositiveReviews.currentValue) ||
      (this.globalConfig?.emailOpt?.neutral !== this.emailNeutralReviews.currentValue) ||
      (this.globalConfig?.emailOpt?.negative !== this.emailNegativeReviews.currentValue) ||
      (this.globalConfig?.instantNotifications !== this.instantNotification.currentValue)) ? true: false;
  }

  public handleConsoleNotificationsChange(event): void {
    this.mapSelectedItems(event, this.selectedConsoleNotifications)

    if (event.value.includes('positiveReviews') && event.value.includes('neutralReviews') && event.value.includes('negativeReviews')) {
      this.selectedConsoleNotifications = ['allReviews', 'positiveReviews', 'neutralReviews', 'negativeReviews'];
    }

    if(event?.itemValue === 'allReviews' && !event?.value.includes('allReviews')){
      this.selectedConsoleNotifications = [];
    }

    if(event?.itemValue === 'allReviews' && event?.value.includes('allReviews')){
      this.selectedConsoleNotifications = ['allReviews', 'positiveReviews', 'neutralReviews', 'negativeReviews'];
    }

    this.inConsole.currentValue = this.selectedConsoleNotifications.length ? true : false;
    this.inConsoleAllReviews.currentValue = this.selectedConsoleNotifications.includes('allReviews');
    this.inConsolePositiveReviews.currentValue = this.selectedConsoleNotifications.includes('positiveReviews');
    this.inConsoleNeutralReviews.currentValue = this.selectedConsoleNotifications.includes('neutralReviews');
    this.inConsoleNegativeReviews.currentValue = this.selectedConsoleNotifications.includes('negativeReviews');

    this.hasUnsavedData = (
      (this.globalConfig?.inConsole !== this.inConsole.currentValue) ||
      (this.globalConfig?.consoleOpt?.all !== this.inConsoleAllReviews.currentValue) ||
      (this.globalConfig?.consoleOpt?.positive !== this.inConsolePositiveReviews.currentValue) ||
      (this.globalConfig?.consoleOpt?.neutral !== this.inConsoleNeutralReviews.currentValue) ||
      (this.globalConfig?.consoleOpt?.negative !== this.inConsoleNegativeReviews.currentValue)) ? true: false;



  }

  private postNotificationsUpdates(data): void {
    const configuration = data;
    const notification_type: string = 'review';
    let item = {}

    if (this.id) {
      item = { configuration, notification_type, _id: this.id }
    } else {
      item = {
        configuration,
        notification_type
      }
    }

    this.socketService.sendRequest('save-notification-configuration', {
      item
    }).then(res => {
      if (res['status'] === 'ok') {
        this.notifyService.success('Changes saved successfuly', 3000);
      }
    },
      (error) => {
        this.notifyService.error('Error occured while saving changes', 3000);
      });

  }

  private mapSelectedItems(event: any, array: string[]): void {
    if (
      (event?.itemValue === 'positiveReviews' && event?.value?.length && !event?.value.includes('positiveReviews')) ||
      (event?.itemValue === 'neutralReviews' && event?.value?.length && !event?.value.includes('neutralReviews')) ||
      (event?.itemValue === 'negativeReviews' && event?.value?.length && !event?.value.includes('negativeReviews'))
    ) {
      const index = array.indexOf('allReviews');
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
  }

  save(): void {
    this.hasUnsavedData = false;
    const console =  JSON.stringify({
      byEmail: this.selectedEmailNotifications.length ? true : false,
      emailOpt: {
        positive: this.selectedEmailNotifications.includes('positiveReviews'),
        neutral: this.selectedEmailNotifications.includes('neutralReviews'),
        negative: this.selectedEmailNotifications.includes('negativeReviews'),
        all: this.selectedEmailNotifications.includes('allReviews'),
      },
      weeklyDigest: {
        value: this.weeklyOpt.currentValue,
        weeklySelectedDay: this.weeklyOptDay.currentValue,
        weeklySelectedTime: this.weeklyOptTime.currentValue,
        weeklySelectedTimezone: this.weeklyOptTimeZone.currentValue,
      },
      dailyDigest: {
        value: this.dailyOpt.currentValue,
        dailySelectedTime: this.dailyOptTime.currentValue,
        dailySelectedTimezone: this.dailyOptTimeZone.currentValue,
      },
      instantNotifications: this.instantNotification.currentValue,
      inConsole: this.selectedConsoleNotifications.length ? true : false,
      consoleOpt: {
        positive: this.selectedConsoleNotifications.includes('positiveReviews'),
        neutral:  this.selectedConsoleNotifications.includes('neutralReviews'),
        negative: this.selectedConsoleNotifications.includes('negativeReviews'),
        all: this.selectedConsoleNotifications.includes('allReviews'),
      }
    });

    this.postNotificationsUpdates(console);
  }

  public openDialog(): void {
    this.dialogRef = this.dialogService.open(NotificationPopupComponent, {
      width: '800px',
      height: '300px',
    });

    this.dialogRef.onClose.subscribe(res => {
      if (res === undefined || !res) {
        this.hasUnsavedData = true;
      } else {
        this.save();
      }
    })
  }
}
