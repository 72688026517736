<div class="header-parent-title">
  <div class="header-child-title">Delete Review</div>
  <div class="header-child-title">.</div>
</div>
<div class="delete-popup-wrapper">
  <p>Are you sure you want to delete this review? This action cannot be undone.</p>
</div>
<div class="bottom-buttons">
  <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
  <button pButton label="DELETE" class="p-button-primary" (click)="deleteItem()"></button>
</div>
