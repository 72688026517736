import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Accordion } from 'primeng/accordion';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { SocketService } from '../../core/backend-adapter/socket.service';
import { BroadcastService } from '../../core/backend-adapter/broadcast.service';
import { SessionService } from '../../core/backend-adapter/session.service';
import { DBService } from '../../core/backend-adapter/db.service';
import { TWO_YELLOW_CHART_PALETTE } from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import { MapsInsightsConfigService } from '../shared/services/apple-maps-graph-config.service';
import { GraphConfigService } from '../shared/services/graph-config.service';

@Component({
  selector: 'app-yelp-dashboard-tab',
  templateUrl: './yelp-dashboard-tab.component.html',
  styleUrls: ['./yelp-dashboard-tab.component.scss'],
})
export class YelpDashboardTabComponent implements OnInit {
  //// dashboard view
  showReviews = true;
  showReviewsOnly = false;
  insightsErr = false;
  reviewsErr = false;
  public stackOptions;
  viewsDiff = '';
  searchesDiff = '';
  clicksDiff = '';
  photosDiff = '';
  yearlyViewsDiff = '';
  yearlySearchesDiff = '';
  yearlyClicksDiff = '';
  yearlyPhotosDiff = '';
  stretchGraphBckgd = false;
  lastPrefElem: string;
  ////

  //// reviews
  reviewResponses = {
    labels: ['unresponded', 'responded'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: TWO_YELLOW_CHART_PALETTE,
        borderColor: TWO_YELLOW_CHART_PALETTE,
      },
    ],
  };

  doughnutOptions = {
    layout: {
      padding: 10, // overall padding
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    // disable hover, not needed
    events: [],
  };

  averageRating: number;
  roundedRating: number;
  ratingDiff = '';
  yearlyRatingDiff = '15% decrease from same month of last year';
  unrespondedPct: number;
  respondedPct: number;
  faCircle = faCircle;
  ////

  //// date range dropdown
  dateRanges = [
    { name: 'Last 3 months', code: 3 },
    { name: 'Last 6 months', code: 6 },
    { name: 'Quarterly comparison', code: 4 },
    { name: 'Last 12 months', code: 12 },
    { name: 'Last 24 months', code: 24 },
  ];

  selectedRange = 3;
  ////

  //// Customize Insights Selection popup
  showEditPopup = false;
  selectedInsightPrefs: string[] = [
    'totalPageViews',
    'directionsAndMapViews',
    'urlClicks',
    'desktopSearchAppearances',
    'mobileSearchAppearances',
    'desktopPageViews',
    'mobilePageViews',];
  disabledField: string;
  prevInsightPrefs: string[]; // used to easily restore previous state when canceling popup
  ///

  //// Download selection popup
  showDwnldPopup = false;
  reportType: string;
  ////

  //// generated report view
  reportView = false;
  ////

  showOptPopup = false;
  noAcct: boolean;
  ////

  //// comparison report pop-up
  reportName: string;
  // reportName: string = 'Rep1';
  aggregateBy: string;
  compareBy: string;
  missingOptions = true;
  missingReportName = false;
  ////

  //// Generate Report pop-up
  showGenRepDialog = false;
  locsToCompare: number[] = [1, 2];
  startDate: Date;
  endDate: Date;
  dateViews: any[] = [
    { name: 'Weekly View (only for date ranges under 1 month)', code: 'weekly' },
    { name: 'Monthly View', code: 'monthly' },
    { name: 'Quarterly View', code: 'quarterly' },
    { name: 'Aggregate View', code: 'aggregate' },
  ];
  selectedView: any;
  selInsightsCrit: any[] = [];
  selReviewCrit: any[] = [];
  missingFields = true;
  locSearchText: string;
  locSearchResults: any[];

  /// Report View
  locationsList: any = {};
  generatedTimestamp: any;
  dateRange: string;
  locListLen: number;
  reviewsTableData: any[] = [];
  insightsTableData: any[] = [];
  reviewsHeaders: string[] = [];
  insightsHeaders: string[] = [];
  graphColors: string[] = ['#FFAB0C', '#007EFF', '#8a3ffc', '#791e6c', '#FC3B00'];
  googleInsights: any = {};
  reviewInsights: any = {};

  @ViewChild('insightsAccordion') insightsAccordion: Accordion;
  @ViewChild('reviewsAccordion') reviewsAccordion: Accordion;
  ////

  //// Working on it popup
  showProgressDialog = false;
  ////

  public popupData = [];
  public demoData = [];
  public fullWidth: boolean = false;

  constructor(
    public socketService: SocketService,
    public sessionService: SessionService,
    private broadcastService: BroadcastService,
    private dbService: DBService,
    private mapsInsightsConfigService: MapsInsightsConfigService,
    private graphConfigService: GraphConfigService
  ) {}

  ngOnInit(): void {
    this.popupData = this.graphConfigService.yelpPopupConfig;
    this.stackOptions = this.mapsInsightsConfigService.stackOptions;
    Chart.register(ChartDataLabels); // needed for charts to build correctly

    this.broadcastService.subscribe('accountChanged', (item) => {
      this.insightsErr = false;
      this.reviewsErr = false;
      // this.loadInsightsData(this.selectedRange);
    });

    this.loadInsightsData(this.selectedRange);
    if (this.showReviews) {
      if (!this.showReviewsOnly) {
        // in split screen only three insights graphs are allowed
        this.selectedInsightPrefs = [
          'totalPageViews',
          'directionsAndMapViews',
          'urlClicks',
          'desktopSearchAppearances',
          'mobileSearchAppearances',
          'desktopPageViews',
          'mobilePageViews'];
      }
    }
    // else {
    //   this.selectedInsightPrefs = ['totalPageViews', 'directionsAndMapViews', 'urlClicks', 'totalPhotoViews'];
    // }

    this.noAcct = !this.sessionService.currAccount;
  }

  trackIndex(index, item) {
    return index;
  }

  checkForMissingFields() {
    if (
      this.startDate &&
      this.endDate &&
      this.selectedView &&
      (this.selInsightsCrit.length > 0 || this.selReviewCrit.length > 0)
    ) {
      this.missingFields = false;
    }
  }

  triggerTimer() {
    setTimeout(() => {
      this.showProgressDialog = false;
      this.reportView = true;
    }, 3000);
  }

  searchLocation(event) {
    if (event.query && event.query.length > 2) {
      console.log('query:', event.query);

      const whereClause = ['-or'];

      whereClause.push(`storeCode::text ILIKE '${event.query}'`);
      whereClause.push(`addressLines[0]::text ILIKE '${event.query}'`);
      whereClause.push(`city::text ILIKE '${event.query}'`);

      console.log('whereClause :', whereClause);

      this.dbService.Location.loadObjects({ where: whereClause }).then(
        (repl) => {
          this.locSearchResults = repl.collection;
        },
        (err) => {
          console.warn('Error loading locations:', err);
        }
      );
    }
  }

  // location search on generate report popup
  resultSelected(event, locNum) {
    event.color = this.graphColors[locNum - 1];
    this.locationsList[`loc${locNum}`] = event;
  }
  ////

  //// Report view
  backToDashboard() {
    this.reportView = false;
  }

  loadInsightsData(range) {
    this.viewsDiff = '';
    this.searchesDiff = '';
    this.clicksDiff = '';
    this.photosDiff = '';

    let requestName: string;
    let excludeParam: boolean;

    if (range === 4) {
      requestName = 'get-quarterly-metrics';
      excludeParam = false;
    } else {
      requestName = 'get-monthly-metrics';
      excludeParam = true;
    }

    this.socketService.sendRequest(requestName, { numPeriods: range, excludePast30Days: true, publisher: "yelp", where
    :
    ['-and']  }).then(
      (repl: any) => {
        const currData = repl.collection;
        const prevData = repl.collection_past30;

        if (currData) {
          const {
            labels,
            totalPageViews,
            calls,
            directionsAndMapViews,
            urlClicks,
            checkIns,
            userPhotos,
            bookmarks,
            desktopCTAClicks,
            mobileCTAClicks,
            messagesToBusiness,
            desktopSearchAppearances,
            mobileSearchAppearances,
            desktopPageViews,
            mobilePageViews,
            trackingCalls,
            dealsSold,
            onlineOrders,
            onlineBookings,
            checkInOfferRedemptions,
            collectionItemAdded,
            rapcInitiated,
            waitlistWisitCreated,
            medianResponseTimeInSec,
            replyRate,
            organicBizPageViews,
            organicBizPageViewsPercentage,
            totalLeads,
            billedImpressions,
            billedClicks,
            adCost,
            adDrivenBookmarks,
            adDrivenCalls,
            adDrivenCTAClicks,
            adDrivenCheckIns,
            adDrivenDealsSold,
            adDrivenDirectionsAndMapViews,
            adDrivenMessagesToBusiness,
            adDrivenUserPhotos,
            adDrivenOnlineReservations,
            adDrivenUrlClicks,
            adClickThroughRate,
            averageCostPerClick,
            billableAdClicks,
            billableAdImpressions,
            adDrivenBizPageViews,
            adDrivenCallsTracked,
            adDrivenRapcInitiated,
            adDrivenWaitlistVisitCreated,
            adDrivenTotalLeads,
            adDrivenPlatformPurchaseMade,
            adDrivenBizPageViewsPercentage,
          } = this.getGraphVals(currData, prevData);

          this.demoData = [
            {
              id: 'totalPageViews',
              title: 'Total User Views',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Map',
                    backgroundColor: '#003a6d',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: totalPageViews,
                  },
                ],
              },
            },
            {
              id: 'calls',
              title: 'Mobile Calls',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Direct',
                    backgroundColor: '#491d8b',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: calls,
                  },
                ],
              },
            },
            {
              id: 'directionsAndMapViews',
              title: 'Directions & Map Views',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Website',
                    backgroundColor: '#791e6c',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: directionsAndMapViews,
                  },
                ],
              },
            },
            {
              id: 'urlClicks',
              title: 'Clicks to Website',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#206A02',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: urlClicks,
                  },
                ],
              },
            },
            {
              id: 'checkIns',
              title: 'Mobile Check-ins',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#791e6c',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: checkIns,
                  },
                ],
              },
            },
            {
              id: 'userPhotos',
              title: 'User Uploaded Photos',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#d51ba0',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: userPhotos,
                  },
                ],
              },
            },
            {
              id: 'bookmarks',
              title: 'Yelp Bookmarks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#fa6bc1',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: bookmarks,
                  },
                ],
              },
            },
            {
              id: 'desktopCTAClicks',
              title: 'Desktop Call to Action Clicks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#609c51',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: desktopCTAClicks,
                  },
                ],
              },
            },
            {
              id: 'mobileCTAClicks',
              title: 'Mobile Call to Action Clicks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#266317',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: mobileCTAClicks,
                  },
                ],
              },
            },
            {
              id: 'messagesToBusiness',
              title: 'Request a Quote - messages',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#64f241',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: messagesToBusiness,
                  },
                ],
              },
            },
            {
              id: 'desktopSearchAppearances',
              title: 'Desktop Appearances in Search',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#F68A8A',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: desktopSearchAppearances,
                  },
                ],
              },
            },
            {
              id: 'mobileSearchAppearances',
              title: 'Mobile Appearances in Search',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#491d8b',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: mobileSearchAppearances,
                  },
                ],
              },
            },
            {
              id: 'desktopPageViews',
              title: 'Desktop User Views',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#e8e52e',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: desktopPageViews,
                  },
                ],
              },
            },
            {
              id: 'mobilePageViews',
              title: 'Mobile User Views',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#e85325',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: mobilePageViews,
                  },
                ],
              },
            },
            {
              id: 'trackingCalls',
              title: 'Calls Tracked',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#003a6d',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: trackingCalls,
                  },
                ],
              },
            },
            {
              id: 'dealsSold',
              title: 'Deals Sold',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#007eff',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: dealsSold,
                  },
                ],
              },
            },
            {
              id: 'onlineOrders',
              title: 'Online Orders',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#791e6c',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: onlineOrders,
                  },
                ],
              },
            },
            {
              id: 'onlineBookings',
              title: 'Online Bookings',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#d51ba0',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: onlineBookings,
                  },
                ],
              },
            },
            {
              id: 'checkInOfferRedemptions',
              title: 'Check In Offer Redemptions',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#fa6bc1',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: checkInOfferRedemptions,
                  },
                ],
              },
            },
            {
              id: 'collectionItemAdded',
              title: 'Collections',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#266317',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: collectionItemAdded,
                  },
                ],
              },
            },
            {
              id: 'rapcInitiated',
              title: 'RaPC Initiated',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#609c51',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: rapcInitiated,
                  },
                ],
              },
            },
            {
              id: 'waitlistWisitCreated',
              title: 'Waitlist Visit Created',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#64f241',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: waitlistWisitCreated,
                  },
                ],
              },
            },
            {
              id: 'medianResponseTimeInSec',
              title: 'Median response time (secs)',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#491d8b',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: medianResponseTimeInSec,
                  },
                ],
              },
            },
            {
              id: 'replyRate',
              title: 'Reply rate',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#F68A8A',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: replyRate,
                  },
                ],
              },
            },
            {
              id: 'organicBizPageViews',
              title: 'Organic Page Visits',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#6e44ad',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: organicBizPageViews,
                  },
                ],
              },
            },
            {
              id: 'organicBizPageViewsPercentage',
              title: '% Biz Page Views Organic',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#e8e52e',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: organicBizPageViewsPercentage,
                  },
                ],
              },
            },
            {
              id: 'totalLeads',
              title: 'Total # of leads',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#fgh409',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: totalLeads,
                  },
                ],
              },
            },
            // //////---------------------------------------
            {
              id: 'billedImpressions',
              title: 'Billed Ad Impressions',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#frd009',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: billedImpressions,
                  },
                ],
              },
            },
            {
              id: 'billedClicks',
              title: 'Billed Ad Clicks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#aaf765',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: billedClicks,
                  },
                ],
              },
            },
            {
              id: 'adCost',
              title: 'Ad Cost',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#bbe454',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adCost,
                  },
                ],
              },
            },
            {
              id: 'adDrivenBookmarks',
              title: 'Ad Driven Yelp Bookmarks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#bhb100',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenBookmarks,
                  },
                ],
              },
            },
            {
              id: 'adDrivenCalls',
              title: 'Ad Driven Mobile Calls',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#jkl121',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenCalls,
                  },
                ],
              },
            },
            {
              id: 'adDrivenCTAClicks',
              title: 'Ad Driven Total Call to Action Clicks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#db675e',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenCTAClicks,
                  },
                ],
              },
            },
            {
              id: 'adDrivenCheckIns',
              title: 'Ad Driven Mobile Check-ins',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#2b9971',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenCheckIns,
                  },
                ],
              },
            },
            {
              id: 'adDrivenDealsSold',
              title: 'Ad Driven Deals Sold',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#2b3d99',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenDealsSold,
                  },
                ],
              },
            },
            {
              id: 'adDrivenDirectionsAndMapViews',
              title: 'Ad Driven Directions & Map Views',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#cc2f3f',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenDirectionsAndMapViews,
                  },
                ],
              },
            },
            {
              id: 'adDrivenMessagesToBusiness',
              title: 'Ad Driven Request a Quote - messages',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#de8518',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenMessagesToBusiness,
                  },
                ],
              },
            },
            {
              id: 'adDrivenUserPhotos',
              title: 'Ad Driven User Uploaded Photos',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#62bf54',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenUserPhotos,
                  },
                ],
              },
            },
            {
              id: 'adDrivenOnlineReservations',
              title: 'Ad Driven Online Reservations',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#4e91c2',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenOnlineReservations,
                  },
                ],
              },
            },
            {
              id: 'adDrivenUrlClicks',
              title: 'Ad Driven Clicks to Website',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#685bde',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenUrlClicks,
                  },
                ],
              },
            },
            {
              id: 'adClickThroughRate',
              title: 'Ad Click Through Rate',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#c2b24e',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adClickThroughRate,
                  },
                ],
              },
            },
            {
              id: 'averageCostPerClick',
              title: 'Average Cost Per Click',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#1b7a67',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: averageCostPerClick,
                  },
                ],
              },
            },
            {
              id: 'billableAdClicks',
              title: 'Billable Ad Clicks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#ad3d9e',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: billableAdClicks,
                  },
                ],
              },
            },
            {
              id: 'billableAdImpressions',
              title: 'Billable Ad Impressions',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#b56438',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: billableAdImpressions,
                  },
                ],
              },
            },
            {
              id: 'adDrivenBizPageViews',
              title: 'Ad Driven Page Visits',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#51b538',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenBizPageViews,
                  },
                ],
              },
            },
            {
              id: 'adDrivenCallsTracked',
              title: 'Ad Driven Calls Tracked',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#522209',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenCallsTracked,
                  },
                ],
              },
            },
            {
              id: 'adDrivenRapcInitiated',
              title: 'Ad Driven RaPC Initiated',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#961b4e',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenRapcInitiated,
                  },
                ],
              },
            },
            {
              id: 'adDrivenWaitlistVisitCreated',
              title: 'Ad Driven Waitlist Visit Created',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#305d91',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenWaitlistVisitCreated,
                  },
                ],
              },
            },
            {
              id: 'adDrivenTotalLeads',
              title: 'Total # of ad driven leads',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#1b12c7',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenTotalLeads,
                  },
                ],
              },
            },
            {
              id: 'adDrivenPlatformPurchaseMade',
              title: 'Ad Driven Platform Purchases',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#206A02',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenPlatformPurchaseMade,
                  },
                ],
              },
            },
            {
              id: 'adDrivenBizPageViewsPercentage',
              title: '% Biz Page Views Ad Driven',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Customer',
                    backgroundColor: '#5f1f87',
                    barThickness: this.mapsInsightsConfigService.setBarThickness(range, this.showReviews),
                    maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(range, this.showReviews),
                    data: adDrivenBizPageViewsPercentage,
                  },
                ],
              },
            },
          ];
        }
      },
      (err) => {
        console.log('Monthly metrics error:', err);
        if (err.category == 'DB') {
          this.insightsErr = true;
        }
      }
    );
  }

  getGraphVals(currData, prevData?) {
    const labels = [];
    const totalPageViews = [];
    const calls = [];
    const directionsAndMapViews = [];
    const urlClicks = [];
    const checkIns = [];
    const userPhotos = [];
    const bookmarks = [];
    const desktopCTAClicks = [];
    const mobileCTAClicks = [];
    const messagesToBusiness = [];
    const desktopSearchAppearances = [];
    const mobileSearchAppearances = [];
    const desktopPageViews = [];
    const mobilePageViews = [];
    const trackingCalls = [];
    const dealsSold = [];
    const onlineOrders = [];
    const onlineBookings = [];
    const checkInOfferRedemptions = [];
    const collectionItemAdded = [];
    const rapcInitiated = [];
    const waitlistWisitCreated = [];
    const medianResponseTimeInSec = [];
    const replyRate = [];
    const organicBizPageViews = [];
    const organicBizPageViewsPercentage = [];
    const totalLeads = [];
    const billedImpressions = [];
    const billedClicks = [];
    const adCost = [];
    const adDrivenBookmarks = [];
    const adDrivenCalls = [];
    const adDrivenCTAClicks = [];
    const adDrivenCheckIns = [];
    const adDrivenDealsSold = [];
    const adDrivenDirectionsAndMapViews = [];
    const adDrivenMessagesToBusiness = [];
    const adDrivenUserPhotos = [];
    const adDrivenOnlineReservations = [];
    const adDrivenUrlClicks = [];
    const adClickThroughRate = [];
    const averageCostPerClick = [];
    const billableAdClicks = [];
    const billableAdImpressions = [];
    const adDrivenBizPageViews = [];
    const adDrivenCallsTracked = [];
    const adDrivenRapcInitiated = [];
    const adDrivenWaitlistVisitCreated = [];
    const adDrivenTotalLeads = [];
    const adDrivenPlatformPurchaseMade = [];
    const adDrivenBizPageViewsPercentage = [];


    for (const item of currData) {
      if (prevData) {
        // monthly calls
        const label = this.getMonthName(item.month) + ' ' + item.year.toString();
        labels.push(label);
      } else {
        // quarterly calls
        const label = 'Q' + item.qtr + ' ' + item.year.toString();
        labels.push(label);
      }

      totalPageViews.push(item.num_total_page_views);
      calls.push(item.num_calls);
      directionsAndMapViews.push(item.num_directions_and_map_views);
      urlClicks.push(item.url_clicks);
      checkIns.push(item.num_check_ins);
      userPhotos.push(item.num_user_photos);
      bookmarks.push(item.num_bookmarks);
      desktopCTAClicks.push(item.num_desktop_cta_clicks);
      mobileCTAClicks.push(item.num_mobile_cta_clicks);
      messagesToBusiness.push(item.num_messages_to_business);
      desktopSearchAppearances.push(item.num_desktop_search_appearances);
      mobileSearchAppearances.push(item.num_mobile_search_appearances);
      desktopPageViews.push(item.num_desktop_page_views);
      mobilePageViews.push(item.num_mobile_page_views);
      trackingCalls.push(item.tracking_calls);
      dealsSold.push(item.deals_sold);
      onlineOrders.push(item.online_orders);
      onlineBookings.push(item.online_bookings);
      checkInOfferRedemptions.push(item.check_in_offer_redemptions);
      collectionItemAdded.push(item.collection_item_added);
      rapcInitiated.push(item.rapc_initiated);
      waitlistWisitCreated.push(item.waitlist_visit_created);
      medianResponseTimeInSec.push(item.median_response_time_in_sec);
      replyRate.push(item.reply_rate);
      organicBizPageViews.push(item.organic_biz_page_views);
      organicBizPageViewsPercentage.push(item.organic_biz_page_views_percentage);
      totalLeads.push(item.total_leads);
      billedImpressions.push(item.billed_impressions);
      billedClicks.push(item.billed_clicks);
      adCost.push(item.ad_cost);
      adDrivenBookmarks.push(item.ad_driven_bookmarks);
      adDrivenCalls.push(item.ad_driven_calls);
      adDrivenCTAClicks.push(item.ad_driven_cta_clicks);
      adDrivenCheckIns.push(item.ad_driven_check_ins);
      adDrivenDealsSold.push(item.ad_driven_deals_sold);
      adDrivenDirectionsAndMapViews.push(item.ad_driven_directions_and_map_views);
      adDrivenMessagesToBusiness.push(item.ad_driven_messages_to_business);
      adDrivenUserPhotos.push(item.ad_driven_user_photos);
      adDrivenOnlineReservations.push(item.ad_driven_online_reservations);
      adDrivenUrlClicks.push(item.ad_driven_url_clicks);
      adClickThroughRate.push(item.ad_click_through_rate);
      averageCostPerClick.push(item.average_cost_per_click);
      billableAdClicks.push(item.billable_ad_clicks);
      billableAdImpressions.push(item.billable_ad_impressions);
      adDrivenBizPageViews.push(item.ad_driven_biz_page_views);
      adDrivenCallsTracked.push(item.ad_driven_calls_tracked);
      adDrivenRapcInitiated.push(item.ad_driven_rapc_initiated);
      adDrivenWaitlistVisitCreated.push(item.ad_driven_waitlist_visit_created);
      adDrivenTotalLeads.push(item.ad_driven_total_leads);
      adDrivenPlatformPurchaseMade.push(item.ad_driven_platform_purchase_made);
      adDrivenBizPageViewsPercentage.push(item.ad_driven_biz_page_views_percentage);
    }

    return {
      labels,
      totalPageViews,
      calls,
      directionsAndMapViews,
      urlClicks,
      checkIns,
      userPhotos,
      bookmarks,
      desktopCTAClicks,
      mobileCTAClicks,
      messagesToBusiness,
      desktopSearchAppearances,
      mobileSearchAppearances,
      desktopPageViews,
      mobilePageViews,
      trackingCalls,
      dealsSold,
      onlineOrders,
      onlineBookings,
      checkInOfferRedemptions,
      collectionItemAdded,
      rapcInitiated,
      waitlistWisitCreated,
      medianResponseTimeInSec,
      replyRate,
      organicBizPageViews,
      organicBizPageViewsPercentage,
      totalLeads,
      billedImpressions,
      billedClicks,
      adCost,
      adDrivenBookmarks,
      adDrivenCalls,
      adDrivenCTAClicks,
      adDrivenCheckIns,
      adDrivenDealsSold,
      adDrivenDirectionsAndMapViews,
      adDrivenMessagesToBusiness,
      adDrivenUserPhotos,
      adDrivenOnlineReservations,
      adDrivenUrlClicks,
      adClickThroughRate,
      averageCostPerClick,
      billableAdClicks,
      billableAdImpressions,
      adDrivenBizPageViews,
      adDrivenCallsTracked,
      adDrivenRapcInitiated,
      adDrivenWaitlistVisitCreated,
      adDrivenTotalLeads,
      adDrivenPlatformPurchaseMade,
      adDrivenBizPageViewsPercentage,
    };
  }

  getPercentDiff(current, previous, type) {
    const diff = Math.abs(previous - current);
    const pctDiff = ((diff * 100) / current).toFixed(1);
    if (current > previous) {
      return pctDiff + '% increase from last ' + (type == 'month' ? '30 days' : 'quarter');
    } else if (previous > current) {
      return pctDiff + '% decrease from last ' + (type == 'month' ? '30 days' : 'quarter');
    } else {
      return 'No change from last ' + (type == 'month' ? '30 days' : 'quarter');
    }
  }

  getYearlyPercentDiff(current, previous, type) {
    const diff = Math.abs(previous - current);
    const pctDiff = ((diff * 100) / current).toFixed(1);
    if (current > previous) {
      return pctDiff + '% increase from same ' + type + ' of last year';
    } else if (previous > current) {
      return pctDiff + '% decrease from same ' + type + ' of last year';
    } else {
      return 'No change from same ' + type + ' of last year';
    }
  }

  getRatingDiff(current, previous) {
    const diff = Math.abs(Number(current) - Number(previous)).toFixed(1);
    if (current > previous) {
      return diff + ' increase in last 30 days';
    } else if (previous > current) {
      return diff + ' decrease in last 30 days';
    } else {
      return 'No change in last 30 days';
    }
  }

  dateRangeChanged(event) {
    this.fullWidth = (this.selectedRange === 12 || this.selectedRange === 24) ? true : false;
    this.loadInsightsData(this.selectedRange);
    // this.loadTrends();
  }

  getMonthName(month: number) {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'][month - 1];
  }

  hasIncreased(diff) {
    if (diff.includes('increase')) {
      return true;
    } else if (diff.includes('decrease')) {
      return false;
    } else {
      return null;
    }
  }
  ////

  //// insights popup
  editInsights() {
    this.prevInsightPrefs = [...this.selectedInsightPrefs];
    this.disabledField = this.getDisabledField();
    this.showEditPopup = true;
  }

  cancelEditPopup() {
    this.setInsightsCardVisibility();
    this.showEditPopup = false;
  }

  savePrefs() {
    this.showEditPopup = false;
  }

  getDisabledField() {
    if (this.showReviews) {
      if (this.selectedInsightPrefs.length === 7 ) {
        for (const val of ['totalPageViews',
        'directionsAndMapViews',
        'urlClicks',
        'desktopSearchAppearances',
        'mobileSearchAppearances',
        'desktopPageViews',
        'mobilePageViews',]) {
          if (!this.selectedInsightPrefs.includes(val)) {
            return val;
          }
        }
      }
    }
  }

  prefsChanged() {
    this.setInsightsCardVisibility();
  }

  private setInsightsCardVisibility(): void {
    this.demoData.forEach((card) => {
      card['visibility'] = this.selectedInsightPrefs.includes(card.id) ? true : false;
    });
  };


    // generateReport() {
  //   // console.log('locationsList:', this.locationsList, 'startDate:', this.startDate, 'endDate:', this.endDate, 'selectedView:', this.selectedView, 'reviews criteria:', this.selReviewCrit, 'insights criteria:', this.selInsightsCrit);

  //   this.generatedTimestamp = new Date().toLocaleString('en-US');
  //   this.dateRange = this.startDate.toLocaleDateString('en-US') + ' - ' + this.endDate.toLocaleDateString('en-US');
  //   this.locListLen = Object.keys(this.locationsList).length;

  //   // populate tables
  //   for (let i = 0; i < this.locListLen; i++) {
  //     const rowData = {};
  //     let insightsRow = {};
  //     let reviewsRow = {};
  //     const currentLoc = this.locationsList['loc' + (i + 1)];

  //     rowData['LOCATION NAME'] = currentLoc.businessName;
  //     rowData['STORE CODE'] = currentLoc.storeCode;
  //     // tslint:disable-next-line: no-string-literal
  //     rowData['ADDRESS'] =
  //       currentLoc.addressLines[0] +
  //       ', ' +
  //       currentLoc.addressLines[1] +
  //       ', ' +
  //       currentLoc.city +
  //       ', ' +
  //       currentLoc.state;
  //     insightsRow = { ...rowData };
  //     reviewsRow = { ...rowData };

  //     if (this.selInsightsCrit.length > 0) {
  //       for (const insCrit of this.selInsightsCrit) {
  //         // hardcoded data that needs to be connected to live call
  //         insightsRow[insCrit.toUpperCase()] = 200;
  //       }
  //       this.insightsTableData.push(insightsRow);
  //     }

  //     if (this.selReviewCrit.length > 0) {
  //       for (const revCrit of this.selReviewCrit) {
  //         // hardcoded data that needs to be connected to live call
  //         reviewsRow[revCrit.toUpperCase()] = 45;
  //       }
  //       this.reviewsTableData.push(reviewsRow);
  //     }
  //   }

  //   this.generateGraphs();

  //   this.reviewsHeaders = Object.keys(this.reviewsTableData[0]);
  //   this.insightsHeaders = Object.keys(this.insightsTableData[0]);

  //   this.showGenRepDialog = false;
  //   this.showProgressDialog = true;
  //   this.triggerTimer();
  // }

  // generateGraphs() {
  //   console.log('this.locationsList :', this.locationsList);
  //   console.log('this.selInsightsCrit :', this.selInsightsCrit);
  //   console.log('this.insightsTableData :', this.insightsTableData);
  //   console.log('this.selReviewCrit :', this.selReviewCrit);
  //   console.log('this.reviewsTableData :', this.reviewsTableData);

  //   const labels = [];
  //   const insightsData = [];
  //   const reviewsData = [];

  //   for (let i = 0; i < this.locationsList.length; i++) {
  //     const entry = this.insightsTableData[i];
  //     labels.push(
  //       `${entry['LOCATION NAME'].toUpperCase()} ${entry['STORE CODE'].toUpperCase()} ${entry.ADDRESS.toUpperCase()}`
  //     );

  //     const insightKeys = Object.keys(this.insightsTableData);
  //     for (const key of insightKeys) {
  //       if (key !== 'LOCATION NAME' && key !== 'STORE CODE' && key !== 'ADDRESS') {
  //         insightsData.push({
  //           label: key,
  //           background: this.graphColors,
  //         });
  //       }
  //     }
  //   }

  //   this.googleInsights = {
  //     labels,
  //     datasets: insightsData,
  //   };
  //   console.log('this.googleInsights :', this.googleInsights);

  //   this.reviewInsights = {
  //     labels,
  //     datasets: reviewsData,
  //   };
  //   console.log('this.reviewInsights :', this.reviewInsights);
  // }


    ////

  //// load graphs
  // loadTrends() {
  //   this.socketService.sendRequest('get-trend-metrics', {}).then(
  //     (repl: any) => {
  //       console.log(repl);
  //       const prevYear = repl.collection[0];
  //       const prevMonth = repl.collection[1];
  //       const currMonth = repl.collection[2];
  //       let callType = '';

  //       if (this.selectedRange == 3 || this.selectedRange == 6) {
  //         callType = 'month';
  //       } else if (this.selectedRange == 4) {
  //         callType = 'quarter';
  //       }

  //       if (currMonth) {
  //         const currViews = currMonth.viewsMaps + currMonth.viewsSearch;
  //         const prevViews = prevMonth.viewsMaps + prevMonth.viewsSearch;
  //         const prevYearViews = prevYear.viewsMaps + prevYear.viewsSearch;
  //         this.viewsDiff = this.getPercentDiff(currViews, prevViews, callType);
  //         this.yearlyViewsDiff = this.getYearlyPercentDiff(currViews, prevYearViews, callType);

  //         const currSrch = currMonth.queriesDirect + currMonth.queriesIndirect + currMonth.queriesChain;
  //         const prevSrch = prevMonth.queriesDirect + prevMonth.queriesIndirect + prevMonth.queriesChain;
  //         const prevYearSrch = prevYear.queriesDirect + prevYear.queriesIndirect + prevYear.queriesChain;
  //         this.searchesDiff = this.getPercentDiff(currSrch, prevSrch, callType);
  //         this.yearlySearchesDiff = this.getYearlyPercentDiff(currSrch, prevYearSrch, callType);

  //         const currClicks = currMonth.actionsWebsite + currMonth.actionsPhone + currMonth.actionsDrivingDirections;
  //         const prevClicks = prevMonth.actionsWebsite + prevMonth.actionsPhone + prevMonth.actionsDrivingDirections;
  //         const prevYearClicks = prevYear.actionsWebsite + prevYear.actionsPhone + prevYear.actionsDrivingDirections;
  //         this.clicksDiff = this.getPercentDiff(currClicks, prevClicks, callType);
  //         this.yearlyClicksDiff = this.getYearlyPercentDiff(currClicks, prevYearClicks, callType);

  //         const currPhotoViews = currMonth.photosViewsCustomer + currMonth.photosViewsMerchant;
  //         const prevPhotoViews = prevMonth.photosViewsCustomer + prevMonth.photosViewsMerchant;
  //         const prevYearPhotoViews = prevYear.photosViewsCustomer + prevYear.photosViewsMerchant;
  //         this.photosDiff = this.getPercentDiff(currPhotoViews, prevPhotoViews, callType);
  //         this.yearlyPhotosDiff = this.getYearlyPercentDiff(currPhotoViews, prevYearPhotoViews, callType);
  //       }
  //     },
  //     (err) => {
  //       console.log('Trend metrics error:', err);
  //     }
  //   );
  // }

  // loadReviewsData() {
  //   this.socketService.sendRequest('get-review-metrics', { excludePast30Days: true }).then(
  //     (repl: any) => {
  //       const currData = repl.collection[0];
  //       const prevData = repl.collection_past30[0];
  //       if (currData) {
  //         this.averageRating = currData.avgStarRating;
  //         this.roundedRating = Math.round(this.averageRating);
  //         this.ratingDiff = this.getRatingDiff(currData.avgStarRating, prevData.avgStarRating);

  //         this.respondedPct = parseFloat(((currData.totalRepliedTo / currData.totalReviews) * 100).toFixed(2));
  //         this.unrespondedPct = 100 - this.respondedPct;
  //         this.reviewResponses = {
  //           labels: ['unresponded', 'responded'],
  //           datasets: [
  //             {
  //               data: [this.unrespondedPct, this.respondedPct],
  //               backgroundColor: TWO_YELLOW_CHART_PALETTE,
  //               borderColor: TWO_YELLOW_CHART_PALETTE,
  //             },
  //           ],
  //         };
  //       }
  //     },
  //     (err) => {
  //       console.log('Review metrics error:', err);
  //       if (err.category == 'DB') {
  //         this.reviewsErr = true;
  //       }
  //     }
  //   );
  // }


};
