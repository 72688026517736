import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import { GBPService } from '../../gbp.service';

@Component({
  selector: 'app-gbp-date-locations-form',
  templateUrl: './gbp-date-locations-form.component.html',
  styleUrls: ['./gbp-date-locations-form.component.scss'],
})
export class GbpDateLocationsFormComponent implements OnInit, OnChanges, OnDestroy {
  timeZones = [];
  locations = [];
  isSelectedAll: boolean;
  selectedLocationsLength: number;
  current = 0;
  currentLocations = [];
  // paginatorRows = 500;
  // paginatorLength = 0;
  filterValue = '';
  filtered = [];
  unsubscribe$ = new Subject<void>();
  @Input() scheduleGroup: FormGroup;
  @Input() rows: number;
  @Input() width = '100%';
  @Input() displayScheduleLaterSwitch: boolean;
  public disableScheduleForLaterSwitch: boolean = false;
  public preSelectedLocs: any[] = [];

  constructor(private gbpService: GBPService) {}

  ngOnInit(): void {
    // Get locations from service
    this.gbpService
      .getAllLocations(['_id', 'storeCode', 'businessName', 'city', 'state', 'tags', 'labels'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locations) => {
        this.scheduleGroup.addControl('totalLocationsCount', new FormControl(locations.length));
        if (this.scheduleGroup.controls['preSelectedLocation']?.value?.length) {
          this.preSelectedLocs = this.scheduleGroup.controls['preSelectedLocation']?.value;
          this.preSelectedLocs.forEach(loc => {
            locations.forEach((l, i) => {
              if(l[1] === loc?.storeCode) {
                locations.splice(i, 1);
              }
            })
          });
        }

        this.locations = locations;
        if (this.locations?.length === this.preSelectedLocs?.length) {
          this.scheduleGroup.patchValue({
            isSelectedAll:  true
          })
        }

        this.timeZones = TIME_ZONES;
        this.currentLocations = this.locations;
        this.filtered = this.locations;
      });

    if (this.displayScheduleLaterSwitch) {
      if (!this.scheduleGroup.controls['timeZone'].value) {
        this.scheduleGroup.addControl('checked', new FormControl(false));
      }
      // To disable Schedule for Later switch if Post status === A (Active).
      if (this.scheduleGroup.controls['postStatus']?.value) {
        this.disableScheduleForLaterSwitch = true;
      }
    }

    this.scheduleGroup.controls.isSelectedAll.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      // console.log(value);
      if (value) {
        // if(this.preSelectedLocs.length) {
        //   // this.scheduleGroup.controls.location.setValue(this.locations, this.preSelectedLocs);
        //   this.scheduleGroup.patchValue({
        //     location: [...this.locations, ...this.preSelectedLocs]
        //   })
        // } else {
        this.scheduleGroup.controls.location.setValue(this.locations);
        // }
      }
      // else if(!value && this.preSelectedLocs.length) {
      //   this.scheduleGroup.controls.location.setValue([this.preSelectedLocs]);
      // }
      else {
        this.scheduleGroup.controls.location.setValue([]);
      }
    });
    this.scheduleGroup.controls.location.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      if(value.length === (this.locations.length)) {
        this.scheduleGroup.controls.isSelectedAll.setValue(true, { emitEvent: false });
        // this.scheduleGroup.patchValue({
        //   isSelectedAll: true
        // })
      } else {
        this.scheduleGroup.controls.isSelectedAll.setValue(false, { emitEvent: false });
        // this.scheduleGroup.patchValue({
        //   isSelectedAll: true
        // })
      }
    });

    if(this.preSelectedLocs.length) {
      this.scheduleGroup.controls.location.setValue([]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.scheduleGroup.currentValue['controls']['timeZone'].value) {
      this.scheduleGroup.patchValue({
        checked: true
      });
    };
  }

  onPageChange(event) {
    this.currentLocations = this.filtered; //.slice(event.first, +event.first + +event.rows);
  }

  onPanelHide() {
    this.filterValue = '';
    this.currentLocations = this.locations; //.slice(0, this.paginatorRows);
  }

  onFilter(event) {
    const searchStr = event.target.value.toLowerCase();
    const pattern = new RegExp('\\b' + searchStr);
    this.filtered = [
      ...this.locations.filter(
        (elem) => elem.join(' ').toLowerCase().match(pattern)
      ),
    ];
    // this.paginatorLength = this.filtered.length;
    this.currentLocations = this.filtered; //.slice(0, this.paginatorRows);
  }

  public handleLocChange(event): void {
    if (event.value.length === this.locations.length) {
      this.scheduleGroup.controls.isSelectedAll.setValue(true, { emitEvent: false });
    }
  }

  public hendleScheduleLater(): void {
    // console.log(this.scheduleGroup.controls['checked']);
    // if(!this.scheduleGroup.controls['checked'].value) {
    //   this.scheduleGroup.patchValue({
    //     timeZones: null
    //   })
    // }
  }

  handleAllLocChange(event) {
    // console.log(event);
    // console.log(this.scheduleGroup.controls.location.value);

    // this.scheduleGroup.controls.isSelectedAll.setValue(event.checked, {emitEvent: false});
    // this.scheduleGroup.patchValue({
    //   location: [...this.scheduleGroup.controls.location.value, ...this.preSelectedLocs]
    // });
    // console.log(this.scheduleGroup.controls.location.value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
