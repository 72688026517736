<app-progress #progress (stateChange)="onStateChange($event)" [stepsLabels]="stepsLabels" [isMediaTypePhoto]="true">
  <app-progress-step>
    <app-upload-media
      [multiple]="false"
      [isCustomUrl]="true"
      [onlyCustomURL]="false"
      [customURL]="uploadGroup.controls['imageUrl'].value"
      [mediaCategory]="selectedMediaCategory?.imgCategory"
      [mediaRequirements]="mediaRequirements"
      [mediaTitle]="mediaRequirementsTitle"
      (imagesUrlChanged)="imageChanged($event)"
    ></app-upload-media>
  </app-progress-step>
  <app-progress-step>
    <app-gbp-date-locations-form
      [displayScheduleLaterSwitch]="true"
      [scheduleGroup]="scheduleGroup"
      [rows]="2"
    ></app-gbp-date-locations-form>
  </app-progress-step>
  <app-progress-step>
    <app-gbp-photo-submit
      *ngIf="activeIndex === 2"
      [form]="photoForm"
      [mediaCategory]="selectedMediaCategory?.imgCategory"
      [preSelectedLocs]="preSelectedLocation.length"
      ></app-gbp-photo-submit>
  </app-progress-step>
</app-progress>

<div class="bottom-buttons">
  <button *ngIf="activeIndex == 0" pButton label="Cancel" class="p-button-outlined" (click)="closeDialog()"></button>
  <button *ngIf="activeIndex > 0" pButton label="Back" class="p-button-outlined" (click)="goBack()" progressStepPrev></button>
  <button *ngIf="activeIndex === 0 || activeIndex === 2" pButton [label]="saveButtonLabel" class="p-button-primary"
    [disabled]="checkMediaUploadStatus" (click)="goNext(progress)"></button>

  <button *ngIf="activeIndex === 1" pButton [label]="saveButtonLabel" class="p-button-primary"
    [disabled]="checkDateAndLocStatus" (click)="goNext(progress)"></button>
</div>

