import { NgModule } from '@angular/core';
import { RSCommonModule } from '../core/feature-modules/facade.module';
import { GbpManagementComponent } from './gbp-management.component';
import { GbpManagementRoutingModule } from './gbp-managment.routing';
import { GbpPhotoModule } from './gbp-tab-photo/gbp-tab-photo.module';
import { GbpPostModule } from './gbp-tab-post/gbp-tab-post.module';
import { GbpQaModule } from './gbp-tab-qa/gbp-tab-qa.module';

@NgModule({
  declarations: [GbpManagementComponent],
  exports: [GbpManagementComponent],
  imports: [RSCommonModule, GbpManagementRoutingModule, GbpPostModule, GbpPhotoModule, GbpQaModule],
  providers: [],
})
export class GbpManagementModule {}
