import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from 'src/app/core/backend-adapter/session.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GbpPhotoUploadComponent} from './components/gbp-photo-upload/gbp-photo-upload.component';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {GbpPhotoExifComponent} from './components/gbp-photo-exif/gbp-photo-exif.component';
import {GbpPhotoLocationsComponent} from './components/gbp-photo-locations/gbp-photo-locations.component';
import {GbpPhotoDetailsComponent} from './components/gbp-photo-details/gbp-photo-details.component';
import {ACTIVITY_STATUS} from '../gbp-tabs.constants';
import {GbpPhotoOptimizeComponent} from './components/gbp-photo-optimize/gbp-photo-optimize.component';
import {GbpPhotoDeleteComponent} from './components/gbp-photo-delete/gbp-photo-delete.component';
import {GbpSelectLocationsComponent} from './components/gbp-select-locations/gbp-select-locations.component';
import {TableFilterService} from 'src/app/core/feature-modules/table-filter/table-filter.service';
import {FILTER_TYPES, IFilterData} from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';
import {Observable, Subject} from 'rxjs';
import {GBPService} from '../gbp.service';
import {takeUntil, tap} from 'rxjs/operators';
import {NotifyService} from '@app/core/layouts/notifications/notify/notify.service';
import {BroadcastService} from '@app/core/backend-adapter/broadcast.service';
import {DBService} from '@app/core/backend-adapter/db.service';
import { SYSTEM_COLORS } from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import {IAccount} from '@app/acct-comps/accounts.interfaces';
import { IFitersObject } from 'src/app/review-management/review-management.interfaces';
import { GBPFiltersDateService } from '../services/gbp-filters-date-service/gbp-filters-date-service';

@Component({
  selector: 'app-gbp-tab-photo',
  templateUrl: './gbp-tab-photo.component.html',
  styleUrls: ['./gbp-tab-photo.component.scss'],
  providers: [DialogService],
})
export class GbpTabPhotoComponent implements OnInit, OnDestroy {
  SYSTEM_COLORS = SYSTEM_COLORS;
  private unsubscribe$ = new Subject<void>();

  keyword = '';
  photoData = [];
  selectedRows = [];
  filterData: IFilterData[];
  chipsArray: string[] = [];
  filterMenuItems: MenuItem[];
  tooltipText = 'Coming soon. Please reach out to support if you need to edit a photo.';
  ref: DynamicDialogRef;
  activityStatus: typeof ACTIVITY_STATUS = ACTIVITY_STATUS;
  totalLocationsCount: number;
  totalPhotosCount: number;
  offset = 0;
  pageSize = 25;
  sortOrder = '';
  private account: IAccount;
  filters: IFitersObject = { };
  public actionItems = [
    {
      label: 'Logo',
      command: () => {
        this.openUploadPhoto({imgCategory: 'LOGO', category: 'LOGO'});
      }
    },
    {
      label: 'Cover Photo',
      command: () => {
        this.openUploadPhoto({imgCategory:'COVER', category: 'COVER PHOTO'});
      }
    },
    {
      label: 'Photo',
      command: () => {
        this.openUploadPhoto({imgCategory:'ADDITIONAL', category: 'PHOTO'});
      }
    },
    {
      label: 'Video',
      command: () => {
        this.openUploadPhoto({imgCategory: 'ADDITIONAL', category: 'VIDEO'});
      }
    }
  ];

  constructor(
    public sessionService: SessionService,
    public dialogService: DialogService,
    private tableFilterService: TableFilterService,
    private gbpService: GBPService,
    private notifyService: NotifyService,
    private broadcastService: BroadcastService,
    private dbService: DBService,
    private gbpFiltersDateService: GBPFiltersDateService
  ) {
  }

  ngOnInit(): void {
    this.account = this.dbService.Account.findByIdent(this.sessionService.currAccount);

    // this.onResize(window.innerHeight);
    this.filterMenuItems = [
      {
        label: 'File',
        items: [
          {label: 'New', icon: 'pi pi-fw pi-plus'},
          {label: 'Download', icon: 'pi pi-fw pi-download'},
        ],
      },
      {
        label: 'Edit',
        items: [
          {label: 'Add User', icon: 'pi pi-fw pi-user-plus'},
          {label: 'Remove User', icon: 'pi pi-fw pi-user-minus'},
        ],
      },
    ];

    this.filterData = [
      {
        type: FILTER_TYPES.MULTI,
        title: 'ATTRIBUTES',
        options: ['Scheduled', 'Published', 'Unpublished'],
      },
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: ['This month', 'Last month'],
      },
    ];

    this.gbpService
      .getTotalLocationsCount()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((totalLocationsCount) => (this.totalLocationsCount = totalLocationsCount))
      )
      .subscribe();

    // this.loadData();
    this.setSubscriptions();
  }

  lazyLoadData(event: LazyLoadEvent) {
    this.offset = event.first;
    this.pageSize = event.rows;
    if (event.sortField) {
      this.sortOrder = `${event.sortField}${event.sortOrder < 0 ? ' DESC' : ''}`;
    }
    return this.loadData();
  }

  loadData() {
    this.dbService.MediaBatchGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      order: this.sortOrder,
      where: this.buildWhereClause()
    }).then(
      (repl) => {
        if (repl.collection.length > 0) {
          this.photoData = repl.collection.map(el => {
            const url = el.internalURL || el.sourceURL || null;
            return {
              ...el,
              isVideo: (url) ? (url.endsWith('.mp4') ? true : false ) : false,
            };
          });
          this.totalPhotosCount = repl.totalEntries;
          this.gbpService.account_id = repl.collection[0]?.account_id;
        } else {
          this.photoData = [];
          this.totalPhotosCount = 0;
          this.notifyService.error('There is no data for this account');
        }
      },
      (err) => {
        console.warn('Error loading photos: %j', err);
      }
    );
  }

  setSubscriptions() {
    this.broadcastService.subscribe('mediaBatchStarted', (repl) => {
      this.loadData();
    });

    this.broadcastService.subscribe('mediaBatchProcessed', (repl) => {
      this.loadData();
    });

    this.broadcastService.subscribe('mediaBatchGMBUpdated', (repl) => {
      this.loadData();
    });
  }

  get accountIsNotLeaf(): boolean {
    return !this.account?.isLeaf;
  }

  deleteBtnClass(data) {
    return ( ['DP', 'UR', 'AR'].includes(data._status) ) ? 'disabled' : '';
  }

  optimizeBtnClass(data) {
    return data._status == this.activityStatus.UNPUBLISHED ? 'disabled' : '';
  }

  optimizeTooltipText(data) {
    return data.optimized ? 'RE-OPTIMIZE' : 'OPTIMIZE';
  }

  createMediaTooltip() {
    return (!this.account?.isLeaf) ? 'Please choose a sub-account before uploading a photo' : '';
  }

  openUploadPhoto(mediaType: {}) {
    this.ref = this.dialogService.open(GbpPhotoUploadComponent, {
      width: '90%',
      data: {
        selectedMedia: mediaType
      }
    });
  };

  openPhotoExif() {
    this.dialogService.open(GbpPhotoExifComponent, {
      width: '70%',
    });
  }

  openPhotoLocations(batchId) {
    this.dialogService.open(GbpPhotoLocationsComponent, {
      width: '70%',
      data: {
        isPagination: true,
        id: batchId,
      },
    });
  }

  openPhotoDetails(rowData, fromAction?: boolean) {
    if (fromAction) {
      this.dialogService.open(GbpSelectLocationsComponent, {
        data: {...rowData},
        width: '70%',
      });
    } else {
      this.dialogService.open(GbpPhotoDetailsComponent, {
        data: {...rowData},
        width: '90%',
      });
    }
  }

  openPhotoOptimize(rowData) {
    this.dialogService.open(GbpPhotoOptimizeComponent, {
      data: {...rowData},
      width: '80%',
    });
  }

  openPhotoDelete(rowData) {
    this.dialogService.open(GbpPhotoDeleteComponent, {
      data: {...rowData},
      width: '40%',
    });
  }

  onChipsRefresh(event) {
    console.log(event);
    this.chipsArray = [].concat.apply([], Object.values(event)); // merge all checkbox values into one array
    this.chipsArray = this.chipsArray.filter((e) => e); // remove all possible undefined
    this.filters = event;
    this.loadData();
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
    this.tableFilterService.clearFilters(); // clear table filters
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildWhereClause(): Array<string> {
    const filterClauses: string[][] = [];
    const tempFilterArr: string[] = [];
    const isFiltersApplied = this.chipsArray.length > 0;

    const pushQueriesToMainArray = () => {
      let filterClause: Array<string> = ['-or'];
      filterClause = filterClause.concat(tempFilterArr);
      filterClauses.push(filterClause);
      tempFilterArr.length = 0;
    };

    if (isFiltersApplied) {
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.length > 0 && value[0] != undefined) {
          switch (key) {
            case 'ATTRIBUTES':
              value.forEach((element: string) => {
                switch (element) {
                  case 'Scheduled':
                    tempFilterArr.push("_status IN ('AP', 'UP','DP')");
                    break;
                  case 'Published':
                    tempFilterArr.push("_status = 'A'");
                    break;
                  case 'Unpublished':
                    tempFilterArr.push("_status = 'U'");
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'DATE':
              value.forEach((element: string) => {
                switch (element) {
                  case 'This month':
                    tempFilterArr.push(this.gbpFiltersDateService.inThisMonth('(SELECT min(createTime) FROM mediadetailgmb p WHERE p.batch_id = mediabatchgmb._id)'));
                    break;
                  case 'Last month':
                    tempFilterArr.push(this.gbpFiltersDateService.inLastMonth('(SELECT min(createTime) FROM mediadetailgmb p WHERE p.batch_id = mediabatchgmb._id)'));
                    break;
                  default:
                    const dateArray: string[] = element.split(' - ');
                    tempFilterArr.push(`batchcreatetime >= '${dateArray[0]}' AND batchcreatetime < '${dateArray[1]}'`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
          }
        }
      }
    }

    if (this.keyword) {
      tempFilterArr.push(`b._id::text ~* '\\y${this.keyword}'`);
      tempFilterArr.push(`b.category::text ~* '\\y${this.keyword}'`);
      tempFilterArr.push(`l.storeCode::text ~* '\\y${this.keyword}'`);
      pushQueriesToMainArray();
    }

    // join all queries
    let whereClauseArray: Array<any> = ['-and'];
    whereClauseArray = whereClauseArray.concat(filterClauses);
    return whereClauseArray;
  };

  public editPhotoDate(photoData): void {
    let category: string;
    const videoFormat = new RegExp(/[^\s]+(.*?).(mp4)$/);
    if (photoData.mediaFormat === 'COVER' || photoData.category === 'COVER') {
      category = 'COVER PHOTO';
    }
    if (photoData.mediaFormat === 'LOGO' || photoData.category === 'LOGO') {
      category = 'LOGO';
    }
    if (photoData.mediaFormat === 'ADDITIONAL' || photoData.category === 'ADDITIONAL') {
      if (videoFormat.test(photoData.internalURL)) {
        category = 'VIDEO'
      } else {
        category = 'PHOTO'
      }
    }
    this.ref = this.dialogService.open(GbpPhotoUploadComponent, {
      width: '90%',
      data: {
        selectedMedia: { category: category },
        data: { ...photoData }
      }
    });
  }

}
