import { Injectable, EventEmitter } from '@angular/core';
import { SessionService } from '../../core/backend-adapter/session.service';
import { BehaviorSubject } from 'rxjs';
import { DBService } from '../../core/backend-adapter/db.service';
import { ILocation } from 'src/app/loc-comps/locations.interfaces';
import Mustache from 'mustache';
import { IAccount, IAccountDefaultUtm } from 'src/app/acct-comps/accounts.interfaces';

export interface CampaignField {
  id: string;
  label: string;
  fieldChooser?: boolean;
  isRequired?: boolean;
  value?: string;
}

export type ProviderName = 'default' | 'google' | 'bing' | string;
export interface ProviderCampaignFields {
  [provider: string]: CampaignField[];
}
export interface ProviderCampaignUtmCodes {
  [provider: string]: string;
}

export interface ProviderCampaignUtmLinks {
  [provider: string]: Object[];
}

// Allowed providers and their label:
export const providers = [
  { id: 'default', label: 'Default' },
  { id: 'gmb', label: 'Google' },
  { id: 'bing', label: 'Bing' },
  { id: 'facebook', label: 'Facebook' },
  { id: 'factual', label: 'Factual' },
  { id: 'nextdoor', label: 'Nextdoor' },
  { id: 'mapquest', label: 'Mapquest' },
];

@Injectable({ providedIn: 'root' })
export class UTMService {
  public account_id = 0;
  public properties = null;
  public global = null;
  public customFields = [];

  private gmbPostQueryParamsUrl: string = '';
  private gmbPostQueryParams: Object = {};
  private account: IAccount = null;
  sampleLocations: ILocation[] = null;
  sampleLocationsUtmLinks: ProviderCampaignUtmLinks = null;

  refreshNeeded = new EventEmitter<void>();

  private campaignFields: CampaignField[] = [
    { id: 'utm_source', label: 'Campaign Source', value: '', isRequired: true },
    { id: 'utm_medium', label: 'Campaign Medium', value: '', isRequired: true },
    { id: 'utm_campaign', label: 'Campaign Name', value: '', isRequired: true, fieldChooser: true },
    { id: 'utm_term', label: 'Campaign Term', value: '', fieldChooser: true },
    { id: 'utm_content', label: 'Campaign Content', value: '', fieldChooser: true },
    { id: 'utm_id', label: 'Campaign ID', value: '', fieldChooser: true },
    { id: 'utm_source_platform', label: 'Campaign Source Platform', value: '', fieldChooser: true },
  ];
  private providerCampaignsSubject = new BehaviorSubject<ProviderCampaignFields>({});
  public providerCampaigns$ = this.providerCampaignsSubject.asObservable();

  constructor(private sessionService: SessionService, public dbService: DBService) {
    this.sessionService.getSelectedAccount$().subscribe((res) => {
      // Set a flag saying that the account is available
      if (res) {
        this.account = res;
        this.account_id = res._id;
        // keep the account global and properties locally
        this.properties = res.properties;
        this.global = res.global;
        // Add default fields to the customFields: (Store code, Business name, City, State, Postal code)
        this.customFields.push({
          index: 0,
          ident: 'storeCode',
          label: 'Store code',
          value: '{{storeCode}}',
        });
        this.customFields.push({
          index: 1,
          ident: 'businessName',
          label: 'Business Name',
          value: '{{businessName}}',
        });
        this.customFields.push({
          index: 2,
          ident: 'city',
          label: 'City',
          value: '{{city}}',
        });
        this.customFields.push({
          index: 3,
          ident: 'state',
          label: 'State',
          value: '{{state}}',
        });
        this.customFields.push({
          index: 4,
          ident: 'postalCode',
          label: 'Postal Code',
          value: '{{postalCode}}',
        });
        // add the active customColumnDefs from the properties to the customFields:
        let idx = 5;
        this.customFields.push({
          ...this.properties?.customColumnDefs?.map(
            (field) =>
              // return only active fields
              field._status === 'A' && {
                index: idx++,
                ident: 'custom.' + field.ident,
                label: field.label,
                value: '{{custom.' + field.ident + '}}',
              }
          ),
        });

        // If the global object has a 'utm' property, we use it to fill the campaign fields
        // else we initialize the campaign fields with the default publishers
        if (this.global.utm) {
          // The campaign fields and publishers are coming from the global.utm object:
          this.providerCampaignsSubject.next(this.global.utm);
        } else {
          this.initCampaignFields();
        }
        if (!this.sampleLocations) {
          this.getSampleLocations();
        }
      }
    });
  }

  public notifyDataChange() {
    this.refreshNeeded.emit(); // Emitting the refresh message
  }

  // Set the campaign field values for the "default" provider or override for other providers
  public setCampaignFieldValues(provider: ProviderName, values: Partial<Record<string, string>>): void {
    const currentProviders = this.providerCampaignsSubject.getValue();

    // If there's no provider data, copy from the fixed fields
    if (!currentProviders[provider]) {
      currentProviders[provider] = this.campaignFields.map((field) => ({ ...field }));
    }

    // Update the field values based on passed values
    currentProviders[provider].forEach((field) => {
      if (values[field.id] !== undefined) {
        field.value = values[field.id];
      }
    });

    // // Update utm links samples
    this.sampleLocationsUtmLinks = this.updateSampleUtmLinksForProviders();

    // Update the subject
    this.providerCampaignsSubject.next(currentProviders);
  }

  // Retrieve the campaign fields for a provider including defaults or overrides
  public getCampaignFieldsForProvider(provider: ProviderName): CampaignField[] {
    const providers = this.providerCampaignsSubject.getValue();
    return providers[provider] ? providers[provider] : this.campaignFields;
  }

  // update the sample utm links for all providers
  updateSampleUtmLinksForProviders(): ProviderCampaignUtmLinks {
    // Make sure we have sample locations:
    if (!this.sampleLocations) return;

    const utmCodes = this.updateUtmCodes();
    const providers = this.providerCampaignsSubject.getValue();
    // for each provider, we generate the corresponding utm code url query string
    let sampleUtmLinks: ProviderCampaignUtmLinks = {};
    for (const provider of Object.keys(providers)) {
      let linksArray: Object[] = [];
      for (const location of this.sampleLocations) {
        Mustache.escape = function (text) {
          return encodeURIComponent(text).replace(/%20/g, '+');
        };
        const parsedUmlCodes = Mustache.render(utmCodes[provider], location);
        linksArray.push({ storeCode: location.storeCode, url: location.websiteURL + parsedUmlCodes });
      }
      sampleUtmLinks[provider] = linksArray;
    }
    return sampleUtmLinks;
  }

  getSampleUtmLinksForProvider(provider: ProviderName): Object[] {
    const sampleUtmLinks = this.updateSampleUtmLinksForProviders();
    if (!sampleUtmLinks || !sampleUtmLinks[provider]) return;
    return sampleUtmLinks[provider];
  }

  // Retrieve the default campaign fields for the "default" provider
  public getDefaultCampaignFields(): Object {
    // Return the campaign fields for the "default" provider
    const providers = this.providerCampaignsSubject.getValue();
    // Return a index,value string array of the default fields, the index is the field id, the value is the field value for the "default" provider
    return providers['default'].reduce((acc, field) => {
      acc[field.id] = field.value;
      return acc;
    }, {});
  }

  public hasAccount(): boolean {
    return this.account_id > 0;
  }

  public checkCanSave(): boolean {
    // we can save only if the utm links for all the providers contain at least a value in the fields utm_source, utm_medium and utm_campaign
    const providers = this.providerCampaignsSubject.getValue();
    // we look at all the providers except the default one
    // we check if the utm_source, utm_medium and utm_campaign fields have a value and use the default value if not
    // if the field value has no value and the default value for this field has no value, then we return false
    for (let [provider, fields] of Object.entries(providers)) {
      if (provider !== 'default') {
        // if the default provider has no values for the utm_source, utm_medium and utm_campaign fields and the current provider has no values for these fields, then we skip this provider
        if (
          !providers['default'].find((f) => f.id === 'utm_source').value &&
          !providers['default'].find((f) => f.id === 'utm_medium').value &&
          !providers['default'].find((f) => f.id === 'utm_campaign').value &&
          !fields.find((f) => f.id === 'utm_source').value &&
          !fields.find((f) => f.id === 'utm_medium').value &&
          !fields.find((f) => f.id === 'utm_campaign').value
        ) {
          continue;
        }
        // we use the default field value if the current provider has no value for the field
        for (let field of fields) {
          let fieldValue = !!field.value ? field.value : providers['default'].find((f) => f.id === field.id).value;
          if (field.id === 'utm_source' || field.id === 'utm_medium' || field.id === 'utm_campaign') {
            if (!fieldValue) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  public saveUTMCodes(): void {
    // Get the current utm codes
    const utmCodes = this.updateUtmCodes();
    // Update the global object with the new utm codes
    // We get all the providers and utm code from the utmCodes object
    // and update each corresponding vendor utm code in the account global object
    for (let [provider, utmCode] of Object.entries(utmCodes)) {
      if (provider !== 'default') {
        this.account.global[provider].utm = utmCode;
      }
    }
    // Add or update the default utm codes to the global object
    const providers = this.providerCampaignsSubject.getValue();

    this.account.properties.defaultUtm = {
      utmSource: providers['default'].find((field) => field.id === 'utm_source').value,
      utmMedium: providers['default'].find((field) => field.id === 'utm_medium').value,
      utmCampaign: providers['default'].find((field) => field.id === 'utm_campaign').value,
      utmTerm: providers['default'].find((field) => field.id === 'utm_term').value,
      utmContent: providers['default'].find((field) => field.id === 'utm_content').value,
      utmId: providers['default'].find((field) => field.id === 'utm_id').value,
      utmSourcePlatform: providers['default'].find((field) => field.id === 'utm_source_platform').value,
    };
    // Save the account object
    this.dbService.Account.saveObject(this.account).then((resp) => {
      // Update the campaign fields with the new utm codes
      // this.initCampaignFields();
      // Notify the components that the data has changed
      this.notifyDataChange();
    });
  }

  // Function to return the list of allowed publisher from the global object
  private getPublishers(): string[] {
    let publishers: string[] = [];
    console.log(this.global);
    console.log(typeof this.global);
    const global = typeof this.global === 'string' ? JSON.parse(this.global) : this.global;
    console.log(global)
    for (let [key, value] of Object.entries(global)) {
      // if this published is allowed and if we find it's id in the providers list we add it to the publishers list
      if (value['publish'] && providers.find((p) => p.id === key)) {
        publishers.push(key);
      }
    }
    console.log(publishers)
    return publishers;
  }

  // For each publisher, initialize the campaign fields object
  private initCampaignFields() {
    let providers: ProviderCampaignFields = {};
    let publishers = this.getPublishers();
    // Let's add a default provider first
    providers['default'] = this.campaignFields.map((field) => ({ ...field }));
    // Read the default field values from the account properties
    const defaultUtm = this.account.properties.defaultUtm;
    if (defaultUtm) {
      providers['default'].find((field) => field.id === 'utm_source').value = defaultUtm.utmSource;
      providers['default'].find((field) => field.id === 'utm_medium').value = defaultUtm.utmMedium;
      providers['default'].find((field) => field.id === 'utm_campaign').value = defaultUtm.utmCampaign;
      providers['default'].find((field) => field.id === 'utm_term').value = defaultUtm.utmTerm;
      providers['default'].find((field) => field.id === 'utm_content').value = defaultUtm.utmContent;
      providers['default'].find((field) => field.id === 'utm_id').value = defaultUtm.utmId;
      providers['default'].find((field) => field.id === 'utm_source_platform').value = defaultUtm.utmSourcePlatform;
    }
    // For each publisher, we initialize the campaign fields
    for (let publisher of publishers) {
      providers[publisher] = this.campaignFields.map((field) => ({ ...field }));
      // If the publisher has a 'utm' object, we use it to fill the campaign fields
      if (this.global[publisher]?.utm) {
        // The utm field is the query side of a url like: "utm_source=google&utm_medium=cpc&utm_campaign=google-campaign"
        // We need to split the query string into key/value pairs and fill the fields
        let utm = this.global[publisher].utm;
        let utmFields = utm.split('&');
        for (let utmField of utmFields) {
          // Remove the leading '?' if any
          if (utmField.startsWith('?')) {
            utmField = utmField.slice(1);
          }
          let utmFieldParts = utmField.split('=');
          let utmFieldKey = utmFieldParts[0];
          let utmFieldValue = utmFieldParts[1];
          for (let field of providers[publisher]) {
            if (field.id === utmFieldKey) {
              // we use the utmFieldValue unless this is the same value as the default provider value
              if (utmFieldValue !== providers['default'].find((f) => f.id === field.id).value) {
                field.value = utmFieldValue;
              } else {
                field.value = '';
              }
            }
          }
        }
      }
    }
    this.providerCampaignsSubject.next(providers);
  }

  getSampleLocations() {
    const repl = this.dbService.Location.loadObjects({
      offset: 0,
      limit: 30,
      sort: 'name',
    }).then((res) => {
      // We get the first 3 locations that are also not a sublocation (when the parent id is not empty)
      this.sampleLocations = res['collection'].filter((loc) => !loc._parent_id).slice(0, 3);
      this.notifyDataChange();
    });
  }

  updateUtmCodes(): ProviderCampaignUtmCodes {
    let publishers = this.getPublishers();
    let newUtmCodes: ProviderCampaignUtmCodes = {};
    // Let's add a default provider first
    newUtmCodes['default'] = this.getUtmCodesQueryString('default');
    for (let publisher of publishers) {
      newUtmCodes[publisher] = this.getUtmCodesQueryString(publisher);
    }
    return newUtmCodes;
  }

  // Function to return the utm code query string for a provider
  private getUtmCodesQueryString(provider: ProviderName): string {
    // get the default fields
    const defaultFields = this.getCampaignFieldsForProvider('default');
    // Get the provider field values
    const fields = this.getCampaignFieldsForProvider(provider);
    // Let's create the utm code query string for the provider. We use the value from the default provider if the current provider has no value for the field
    let utmCodesQueryString = '';
    for (let field of fields) {
      // Use the default field value if the current provider has no value for the field
      let fieldValue = !!field.value ? field.value : defaultFields.find((f) => f.id === field.id).value;
      if (!!fieldValue) {
        // If the field value is not a variable, meaning it has handlebars like this {{ }} then we have to urlencode it
        if (!fieldValue.startsWith('{{') && !fieldValue.endsWith('}}')) {
          fieldValue = encodeURIComponent(fieldValue).replace(/%20/g, '+');
        }
        utmCodesQueryString += `${field.id}=${fieldValue}&`;
      }
    }
    // Remove the last '&' if any
    if (utmCodesQueryString.endsWith('&')) {
      utmCodesQueryString = utmCodesQueryString.slice(0, -1);
    }
    if (utmCodesQueryString.length === 0) {
      return '';
    } else {
      return '?' + utmCodesQueryString;
    }
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Methods to manage the google post button url utm codes
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // Parse the current url to get the utm codes, keep all the query parameters, make all the utm codes params available in the gmbPostUtmCodes object
  public parseGmbPostUrl(url: string): void {
    // Keep the url domain
    this.gmbPostQueryParamsUrl = url ? url.split('?')[0] : '';
    // We get all the query parameters from the url
    const queryParams = url ? url.split('?')[1] : '';
    const urlParams = new URLSearchParams(queryParams);
    // We get the utm codes from the url
    this.gmbPostQueryParams = {};
    urlParams.forEach((value, key) => {
      this.gmbPostQueryParams[key] = value;
    });
  }

  // Function to update a utm code in the gmbPostQueryParams object
  public updateCtaUrlUtmCode(utmCode: string, value: string): void {
    this.gmbPostQueryParams[utmCode] = value;
  }

  // Function to return the gmb post url with the utm codes
  public getGmbPostUrl(): string {
    let url = this.gmbPostQueryParamsUrl;
    if (this.gmbPostQueryParams) {
      // Extract all the non empty params from the gmbPostQueryParams object and construct the querystring
      let queryString = Object.keys(this.gmbPostQueryParams).reduce((acc, key) => {
        if (this.gmbPostQueryParams[key]) {
          let fieldValue = this.gmbPostQueryParams[key];
          if (!fieldValue.startsWith('{{') && !fieldValue.endsWith('}}')) {
            fieldValue = encodeURIComponent(fieldValue).replace(/%20/g, '+');
          }
          acc += `${key}=${fieldValue}&`;
        }
        return acc;
      }, '');
      if (queryString.endsWith('&')) {
        queryString.slice(0, -1);
      }
      url += queryString ? '?' + queryString : '';
    }
    // remove the trailing '&' if any
    if (url.endsWith('&')) {
      url = url.slice(0, -1);
    }
    return url;
  }

  getGmbPostQueryParams(): Object {
    return this.gmbPostQueryParams;
  }

  ngOnDestroy(): void {}
}
