<div>
  <p-multiSelect
    [options]="currentLocations"
    formControlName="location"
    optionLabel="businessName"
    appendTo="body"
    [selectedItemsLabel]="'{0} Locations selected'"
    placeholder="Select"
    [showToggleAll]="false"
    [maxSelectedLabels]="0"
    [filter]="false"
    [style]="{ minWidth: '100%' }"
    (onPanelHide)="onPanelHide()"
  >
    <ng-template pTemplate="header">
      <div class="p-inputgroup">
        <input
          type="text"
          pInputText
          placeholder="Search for location(s) by name, city, state..."
          [(ngModel)]="filterValue"
          [ngModelOptions]="{ standalone: true }"
          (input)="onFilter($event)"
        />
      </div>
    </ng-template>

    <ng-template let-location pTemplate="item">
      <div class="location-item">
        <div>{{ location.storeCode }} - {{ location.businessName }} - {{ location.city }} - {{ location.state }}</div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-paginator
        styleClass="multiselect-paginator"
        [rows]="paginatorRows"
        pageLinkSize="3"
        [totalRecords]="paginatorLength"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} locations.'"
        (onPageChange)="onPageChange($event)"
      >
      </p-paginator>
    </ng-template>
  </p-multiSelect>
</div>
