import { Component, Input, OnInit } from '@angular/core';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NotifyService } from './../../layouts/notifications/notify/notify.service';
import { API_URL } from './../../../../config';
import { CookieService } from './../../backend-adapter/cookie.service';

enum DownloadType {
  csv = 'csv',
  xls = 'xls',
  xlsx = 'xlsx',
}

@Component({
  selector: 'app-export-handler',
  templateUrl: './export-handler.component.html',
  styleUrls: ['./export-handler.component.scss'],
})
export class ExportHandlerComponent implements OnInit {
  @Input() account: IAccount;
  public downloadTypes = [
    { value: DownloadType.csv, label: 'CSV', mimetype: 'text/csv' },
    { value: DownloadType.xls, label: 'XLS', mimetype: 'application/vnd.ms-excel' },
    {
      value: DownloadType.xlsx,
      label: 'XLSX',
      mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  ];
  public loading = false;
  public downloadTypeField = DownloadType.csv;
  private locationsQuery = ['-and', '_parent_id IS null'];

  constructor(private http: HttpClient, private notify: NotifyService, private cookieService: CookieService) {}

  ngOnInit(): void {}

  public export(e) {
    e.preventDefault();
    this.loading = true;

    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const selectedfileConfig = this.downloadTypes.find((v) => v.value === this.downloadTypeField);

    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': selectedfileConfig.mimetype,
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'arraybuffer' as any,
    };

    const url =
      `${API_URL}/download/locations/${this.account.ident}` +
      `?where=${this.toJSON(this.locationsQuery)}&type=${this.downloadTypeField}`;

    this.http.get(`${url}`, httpOptions).subscribe(
      (result: ArrayBuffer) => {
        const blob = new Blob([result], {
          type: selectedfileConfig.mimetype,
        });
        const filename = `locations-${this.account.ident}.${this.downloadTypeField}`;

        saveAs(blob, filename);
        this.notify.success('Downloaded successfully!');
      },
      (error) => {
        this.notify.error('Error while exporting');
      },
      () => (this.loading = false)
    );
  }

  public selectSublocationsInQuery(event) {
    if (event.checked) {
      this.locationsQuery = ['-and'];
    } else {
      this.locationsQuery = ['-and', '_parent_id IS null'];
    }
  }

  public toJSON(v) {
    return JSON.stringify(v);
  }
}
