export enum GBP_TABS {
  PHOTO = 'PHOTO',
  POST = 'POST',
  QA = 'Q&A',
}

export enum ACTIVITY_STATUS {
  PUBLISHED = 'A',
  SCHEDULED = 'AP',
  PROCESSING = 'AR',
  FAILED = 'AX',
  INACTIVE = 'D',
  DELETE_SCHEDULED = 'DP',
  DELETE_PROCESSING = 'DR',
  DELETE_FAILED = 'DX',
  UNPUBLISHED = 'U',
  UNPUBLISH_SCHEDULED = 'UP',
  UNPUBLISH_PROCESSING = 'UR',
  UNPUBLISH_FAILED = 'UX',
  DRAFT = 'DRAFT',
  // CLOSED_PERMANENTLY = ''
}
