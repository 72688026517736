<div class="reports-page-container">
  <!-- Main page header -->
  <div class="flex">
    <div class="header-parent-title">
      <div class="header-child-title">Reports</div>
      <div class="header-child-title">.</div>
    </div>
  </div>

  <!-- Tab menu-->
  <p-tabMenu class="tabs-menu" [model]="tabItems" [activeItem]="activeItem">
    <ng-template pTemplate="item" let-item let-i="index">
      <div class="tabs-style">{{ item.label }}</div>
    </ng-template>
  </p-tabMenu>

  <!-- Google Reports Tab -->
  <div *ngIf="activeItem.label == reportsTabs.GOOGLE">
    <app-google-dashboard-tab></app-google-dashboard-tab>
  </div>

  <!-- Yelp Reports Tab -->
  <div *ngIf="activeItem.label == reportsTabs.YELP">
    <app-yelp-dashboard-tab></app-yelp-dashboard-tab>
  </div>

  <div *ngIf="activeItem.label == reportsTabs.APPLE">
    <app-apple-dashboards-maps-tab></app-apple-dashboards-maps-tab>
  </div>

  <div *ngIf="activeItem.label === reportsTabs.BING">
    <bing-insights></bing-insights>
  </div>

  <!-- Reports tools page -->
  <div *ngIf="activeItem.label == reportsTabs.REPORTS_TOOLS">
    <app-reports-tools></app-reports-tools>
  </div>

  <!-- Report Log Tab -->
  <!-- <div *ngIf="activeItem.label == reportsTabs.LOG">test</div> -->

  <!-- Accuracy Report Tab -->
  <div *ngIf="activeItem.label == reportsTabs.ACCURACY">
    <app-accuracy-reports></app-accuracy-reports>
  </div>
</div>
