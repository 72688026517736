import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: 'app-insights-table-card',
  templateUrl: './insights-table-card.component.html',
  styleUrls: ['./insights-table-card.component.scss']
})

export class InsightsTableCardComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() cardDescription: string;
  @Input() zipCodes = [];

  ngOnInit() {

  }

  changeMonth() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
