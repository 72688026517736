import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppleMapsInsightsDataService } from '../../services/apple-maps-insights-data.service';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss']
})

export class FilterButtonComponent implements OnInit, OnDestroy {
  public readonly tooltipText: string = `The AND Filter Type will only display a location if it matches ALL selected filter criteria.
  <br> The OR Filter type will display a location if it matches ANY selected filter criteria`;
  public formData: FormGroup | FormArray;
  public data;
  public selectedCheckboxOptions: any[];
  public displayFiltersCriteria: boolean = false;
  public textInputs;
  public operandType: string;

  private filterSelections: Set<string[]> = new Set();
  private finalSelection: any[] = [];
  private ngUnsubscribe$ = new Subject();

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private appleMapsInsightsDataService: AppleMapsInsightsDataService
  ) { }

  ngOnInit() {
    this.data = this.dynamicDialogConfig.data?.data;
    this.displayFiltersCriteria = this.dynamicDialogConfig.data?.isAdvanceFilterActive;
    this.appleMapsInsightsDataService.currentFilterOperandType
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((opType: string) => {
        this.operandType = opType;
      })
  }

  public closeDialog(): void {
    this.appleMapsInsightsDataService.dialogRefComponent.close();
  }

  public submit(): void {
    const selectedCheckboxesObject = {};

    // For inputs type text fields...
    this.data.forEach(filter => {

      // If Store code filter is selected, then set the operandType = '-and'...
      if (filter.label === 'Store code') {
        this.operandType = '-and';
        this.appleMapsInsightsDataService.setFilterOperandType(this.operandType);
      }

      if (filter.fieldType === 'chipInput' && filter.value.length) {
        this.finalSelection.push({
          [filter.label]: filter.value
        })
      }
    });

    // for inputs type checkbox...
    if (this.selectedCheckboxOptions?.length) {
      this.selectedCheckboxOptions.forEach((el) => {
        if (!selectedCheckboxesObject.hasOwnProperty(el[0])) {
          selectedCheckboxesObject[el[0]] = [el[1]];
        } else {
          selectedCheckboxesObject[el[0]].push(el[1]);
        }
      });

      Object.keys(selectedCheckboxesObject).forEach((key) => {
        this.finalSelection.push({
          [key]: [...selectedCheckboxesObject[key]]
        })
      });
    }

    this.appleMapsInsightsDataService.setCurrentFilterState(this.finalSelection);
    this.appleMapsInsightsDataService.dialogRefComponent.close();
  }

  public reset(): void {
    this.data.forEach(filterItem => {
      if (filterItem.fieldType === 'chipInput') {
        filterItem.value = [];
      }
    });
    this.selectedCheckboxOptions = [];
    this.finalSelection = []
    this.appleMapsInsightsDataService.setCurrentFilterState(this.finalSelection);
  }

  public chipAdded(event): void {
    this.filterSelections.add(event.value);
    this.appleMapsInsightsDataService.getSelectedFiltersList(this.filterSelections);
  };

  public chipRemoved(event): void {
    this.filterSelections.delete(event.value);
    this.appleMapsInsightsDataService.getSelectedFiltersList(this.filterSelections);
  }

  public onChange(event): void {
    if (this.filterSelections.has(event)) {
      this.filterSelections.delete(event);
    } else {
      this.filterSelections.add(event);
    }
    this.appleMapsInsightsDataService.getSelectedFiltersList(this.filterSelections);
  }

  public updateFilterOperation(operationType: string): void {
    this.operandType = operationType;
    this.appleMapsInsightsDataService.setFilterOperandType(operationType);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
