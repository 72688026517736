import { Injectable } from "@angular/core";
import Papa from 'papaparse';

@Injectable({
  providedIn: 'root',
})

export class ConvertCSVToJSONService {
  constructor() {}

  public csvToJson(csvData: any, config?: any): any {
    return Papa?.parse(csvData, config);
  };

  public jsonToCsv(jsonData: any): any {
    return Papa?.unparse(jsonData);
  };

}
