import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { BroadcastService } from './core/backend-adapter/broadcast.service';
import { IAuthenticateData } from './core/backend-adapter/session.interfaces';
import { SessionService } from './core/backend-adapter/session.service';

type IResolve = (value: boolean | UrlTree | PromiseLike<boolean | UrlTree>) => boolean;

@Injectable({
  providedIn: 'root',
})
export class IsAuthGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private broadcastService: BroadcastService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /**
     * new router changes don't wait for startup service
     */
    // return new Promise((resolve) => {
    //   if (this.sessionService.isAuthenticated('auth2')) {
    //     return resolve(true);
    //   } else {
    //     this.refreshAuth(resolve);
    //   }
    // });
    return this.sessionService.getCurrentUser$().pipe(
      map((v) => {
        // debugger
        if (!!v) {
          // debugger;
          return true;
        } else {
          // debugger;
          return false;
        }
      })
    );
  }

  // deprecated due to app_initialiser
  private refreshAuth(resolve) {
    this.sessionService
      .authenticateWSS()
      .then((data: IAuthenticateData) => {
        // debugger;
        if (data && data.status === 'ok') {
          // this.broadcastService.update('sessionAuthenticated', data);
          return resolve(true);
        } else {
          this.router.navigate(['/login']);
          return resolve(false);
        }
      })
      .catch((error) => {
        // debugger;
        this.router.navigate(['/login']);
        return resolve(false);
      });
  }
}
