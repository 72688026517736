import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success',
  template: `
    <div class="success-wrapper">
      <img src="assets/loc-add-edit-widget-logos/check.png" width="70px" height="70px" />
      <div class="header-parent-title">
        <div class="header-child-title">Success</div>
        <div class="header-child-title">!</div>
      </div>
    </div>
  `,
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
