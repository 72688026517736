import {Component, Inject, OnInit} from '@angular/core';
import {COMPUTED_FONTS} from './styles/fonts.styles';
import {StyleChangerService} from './style-changer.service';
import {RsStyleManager} from './style-manager.worker';
import {RsThemes} from 'src/app/core/feature-modules/whitelabel/style-changer/style-changer.interfaces';
import {themeConfig} from './theme-config';
import {COMPUTED_COLORS} from './styles/colors/colors.styles';
import {zip} from 'rxjs';
import {RsThemeConfig} from './theme-config.interfaces';
import {HttpClient} from '@angular/common/http';
import {COMPUTED_LOGOS} from '@app/core/feature-modules/whitelabel/style-changer/styles/logos.styles';
import {Title} from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-style-changer',
  template: ``,
  styles: [``],
})
export class StyleChangerComponent implements OnInit {
  private CSS_STATIC = '';

  constructor(private styleChangerService: StyleChangerService,
              private rsStyleManager: RsStyleManager,
              private http: HttpClient,
              private titleService: Title,
              @Inject(DOCUMENT) private htmlDocument: HTMLDocument) {

    zip(this.styleChangerService.getCssBase(), this.styleChangerService.getCssHeaders()).subscribe(
      ([css, headers]: [string, string]) => {
        this.CSS_STATIC = css + headers;
        this.listenThemeChanges();
      }
    );

    this.http.get('/theme/theme.json').subscribe((contents) => {
      const loadedTheme: object = contents;
      themeConfig.whitelabeled = ({
        name: RsThemes.whitelabeled,
        // @ts-ignore
        orgName: loadedTheme.orgName,
        // @ts-ignore
        font: loadedTheme.font,
        logos: {
          // @ts-ignore
          large: loadedTheme.logo.large,
          // @ts-ignore
          square: loadedTheme.logo.square,
          // @ts-ignore
          favicon: loadedTheme.logo.favicon
        },
        colors: {
          // @ts-ignore
          primary: loadedTheme.primaryColor.rgb.join(),
          // @ts-ignore
          secondary: loadedTheme.primaryColor.rgb.join(),
          // @ts-ignore
          button: loadedTheme.buttonColor.rgb.join()
        }
      } as RsThemeConfig);

      this.styleChangerService.setCurrentTheme(RsThemes.whitelabeled);
      // this.rsStyleManager.createStyle(this.buildCssWithConfig(this.whitelabeledThemeConfig));
    }, error => {
      this.styleChangerService.setCurrentTheme(RsThemes.default);
    });
  }

  ngOnInit() {
  }

  private listenThemeChanges() {
    this.styleChangerService.getCurrentTheme().subscribe((theme: RsThemes) => {
      this.rsStyleManager.createStyle(this.buildCss(theme));
      this.titleService.setTitle(this.getThemeConfig(theme).orgName + ' Console');
      this.htmlDocument.getElementById('favicon').setAttribute('href', this.getThemeConfig(theme).logos.favicon);
    });
  }

  private buildCssWithConfig(themeConfig: RsThemeConfig): string {
    return `
    ${COMPUTED_FONTS(themeConfig.font)}
    ${COMPUTED_COLORS(themeConfig.colors)}
    ${COMPUTED_LOGOS(themeConfig.logos)}
    ${this.CSS_STATIC}
    `;
  }

  private buildCss(theme: RsThemes): string {
    const themeConfig = this.getThemeConfig(theme);

    return `
    ${COMPUTED_FONTS(themeConfig.font)}
    ${COMPUTED_COLORS(themeConfig.colors)}
    ${COMPUTED_LOGOS(themeConfig.logos)}
    ${this.CSS_STATIC}
    `;
  }

  private getThemeConfig(theme: RsThemes): RsThemeConfig {
    return themeConfig[theme];
  }

}
