import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RsElementsModule } from '../elements/rs-elements.module';
import { PrimengModule } from './primeng.module';

const MODULES = [
  CommonModule,
  FormsModule,
  PrimengModule,
  FontAwesomeModule,
  RsElementsModule,
  ReactiveFormsModule, // HttpClientModule,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class RSCommonModule {}
