<div class="card-reviews">
  <header>
    <p>METRICS</p>
    <span>
      <button
        pButton
        type="button"
        icon="pi pi-eye-slash"
        class="hide-graphs"
        pTooltip="Hide Metrics"
        tooltipPosition="bottom"
        tooltipStyleClass="hide-tooltip"
        (click)="hideOverview()">
      </button>
    </span>
  </header>
  <hr *ngIf="!hideCards" />
  <ng-container>
    <ng-content select="[reviewsGraph]"></ng-content>
  </ng-container>
</div>
