import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-qa-edit',
  templateUrl: './gbp-qa-edit.component.html',
  styleUrls: ['./gbp-qa-edit.component.scss'],
})
export class GbpQaEditComponent implements OnInit {
  editForm = new FormGroup({
    questionText: new FormControl('', Validators.required),
    answerText: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    time: new FormControl('', Validators.required),
    timeZone: new FormControl('', Validators.required),
    scheduleForLater: new FormControl(false, Validators.required),
    scheduleTime: new FormControl(new RZLib.Timestamp().setNow()),
  });
  isSuccess = false;
  public timeZones = TIME_ZONES;
  public isScheduleSwitchOn: boolean = false;


  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {
    this.editForm.patchValue({
      questionText: this.dynamicDialogConfig?.data?.questionText,
      answerText: this.dynamicDialogConfig?.data?.answerText,
    });

    if (!this.dynamicDialogConfig?.data?.scheduleTime) {
      this.isScheduleSwitchOn = false;
      this.editForm.patchValue({
        scheduleForLater: false,
        date: '',
        time: '',
        timeZione: ''
      })
    } else {
      this.isScheduleSwitchOn = true;
      const setDate = `${this.dynamicDialogConfig?.data?.scheduleTime['_month_']}/${this.dynamicDialogConfig?.data?.
        scheduleTime['_day_']}/${this.dynamicDialogConfig?.data?.scheduleTime['_year_']}`;
      const setTime = (new Date(new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), this.dynamicDialogConfig?.data?.scheduleTime?._hour_, this.dynamicDialogConfig?.data?.scheduleTime?._minute_, this.dynamicDialogConfig?.data?.scheduleTime?._second_));

      this.editForm.patchValue({
        scheduleForLater: true,
        timeZone: this.dynamicDialogConfig?.data?.scheduleTimeZone || 'US/Eastern',
        date: setDate,
        time: setTime
      })
    }
  }

  save() {
    // call to backend will be here
    const questionText = this.editForm.controls['questionText'].value;
    const answerText = this.editForm.controls['answerText'].value;
    const scheduleTime = this.editForm.controls['scheduleForLater'].value ?
      this.gbpService.prepareDate(this.editForm.controls['date'].value, this.editForm.controls['scheduleTime'].value, this.editForm.controls['time'].value) : '';
    const scheduleTimeZone = this.editForm.controls['scheduleForLater'].value ? this.editForm.controls['timeZone'].value : '';
    const tempObj = { ...this.dynamicDialogConfig.data, questionText, answerText, scheduleTime, scheduleTimeZone, _status: 'AP' };
    console.log(tempObj);
    this.gbpService.updateBulkOwnerQa(tempObj);
    this.isSuccess = true;
  }

  public hendleScheduleLater(event){
    // this.isScheduleSwitchOn =
    console.log(event);
    this.isScheduleSwitchOn = event?.checked;
  }

  closeDialog() {
    this.ref.close();
  }
}
