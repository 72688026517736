<div class="success-wrapper">
  <div class="top-content">
    <h1>Success!</h1>
    <p *ngIf="publishObject.type == 'single'">Your response was published.</p>
    <p *ngIf="publishObject.type == 'multi'">
      Your response was published to all ({{ publishObject.data.length }}) selected reviews.
    </p>
  </div>
  <div class="buttons">
    <!-- button hidden until activity log functionality is updated -->
    <!-- <button
      pButton
      label="VIEW LOG"
      class="p-button-outlined p-button-text"
    ></button> -->
    <button pButton label="OK, THANKS" (click)="closeSuccessPopup()"></button>
  </div>
</div>
