import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SessionService } from '../backend-adapter/session.service';
import { isUserAdmin, isUserSingleAccounted } from './permissions.utils';

@Pipe({
  name: 'hasPerm',
})
export class HasPermPipe implements PipeTransform {
  private user$ = this.sessionService.getCurrentUser$();

  constructor(private sessionService: SessionService) {}

  transform(condition: 'isUserAdmin' | 'isUserSingleAccounted'): Observable<boolean> {
    switch (condition) {
      case 'isUserAdmin':
        return this.use(isUserAdmin);
      case 'isUserSingleAccounted':
        return this.use(isUserSingleAccounted);
    }
  }

  private use(cb) {
    return this.user$.pipe(switchMap((v) => of(cb(v))));
  }
}
