<div class="header-parent-title">
  <div class="header-child-title">Edit Post</div>
  <div class="header-child-title">.</div>
</div>

<div class="post-edit-wrapper" [ngSwitch]="this.rowData.topicType">
  <app-event-post *ngSwitchCase="'EVENT'" [rowData]="rowData"></app-event-post>
  <app-offer-post *ngSwitchCase="'OFFER'" [rowData]="rowData"></app-offer-post>
  <app-covid19-update-post *ngSwitchCase="'ALERT'" [rowData]="rowData"></app-covid19-update-post>
  <app-covid19-update-post *ngSwitchCase="'STANDARD'" [isStandard]="true" [rowData]="rowData"></app-covid19-update-post>
</div>
