<div class="error-page" id="afs-content">
  <div class="error-content">
    <img src="/assets/static/404.svg" />
    <h2 class="error-text">Page not found</h2>
    <div class="back-to-main-page flex-center">
      <button pButton type="button" class="p-button-primary" (click)="goBack()">Go back</button>

      <button pButton type="button" class="p-button-secondary" [routerLink]="['/dashboard']">Go home</button>
    </div>
  </div>
</div>
