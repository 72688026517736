import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UTM_TABS } from './utm-tabs.constants';
import { UTMService, providers as providersLabels } from './services/utm.service';
@Component({
  selector: 'app-utm-management',
  templateUrl: './utm-management.component.html',
  styleUrls: ['./utm-management.component.scss'],
  providers: [UTMService],
})
export class UtmManagementComponent implements OnInit, OnDestroy {
  utmTabs: typeof UTM_TABS = UTM_TABS;

  // tab menu
  tabItems: MenuItem[];

  activeItem: MenuItem;
  hasAccount: boolean = this.utmService.hasAccount();

  constructor(private utmService: UTMService) {
    this.utmService.providerCampaigns$.subscribe((providers) => {
      this.tabItems = Object.keys(providers).map((provider) => {
        // find the label for the providerLabels array
        const { id, label } = providersLabels.find((item) => item.id === provider);
        return {
          label: label,
          id: id.toLowerCase(),
          hash: id.toLowerCase(),
          command: (event) => {
            this.setActiveTab(event.item);
          },
        };
      });
    });
    this.activeItem = this.tabItems[0];
  }

  ngOnInit(): void {}

  // tab menu navigation
  setActiveTab(item) {
    this.activeItem = item;
  }

  ngOnDestroy(): void {}
}
