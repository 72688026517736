<div class="notifications">

  <div class="notifications_header">
    <p class="notifications_title">NOTIFICATION SETTINGS<span class="notifications_title">.</span></p>
    <div class="ack_btn">
      <p>Configure you're review notifications settings for {{ locationName }} locations</p>
      <button pButton label="SAVE" class="p-button-primary" (click)="save()"></button>
    </div>
  </div>

  <div class="card">
    <div class="card-block">
      <p class="title">Email notifications</p>
      <div class="email_notofications">
        <p class="summary">Receive email notifications when a new review is posted to any of your locations.</p>
        <p-multiSelect [options]="emailNotifications" [(ngModel)]="selectedEmailNotifications" optionLabel="name"
          display="chip" optionValue="value" [filter]="false" [showHeader]="false" [defaultLabel]="'Select'"
          [displaySelectedLabel]="selectedEmailNotifications.length"
          (onChange)="handleEmailNotificationsChange($event)">
        </p-multiSelect>
      </div>
    </div>

    <ng-container *ngIf="selectedEmailNotifications.length">
      <hr />

      <div class="card-block last">
        <div class="text">
          <p class="title">EMAIL FREQUENCY</p>
          <span class="messg">Please select at least one option to get notified.</span>
          <p class="title">Instant Notifications</p>
          <div class="email_notofications">
            <p class="summary">Receive an email notification each time a new review is published.</p>
            <p-inputSwitch [(ngModel)]="instantNotification.currentValue"
              (onChange)="handleInstantNotifications($event)"></p-inputSwitch>
          </div>
        </div>
      </div>

      <div class="card-block">
        <p class="title">Daily Digest</p>
        <div class="email_notofications">
          <p class="summary">Receive an email summary of new reviews published once per day.</p>
          <p-inputSwitch [(ngModel)]="dailyOpt.currentValue"
            (onChange)="handleDailyDigestUpdated($event)">
          </p-inputSwitch>
        </div>
        <div class="email-options" *ngIf="dailyOpt.currentValue">
          <p class="arrow">&#10551;</p>
          <p class="small-text">Send me an email every day at&nbsp;</p>
          <p-autoComplete [(ngModel)]="dailyOptTime.currentValue" [suggestions]="filteredHrs" [dropdown]="true"
            (completeMethod)="filterHrs($event)" (onSelect)="setDigestHrs($event, 'daily')"></p-autoComplete>
          <p-dropdown [style]="{ minWidth: '100%' }" [options]="timeZones" [group]="true" optionLabel="name"
            optionValue="value" placeholder="Time zone" [(ngModel)]="dailyOptTimeZone.currentValue"
            (onChange)="handleTimeZone($event, 'daily')">
          </p-dropdown>
        </div>
      </div>

      <div class="card-block">
        <p class="title">Weekly Digest</p>
        <div class="email_notofications">
          <p class="summary">Receive an email summary of new reviews published once per week.</p>
          <p-inputSwitch [(ngModel)]="weeklyOpt.currentValue" (onChange)="handleWeeklyUpdates($event)"></p-inputSwitch>
        </div>
        <div class="email-options" *ngIf="weeklyOpt.currentValue">
          <p class="arrow">&#10551;</p>
          <p class="small-text">Send me an email every&nbsp;</p>
          <p-dropdown [options]="weekDays" [(ngModel)]="weeklyOptDay.currentValue" (onChange)="handleWeekDayUpdates()"></p-dropdown>
          <p class="small-text">&nbsp;at&nbsp;</p>
          <p-autoComplete [(ngModel)]="weeklyOptTime.currentValue" [suggestions]="filteredHrs" [dropdown]="true"
            (completeMethod)="filterHrs($event)" (onSelect)="setDigestHrs($event, 'weekly')"></p-autoComplete>
          <p-dropdown [style]="{ minWidth: '100%' }" [options]="timeZones" [group]="true" optionLabel="name"
            optionValue="value" placeholder="Time zone" [(ngModel)]="weeklyOptTimeZone.currentValue"
            (onChange)="handleTimeZone($event, 'weekly')">
          </p-dropdown>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="card">
    <div class="card-block">
      <p class="title">Console notifications</p>
      <div class="email_notofications">
        <p class="summary">Receive notifications in console when reviews are posted to any of your locations.</p>
        <p-multiSelect [options]="emailNotifications" [(ngModel)]="selectedConsoleNotifications" optionLabel="name"
          display="chip" optionValue="value" [filter]="false" [showHeader]="false" [defaultLabel]="'Select'"
          [displaySelectedLabel]="selectedConsoleNotifications.length"
          (onChange)="handleConsoleNotificationsChange($event)">
        </p-multiSelect>
      </div>
    </div>
  </div>
</div>

