import { Component } from "@angular/core";
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})

export class NotificationPopupComponent {
  constructor(private dynamicDialogRef: DynamicDialogRef) { }


  cancel(): void {
    this.dynamicDialogRef.close(false);
  };

  save(): void {
    this.dynamicDialogRef.close(true)
  }
}
