import { Component } from '@angular/core';

@Component({
  selector: 'app-public-wrapper',
  template: `<router-outlet></router-outlet>`,
  styles: [
    `
      /* */
    `,
  ],
})
export class PublicWrapperComponent {}
