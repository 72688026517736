

<div class="upload-media-wrapper">
  <div class="media_wrapper" [class.width_half]="mediaRequirements.length" [class.width_full]="!mediaRequirements.length">
    <div *ngIf="isScroll && multiple" (click)="scroll(scrollTarget)" class="scroller">
      <i class="pi pi-arrow-down"></i>
    </div>
    <!-- Upload image -->
    <ng-container *ngIf="!onlyCustomURL">
      <div *ngIf="!selectedImagesUrl.length" #uploadContainerRef class="upload-container" (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)" (drop)="onDropSuccess($event)">
        <ng-container *ngIf="!isImageLoading">
          <img src="/assets/uploader-logos/uploadIconGreen.png" width="70px" />
          <p>Drag & Drop or <span (click)="chooseFile()" class="upload-link">Select From Computer</span></p>
        </ng-container>
        <p-progressSpinner *ngIf="isImageLoading" styleClass="w-2rem h-2rem" strokeWidth="2" fill="var(--surface-ground)"
          animationDuration="spinnerAnimationDuration">
        </p-progressSpinner>
      </div>
    </ng-container>

    <div class="upload-container" *ngIf="selectedImagesUrl.length">
      <i class="pi pi-times-circle close" (click)="cancelItem($event, 0)"></i>
      <img *ngIf="!isImageLoading && !isFileTypeVideo" [src]="selectedImagesUrl[0]" height="300px" />
      <video *ngIf="!isImageLoading && isFileTypeVideo" width="540" height="300" controls>
        <source [src]="selectedImagesUrl[0]" type="video/mp4">
      </video>
    </div>

    <!-- Custome URL -->
    <div *ngIf="selectedFiles.length === 0 || isCustomUrl" class="upload-url">
      <span class="upload-url-title">Media URL</span>
      <div class="media_url">
        <input class="media_url_input" type="text" pInputText [(ngModel)]="customURL" placeholder="Type Here..." />
        <button [disabled]="!customURL" class="media_url_cta" (click)="submitURL()" [class.disable]="!customURL">
          Submit URL
        </button>
      </div>
    </div>

    <ng-container *ngIf="errorLoadingImgMsg">
      <div class="error_msg">{{ errorLoadingImgMsg }}</div>
    </ng-container>

    <ng-container *ngIf="multiple && selectedFiles.length">
      <div *ngFor="let file of selectedFiles; let i = index" class="upload-item">
        <img [src]="selectedImagesUrl[i]" class="item-image" />
        <div class="item-title">
          <div class="item-title-name">{{ file.name }}</div>
          <div class="item-title-size">{{ file.size | formatFileSize: false }}</div>
        </div>
        <i class="pi pi-times-circle" (click)="cancelItem($event, i)"></i>
      </div>
    </ng-container>
    <br #scrollTarget />

    <p-fileUpload #fileInput name="demo[]" url="#" (onSelect)="onSelect($event)" [multiple]="multiple"
      accept="image/*, video/mp4" maxFileSize="75000000">
    </p-fileUpload>
  </div>

  <div class="requirements_wrapper" *ngIf="mediaRequirements.length">
    <b class="requirements_wrapper_title" *ngIf="mediaTitle">{{ mediaTitle }}</b>
    <ul *ngFor="let mediaRequirement of mediaRequirements">
      <li>{{mediaRequirement}}</li>
    </ul>
  </div>
</div>
