// tslint:disable: variable-name
import { Injectable } from '@angular/core';
import { ILoginData } from '../core/backend-adapter/session.interfaces';
import { StorageService, STORAGE_KEYS } from '../core/backend-adapter/storage.service';
import { IAccount } from './accounts.interfaces';

interface IMultiUsersRecentAccounts {
  [_id: string]: IAccount[];
}
@Injectable({ providedIn: 'root' })
export class RecentAccountsService {
  private ACCOUNTS_COUNT = 2;
  private recentAccounts: IAccount[] = [];
  private multipleUsersRecentAccountsObj: IMultiUsersRecentAccounts = {};

  constructor(public storageService: StorageService) {}

  public setRecentAccounts(a: IAccount, user: ILoginData): void {
    a._subAccountsToggle = false;
    let arr = [...this.recentAccounts];
    if (arr.length > this.ACCOUNTS_COUNT) {
      arr = arr.slice(0, this.ACCOUNTS_COUNT);
    }

    if (!arr.some((v) => a.ident === v.ident)) {
      arr.unshift(a);
    } else {
      arr = arr.filter((v) => a.ident !== v.ident);
      arr.unshift(a);
    }
    this.recentAccounts = [...arr];
    this.multipleUsersRecentAccountsObj[user._id] = this.recentAccounts;
    this.storageService.set(STORAGE_KEYS.recentAccounts, this.multipleUsersRecentAccountsObj);
  }

  public getRecentAccounts(user: ILoginData): IAccount[] {
    let recentAccountsObj = this.storageService.get(STORAGE_KEYS.recentAccounts) || {};

    recentAccountsObj = this.patchOldRecentObj(recentAccountsObj, user);

    if (recentAccountsObj) {
      this.multipleUsersRecentAccountsObj = recentAccountsObj;
      recentAccountsObj = recentAccountsObj[`${user?._id}`] ?? [];
      // debugger
      recentAccountsObj.forEach((v) => (v._subAccountsToggle = false));
    }
    this.recentAccounts = recentAccountsObj;

    return this.recentAccounts;
  }

  // INFO: needed for patching already existing LS data to object with users
  private patchOldRecentObj(
    recentAccountsObj: any | IMultiUsersRecentAccounts | IAccount[],
    user: ILoginData
  ): IMultiUsersRecentAccounts {
    const isOldImplementation = Array.isArray(recentAccountsObj);
    let multipleAccountsObj: IMultiUsersRecentAccounts;

    if (isOldImplementation) {
      multipleAccountsObj = { [user._id]: [] };
      this.storageService.set(STORAGE_KEYS.recentAccounts, multipleAccountsObj);
    }

    return multipleAccountsObj ? multipleAccountsObj : (recentAccountsObj as IMultiUsersRecentAccounts);
  }

  clearRecentAccounts(): void {
    this.storageService.remove(STORAGE_KEYS.recentAccounts);
    this.recentAccounts = [];
  }
}
