<div class="review-submit-wrapper">
  <div *ngIf="uploadGroup?.value?.imageUrl" class="review-submit-row">
    <div class="review-submit-title">Uploaded Photo</div>
    <div class="review-submit-text">
      <div class="review-submit-img">
        <img [src]="uploadGroup.value.imageUrl" height="300px" />
      </div>
    </div>
  </div>
  <div *ngIf="postDetailsGroup?.value?.description" class="review-submit-row">
    <div class="review-submit-title">Photo Description</div>
    <div class="review-submit-text">
      {{ postDetailsGroup.value.description }}
    </div>
  </div>
  <div *ngIf="postDetailsGroup?.value?.category" class="review-submit-row">
    <div class="review-submit-title">Photo Category</div>
    <div class="review-submit-text">
      {{ postDetailsGroup.value.category }}
    </div>
  </div>
  <div class="review-submit-row">
    <div class="review-submit-title">Photo Schedule On</div>
    <div *ngIf="!scheduleGroup.value.checked" class="review-submit-text">Now</div>
    <div *ngIf="scheduleGroup.value.checked" class="review-submit-text">
      {{ scheduleGroup.value.date | date: 'MM/dd/yyyy' }} | {{ scheduleGroup.value.time | date: 'shortTime' }}
      {{ scheduleGroup.value.timeZone }}
    </div>
  </div>
  <div class="review-submit-row">
    <div class="review-submit-title">Location</div>
    <div class="review-submit-text">
      <span class="review-submit-text-link"
        >{{ selectedLocationsCount }} Locations Selected</span
      >
    </div>
  </div>
</div>
