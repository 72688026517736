import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoticebarService {
  message$$ = new Subject<{ text; delay }>();

  message$() {
    return this.message$$.asObservable();
  }

  constructor() {}

  success(text: string, delay = 3000) {
    this.message$$.next({ text, delay });
  }
}
