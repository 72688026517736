import { Pipe, PipeTransform } from '@angular/core';
import { IAccount } from './../../../../acct-comps/accounts.interfaces';

@Pipe({
  name: 'getParentName',
})
export class GetParentNamePipe implements PipeTransform {
  transform(parentId: number, accounts: IAccount[]): string {
    const match = accounts.find((a) => a._id === parentId);
    return match?.name ?? 'No parent';
  }
}
