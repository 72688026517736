<div class="table-filter" novalidate>
  <div class="table-filter-chips">
    <p-chip
      *ngFor="let chipText of chipsValues"
      [label]="chipText"
      [removable]="true"
      (onRemove)="onChipRemove(chipText)"
    ></p-chip>
  </div>
  <div *ngFor="let item of data" class="table-filter-group">
    <div class="table-filter-title">{{ item.title }}</div>
    <div *ngFor="let option of item.options" class="field-checkbox">
      <div class="table-filter-checkbox-row">
        <p-checkbox
          [name]="item.title"
          [value]="option"
          [inputId]="option"
          (onChange)="onCheckboxChange(item.title, item.type)"
          [(ngModel)]="selected[item.title]"
        ></p-checkbox>
        <label [for]="option">{{ option }}</label>
      </div>
    </div>
    <div *ngIf="item.type == filterTypes.DATE" class="field-checkbox">
      <div class="table-filter-checkbox-row">
        <p-checkbox
          [binary]="true"
          inputId="binary"
          (onChange)="onCustomChange(item.title)"
          [(ngModel)]="isCustomDates"
        ></p-checkbox>
        <label for="binary">Custom</label>
      </div>
    </div>
    <div *ngIf="item.type == filterTypes.DATE && isCustomDates">
      <div class="table-filter-calendar">
        <p-calendar
        [showIcon]="true"
        dateFormat="mm/dd/yy"
        [(ngModel)]="dateValue['start']"
        (onSelect)="onCalendarSelect(item.title, $event)"
        (onShow)="openCustomDate($event)"
        (onClose)="closeCustomDate($event)"
        [readonlyInput]="true"
      ></p-calendar>
      <p-calendar
        [showIcon]="true"
        dateFormat="mm/dd/yy"
        [(ngModel)]="dateValue['end']"
        (onSelect)="onCalendarSelect(item.title, $event)"
        (onShow)="openCustomDate($event)"
        (onClose)="closeCustomDate($event)"
        [readonlyInput]="true"
      ></p-calendar>
      </div>
      <div *ngIf="isCustomDateCalendarOpen" class="custom_date_sbt">
        <button pButton type="button" label="Submit" class="p-button-primary" (click)="submitCustomDate()">
        </button>
      </div>
    </div>
  </div>
</div>
