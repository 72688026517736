import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermPipe } from './permissions.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HasPermPipe],
  exports: [HasPermPipe],
})
export class HasPermModule {}
