import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { ActivityStatusPipe } from './activity-status.pipe';

@NgModule({
  imports: [RSCommonModule],
  declarations: [ActivityStatusPipe],
  exports: [ActivityStatusPipe],
})
export class ActivityStatusModule {}
