<ng-container *ngIf="!isSuccess; else success">
  <div class="header-parent-title">
    <div class="header-child-title">Add Location</div>
    <div class="header-child-title">.</div>
  </div>
  <div class="qa-add-location-wrapper">
    <div class="qa-add-location-row">
      <div class="qa-add-location-title">*Location</div>
      <div class="qa-add-location-input">
        <p-multiSelect
          [options]="currentLocations"
          [(ngModel)]="location"
          optionLabel="businessName"
          appendTo="body"
          [selectedItemsLabel]="'{0} Locations selected'"
          placeholder="Select"
          [showToggleAll]="false"
          [maxSelectedLabels]="0"
          [filter]="false"
          [style]="{ minWidth: '100%' }"
          (onPanelHide)="onPanelHide()"
        >
          <ng-template pTemplate="header">
            <div class="p-inputgroup">
              <input
                type="text"
                pInputText
                placeholder="Search for location(s) by name, city, state..."
                [(ngModel)]="filterValue"
                (input)="onFilter($event)"
              />
            </div>
          </ng-template>

          <ng-template let-location pTemplate="item">
            <div class="location-item">
              <div>
                {{ location.storeCode }} - {{ location.businessName }} - {{ location.city }} - {{ location.state }}
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-paginator
              styleClass="multiselect-paginator"
              [rows]="paginatorRows"
              pageLinkSize="3"
              [totalRecords]="paginatorLength"
              [showCurrentPageReport]="true"
              [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} locations.'"
              (onPageChange)="onPageChange($event)"
            >
            </p-paginator>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="qa-add-location-row">
      <div class="qa-add-location-title"></div>
      <span
        (click)="openCurrentLocations()"
        pTooltip="View locations on which the Q&A has previously been posted to."
        tooltipPosition="right"
        class="qa-add-location-view-current"
        >VIEW CURRENT LOCATIONS</span
      >
    </div>
  </div>
  <div class="bottom-buttons">
    <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
    <button
      pButton
      label="PUBLISH"
      [disabled]="location.length == 0"
      (click)="saveLocation()"
      class="p-button-primary"
    ></button>
  </div>
</ng-container>
<ng-template #success>
  <div class="qa-add-location-success">
    <img src="assets/loc-add-edit-widget-logos/check.png" width="100px" height="100px" />
    <div class="header-parent-title">
      <div class="header-child-title">Success</div>
      <div class="header-child-title">!</div>
    </div>
  </div>
</ng-template>
