import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UTMService, CampaignField } from '../services/utm.service';
import { ILocation } from 'src/app/loc-comps/locations.interfaces';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-utm-tab',
  templateUrl: './utm-tab.component.html',
  styleUrls: ['./utm-tab.component.scss'],
  providers: [UTMService],
})
export class UtmTabComponent implements OnInit, OnDestroy {
  @Input() providerName: string; // 'gmb', 'bing', etc.
  @Input() providerLabel: string; // 'Google', 'Bing', etc.

  showUtmFieldChooser = false;
  campaignFieldsDefaults: Object;
  campaignFields: CampaignField[];
  selectedFieldId: string = '';
  sampleLocations: ILocation[] = [];
  sampleUtmLinks: Object[] = [];
  private refreshSubscription: Subscription;
  constructor(private utmDataService: UTMService) {}

  ngOnInit(): void {
    this.refreshSubscription = this.utmDataService.refreshNeeded.subscribe(() => {
      this.updateCampaignFields(this.providerName);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.providerName) {
      const currentProviderName = changes.providerName.currentValue;
      this.updateCampaignFields(currentProviderName);
    }
  }

  updateCampaignFields(providerName: string): void {
    // Retrieve the default fields
    this.campaignFieldsDefaults = this.utmDataService.getDefaultCampaignFields();
    this.campaignFields = this.utmDataService.getCampaignFieldsForProvider(providerName);
    this.sampleUtmLinks = this.utmDataService.getSampleUtmLinksForProvider(providerName);
  }

  // If the field values change within your component, you can update them in the service
  onFieldChange(fieldId: string, newValue: string): void {
    this.utmDataService.setCampaignFieldValues(this.providerName, { [fieldId]: newValue });
    this.updateCampaignFields(this.providerName);
  }

  public canSave(): boolean {
    return this.utmDataService.checkCanSave();
  }

  saveCampaign() {
    if (!this.canSave()) {
      return;
    }
    this.utmDataService.saveUTMCodes();
  }

  openFieldChooser(fieldId: string): void {
    this.selectedFieldId = fieldId;
    this.showUtmFieldChooser = true;
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
