<div class="header-parent-title">
  <div class="header-child-title">Create Question</div>
  <div class="header-child-title">.</div>
</div>
<app-progress #progress (stateChange)="onStateChange($event)" [stepsLabels]="stepsLabels">
  <app-progress-step [formGroup]="qAGroup">
    <div class="create-question-wrapper">
      <div class="create-question-row">
        <div class="create-question-title">Question</div>
        <div class="create-question-input">
          <textarea
            class="create-question-field"
            [rows]="3"
            placeholder="Type Here..."
            pInputTextarea
            autoResize="autoResize"
            formControlName="question"
            maxlength="245"
          ></textarea>
        </div>
      </div>
      <div class="create-question-row">
        <div class="create-question-title">Answer</div>
        <div class="create-question-input">
          <textarea
            class="create-question-field"
            [rows]="3"
            placeholder="Type Here..."
            pInputTextarea
            autoResize="autoResize"
            formControlName="answer"
            maxlength="980"
          ></textarea>
        </div>
      </div>

      <div class="create-question-row">
        <div class="create-question-title">Language</div>
        <div class="create-question-input">
          <p-dropdown
            [options]="languages"
            appendTo="body"
            formControlName="languageCode"
            placeholder="Select Language..."
          ></p-dropdown>
        </div>
      </div>
    </div>
  </app-progress-step>
  <app-progress-step [formGroup]="scheduleGroup">
    <div class="create-question-wrapper">
      <div class="create-question-row">
        <div class="create-question-title">*Location</div>
        <div class="create-question-input">
          <p-multiSelect
            [options]="currentLocations"
            formControlName="location"
            optionLabel="businessName"
            appendTo="body"
            [selectedItemsLabel]="'{0} Locations selected'"
            placeholder="Select"
            [showToggleAll]="true"
            [maxSelectedLabels]="0"
            [filter]="false"
            [style]="{ minWidth: '100%' }"
            (onPanelHide)="onPanelHide()"
          >
            <ng-template pTemplate="header">
              <div class="p-inputgroup">
                <input
                  type="text"
                  pInputText
                  placeholder="Search by store code, city, state, tag..."
                  [(ngModel)]="filterValue"
                  [ngModelOptions]="{ standalone: true }"
                  (input)="onFilter($event)"
                />
              </div>
              <div class="select-filtered-label">Select {{ filtered.length }} locations</div>
            </ng-template>

            <ng-template let-location pTemplate="item">
              <div class="location-item">
                <div>
                  {{ location[1] }} - {{ location[2] }} - {{ location[3] }}, {{ location[4] }}
                </div>
              </div>
            </ng-template>

<!--            <ng-template pTemplate="footer">-->
<!--              <p-paginator-->
<!--                styleClass="multiselect-paginator"-->
<!--                [rows]="paginatorRows"-->
<!--                pageLinkSize="3"-->
<!--                [totalRecords]="paginatorLength"-->
<!--                [showCurrentPageReport]="true"-->
<!--                [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} locations.'"-->
<!--                (onPageChange)="onPageChange($event)"-->
<!--              >-->
<!--              </p-paginator>-->
<!--            </ng-template>-->
          </p-multiSelect>
        </div>
      </div>
      <div class="create-question-row">
        <div class="create-question-title"></div>
        <div class="create-question-input">
          <div class="create-question-schedule">
            <div class="create-question-select-all">
              <p-checkbox formControlName="isSelectedAll" [binary]="true" inputId="binary"></p-checkbox>
              <span
                >Select all
                <b>({{ scheduleGroup.controls.isSelectedAll.value ? selectedLocationsLength : locations.length }})</b>
                locations</span
              >
              <span
                class="material-icons-outlined"
                [pTooltip]="'Use the search tool to select/unselect specific location(s)'"
                [tooltipPosition]="'bottom'"
              >
                info
              </span>
            </div>
            <span class="create-question-scheduleSwitch">
              <span class="create-question-schedule-label">Schedule For Later</span>
              <p-inputSwitch class="switch" formControlName="isScheduled"></p-inputSwitch
            ></span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="scheduleGroup.value?.isScheduled">
        <div class="create-question-row">
          <div class="create-question-title">*Date</div>
          <div class="create-question-input">
            <p-calendar
              [style]="{ minWidth: '100%' }"
              formControlName="date"
              [showIcon]="true"
              inputId="icon"
              appendTo="body"
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
            ></p-calendar>
          </div>
        </div>
        <div class="create-question-row">
          <div class="create-question-title">*Time</div>
          <div class="create-question-input">
            <p-calendar
              [style]="{ minWidth: '100%' }"
              formControlName="time"
              [timeOnly]="true"
              [hourFormat]="12"
              inputId="timeonly"
              appendTo="body"
              placeholder="00:00 PM"
            ></p-calendar>
          </div>
        </div>
        <div class="create-question-row">
          <div class="create-question-title">*Time Zone</div>
          <div class="create-question-input">
            <p-dropdown
              [style]="{ minWidth: '100%' }"
              [options]="timeZones"
              [group]="true"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
              formControlName="timeZone"
              placeholder="Select"
            ></p-dropdown>
          </div>
        </div>
      </ng-container>
    </div>
  </app-progress-step>
  <app-progress-step>
    <div class="create-question-wrapper">
      <div class="create-question-row">
        <div class="create-question-title-bold">Question</div>
        <div class="create-question-text">
          {{ qAGroup.value.question }}
        </div>
      </div>
      <div class="create-question-row">
        <div class="create-question-title-bold">Answer</div>
        <div class="create-question-text">
          {{ qAGroup.value.answer }}
        </div>
      </div>
      <div class="create-question-row">
        <div class="create-question-title-bold">Locations</div>
        <div class="create-question-text">
          <span class="create-question-text-link"
            >{{ scheduleGroup.value.location.length }} Locations Selected</span
          >
        </div>
      </div>
      <div *ngIf="scheduleGroup.value?.date" class="create-question-row">
        <div class="create-question-title-bold">Post On</div>
        <div class="create-question-text">
          {{ scheduleGroup.value.date | date: 'MM/dd/yyyy' }} | {{ scheduleGroup.value.time | date: 'shortTime' }}
          {{ scheduleGroup.value.timeZone }}
        </div>
      </div>
    </div>
  </app-progress-step>
</app-progress>

<div class="bottom-buttons">
  <button *ngIf="activeIndex == 0" pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
  <button *ngIf="activeIndex > 0" pButton label="BACK" class="p-button-outlined" progressStepPrev></button>
  <button
    pButton
    [label]="buttonLabel"
    class="p-button-primary"
    [disabled]="!qaForm.controls[formGroupName].valid"
    (click)="goNext(progress)"
  ></button>
</div>
