import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class ViewGraphReportsService {

  private readonly graphConfigs = {
    type: 'bar',
    barThickness: 30,
    maxBarThickness: 60,
  };


  private mapGggoleStringsToNumber(data) {
    return data.map(el => {
      return {
        viewsMap: Number.isNaN(+el?.views__maps) ? 0 : (+el?.views__maps),
        viewsSearch: Number.isNaN(+el?.views__search) ? 0 : (+el?.views__search),
        actionsPhone: Number.isNaN(+el?.actions__phone) ? 0 : (+el?.actions__phone),
        actionsDir: Number.isNaN(+el?.actions__directions) ? 0 : (+el?.actions__directions),
        viewsMapsDesktop: Number.isNaN(+el?.views__maps__desktop) ? 0 : (+el?.views__maps__desktop),
        viewsMapsMobile: Number.isNaN(+el?.views__maps__mobile) ? 0 : (+el?.views__maps__mobile),
        viewsSearchDesktop: Number.isNaN(+el?.views__search__desktop) ? 0 : (+el?.views__search__desktop),
        viewsSearchMobile: Number.isNaN(+el?.views__search__mobile) ? 0 : (+el?.views__search__mobile),
        conversations: Number.isNaN(+el?.conversations) ? 0 : (+el?.conversations),
        bookings: Number.isNaN(+el?.bookings) ? 0 : (+el?.bookings),
        foodOrders: Number.isNaN(+el?.foodOrders) ? 0 : (+el?.foodOrders),
        foodMenu: Number.isNaN(+el.actions__food_menu) ? 0 : (+el?.actions__food_menu),
        actionsWeb: Number.isNaN(+el.actions__website_clicks) ? 0 : (+el?.actions__website_clicks),
        startDate: el.period_start_date
      };
    });
  };

  private removeDuplicates(data) {
    const mapedData = new Map();
    const mapedArray = [];
    data.forEach(d => {
      !mapedData.has(d.startDate) ?
        mapedData.set(d.startDate, d) :
        mapedData.set(d.startDate,
          {
            ...mapedData.get(d.startDate),
            viewsMap: mapedData.get(d.startDate).viewsMap + d.viewsMap,
            viewsSearch: mapedData.get(d.startDate).viewsSearch + d.viewsSearch,
            actionsPhone: mapedData.get(d.startDate).actionsPhone + d.actionsPhone,
            actionsDir: mapedData.get(d.startDate).actionsDir + d.actionsDir,
            viewsMapsDesktop: mapedData.get(d.startDate).viewsMapsDesktop + d.viewsMapsDesktop,
            viewsMapsMobile: mapedData.get(d.startDate).viewsMapsMobile + d.viewsMapsMobile,
            viewsSearchDesktop: mapedData.get(d.startDate).viewsSearchDesktop + d.viewsSearchDesktop,
            viewsSearchMobile: mapedData.get(d.startDate).viewsSearchMobile + d.viewsSearchMobile,
            conversations: mapedData.get(d.startDate).conversations + d.conversations,
            bookings: mapedData.get(d.startDate).bookings + d.bookings,
            foodOrders: mapedData.get(d.startDate).foodOrders + d.foodOrders,
            foodMenu: mapedData.get(d.startDate).foodMenu + d.foodMenu,
            actionsWeb: mapedData.get(d.startDate).actionsWeb + d.actionsWeb,
          }
        );
    });

    for (const [key, value] of mapedData) {
      if (key) {
        mapedArray.push(value);
      }
    };
    return mapedArray;
  }

  public mapGoogleInsightsData(graphData, dateLabels: string[], timePeriod: string) {
    let viewsMap: number[] = [];
    let viewsSearch: number[] = [];
    let actionsPhone: number[] = [];
    let actionsDir: number[] = [];
    let viewsMapsDesktop: number[] = [];
    let viewsMapsMobile: number[] = [];
    let viewsSearchDesktop: number[] = [];
    let viewsSearchMobile: number[] = [];
    let conversations: number[] = [];
    let bookings: number[] = [];
    let foodOrders: number[] = [];
    let foodMenu: number[] = [];
    let actionsWeb: number[] = [];
    let labels: string[] = [];

    let isGraphSingleBar: boolean;
    isGraphSingleBar = (
      timePeriod === 'day_previous' ||
      timePeriod === 'week_current' ||
      timePeriod === 'week_previous' ||
      timePeriod === 'month_current' ||
      timePeriod === 'month_previous'
    ) ? true : false;
    const newArr = isGraphSingleBar ? this.mapGggoleStringsToNumber(graphData) : this.removeDuplicates(this.mapGggoleStringsToNumber(graphData));

    newArr.forEach(el => {
      viewsMap.push(el.viewsMap);
      viewsSearch.push(el.viewsSearch);
      actionsPhone.push(el.actionsPhone);
      actionsDir.push(el.actionsDir);
      viewsMapsDesktop.push(el.viewsMapsDesktop);
      viewsMapsMobile.push(el.viewsMapsMobile);
      viewsSearchDesktop.push(el.viewsSearchDesktop);
      viewsSearchMobile.push(el.viewsSearchMobile);
      conversations.push(el.conversations);
      bookings.push(el.bookings);
      foodOrders.push(el.foodOrders);
      foodMenu.push(el.foodMenu);
      actionsWeb.push(el.actionsWeb);
      labels.push(el.startDate);
    });

    return [
      {
        id: 'searchCard',
        title: 'Search Clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Website',
              backgroundColor: '#791e6c',
              data: actionsWeb,
              ...this.graphConfigs
            },
            {
              ...this.graphConfigs,
              label: 'Phone',
              backgroundColor: '#d51ba0',
              data: actionsPhone,
            },
            {
              ...this.graphConfigs,
              label: 'Directions',
              backgroundColor: '#fa6bc1',
              data: actionsDir,
            },
          ],
        }
      },
      {
        id: 'viewsSearchViewsMaps',
        title: 'Views',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Map',
              backgroundColor: '#003a6d',
              data: viewsMap,
              ...this.graphConfigs
            },
            {
              label: 'Search',
              backgroundColor: '#007eff',
              data: viewsSearch,
              ...this.graphConfigs
            },
          ]
        }
      },
      {
        id: 'totalImpressions',
        title: 'Total Impressions',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Desktop Maps',
              backgroundColor: '#206A02',
              data: viewsMapsDesktop,
              ...this.graphConfigs
            },
            {
              label: 'Mobile Maps',
              backgroundColor: '#609c51',
              data: viewsMapsMobile,
              ...this.graphConfigs
            },
            {
              label: 'Desktop Search',
              backgroundColor: '#266317',
              data: viewsSearchDesktop,
              ...this.graphConfigs
            },
            {
              label: 'Mobile Search',
              backgroundColor: '#64f241',
              data: viewsSearchMobile,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'businessConversations',
        title: 'Business Conversations',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Conversations',
              backgroundColor: '#491d8b',
              data: conversations,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'bookings',
        title: 'Bookings',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Bookings',
              backgroundColor: '#e8e52e',
              data: bookings,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'foodOrders',
        title: 'Food Orders',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Food Orders',
              backgroundColor: '#e85325',
              data: foodOrders,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'foodMenuClicks',
        title: 'Food Menu Clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Food Orders',
              backgroundColor: '#e85325',
              data: foodMenu,
              ...this.graphConfigs
            },
          ],
        }
      },
    ];
  }

  private mapAppleStringsToNumber(data) {
    return data.map(el => {
      return {
        nameSearch: Number.isNaN(+el?.search_clicks__name_search) ? 0 : (+el?.search_clicks__name_search),
        categorySearch: Number.isNaN(+el?.search_clicks__category_search) ? 0 : (+el?.search_clicks__category_search),
        otherSearch: Number.isNaN(+el?.search_clicks__other_search) ? 0 : (+el?.search_clicks__other_search),
        placeCardViews: Number.isNaN(+el?.placecard_views) ? 0 : (+el?.placecard_views),
        directions: Number.isNaN(+el?.placecard_actions__directions) ? 0 : (+el?.placecard_actions__directions),
        websiteClicks: Number.isNaN(+el?.placecard_actions__website_clicks) ? 0 : (+el?.placecard_actions__website_clicks),
        calls: Number.isNaN(+el?.placecard_actions__calls) ? 0 : (+el?.placecard_actions__calls),
        shares: Number.isNaN(+el?.placecard_actions__shares) ? 0 : (+el?.placecard_actions__shares),
        showcaseClicks: Number.isNaN(+el?.placecard_actions__showcase_clicks) ? 0 : (+el?.placecard_actions__showcase_clicks),
        appointments: Number.isNaN(+el?.placecard_actions__appointments) ? 0 : (+el?.placecard_actions__appointments),
        orders: Number.isNaN(+el?.placecard_actions__orders) ? 0 : (+el?.placecard_actions__orders),
        deliveryOrders: Number.isNaN(+el?.placecard_actions__delivery_orders) ? 0 : (+el?.placecard_actions__delivery_orders),
        foodOrders: Number.isNaN(+el?.placecard_actions__food_orders) ? 0 : (+el?.placecard_actions__food_orders),
        takeoutOrders: Number.isNaN(+el?.placecard_actions__takeout_orders) ? 0 : (+el?.placecard_actions__takeout_orders),
        pickupOrders: Number.isNaN(+el?.placecard_actions__pickup) ? 0 : (+el?.placecard_actions__pickup),
        reserveParking: Number.isNaN(+el?.placecard_actions__reserve_parking) ? 0 : (+el?.placecard_actions__reserve_parking),
        waitlist: Number.isNaN(+el?.placecard_actions__join_waitlist) ? 0 : (+el?.placecard_actions__join_waitlist),
        reserveTable: Number.isNaN(+el?.placecard_actions__reserve_table) ? 0 : (+el?.placecard_actions__reserve_table),
        startDate: el.period_start_date
      };
    });
  };

  private removeAppleDuplicates(data) {
    const mapedData = new Map();
    const mapedArray = [];
    data.forEach(d => {
      !mapedData.has(d.startDate) ?
        mapedData.set(d.startDate, d) :
        mapedData.set(d.startDate,
          {
            ...mapedData.get(d.startDate),
            nameSearch: mapedData.get(d.startDate).nameSearch + d.nameSearch,
            categorySearch: mapedData.get(d.startDate).categorySearch + d.categorySearch,
            otherSearch: mapedData.get(d.startDate).otherSearch + d.otherSearch,
            placeCardViews: mapedData.get(d.startDate).placeCardViews + d.placeCardViews,
            directions: mapedData.get(d.startDate).directions + d.directions,
            websiteClicks: mapedData.get(d.startDate).websiteClicks + d.websiteClicks,
            calls: mapedData.get(d.startDate).calls + d.calls,
            shares: mapedData.get(d.startDate).shares + d.shares,
            showcaseClicks: mapedData.get(d.startDate).showcaseClicks + d.showcaseClicks,
            appointments: mapedData.get(d.startDate).appointments + d.appointments,
            orders: mapedData.get(d.startDate).orders + d.orders,
            deliveryOrders: mapedData.get(d.startDate).deliveryOrders + d.deliveryOrders,
            foodOrders: mapedData.get(d.startDate).foodOrders + d.foodOrders,
            takeoutOrders: mapedData.get(d.startDate).takeoutOrders + d.takeoutOrders,
            pickupOrders: mapedData.get(d.startDate).pickupOrders + d.pickupOrders,
            reserveParking: mapedData.get(d.startDate).reserveParking + d.reserveParking,
            waitlist: mapedData.get(d.startDate).waitlist + d.waitlist,
            reserveTable: mapedData.get(d.startDate).reserveTable + d.reserveTable,
          }
        );
    });

    for (const [key, value] of mapedData) {
      if (key) {
        mapedArray.push(value);
      }
    };
    return mapedArray;
  }

  mapAppleInsightsData(graphData, dateLabels: string[], timePeriod: string) {
    let nameSearch: number[] = [];
    let categorySearch: number[] = [];
    let otherSearch: number[] = [];
    let placeCardViews: number[] = [];
    let directions: number[] = [];
    let websiteClicks: number[] = [];
    let calls: number[] = [];
    let shares: number[] = [];
    let showcaseClicks: number[] = [];
    let appointments: number[] = [];
    let menuCard: number[] = [];
    let orders: number[] = [];
    let deliveryOrders: number[] = [];
    let foodOrders: number[] = [];
    let takeoutOrders: number[] = [];
    let pickupOrders: number[] = [];
    let reserveParking: number[] = [];
    let waitlist: number[] = [];
    let reserveTable: number[] = [];
    let labels: string[] = [];


    let isGraphSingleBar: boolean;
    isGraphSingleBar = (
      timePeriod === 'day_previous' ||
      timePeriod === 'week_current' ||
      timePeriod === 'week_previous' ||
      timePeriod === 'month_current' ||
      timePeriod === 'month_previous'
    ) ? true : false;
    const newArr = isGraphSingleBar ? this.mapAppleStringsToNumber(graphData) : this.removeAppleDuplicates(this.mapAppleStringsToNumber(graphData));


    newArr?.forEach(el => {
      nameSearch.push(el.nameSearch),
        categorySearch.push(el.categorySearch),
        otherSearch.push(el.otherSearch),
        placeCardViews.push(el.placeCardViews),
        directions.push(el.directions),
        websiteClicks.push(el.websiteClicks),
        calls.push(el.calls),
        shares.push(el.shares),
        showcaseClicks.push(el.showcaseClicks),
        appointments.push(el.appointments),
        orders.push(el.orders),
        foodOrders.push(el.foodOrders),
        takeoutOrders.push(el.takeoutOrders),
        pickupOrders.push(el.pickupOrders),
        reserveParking.push(el.reserveParking),
        waitlist.push(el.waitlist),
        reserveTable.push(el.reserveTable),
        labels.push(el.startDate);
    })

    return [
      {
        id: 'searchCard',
        title: 'Search Clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Name search',
              backgroundColor: '#791e6c',
              data: nameSearch,
              ...this.graphConfigs
            },
            {
              label: 'Category search',
              backgroundColor: '#d51ba0',
              data: categorySearch,
              ...this.graphConfigs
            },
            {
              label: 'Other',
              backgroundColor: '#fa6bc1',
              data: otherSearch,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'views',
        title: 'Views',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Views',
              backgroundColor: '#491d8b',
              data: placeCardViews,
              ...this.graphConfigs
            }
          ],
        }
      },
      {
        id: 'directions',
        title: 'Directions',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Directions',
              backgroundColor: '#F68A8A',
              data: directions,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'websiteClicks',
        title: 'Website clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Website clicks',
              backgroundColor: '#e8e52e',
              data: websiteClicks,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'calls',
        title: 'Calls',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Calls',
              backgroundColor: '#6e44ad',
              data: calls,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'shares',
        title: 'Shares',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Shares',
              backgroundColor: '#8a3ffc',
              data: shares,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'showCaseClicks',
        title: 'Showcase Clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Show case',
              backgroundColor: '#651e7d',
              data: showcaseClicks,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'appointments',
        title: 'Appointments',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Appointments',
              backgroundColor: '#cf44be',
              data: appointments,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'menuCard',
        title: 'Menu',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Menu',
              backgroundColor: '#d1548c',
              data: menuCard,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'ordering',
        title: 'Ordering',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Order',
              backgroundColor: '#206A02',
              data: orders,
              ...this.graphConfigs
            },
            {
              label: 'Delivery',
              backgroundColor: '#609c51',
              data: deliveryOrders,
              ...this.graphConfigs
            },
            {
              label: 'Order food',
              backgroundColor: '#266317',
              data: foodOrders,
              ...this.graphConfigs
            },
            {
              label: 'Order takeout',
              backgroundColor: '#64f241',
              data: takeoutOrders,
              ...this.graphConfigs
            },
            {
              label: 'Order pickup',
              backgroundColor: '#317d1e',
              data: pickupOrders,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'reservations',
        title: 'Reservations',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Reserve parking',
              backgroundColor: '#abdadb',
              data: reserveParking,
              ...this.graphConfigs
            },
            {
              label: 'Reserve table',
              backgroundColor: '#4b797a',
              data: reserveTable,
              ...this.graphConfigs
            },
            {
              label: 'Join waitlist',
              backgroundColor: '#1cc6c9',
              data: waitlist,
              ...this.graphConfigs
            },
          ],
        }
      }
    ];
  }

  private mapReviewsInsightsStringsToNumber(data) {
    console.log(data);
    return data.map(el => {
      return {
        avgRating: Number.isNaN(+el?.average_rating) ? 0 : (+el?.average_rating),
        avgRatingChange: Number.isNaN(+el?.average_rating_change) ? 0 : (+el?.average_rating_change),
        totalNegativeRating: Number.isNaN(+el?.total_negative_ratings) ? 0 : (+el?.total_negative_ratings),
        totalNeutralRating: Number.isNaN(+el?.total_neutral_ratings) ? 0 : (+el?.total_neutral_ratings),
        totalPositiveRating: Number.isNaN(+el?.total_positive_ratings) ? 0 : (+el?.total_positive_ratings),
        totalRating: Number.isNaN(+el?.total_ratings) ? 0 : (+el?.total_ratings),
        startDate: el.period_start_date
      };
    });
  };

  private removeReviewsInsightsAppleDuplicates(data) {
    const mapedData = new Map();
    const mapedArray = [];
    data.forEach(d => {
      !mapedData.has(d.startDate) ?
        mapedData.set(d.startDate, d) :
        mapedData.set(d.startDate,
          {
            ...mapedData.get(d.startDate),
            avgRating: mapedData.get(d.startDate).avgRating + d.avgRating,
            avgRatingChange: mapedData.get(d.startDate).avgRatingChange + d.avgRatingChange,
            totalNegativeRating: mapedData.get(d.startDate).totalNegativeRating + d.totalNegativeRating,
            totalNeutralRating: mapedData.get(d.startDate).totalNeutralRating + d.totalNeutralRating,
            totalPositiveRating: mapedData.get(d.startDate).totalPositiveRating + d.totalPositiveRating,
            totalRating: mapedData.get(d.startDate).totalRating + d.totalRating,
          }
        );
    });

    for (const [key, value] of mapedData) {
      if (key) {
        mapedArray.push(value);
      }
    };
    return mapedArray;
  }



  public mapReviewsInsights(graphData, dateLabels: string[], timePeriod: string) {
    let avgRating: number[] = [];
    let avgRatingChange: number[] = [];
    let totalNegativeRating: number[] = [];
    let totalNeutralRating: number[] = [];
    let totalPositiveRating: number[] = [];
    let totalRating: number[] = [];
    let labels: string[] = [];

    let isGraphSingleBar: boolean;
    isGraphSingleBar = (
      timePeriod === 'day_previous' ||
      timePeriod === 'week_current' ||
      timePeriod === 'week_previous' ||
      timePeriod === 'month_current' ||
      timePeriod === 'month_previous'
    ) ? true : false;
    const newArr = isGraphSingleBar ? this.mapReviewsInsightsStringsToNumber(graphData) : this.removeReviewsInsightsAppleDuplicates(this.mapReviewsInsightsStringsToNumber(graphData));

    newArr.forEach(el => {
      avgRating.push(el.avgRating),
        avgRatingChange.push(el.avgRatingChange);
      totalNegativeRating.push(el.totalNegativeRating);
      totalNeutralRating.push(el.totalNeutralRating);
      totalPositiveRating.push(el.totalPositiveRating);
      totalRating.push(el.totalRating),
        labels.push(el.startDate);
    });

    return [
      {
        id: 'avgRating',
        title: 'Average Ratings',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Average Ratings',
              backgroundColor: '#d1548c',
              data: avgRating,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'avgRatingChange',
        title: 'Average Rating Change',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Average Rating Change',
              backgroundColor: '#64f241',
              data: avgRatingChange,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'totalNegativeRating',
        title: 'Total Negative Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Negative Rating',
              backgroundColor: '#317d1e',
              data: totalNegativeRating,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'totalNeutralRating',
        title: 'Total Neutral Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Neutral Rating',
              backgroundColor: '#abdadb',
              data: totalNeutralRating,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'totalPositiveRating',
        title: 'Total Positive Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Positive Rating',
              backgroundColor: '#4b797a',
              data: totalPositiveRating,
              ...this.graphConfigs
            },
          ],
        }
      },
      {
        id: 'totalRating',
        title: 'Total Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Rating',
              backgroundColor: '#1cc6c9',
              data: totalRating,
              ...this.graphConfigs
            },
          ],
        }
      },
    ]

  }

}
