<div class="palette-container m-l-20">
  <h1>Project current style palette</h1>
  <button (click)="changeTheme(RsThemes.default)" pButton label="default"></button>
  <button (click)="changeTheme(RsThemes.whitelabeled)" pButton label="whitelabel"></button>
  <button (click)="changeTheme(RsThemes.custom)" pButton label="custom"></button>

  <h2>Texts</h2>
  <section class="colors-container">
    <section class="texts-section">
      <h2>Available text classes</h2>
      <span class="rs-h1"><code>rs-h1</code>H1 text</span>
      <span class="rs-h2"><code>rs-h2</code>H2 text</span>
      <span class="rs-sub-h1"><code>rs-sub-h1</code>Sub H2 text</span>
      <span class="rs-sub-h2"><code>rs-sub-h2</code>Sub H2 text</span>
      <span class="rs-text"><code>rs-text</code>Regular text</span>
      <span class="rs-text-24"><code>rs-text-24</code>Regular text</span>
      <span class="rs-text-18 c-primary-l-10"><code>rs-text-18 c-primary-l-10</code>Regular text</span>
      <span class="rs-text bold c-primary-d-10"><code>rs-text bold c-primary-d-10</code>Regular text bold</span>
      <span class="rs-text italic"><code>rs-text italic</code> Regular text italic</span>

      <h2>Colors</h2>

      <span class="c-primary rs-text-24 bold"><code>c-primary</code>Primary</span>
      <span class="c-secondary rs-text-24 bold"><code>c-secondary</code>Secondary</span>
      <span class="c-primary-d-100 rs-text-24 bold"
        ><code>c-primary-d-100 rs-text-24 bold</code>Primary darken 100%</span
      >
      <span class="c-primary-l-100 rs-text-24 bold"
        ><code>c-primary-l-100 rs-text-24 bold</code>Primary lighten 100%</span
      >
    </section>
    <section class="colors-section">
      <h2>Available colors palette</h2>
      <h3>Primary lighten</h3>
      <div class="palette-row">
        <div
          class="square"
          *ngFor="let i of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          [ngClass]="'bgc-primary-l-' + i"
        >
          {{ i }}
        </div>
      </div>

      <h3>Primary darken</h3>
      <div class="palette-row">
        <div
          class="square"
          *ngFor="let i of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          [ngClass]="'bgc-primary-d-' + i"
        >
          {{ i }}
        </div>
      </div>

      <h3>Secondary lighten</h3>
      <div class="palette-row">
        <div
          class="square"
          *ngFor="let i of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          [ngClass]="'bgc-secondary-l-' + i"
        >
          {{ i }}
        </div>
      </div>

      <h3>Secondary darken</h3>
      <div class="palette-row">
        <div
          class="square"
          *ngFor="let i of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          [ngClass]="'bgc-secondary-d-' + i"
        >
          {{ i }}
        </div>
      </div>
    </section>
  </section>
  <!-- <section>
    <h2>Notify toasts</h2>
    <button (click)="notifyService.success('Yoooohooo!!')" pButton label="success" class="p-button-primary"></button>
    <button (click)="notifyService.info('FYI...')" pButton label="info" class="p-button-info"></button>
    <button (click)="notifyService.warning('OOps!!')" pButton label="warning" class="p-button-warning"></button>
    <button (click)="notifyService.error('Hey! Error!!')" pButton label="error" class="p-button-danger"></button>
    <button
      (click)="notifyService.success('newerending', 30000)"
      pButton
      label="newerending"
      class="p-button-primary"
    ></button>
  </section>

  <section>
    <h2>Buttons</h2>
    <button pButton label="primary with icon" class="p-button-primary" icon="pi pi-plus-circle"></button>
    <button pButton label="primary without icon" class="p-button-primary"></button>
    <button pButton label="secondary" class="p-button-secondary"></button>
  </section>

  <section>
    <h2>Dialogs</h2>
    <button pButton label="show dialog" class="p-button-secondary"></button>
  </section>
  <section>
    <h2>Tooltips</h2>
    <i
      class="pi pi-question-circle c-p"
      tooltipStyleClass="appFixTooltipWidth"
      appFixTooltipWidth
      [pTooltip]="
        'some hovered text with a lot of words and of words and some hoveredsome hovered  of words and some hoveredsome hovered  of words and some hoveredsome hovered  some hoveredsome hovered text with a lot of words and some hoveredsome hovered text with a lot of words and some hovered'
      "
    ></i>
  </section> -->
</div>
