<ng-container *ngIf="!scheduleSuccess && !isPublished; else success">
  <div class="header-parent-title">
    <div class="header-child-title">Select Locations and Schedule</div>
    <div class="header-child-title">.</div>
  </div>

  <div class="gbp-photo-select-locations-wrapper">
    <ng-container *ngIf="!isScheduled; else scheduleTemp">
      <span>Location</span>
      <app-gbp-select-location-table class="select-locations-table"></app-gbp-select-location-table>
    </ng-container>
    <ng-template #scheduleTemp>
      <div class="schedule-main">
        <div class="schedule-row">
          <div class="grid-item">Date</div>
          <div class="grid-item">
            <p-calendar
              [style]="{ minWidth: '100%' }"
              [(ngModel)]="formValue['date']"
              [showIcon]="true"
              inputId="icon"
              placeholder="mm/dd/yyyy"
            ></p-calendar>
          </div>
        </div>
        <div class="schedule-row">
          <div class="grid-item">Time</div>
          <div class="grid-item">
            <p-calendar
              [style]="{ minWidth: '100%' }"
              [(ngModel)]="formValue['time']"
              [timeOnly]="true"
              [hourFormat]="12"
              inputId="timeonly"
              placeholder="00:00 PM"
            ></p-calendar>
          </div>
        </div>
        <div class="schedule-row">
          <div class="grid-item">Time Zone</div>
          <div class="grid-item">
            <p-dropdown
              [style]="{ minWidth: '100%' }"
              [options]="timeZones"
              [group]="true"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
              placeholder="Select"
              [(ngModel)]="formValue['timeZone']"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="bottom-buttons">
    <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
    <button
      *ngIf="!isScheduled"
      pButton
      label="SAVE AND SCHEDULE"
      class="p-button-primary"
      (click)="isScheduled = true"
    ></button>
    <button *ngIf="isScheduled" pButton label="SCHEDULE" class="p-button-primary" (click)="schedule()"></button>
    <button *ngIf="!isScheduled" pButton label="PUBLISH NOW" class="p-button-primary" (click)="publishNow()"></button>
  </div>
</ng-container>

<ng-template #success>
  <div class="success-wrapper">
    <img src="./../../../../../assets/loc-add-edit-widget-logos/check.png" width="100px" height="100px" />
    <div class="header-parent-title">
      <div class="header-child-title">Success</div>
      <div class="header-child-title">!</div>
    </div>
    <div>Photo has been {{ isPublished ? 'published' : 'scheduled' }}. Do you want to optimize it?</div>
    <div class="bottom-buttons">
      <button pButton label="LATER" class="p-button-outlined" (click)="closeDialog()"></button>
      <button pButton label="OPTIMIZE" class="p-button-primary" (click)="optimizeNow()"></button>
    </div>
  </div>
</ng-template>
