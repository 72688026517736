import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { REPORTS_TABS } from './reports.constants';
import { SessionService } from '@app/core/backend-adapter/session.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IAccount, IAccountVendor } from '../acct-comps/accounts.interfaces';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})

export class ReportsComponent implements OnInit, OnDestroy {
  reportsTabs: typeof REPORTS_TABS = REPORTS_TABS;
  tabItems: MenuItem[];
  activeItem: MenuItem;
  private ngUnsubscribe$ = new Subject();
  private globalAccObject: any;

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.getCurrAccDetails();
    this.initReportsTab();
  }

  /**
   * @description Method to get global object of the current sihned in user.
   * @returns {void}
   */
  private getCurrAccDetails(): void {
    this.sessionService.getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: IAccount) => {
        this.globalAccObject = typeof res?.global === 'string' ? JSON.parse(res?.global) : res?.global;
      })
  }

  /**
   * @description To initializa the Reports section tab menu items visibility, enable or disable them.
   * @returns {void}
   */
  private initReportsTab(): void {
    const { publish: applePublishStatus = false, businessId: appleBusinessId = undefined } = this.globalAccObject?.appleMaps || {};
    const { publish: yelpPublish = false, subscribeToLM: lmFlag = false, subscribeToYK: ykFlag = false } = this.globalAccObject?.yelp || {};
    const { publish: bingPublish = false } = this.globalAccObject?.bing || {};
    this.tabItems = Object.values(this.reportsTabs).map((tabName: string) => {
      return {
        label: tabName,
        disabled: tabName == this.reportsTabs.LOG ||
        (tabName === this.reportsTabs.YELP && !(yelpPublish && lmFlag && ykFlag)) ||
        (tabName === this.reportsTabs.APPLE && !(applePublishStatus && appleBusinessId)) ||
        (tabName === this.reportsTabs.BING && !bingPublish),
        visible: tabName == this.reportsTabs.ACCURACY ? this.sessionService.hasPerm('report.accuracy', 'r') : true,
        command: (event) => {
          this.setActiveTab(event.item);
        },
      };
    });
    this.activeItem = this.tabItems[0];
  }

  setActiveTab(item: MenuItem) {
    this.activeItem = item;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
