<div *ngIf="headerTitle" class="header">
  <h3>{{ headerTitle }}</h3>
  <ng-content select="[headerAction]"></ng-content>
</div>

<div *ngIf="doughnutData !== null; else noData" class="content">
  <div class="rs-card-numbers">
    <ng-content select="[numbers]"></ng-content>
  </div>
  <div class="graph">
    <p-chart type="doughnut" [data]="doughnutData" [options]="doughnutOptions" width="100%" height="70%"></p-chart>
  </div>
  <div class="summary">
    <ng-content select="[summary]"></ng-content>
  </div>
</div>

<ng-template #noData>
  <div class="flex-center flex-1"><h3>No data for this account</h3></div>
</ng-template>
