<ng-container *ngIf="answers$ | async as answers">
  <div class="header-parent-title">
    <div class="header-child-title">
      {{ type == 'owner' ? 'Owner' : 'Customers' }} {{ type == 'owner' ? 'Answer' : 'Answers' }}
    </div>
    <div class="header-child-title">.</div>
  </div>
  <div class="qa-answer-wrapper">
    <div class="qa-answer-col">
      <div class="qa-answer-image">
        <img class="photo" [src]="'http:' + question.authorPhotoURL" (error)="question.authorPhotoURL = errorImg" />
      </div>
    </div>
    <div class="qa-answer-col">
      <div class="qa-answer-question">
        <div class="qa-answer-question-info">
          {{ question.authorName }} on {{ question.updateTime?.strftime('%m/%d/%Y') }}
        </div>
        <span *ngIf="!question.updateTime"> Question </span>
        <span class="qa-answer-question-title">{{ question.questionText }}</span>
      </div>
      <div class="qa-answer-row qa-answer-reply">
        <span class="material-icons"> comment </span>
        <span class="qa-answer-reply-count"
          >{{ type == 'owner' ? '' : answers.length }} {{ type == 'owner' ? 'Owner' : 'Customers' }} Reply</span
        >
      </div>
      <ng-container>
        <ng-container *ngFor="let answer of answers">
          <div class="qa-answer-row">
            <span class="material-icons"> circle </span>
            <div class="qa-answer-reply-message">
              <span class="qa-answer-reply-title">{{ answer.authorName }}</span>
              <span class="qa-answer-reply-text">{{ answer.answerText }}</span>
            </div>
          </div>
          <p-divider class="qa-answer-divider"></p-divider>
        </ng-container>
      </ng-container>
      <!-- <ng-template #customerTemplate>
        *ngIf="type == 'owner'; else customerTemplate"
        <ng-container *ngFor="let answer of answers$ | async">
          <div class="qa-answer-row">
            <span class="material-icons"> circle </span>
            <div class="qa-answer-reply-message">
              <span class="qa-answer-reply-title">{{ answer.authorName }}</span>
              <span class="qa-answer-reply-text">{{ answer.answerText }}</span>
            </div>
          </div>
          <p-divider class="qa-answer-divider"></p-divider>
        </ng-container>
      </ng-template> -->
    </div>
  </div>
</ng-container>
