<ng-container *ngIf="!isOptimized; else optimized">
  <div class="header-parent-title">
    <div class="header-child-title">{{ isEdit ? 'Edit Optimized Photo' : 'Optimize Photo' }}</div>
    <div class="header-child-title">.</div>
  </div>

  <div class="gbp-photo-optimize-wrapper">
    <app-gbp-photo-scheduled-optimized [detailsOptimize]="false" [edit]="true"></app-gbp-photo-scheduled-optimized>
  </div>

  <div class="bottom-buttons">
    <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
    <button
      pButton
      label="{{ isEdit ? 'SAVE CHANGES' : 'OPTIMIZE' }}"
      class="p-button-primary"
      (click)="optimize()"
    ></button>
  </div>
</ng-container>
<ng-template #optimized>
  <div class="gbp-photo-optimize-saved">
    <img src="./../../../../../assets/loc-add-edit-widget-logos/check.png" width="100px" height="100px" />
    <div class="header-parent-title">
      <div class="header-child-title">Photo Optimized</div>
      <div class="header-child-title">!</div>
    </div>
    <div>Your photo is now optimized. You can view it on your GBP photo profile.</div>
  </div>
</ng-template>
