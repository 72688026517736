<!-- @format -->

<div class="main-perms-container">
  <div class="table-container">
    <p-table
      id="permsTable"
      [value]="perms"
      [scrollable]="true"
      [resizableColumns]="true"
      [style.maxWidth]="tableWidth"
      [scrollHeight]="tableHeight"
      selectionMode="single"
      sortMode="multiple"
      [(selection)]="selPerm"
      dataKey="_id"
    >
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col class="db-id" />
          <col class="role" />
          <col class="code" />
          <col class="perms" />
          <col class="descr" />
          <col class="status" />
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pResizableColumn pSortableColumn="_id" class="db-id">
            ID
            <p-sortIcon field="_id"></p-sortIcon>
          </th>
          <th pResizableColumn pSortableColumn="role" class="role">
            Role
            <p-sortIcon field="role"></p-sortIcon>
          </th>
          <th pResizableColumn pSortableColumn="code" class="code">
            Code
            <p-sortIcon field="code"></p-sortIcon>
          </th>
          <th pResizableColumn pSortableColumn="perms" class="perms">
            Perm
            <p-sortIcon field="perms"></p-sortIcon>
          </th>
          <th pResizableColumn pSortableColumn="descr" class="descr">
            Description
            <p-sortIcon field="descr"></p-sortIcon>
          </th>
          <th pResizableColumn pSortableColumn="_status" class="status">
            Status
            <p-sortIcon field="_status"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-perm>
        <tr [pSelectableRow]="perm" (dblclick)="editPerm(perm)">
          <td pResizableColumn class="db-id">{{ perm._id }}</td>
          <td pResizableColumn class="role">{{ perm.role }}</td>
          <td pResizableColumn class="code">{{ perm.code }}</td>
          <td pResizableColumn class="perms">{{ perm.perms }}</td>
          <td pResizableColumn class="descr">{{ perm.descr }}</td>
          <td pResizableColumn class="status">
            {{ statuses[perm._status]?.name }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="buttons" style="display: flex">
      <button
        pButton
        (click)="addPerm()"
        label="Add"
        class="p-button-success"
        *ngIf="sessionService.hasPerm('admin.perms', 'a')"
      ></button>
      <button
        pButton
        [disabled]="!selPerm"
        (click)="editPerm(selPerm)"
        label="Edit"
        *ngIf="sessionService.hasPerm('admin.perms', 'e')"
      ></button>
    </div>
  </div>
</div>

<p-dialog
  id="perms"
  [(visible)]="showDialog"
  [modal]="true"
  header="{{ newPerm ? 'Add permission' : 'Edit permission:' + permCopy._id + ' - ' + permCopy.role }}"
  (onHide)="closePopup()"
  *ngIf="makeDialog"
>
  <div class="popup-main">
    <h3 *ngIf="errMsg">Could not save: {{ errMsg }}</h3>

    <aside>
      <span class="inline-input-label right-padding">
        <label for="role">Role</label>
        <input pInputText id="role" type="text" [(ngModel)]="permCopy.role" />
      </span>

      <span class="inline-input-label">
        <label for="code">Code</label>
        <input pInputText id="code" type="text" [(ngModel)]="permCopy.code" />
      </span>

      <span class="inline-input-label">
        <label for="perms">Permissions</label>
        <input
          pInputText
          id="perms"
          type="text"
          pTooltip="Leave empty for none  read=read,edit,add,delete"
          tooltipPosition="top"
          [(ngModel)]="permCopy.perms"
        />
      </span>

      <span class="inline-input-label">
        <label for="descr">Description</label>
        <textarea [(ngModel)]="permCopy.descr" rows="5" style="width: 300px"></textarea>
      </span>
    </aside>

    <aside>
      <span class="inline-input-label stat-drpdwn">
        <label>Status</label>
        <p-dropdown
          [(ngModel)]="permCopy._status"
          [options]="statuses"
          optionValue="code"
          optionLabel="name"
          appendTo="body"
        ></p-dropdown>
      </span>
    </aside>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      label="Cancel"
      icon="pi pi-times"
      class="p-button-outlined p-button-secondary dialog"
      (click)="closePopup()"
    ></button>
    <button pButton label="Save" icon="pi pi-check" class="dialog" (click)="savePerm()"></button>
  </ng-template>
</p-dialog>
