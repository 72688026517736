<ng-container *ngIf="!isSuccess; else success">
  <div class="header-parent-title">
    <div class="header-child-title">Edit</div>
    <div class="header-child-title">.</div>
  </div>
  <div [formGroup]="editForm" class="qa-edit-wrapper">
    <div class="qa-edit-row">
      <div class="qa-edit-title">Question</div>
      <div class="qa-edit-input">
        <textarea
          [rows]="3"
          placeholder="Type Here..."
          pInputTextarea
          autoResize="autoResize"
          formControlName="questionText"
        ></textarea>
      </div>
    </div>
    <div class="qa-edit-row">
      <div class="qa-edit-title">Answer</div>
      <div class="qa-edit-input">
        <textarea
          [rows]="4"
          placeholder="Type Here..."
          pInputTextarea
          autoResize="autoResize"
          formControlName="answerText"
        ></textarea>
      </div>
    </div>
    <div class="qa-edit-row">
      <span class="schedule_now_title">Schedule for later</span>
      <p-inputSwitch formControlName="scheduleForLater" (onChange)="hendleScheduleLater($event)"></p-inputSwitch>
    </div>

    <ng-container *ngIf="isScheduleSwitchOn">
      <div class="qa-edit-row">
        <div class="qa-edit-title">Date</div>
        <div class="qa-edit-input date_time__date">
          <p-calendar [style]="{ minWidth: '100%' }" formControlName="date" [showIcon]="true" inputId="icon"
            dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy"></p-calendar>
        </div>
      </div>

      <div class="qa-edit-row">
        <div class="qa-edit-title">Time</div>
        <div class="qa-edit-input">
          <p-calendar [style]="{ minWidth: '100%' }" formControlName="time" [timeOnly]="true" [hourFormat]="12"
            inputId="timeonly" placeholder="00:00 PM"></p-calendar>
        </div>
      </div>

      <div class="qa-edit-row">
        <div class="qa-edit-title">Time Zone</div>
        <div class="qa-edit-input">
          <p-dropdown [style]="{ minWidth: '100%' }" [options]="timeZones" [group]="true" optionLabel="name"
            optionValue="value" formControlName="timeZone" placeholder="Select" tooltipPosition="top"></p-dropdown>
        </div>
      </div>
    </ng-container>


  </div>
  <div class="bottom-buttons">
    <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
    <button pButton label="SAVE" class="p-button-primary" [disabled]="!editForm.valid" (click)="save()"></button>
  </div>
</ng-container>
<ng-template #success>
  <div class="qa-edit-success">
    <img src="assets/loc-add-edit-widget-logos/check.png" width="100px" height="100px" />
    <div class="header-parent-title">
      <div class="header-child-title">Changes pushed successfully</div>
      <div class="header-child-title">!</div>
    </div>
  </div>
</ng-template>
