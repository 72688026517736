import { Component, OnDestroy, OnInit } from '@angular/core';
import { tap } from 'lodash-es';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-qa-customer-answers',
  templateUrl: './gbp-qa-customer-answers.component.html',
  styleUrls: ['./gbp-qa-customer-answers.component.scss'],
})
export class GbpQaCustomerAnswersComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  data: any;
  errorImg = '';
  question;
  type: string;
  answers$: Observable<any>;

  constructor(
    public ref: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {
    this.question = this.dynamicDialogConfig.data.question;
    this.type = this.dynamicDialogConfig.data.type;
    let authorType = '';
    switch (this.type) {
      case 'owner':
        authorType = 'MERCHANT';
        break;
      case 'customer':
        authorType = `REGULAR_USER' OR authorType= 'LOCAL_GUIDE`;
        break;
    }
    this.answers$ = this.gbpService.getAnswers(`(authorType='${authorType}') AND question_id='${this.question._id}'`);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
