import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPublish, IReviewData } from '../../review-management.interfaces';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-add-edit-response',
  templateUrl: './add-edit-response.component.html',
  styleUrls: ['./add-edit-response.component.scss'],
})
export class AddEditResponseComponent implements OnInit {
  selReview: IReviewData;
  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    const reviewData = this.dynamicDialogConfig.data;
    switch (reviewData['vendorIdent']) {
      case 'gmb':
        this.selReview = { ...reviewData, icon: `middle.jpeg`};
        break;
      case 'yelp-yelp':
        this.selReview = { ...reviewData, icon: `icon-yelp.png` };
        break;
      default:
        this.selReview = { ...reviewData, icon: `` };
        break;
    }
  }

  public cancelSingleReply(): void {
    this.ref.close();
  }

  public confirmReply(): void {
    const publishObject: IPublish<IReviewData> = {
      type: 'single',
      data: this.selReview,
    };
    this.ref.close();
    this.dialogService.open(ConfirmationPopupComponent, {
      data: { ...publishObject },
      closable: false,
      width: '800px',
      height: '300px',
    });
  }
}
