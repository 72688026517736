import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";

import { SocketService } from "src/app/core/backend-adapter/socket.service";

@Injectable({
  providedIn: 'root'
})

export class AppleMapsSpatialInsightsService {
  private static readonly APPLE_GET_MONTHLY_METRICS: string = 'get-apple-spatial-metrics';

  constructor(private socketService: SocketService) { }

  public getSpatialReport(year, month, location_id): Observable<any> {
    const data$ = this.socketService.sendRequest(AppleMapsSpatialInsightsService.APPLE_GET_MONTHLY_METRICS, {year, month, location_id});
    return from(data$);
  }

}
