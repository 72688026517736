import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoginService } from './login.service';
import { LoginPageComponent } from './login-page/login-page.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login.routing';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';

@NgModule({
  imports: [RSCommonModule, ReactiveFormsModule, HttpClientModule, LoginRoutingModule],
  declarations: [LoginPageComponent],
})
export class LoginModule {
  static forRoot(): ModuleWithProviders<LoginModule> {
    return {
      ngModule: LoginModule,
      providers: [LoginService],
    };
  }
}
