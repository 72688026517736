<div class="main-reports-container">
  <div class="table-container" *ngIf="showTableContent">
    <p-table
      id="contentsTable"
      [value]="sqlReportItems"
      [scrollable]="true"
      [resizableColumns]="true"
      [scrollHeight]="tableHeight"
      [style]="{ width: '880px' }"
      styleClass="p-datatable-striped"
      *ngIf="!viewAdhocSQL && showTableContent"
    >
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col class="name" />
          <col class="descr" />
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="name">Report name</th>
          <th class="descr">Description</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-report>
        <tr>
          <td class="name">
            <a href="{{ '/sql-report/' + report.ident }}">
              {{ report.name }}
            </a>
          </td>
          <td class="descr">{{ report.descr }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="reports-container" *ngIf="!showTableContent">
    <div class="editor-group" *ngIf="viewAdhocSQL && sessionService.hasPerm('report.sql-adhoc', 'r')">
      <textarea rows="8" [(ngModel)]="custQuery"></textarea>
      <div class="editor-buttons">
        <button
          pButton
          icon="pi pi-times"
          (click)="clearQuery()"
          label="Clear"
          class="p-button-secondary p-button-outlined"
        ></button>
        <button pButton icon="pi pi-check" (click)="runQuery(reportIdent, custQuery)" label="Execute" class=""></button>
      </div>
    </div>
    <div class="button-table">
      <div class="query-tbl" *ngIf="!errMsg">
        <p-table
          id="sql-reports-table"
          [value]="dataRows"
          [scrollable]="true"
          [resizableColumns]="true"
          [style.minWidth]="tableWidth"
          [scrollHeight]="tableHeight"
          styleClass="p-datatable-striped"
        >
          <ng-template pTemplate="caption">
            <div class="sql-tbl-buttons">
              <div class="report-download">
                <form
                  ngNoForm
                  name="sql-export"
                  id="sql-export"
                  class="sql-export"
                  method="GET"
                  action="/download/sql-report/{{ reportIdent }}"
                >
                  <input type="hidden" name="format" [value]="downloadFormat" />
                  <p-dropdown
                    [(ngModel)]="downloadFormat"
                    [options]="downloadFormats"
                    optionLabel="label"
                    optionValue="value"
                  ></p-dropdown>
                  <button pButton icon="pi pi-download" pTooltip="Download report" tooltipPosition="top"></button>
                </form>
              </div>
              <p-button
                label="&#x21BB;"
                (click)="runQuery(reportIdent)"
                pTooltip="Reload table"
                tooltipPosition="top"
                *ngIf="reportIdent != 'adhoc'"
              ></p-button>
              <p-button
                label="SQL"
                (click)="toggleTextarea()"
                pTooltip="Open SQL edit"
                tooltipPosition="top"
                *ngIf="reportIdent != 'adhoc'"
              ></p-button>
            </div>

            <div style="text-align: center">
              <div style="display: flex; flex-wrap: wrap; justify-content: center; text-align: center">
                <span>{{ reportTitle }}</span>
                <span style="font-weight: normal" *ngIf="!loading"> &nbsp;- {{ rowCount }} results </span>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let coldef of columnDefs" pResizableColumn [style]="{ 'max-width': coldef.width + 'px' }">
                {{ coldef.name }}
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td
                *ngFor="let coldef of columnDefs"
                [style]="{
                  'text-align': alignStyle(coldef.align),
                  'max-width': coldef.width + 'px'
                }"
                pResizableColumn
              >
                {{ rowData[coldef.index] }}
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="columnDefs?.length" *ngIf="loading">Loading, please wait</td>
              <td [attr.colspan]="columnDefs?.length" *ngIf="!loading">No results</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="err-msg" style="white-space: pre; font-family: monospace; text-align: center" *ngIf="errMsg">
      <p>{{ errMsg }}</p>
      <p *ngIf="!sessionService.currAccount">Select account to run this report</p>
    </div>
  </div>
</div>
