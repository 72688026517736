import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeysCount',
})
export class ObjectKeysCountPipe implements PipeTransform {
  transform(value: any): number {
    return value ? Object.keys(value).length : 0;
  }
}
