<div class="utm-gmb-form-wrapper">
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">Source&#x2a;</div>
    <div class="utm-gmb-form-input">
      <input
        class="utm-gmb-form-field"
        pInputText
        type="text"
        [(ngModel)]="utmSource"
        (ngModelChange)="onUtmSourceInputChange($event)"
        placeholder="Campaign Source..."
      />
    </div>
  </div>
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">Medium&#x2a;</div>
    <div class="utm-gmb-form-input">
      <input
        class="utm-gmb-form-field"
        pInputText
        type="text"
        [(ngModel)]="utmMedium"
        (ngModelChange)="onUtmMediumInputChange($event)"
        placeholder="Campaign Medium..."
      />
    </div>
  </div>
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">Name&#x2a;</div>
    <div class="utm-gmb-form-input">
      <div class="input-container">
        <input
          class="utm-gmb-form-field"
          pInputText
          type="text"
          [(ngModel)]="utmCampaign"
          (ngModelChange)="onUtmCampaignInputChange($event)"
          placeholder="Campaign Name..."
        />
        <div class="icon-container">
          <div class="db-icon-container flex-item">
            <img src="assets/database-2.svg" class="db-icon" (click)="openFieldChooser('utm_campaign')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">Term</div>
    <div class="utm-gmb-form-input">
      <div class="input-container">
        <input
          class="utm-gmb-form-field"
          pInputText
          type="text"
          [(ngModel)]="utmTerm"
          (ngModelChange)="onUtmTermInputChange($event)"
          placeholder="Campaign Term..."
        />
        <div class="icon-container">
          <div class="db-icon-container flex-item">
            <img src="assets/database-2.svg" class="db-icon" (click)="openFieldChooser('utm_term')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">Content</div>
    <div class="utm-gmb-form-input">
      <div class="input-container">
        <input
          class="utm-gmb-form-field"
          pInputText
          type="text"
          [(ngModel)]="utmContent"
          (ngModelChange)="onUtmContentInputChange($event)"
          placeholder="Campaign Content..."
        />
        <div class="icon-container">
          <div class="db-icon-container flex-item">
            <img src="assets/database-2.svg" class="db-icon" (click)="openFieldChooser('utm_content')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">ID</div>
    <div class="utm-gmb-form-input">
      <div class="input-container">
        <input
          class="utm-gmb-form-field"
          pInputText
          type="text"
          [(ngModel)]="utmId"
          (ngModelChange)="onUtmIdInputChange($event)"
          placeholder="Campaign ID..."
        />
        <div class="icon-container">
          <div class="db-icon-container flex-item">
            <img src="assets/database-2.svg" class="db-icon" (click)="openFieldChooser('utm_id')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="utm-gmb-form-row">
    <div class="utm-gmb-form-title">Source Platform</div>
    <div class="utm-gmb-form-input">
      <div class="input-container">
        <input
          class="utm-gmb-form-field"
          pInputText
          type="text"
          [(ngModel)]="utmSourcePlatform"
          (ngModelChange)="onUtmSourcePlatformInputChange($event)"
          placeholder="Campaign Source Platform..."
        />
        <div class="icon-container">
          <div class="db-icon-container flex-item">
            <img src="assets/database-2.svg" class="db-icon" (click)="openFieldChooser('utm_source_platform')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<gbp-utm-field-chooser
  *ngIf="showGbpUtmFieldChooserDialog"
  [utmField]="currentUtmField"
  (onSelectField)="handleSelectField($event)"
></gbp-utm-field-chooser>
