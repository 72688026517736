import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DBService } from 'src/app/core/backend-adapter/db.service';
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';
import { IPublish } from '../../review-management.interfaces';
import { ReviewsService } from '../../reviews.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {
  publishObject: IPublish<any>;
  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private reviewsService: ReviewsService
  ) {}

  ngOnInit(): void {
    this.publishObject = this.dynamicDialogConfig?.data;
  }

  cancelReply() {
    this.ref.close(false);
  }

  publishReply() {
    console.log(this.publishObject.data);
    if (this.publishObject.type == 'multi' || Array.isArray(this.publishObject.data)) {
      this.reviewsService.bulkUpdateReviewsResponse(this.publishObject.data);
    } else {
      this.reviewsService.updateReviewResponse(this.publishObject.data); // update the review response
    }

    this.ref.close(true);
    this.dialogService.open(SuccessPopupComponent, {
      data: { ...this.dynamicDialogConfig?.data },
      closable: false,
      width: '800px',
      height: '300px',
    });
  }
}
