// tslint:disable: curly
import { Component, Input, OnInit } from '@angular/core';
import { AcctHelpers } from 'src/app/acct-comps/helpers';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { IAccount } from '../../../../../acct-comps/accounts.interfaces';
import { IDirectory } from '../../directory.interfaces';
import { DirectoryListingsRow } from '../directory-listings.interfaces';
import { DirectoryListingsService } from '../directory-listings.service';
import { ILocation } from './../../../../../loc-comps/locations.interfaces';

@Component({
  selector: 'app-modal-directory-listings',
  templateUrl: './modal-directory-listings.component.html',
  styleUrls: ['./modal-directory-listings.component.scss'],
})
export class ModalDirectoryListingsComponent implements OnInit {
  loading = true;
  VENDOR_IMAGES = this.acctHelpers.VENDOR_IMAGES;
  listings: DirectoryListingsRow[];
  copyToClipboard: string;
  @Input() account: IAccount;
  @Input() location: ILocation;
  @Input() directory: IDirectory;

  constructor(
    private notifyService: NotifyService,
    private acctHelpers: AcctHelpers,
    private directoryListingsService: DirectoryListingsService
  ) {}

  ngOnInit(): void {
    this.getListings();
  }

  getListings() {
    this.directoryListingsService.getListingsByVendor(this.directory.vendor).subscribe((v) => {
      if (this.location) {
        v = v.filter((l) => l.locationId === this.location._id);
      }
      this.listings = v;
      this.loading = false;
    });
  }

  handleCopyToClipboard(link) {
    this.copyToClipboard = link;
    this.notifyService.info('Link was successfully copied to clipboard');
  }
}
