<div class="confirmation-wrapper">
  <div class="content">
    <img alt="" class="warning-icon" src="/assets/loc-add-edit-widget-logos/OrangeTriangle.png" />
    <div class="header-parent-title">
      <div class="header-child-title">Unsaved Changes</div>
      <div class="header-child-title">.</div>
    </div>
    <div class="text">Exiting without saving will cause you to lose any changes made.</div>
  </div>
  <div class="buttons">
    <button pButton label="EXIT WITHOUT SAVING" class="p-button-outlined p-button-secondary cancel"
      (click)="cancel()">EXIT WITHOUT SAVING</button>
    <button pButton label="SAVE CHANGES" class="p-button-primary save" (click)="save()">SAVE CHANGES</button>
  </div>
</div>
