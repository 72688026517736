<div class="acct-search">
  <span class="search-field p-input-icon-right">
    <i class="pi pi-search"></i>

    <input
      class="input"
      pInputText
      type="text"
      [placeholder]="placeholder"
      scrollable="true"
      (keyup)="search$$.next($event.target.value)"
    />
  </span>
  <ng-content></ng-content>
</div>
