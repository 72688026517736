import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Renderer2,
  RendererStyleFlags2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { AccountsService } from 'src/app/acct-comps/accounts.service';
import { RecentAccountsService } from 'src/app/acct-comps/recent-accounts.service';
import { IAuthenticateData } from 'src/app/core/backend-adapter/session.interfaces';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { isUserSingleAccounted } from 'src/app/core/permissions/permissions.utils';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-sidebar-menu-account-selector',
  templateUrl: './sidebar-menu-account-selector.component.html',
  styleUrls: ['./sidebar-menu-account-selector.component.scss'],
})
export class SidebarMenuAccountSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectedAccount$: Observable<IAccount> = this.sessionService.getSelectedAccount$();
  public accounts: IAccount[];
  public filteredAccounts: IAccount[] = [];
  public user: IAuthenticateData;
  public accounts$: Observable<IAccount[]>;
  public search$$ = new Subject<string>();
  private subs = new Subscription();
  originAccounts: IAccount[] = [];
  public isUserSingleAccounted: boolean;
  public isSearching: boolean;
  public userLogoUrl: string;
  public accountLogoUrl = {};
  public logoLink: string = 'https://rseo-platform.s3.amazonaws.com/logos/';

  @Output() public closeSelector = new EventEmitter<void>();
  @ViewChild('searchRef', { static: false }) searchRef: ElementRef<HTMLInputElement>;
  @ViewChild('recentsAccDividerRef', { static: false }) recentsAccDividerRef: ElementRef<HTMLInputElement>;
  @ViewChild('allAccountsRef', { static: false }) allAccountsRef: ElementRef<HTMLInputElement>;

  constructor(
    public sessionService: SessionService,
    private accountsService: AccountsService,
    private recentAccountsService: RecentAccountsService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.listenSearch();

    this.handleAccountChange();

    this.accountsService.getActiveAccounts().subscribe(({ collection }) => {
      this.originAccounts = this.mapAccountsWithSubaccounts(collection).map((acc) => ({...acc, logoUrl: this.getLogoUrl(acc.ident)}));
      this.rerenderAllAccountsListHeight();
    });
  }

  private getLogoUrl(account: string): string {
    return account ?
    `https://rseo-platform.s3.amazonaws.com/logos/${account.split('-')[0]}.png` :
    `https://rseo-platform.s3.amazonaws.com/logos/blank.png`;
  };

  public handleMissingImage(e: Event): void {
    (e.target as HTMLImageElement).setAttribute('src', 'https://rseo-platform.s3.amazonaws.com/logos/blank.png');
  };

  ngAfterViewInit() {
    this.searchRef?.nativeElement && this.searchRef?.nativeElement?.focus();
  }

  public onSearch(event) {
    this.isSearching = !!event.target.value;
    this.search$$.next(event.target.value);
  }

  public handleClickOutside(e: any | PointerEvent) {
    const isClickedMenu = Array.from((e.target as HTMLElement).classList).some((v) =>
      ['agency-logo', 'logo-avatar', 'logo-hover-stub'].includes(v)
    );
    if (!isClickedMenu) {
      this.onCloseSelector();
    }
  }

  /* Hide See all accounts button */
  // public handleNavigationClick(url: string): void {
  //   this.router.navigateByUrl(url);
  //   this.onCloseSelector();
  // }

  public handleAccountNavigation(a: IAccount, redirectToAccPage?: boolean): void {
    this.recentAccountsService.setRecentAccounts(a, this.user.login);
    this.sessionService.setSelectedAccount(a).then(() => {
      if (redirectToAccPage) {
        this.router.navigate(['/accounts', a.ident]);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });

    this.handleAccountChange();

    // this.router.navigate(['/accounts', a.ident]);
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // const url = decodeURIComponent(this.router.url);
    // this.router.navigateByUrl(url);
    // location.reload(); // TODO: as temporary solution, should be subscribing on changed account and reGET data
    this.onCloseSelector();
  }

  private handleAccountChange(): void {
    this.subs.add(
      this.sessionService.getCurrentUser$().subscribe((user: IAuthenticateData) => {
        this.user = {...user, logoUrl: this.getLogoUrl(user?.account)};

        if (isUserSingleAccounted(user)) {
          this.isUserSingleAccounted = true;
        }
        this.enableAccSelectorFullHeight();
        this.filteredAccounts = this.recentAccountsService.getRecentAccounts(this.user.login)
          .map((acc) => ({ ...acc, logoUrl: this.getLogoUrl(acc.ident)}));
      })
    );
  };

  public onCloseSelector() {
    this.closeSelector.emit();
  }

  public handleSubaccountsList(e, a: IAccount): void {
    this.preventClick(e);
    this.filteredAccounts.forEach((v) => (v._subAccountsToggle = false));
    a._subAccountsToggle = !a._subAccountsToggle;
  }

  public handleSearchClick(e): void {
    this.preventClick(e);
  }

  private mapAccountsWithSubaccounts(collection): IAccount[] {
    const myCollection = collection.map(x => Object.assign({}, x));
    const accountsTree = myCollection.reduce((acc, v: IAccount, i, array) => {
      const hasParentAccount = v._parent_id;
      if (hasParentAccount) {
        const currentlyAdded = acc.find((a) => a._id === v._parent_id);
        const parentAccount = array.find((a) => a._id === v._parent_id) || {};
        if (!parentAccount?.hasOwnProperty('_subAccounts')) parentAccount['_subAccounts'] = [];

        if (currentlyAdded) {
          currentlyAdded._subAccounts.push(v);
          currentlyAdded.hasSubAccts = true;
        } else {
          parentAccount['_subAccounts'].push(v);
          parentAccount['hasSubAccts'] = true;
          acc.push(parentAccount);
        }
      } else {
        const currentlyAdded = acc.find((a) => a._id === v._id);
        if (!currentlyAdded) {
          acc.push(v);
        }
      }
      return acc;
    }, []);

    const flattenAccounts = accountsTree.reduce((acc, v) => {
      if (v.hasOwnProperty('_subAccounts')) {
        acc.push(v);
        acc.push(...v._subAccounts.sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        acc.push(v);
      }
      return acc;
    }, []);
    return flattenAccounts;
  }

  private preventClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  private listenSearch(): void {
    this.subs.add(
      this.search$$
        .pipe(
          map((v) => {
            this.filteredAccounts = null;
            return v;
          })
        )
        .pipe(debounceTime(300))
        .subscribe((v) => {
          if (v) {
            const arr = this.originAccounts.filter((a) => {
              const searchStr = (a) => `${a.name}`.toLowerCase();
              return searchStr(a).includes(v.toLowerCase()); // || a._subAccounts.some((k) => searchStr(k).includes(v)) // && !a._parent_id;
            });
            this.filteredAccounts = arr.slice(0, 7);
          } else {
            this.filteredAccounts = this.recentAccountsService.getRecentAccounts(this.user.login);
          }
          this.rerenderAllAccountsListHeight();
        })
    );
  }

  clearRecentAccounts(e) {
    this.preventClick(e);
    this.recentAccountsService.clearRecentAccounts();
    this.filteredAccounts = [];
    this.rerenderAllAccountsListHeight();
  }

  private enableAccSelectorFullHeight(): void {
    if (this.isUserSingleAccounted) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'full-height');
    } else {
      this.renderer.addClass(this.elementRef.nativeElement, 'full-height');
    }
    // this.rerenderAllAccountsListHeight();
  }

  private rerenderAllAccountsListHeight(): void {
    if (this.recentsAccDividerRef) {
      const hrRect: DOMRectReadOnly = this.recentsAccDividerRef.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(
        this.allAccountsRef.nativeElement,
        'height',
        `calc(100vh - ${hrRect.top + 80}px)`,
        {} as RendererStyleFlags2
      );
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
