import { Location, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  constructor(private _location: Location, @Inject(DOCUMENT) private _document: Document) {}

  ngOnInit() {
    this._document.head.title = 'Not found';
  }

  goBack() {
    this._location.back();
  }
}
