import { Directive, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: 'img[appImageOnError]',
})
export class ImageOnErrorDirective {
  constructor(private el: ElementRef) {}

  @HostListener('error')
  private onError() {
    this.el.nativeElement.src = '/assets/error-image.png';
  }
}

@NgModule({
  declarations: [ImageOnErrorDirective],
  exports: [ImageOnErrorDirective],
})
export class ImageOnErrorModule {}
