import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { RsThemes } from './style-changer.interfaces';

@Injectable({ providedIn: 'root' })
export class StyleChangerService {
  private theme$$ = new BehaviorSubject<RsThemes>(RsThemes.default);

  getCurrentTheme(): Observable<RsThemes> {
    return this.theme$$.asObservable();
  }

  setCurrentTheme(theme: RsThemes) {
    this.theme$$.next(theme);
  }

  constructor(public http: HttpClient) {}

  getCssBase() {
    return this.http.get('assets/style-changer/style-sheet.css', { responseType: 'text' }) as any;
  }

  getCssHeaders() {
    return this.http.get('assets/style-changer/headers.css', { responseType: 'text' }) as any;
  }
}
