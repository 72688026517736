import { Component, OnInit, HostListener } from '@angular/core';
import { DBService } from '../core/backend-adapter/db.service';
import { SocketService } from '../core/backend-adapter/socket.service';
import { SessionService } from '../core/backend-adapter/session.service';
import { TypeDefs } from '../acct-comps/type-defs';

declare var window: any;

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  // table variables
  tableWidth: string;
  tableHeight: string;
  perms: any[];
  selPerm: any;

  // popup variables
  showDialog: boolean;
  makeDialog: boolean;
  newPerm: boolean;
  permCopy: any;
  errMsg: string;
  statuses: any[];

  constructor(
    public dbService: DBService,
    public socketService: SocketService,
    public sessionService: SessionService,
    private typeDefs: TypeDefs
  ) {
    this.statuses = this.typeDefs.dbStatusTypes;
    for (const s of this.statuses) {
      this.statuses[s.code] = s;
    }

    window.PermissionsComponent = this;
  }

  ngOnInit(): void {
    this.dbService.loadAll()
      .then((repl) => {
        return this.dbService.Permission.loadObjects();
      })
      .then((repl) => {
        this.perms = repl.collection;
        console.log('this.perms :', this.perms);
      });

    this.onResize({ target: window });
  }

  addPerm() {
    this.permCopy = {
      _status: 'A',
      role: '',
      code: '',
      perms: '',
      descr: '',
      attrs: {},
      tags: [],
    };

    this.newPerm = true;
    this.showDialog = true;
    this.makeDialog = true;
  }

  editPerm(perm) {
    console.log(' edit:');
    this.permCopy = this.dbService.Permission.importFromHash(this.dbService.Permission.exportToHash(perm));
    console.log('this.permCopy :', this.permCopy);
    this.showDialog = true;
    this.makeDialog = true;
  }

  closePopup() {
    this.newPerm = false;
    this.errMsg = null;
    this.showDialog = false;
    this.makeDialog = false;
  }

  savePerm() {
    this.dbService.Permission.saveObject(this.permCopy)
      .then((resp) => {
        this.dbService.Permission.loadObjects().then((repl) => {
          this.perms = repl.collection;
        });

        this.errMsg = null;
        this.newPerm = false;
        this.showDialog = false;
        this.makeDialog = false;
      })
      .catch((err) => {
        this.errMsg = err.message;
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let wid = event.target.innerWidth;
    if (wid > 1900) {
      wid = 1900;
    }
    this.tableWidth = wid - 100 + 'px';

    const hgt = event.target.innerHeight;
    this.tableHeight = hgt - 230 + 'px';
  }
}
