import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {catchError, filter, map, take} from 'rxjs/operators';
import {IAccount} from 'src/app/acct-comps/accounts.interfaces';
import {RecentAccountsService} from 'src/app/acct-comps/recent-accounts.service';
import {NoticebarService} from '../layouts/notifications/noticebar/noticebar.service';
import {BroadcastService} from './broadcast.service';
import {CookieService} from './cookie.service';
import {IAuthenticateData, IAuthPermission, ILoginData} from './session.interfaces';
import {SocketService} from './socket.service';
import {STORAGE_KEYS, StorageService} from './storage.service';
import {isUserAdmin} from '../permissions/permissions.utils';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public currentUser$$ = new BehaviorSubject<IAuthenticateData>(undefined);

  authObj: null | IAuthenticateData;
  loginObj: null | ILoginData;
  permissions: IAuthPermission;
  currAccount: string;
  effAccount_id: any;
  effAccount_ids: any;
  effParty_id: any;
  effParty_ids: any;
  selectedLocation: any;
  selectedAccount: any; // TODO: needs to be deprecated due to Subject below
  selectedAccount$$ = new BehaviorSubject<IAccount>(undefined);

  defer?: { resolve; reject };
  pending: Promise<IAuthenticateData>;
  private lastUsedAccount: IAccount;
  private currentAccount: IAccount;
  AUTH_ID: string;

  constructor(
    private router: Router,
    private socketService: SocketService,
    private broadcastService: BroadcastService,
    private cookieService: CookieService,
    private noticebarService: NoticebarService,
    private recentAccountsService: RecentAccountsService,
    private storageService: StorageService
  ) {
    socketService.on('stateChanged', async (newState: number, oldState: number, args: any) => {
      if (oldState == SocketService.AUTHENTICATED && newState == SocketService.DISCONNECTED) {
        this.reauthenticate();
      } else if (oldState == SocketService.AUTHENTICATED && newState == SocketService.CONNECTED) {
        if (args.category == 'auth') {
          this.deauthenticate();
        }
      }
    });
  }

  public getCurrentUser$(): Observable<IAuthenticateData> {
    return this.currentUser$$.asObservable().pipe(filter((v) => v !== undefined));
  }

  public setCurrentUser$(v): void {
    this.currentUser$$.next(v);
  }

  public getSelectedAccount$(): Observable<IAccount> {
    return this.selectedAccount$$.asObservable().pipe(filter((v) => v !== undefined));
  }

  public setSelectedAccount(acc: IAccount) {
    if (acc && this.currentAccount != null && this.currentAccount !== acc) {
      return this.changeAccount(acc.ident).toPromise()
        .then((_) => {
          this.selectedAccount$$.next(acc);
          this.currentAccount = acc;
          this.lastUsedAccount = acc;
          if (acc) {
            // tslint:disable-next-line: quotemark
            this.noticebarService.success('You\'re now logged in as <b>' + acc.name + '</b>');
          }
          return true;
        })
        .catch((error) => {
          this.selectedAccount$$.next(null);
          this.currentAccount = null;
          this.lastUsedAccount = null;
          console.error('Error while set account on backend');
        });
    } else {
      this.selectedAccount$$.next(acc);
      this.currentAccount = acc; // TODO: helps to assign null if account was dropped. Fix deauth foo with this
      this.lastUsedAccount = acc;
      return Promise.resolve(acc);
    }
  }

  isLoggedIn(): boolean {
    const cookie = !!this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    return cookie;
  }

  // TODO: refactor to subject$$ due to infinite fires on templates
  isAuthenticated(place: string): boolean {
    return !!this.loginObj;
  }

  public deauthenticate(): Promise<any> {
    this.authObj = null;
    this.loginObj = null;
    this.setAccountVars(null);
    this.setCurrentUser$(null);
    this.setSelectedAccount(null);
    this.lastUsedAccount = null;

    this.broadcastService.update('sessionDeauthenticated', this.loginObj);
    this.storageService.set(STORAGE_KEYS.wasRecentlyLogged, true);
    this.router.navigateByUrl('/login');
    return this.socketService.deauthenticate().finally(() => {
      this.shutdown();
    });
  }

  private async reauthenticate() {
    this.authObj = null;
    this.loginObj = null;
    this.setAccountVars(null);

    this.socketService.reconnect().then(() => {
      return this.authenticateWSS();
    });
  }

  public refreshComponent$(): Observable<boolean> {
    return timer(0, 200)
      .pipe(take(2))
      .pipe(
        map((i) => {
          return i !== 0;
        })
      );
  }

  public authenticateWSS(newLoginId?: string): Promise<IAuthenticateData> {
    // debugger;
    if (this.loginObj && !newLoginId) {
      // this.setCurrentUser$({ login: this.loginObj } as IAuthenticateData);
      return Promise.resolve({login: this.loginObj} as IAuthenticateData);
    }
    if (this.pending) {
      return this.pending;
    }

    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    if (!AUTH_ID) {
      this.router.navigateByUrl('/login');
      throw {status: 'err', category: 'auth', type: 'no-auth-id'};
    }

    const authArgs: { authId: string; newLoginId?: string } = {authId: AUTH_ID};
    if (newLoginId) {
      authArgs.newLoginId = newLoginId;
    }

    return (this.pending = this.socketService
      .authenticating(authArgs)
      .then(
        (args: IAuthenticateData) => {
          if (args == null) {
            this.cookieService.setAuthCookie(null);
            document.location.reload();
          }

          // debugger;
          this.pending = undefined;

          if (authArgs.newLoginId) {
            document.location.reload();
          }

          this.pending = undefined;

          this.setupAuthValues(args);
          return args;
        },
        (err) => {
          // debugger;
          this.pending = undefined;
          console.log('authenticate: error');
          throw err;
        }
      )
      .then((args) => {
        // debugger;
        // this.broadcastService.update('sessionAuthenticated', args);
        return args;
      }));
  }

  public setupAuthValues(args: IAuthenticateData): void {
    let defaultLoggedInAccountName: string;
    if (args) {
      this.authObj = args;
      this.loginObj = args && args?.login && args.login;
      this.setAccountVars(args);
      this.AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);

      // if there no last used account
      if (!this.lastUsedAccount) {
        this.lastUsedAccount = this.recentAccountsService.getRecentAccounts(args?.login)[0] ?? null;
      }

      // if user has one account in his profile
      defaultLoggedInAccountName = args?.login?.account && args?.login?.account;
      const isAdmin = isUserAdmin(args);
      if (!isAdmin) {
        this.lastUsedAccount =
          (args?.authorizedAccts && args?.authorizedAccts?.find((a) => defaultLoggedInAccountName === a.ident)) ||
          (args?.authorizedAccts && args?.authorizedAccts[0]);
      }
      this.setSelectedAccount(this.lastUsedAccount);

      this.permissions = args && args?.permissions && args.permissions || ({} as IAuthPermission);

      for (const p of this.permissions?._patterns) {
        p[0] = new RegExp(p[0]);
      }

      this.setCurrentUser$(args as IAuthenticateData);

      this.broadcastService.update('sessionAuthenticated', args);
    }
  }

  cancelAuthenticate() {
    if (this.defer) {
      this.defer.reject('Authenticate cancelled.');
    }
    this.defer = null;
  }

  shutdown() {
    this.socketService.disconnect();
    this.cookieService.setAuthCookie('');
    this.setupAuthValues(null);
    this.loginObj = null;
  }

  public setAccountVars(authData: IAuthenticateData | null, broadcastSubjectName = null) {
    if (authData === null) {
      this.currAccount = null;
      this.effAccount_id = null;
      this.effAccount_ids = [];
      this.effParty_id = null;
      this.effParty_ids = [];
    } else {
      this.currAccount = authData.account;
      this.effAccount_id = authData.effAccount_id;
      this.effAccount_ids = authData.effAccount_ids;
      this.effParty_id = authData.effParty_id;
      this.effParty_ids = authData.effParty_ids;

      if (broadcastSubjectName) {
        setTimeout(() => {
          this.broadcastService.update(broadcastSubjectName, {
            account: authData.account,
            effAccount_id: authData.effAccount_id,
            effAccount_ids: authData.effAccount_ids,
            effParty_id: authData.effParty_id,
            effParty_ids: authData.effParty_ids,
          });
        }, 0);
        console.log(broadcastSubjectName + ' fired');
      }
    }
  }

  // changeAccount1(acctIdent: string): Promise<boolean> {
  //   return this.socketService
  //     .changeAccount(acctIdent)
  //     .then((repl: any) => {
  //       this.setAccountVars(repl, 'accountChanged');
  //       return repl;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // }

  changeAccount(acctIdent: string): Observable<any> {
    return this.socketService.changeAccount(acctIdent)
      .pipe(
        map((resp) => {
          this.setAccountVars(resp, 'accountChanged');
          return resp;
        }),
        catchError(err => {
          throw err;
          return err;
        })
      )
  }

  changeIdentity(partyId: number, acctIdent: string) {
    return this.socketService.changeIdentity(partyId, acctIdent).then((repl: any) => {
      this.setAccountVars(repl, 'identityChanged');
      return repl;
    });
  }

  public hasPerm(permCode: string, checks: string): boolean {
    // TODO: figure out how to do this as directive, coz now it is sync function and cause errors
    let perms = this.permissions[permCode];
    if (typeof perms != 'string') {
      for (const p of this.permissions._patterns) {
        if (permCode.match(p[0])) {
          perms = this.permissions[p[1]];
          break;
        }
      }
    }

    // if (!perms)
    //   throw `Invalid permission name: ${permCode}`;

    if (perms == null) {
      // throw `Invalid permission name: ${permCode}`;
      return false;
    }

    if (perms == '') {
      return false;
    }

    checks = checks || 'r';

    for (const chk of checks) {
      if (!perms.includes(chk)) {
        return false;
      }
    }

    return true;
  }

  isAuthorizedFor(...idents) {
    if (!this.loginObj || !Array.isArray(this.loginObj.authType_ids)) {
      return false;
    }

    for (const authIdent of idents) {
      if (typeof authIdent == 'string' && authIdent.match(/[.?@*]/)) {
        const authRex = authIdent.replace(/\./g, '\\.').replace(/\?/g, '.').replace(/@/g, '[^.]+').replace(/\*/g, '.*');
        for (const id of this.loginObj.authType_ids) {
          //          var auth = dbService.AuthType.findById(id);
          //         if (auth.code.match(authRex))
          return true;
        }
      } else {
        const auth = undefined;
        let id;
        if (typeof authIdent == 'string') {
          if (authIdent == 'admin') {
            id = 1;
          } else {
            //            auth = dbService.AuthType.findByCode(authIdent);
            //          if (!auth)
            return undefined;
            //          id = auth._id;
          }
        } else {
          id = authIdent;
        }

        if (this.loginObj.authType_ids.includes(id)) {
          return true;
        }
      }
    }
    return false;
  }
}
