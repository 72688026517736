<div class="header-parent-title">
  <div class="header-child-title">Delete Item</div>
  <div class="header-child-title">.</div>
</div>

<div class="post-delete-wrapper">
  <p>
    Are you sure you want to delete this post? This action cannot be undone. If you no longer want the post to be
    active, you can unpublish it from GBP and it will no longer appear on your profile
  </p>
</div>

<div class="bottom-buttons">
  <button pButton label="UNPUBLISH" class="p-button-outlined" (click)="unpublishItem()"></button>
  <button pButton label="DELETE" class="p-button-primary" (click)="deleteItem()"></button>
</div>
