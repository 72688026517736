export class TypeDefs {

  constructor() {}

  accountTypes = [
    { code: 'C', name: 'Chain', descr: 'Chain', },
    { code: 'A', name: 'Agency', descr: 'Acency', },
  ];

  dbStatusTypes = [
    { code: 'A', name: 'Active', descr: 'Active', },
    { code: 'I', name: 'Inactive', descr: 'Inactive', },
    { code: 'P', name: 'Pending', descr: 'Pending activation' },
  ];

  formatsForType = {
    str: ['text', 'textarea', 'html', 'phone', 'number', 'checkbox', 'date', 'time', 'datetime', 'choice', 'dropdown'],
    int: ['text', 'number', 'checkbox', 'date', 'time', 'datetime', 'choice', 'dropdown'],
    float: ['text', 'number', 'checkbox', 'date', 'time', 'datetime', 'choice', 'dropdown'],
    bool: ['text', 'checkbox', 'choice', 'checkbox'],
    date: ['text', 'date'],
    time: ['text', 'time'],
    timestamp: ['text', 'date', 'time', 'datetime'],
  };

  customColumnDataTypes = [
    { code: 'str', name: 'String', descr: 'A text string.' },
    { code: 'int', name: 'Integer', descr: 'A whole number.' },
    { code: 'float', name: 'Rdecimal', descr: 'A number that can contain digits after the decimal point.' },
    { code: 'bool', name: 'Boolean', descr: 'A true or false value.' },
    { code: 'date', name: 'Date', descr: 'A date. (YYYY/MM/DD).' },
    { code: 'time', name: 'Time', descr: 'A time (HH:MM:SS).' },
    { code: 'timestamp', name: 'Date/Time', descr: 'A date and time (YYYY/MM/DD HH:MM:SS).' },
    { code: 'enum', name: 'Choice', descr: 'One or more selections from a list of choices.' },
  ];

  customColumnFormatTypes = [
    { code: 'text', name: 'Text', descr: 'Edit a single line of text.' },
    { code: 'textarea', name: 'Multiline', descr: 'Edit multiple lines of text.' },
    { code: 'html', name: 'HTML', descr: 'Edit HTML.' },
    { code: 'phone', name: 'Phone', descr: 'Edit a phone number.' },
    { code: 'number', name: 'Number', descr: 'Edit a numeric value.' },
    { code: 'checkbox', name: 'Checkbox', descr: 'Select a single true or false value.' },
    { code: 'date', name: 'Date', descr: 'Edit a calendar date.' },
    { code: 'time', name: 'Time', descr: 'Edit a time of day.' },
    { code: 'datetime', name: 'DateTime', descr: 'Edit a date/time.' },
    { code: 'choice', name: 'Choice', descr: 'Select one or more items from a list as radio buttons or checkboxes.' },
    { code: 'dropdown', name: 'Dropdown', descr: 'Select one or more items from a list as a dropdown.' },
  ];

}
