<div class="date-locations-form-wrapper" [formGroup]="scheduleGroup" [ngStyle]="{ width }">
  <div class="date-locations-form-col" *ngIf="rows == 2">
    <ng-container *ngIf="(locations?.length !== preSelectedLocs?.length)" [ngTemplateOutlet]="selectLocations"></ng-container>
    <ng-container *ngIf="preSelectedLocs?.length" [ngTemplateOutlet]="selectedLocations"></ng-container>
  </div>

  <div class="schedule_date">
    <div class="date-locations-form-col schedule_later" *ngIf="scheduleGroup.controls['checked']?.value">
      <div class="date-locations-form-row date">
        <div class="grid-item">Date</div>
        <div class="grid-item">
          <p-calendar [style]="{ minWidth: '100%' }" formControlName="date" [showIcon]="true" inputId="icon"
            dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" [disabled]="disableScheduleForLaterSwitch"></p-calendar>
        </div>
      </div>
      <div class="date-locations-form-row time">
        <div class="grid-item">Time</div>
        <div class="grid-item">
          <p-calendar [style]="{ minWidth: '100%' }" formControlName="time" [timeOnly]="true" [hourFormat]="12"
            inputId="timeonly" placeholder="00:00 PM" [disabled]="disableScheduleForLaterSwitch"></p-calendar>
        </div>
      </div>
      <div class="date-locations-form-row time_zone">
        <div class="grid-item">Time Zone</div>
        <div class="grid-item">
          <p-dropdown [style]="{ minWidth: '100%' }" [options]="timeZones" [group]="true" optionLabel="name"
            optionValue="value" formControlName="timeZone" placeholder="Select"  tooltipPosition="top" [disabled]="disableScheduleForLaterSwitch"></p-dropdown>
        </div>
      </div>
      <ng-container *ngIf="rows == 1" [ngTemplateOutlet]="selectLocations"></ng-container>
    </div>
  </div>
</div>

<ng-template #selectLocations [formGroup]="scheduleGroup">
  <div class="date-locations-form-row">
    <div class="grid-item loc_title">{{ preSelectedLocs.length ? 'Add new lcoations' : 'Locations' }}</div>
    <div class="grid-item">
      <p-multiSelect
        [options]="currentLocations"
        formControlName="location"
        optionLabel="businessName"
        appendTo="body"
        [selectedItemsLabel]="'{0} locations selected'"
        placeholder="Select"
        [showToggleAll]="true"
        [maxSelectedLabels]="0"
        [filter]="false"
        [style]="{ minWidth: '100%' }"
        (onPanelHide)="onPanelHide()"
        (onChange)="handleLocChange($event)"
        optionDisabled="disabled"
        [displaySelectedLabel]="true">
        <ng-template pTemplate="header">
          <div class="p-inputgroup search_loc">
            <input type="text" pInputText placeholder="Search by store code, city, state, tag..."
              [(ngModel)]="filterValue" [ngModelOptions]="{ standalone: true }" (input)="onFilter($event)" />
          </div>
          <div class="select-filtered-label">Select {{ filtered.length }} locations</div>
        </ng-template>

        <ng-template let-location pTemplate="item">
          <div>
            {{ location[1] }} - {{ location[2] }} - {{ location[3] }}, {{ location[4] }}
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
  <div class="date-locations-form-row">
    <div class="grid-item"></div>
    <div class="grid-item select-all">
      <p-checkbox formControlName="isSelectedAll" [binary]="true" inputId="binary" (onChange)="handleAllLocChange($event)"></p-checkbox>
      <span>Select all
        <b *ngIf="preSelectedLocs.length">unselected </b>
        <b>({{ locations.length }})</b>
        locations</span>
      <span class="material-icons-outlined" [pTooltip]="'Use the search tool to select/unselect specific location(s)'"
        [tooltipPosition]="'bottom'">
        info
      </span>
    </div>

    <div class="schedule_now" [class.disable]="disableScheduleForLaterSwitch">
      <span class="schedule_now_title">Schedule for later</span>
      <p-inputSwitch formControlName="checked" [disabled]="disableScheduleForLaterSwitch" (onChange)="hendleScheduleLater()"></p-inputSwitch>
    </div>
  </div>
</ng-template>

<ng-template #selectedLocations>
  <div class="date-locations-form-row">
    <div class="grid-item loc_title">Locations</div>
    <div class="grid-item">
      <p-multiSelect [options]="preSelectedLocs" appendTo="body" [ngModel]="preSelectedLocs"
        optionLabel="businessName" [selectedItemsLabel]="'{0} locations selected'"
        [showHeader]="false" maxSelectedLabels="0">
        <ng-template let-location pTemplate="item">
          <div>
            {{ location['storeCode'] }} - {{ location['businessName'] }} - {{ location['city'] }}, {{ location['state']
            }}
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
</ng-template>
