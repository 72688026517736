import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { DBService } from 'src/app/core/backend-adapter/db.service';
import { FILTER_TYPES, IFilterData } from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';
import { TableFilterService } from 'src/app/core/feature-modules/table-filter/table-filter.service';
import { SYSTEM_COLORS } from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { GbpPostLocationsComponent } from 'src/app/gbp-management/gbp-tab-post/components/gbp-post-locations/gbp-post-locations.component';
import { GBPService } from 'src/app/gbp-management/gbp.service';
import { IFitersObject } from 'src/app/review-management/review-management.interfaces';
import { GbpQaAddLocationComponent } from '../gbp-qa-add-location/gbp-qa-add-location.component';
import { GbpQaAddQuestionComponent } from '../gbp-qa-add-question/gbp-qa-add-question.component';
import { GbpQaCustomerAnswersComponent } from '../gbp-qa-customer-answers/gbp-qa-customer-answers.component';
import { GbpQaDeleteComponent } from '../gbp-qa-delete/gbp-qa-delete.component';
import { GbpQaEditComponent } from '../gbp-qa-edit/gbp-qa-edit.component';
import { GbpQaOwnerViewAnswersComponent } from '../gbp-qa-owner-view-answers/gbp-qa-owner-view-answers.component';
import {BroadcastService} from '@app/core/backend-adapter/broadcast.service';
import {IAccount} from '@app/acct-comps/accounts.interfaces';
import {SessionService} from '@app/core/backend-adapter/session.service';
import {Table} from 'primeng/table';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {API_URL} from '../../../../../config';
import {CookieService} from '@app/core/backend-adapter/cookie.service';
import { saveAs } from 'file-saver';
import { GBPFiltersDateService } from 'src/app/gbp-management/services/gbp-filters-date-service/gbp-filters-date-service';

@Component({
  selector: 'app-gbp-qa-owner-view',
  templateUrl: './gbp-qa-owner-view.component.html',
  styleUrls: ['./gbp-qa-owner-view.component.scss'],
})
export class GbpQaOwnerViewComponent implements OnInit, OnDestroy {
  SYSTEM_COLORS = SYSTEM_COLORS;
  @ViewChild('qandaTbl', {static: false}) qandaTbl: Table;

  keyword = '';
  allQuestions = [];
  totalQuestionsCount = 0;
  filterData: IFilterData[];
  filters: IFitersObject;
  chipsArray: string[] = [];
  loading = true;
  offset = 0;
  pageSize = 10;
  selectedRows = [];
  sortOrder = '';
  tooltipText = 'Coming soon. Please reach out to support if you need to edit a question.';
  totalLocationsCount;
  allLocations;
  createQuestionRef: DynamicDialogRef;
  private unsubscribe$ = new Subject<void>();
  private account: IAccount;

  constructor(
    public dialogService: DialogService,
    private tableFilterService: TableFilterService,
    private gbpService: GBPService,
    private notifyService: NotifyService,
    private dbService: DBService,
    private broadcastService: BroadcastService,
    private sessionService: SessionService,
    private cookieService: CookieService,
    private http: HttpClient,
    private gbpFiltersDateService: GBPFiltersDateService
  ) {}

  ngOnInit(): void {
    this.account = this.dbService.Account.findByIdent(this.sessionService.currAccount);

    this.filterData = [
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: ['This month', 'Last month'],
      },
      {
        type: FILTER_TYPES.MULTI,
        title: 'ANSWERED BY',
        options: ['Customers'],
      },
    ];

    this.setSubscriptions();
  }

  setSubscriptions() {
    this.broadcastService.subscribe('questionBatchStarted', (repl) => {
      this.loadQuestions();
    });

    this.broadcastService.subscribe('questionBatchProcessed', (repl) => {
      this.loadQuestions();
    });
  }

  exportData() {
    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'text' as any,
    };
    const url = `${API_URL}/download/sql-report/gmb_owner_qanda_all`;
    const query = `?format=csv`;

    this.http.get(`${url}${query}`, options).subscribe(
      (result) => {
        const blob = new Blob([result], {
          type: 'text/plain;charset=utf-8',
        });

        saveAs(blob, `gmb-owner-qanda-all.csv`);
        // this.notify.success('Downloaded successfully!');
      },
      (error) => {
        this.notifyService.error('Error while exporting');
      }
    );
  }

  openAnswers(rowData) {
    this.dialogService.open(GbpQaOwnerViewAnswersComponent, {
      data: {
        question: rowData,
        type: 'customer',
      },
      width: '40%',
    });
  }

  accountIsNotLeaf() {
    return !this.account.isLeaf;
  }

  createQATooltip() {
    return (!this.account.isLeaf) ? 'Please choose a sub-account before answering a question.' : '';
  }

  addQuestion() {
    this.createQuestionRef = this.dialogService.open(GbpQaAddQuestionComponent, {
      width: '50%',
    });

    // update questions list after creating the new question
    this.createQuestionRef.onClose.subscribe(() => {
      this.loadQuestions();
    });
  }

  addLocations(rowData) {
    this.gbpService.currentBulkOwnerId = rowData._id;
    this.dialogService.open(GbpQaAddLocationComponent, {
      width: '50%',
      data: {
        rowData,
      },
    });
  }

  openLocations(id: number) {
    this.gbpService.currentBulkOwnerId = id;

    this.dialogService.open(GbpPostLocationsComponent, {
      width: '50%',
      data: {
        isPagination: true,
        bulkOwner: true,
        id,
      },
    });
    // this.gbpService
    //   .getAllQALocations(id)
    //   .pipe(
    //     takeUntil(this.unsubscribe$),
    //     tap((locations) => {

    //     })
    //   )
    //   .subscribe();
  }

  openEdit(rowData) {
    if (rowData?._status === 'AP') {
      this.dialogService.open(GbpQaEditComponent, {
        data: { ...rowData },
        width: '50%',
      });
    }
  }

  openDelete(rowData) {
    this.dialogService.open(GbpQaDeleteComponent, {
      data: { ...rowData },
      width: '40%',
    });
  }

  deleteBtnClass(data) {
    return ( ['DP', 'UR', 'AR'].includes(data._status) ) ? 'disabled' : '';
  }

  private loadQuestions() {
    this.loading = true;
    this.dbService.BulkOwnerQuestionGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      order: this.sortOrder,
      where: this.buildWhereClause(),
    })
      .then(
        (repl) => {
          if (repl.collection?.length == 0) {
            this.notifyService.error('There are no questions available');
            this.allQuestions = [];
            this.totalQuestionsCount = 0;
          } else {
            this.allQuestions = repl.collection;
            this.totalQuestionsCount = repl.totalEntries;
            this.gbpService.account_id = repl.collection[0]?.account_id;

            // this.getAnswers();
          }
        },
        (err: Error) => {
          this.allQuestions = [];
          console.warn('Error loading Questions: %j', err);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  private buildWhereClause(): string {
    const filterClauses: string[] = [];
    const tempFilterArr: string[] = [];
    const isFiltersApplied = this.chipsArray.length > 0;
    filterClauses.push(`${isFiltersApplied || this.keyword ? '' : ''}`);
    const pushQueriesToMainArray = () => {
      filterClauses.push(`${tempFilterArr.join(' OR ')}`);
      tempFilterArr.length = 0;
    };

    // build query if filtering is used
    if (isFiltersApplied) {
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.length > 0 && value[0] != undefined) {
          switch (key) {
            case 'ANSWERED BY':
              value.forEach((element: string) => {
                switch (element) {
                  case 'Owner':
                    tempFilterArr.push(`NOT ans_owner IS NULL`);
                    break;
                  case 'Customers':
                    tempFilterArr.push(`EXISTS (SELECT true FROM answergmb a WHERE a.question_id IN (SELECT _id from questiongmb WHERE bulkowner_id = bulkownerquestiongmb._id) AND authorType != 'MERCHANT')`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'DATE':
              value.forEach((element: string) => {
                switch (element) {
                  case 'This month':
                    tempFilterArr.push(this.gbpFiltersDateService.inThisMonth('(SELECT min(updateTime) FROM questiongmb p WHERE p.bulkOwner_id = bulkownerquestiongmb._id)'));
                    break;
                  case 'Last month':
                    tempFilterArr.push(this.gbpFiltersDateService.inLastMonth('(SELECT min(updateTime) FROM questiongmb p WHERE p.bulkOwner_id = bulkownerquestiongmb._id)'));
                    break;
                  default:
                    const dateArray: string[] = element.split(' - ');
                    tempFilterArr.push(
                      `(SELECT min(updateTime) FROM questiongmb p WHERE p.bulkOwner_id = bulkownerquestiongmb._id) >= TO_DATE('${dateArray[0]}', 'YYYY-MM-DD') AND (SELECT min(updateTime) FROM questiongmb p WHERE p.bulkOwner_id = bulkownerquestiongmb._id) <= TO_DATE('${dateArray[1]}', 'YYYY-MM-DD')`
                    );
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
          }
        }
      }
    }

    // build query if searching is used
    if (this.keyword) {
      // tempFilterArr.push(`storeCode::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`b.questionText::text ILIKE '%${this.keyword}%'`);
      tempFilterArr.push(`b.answerText::text ILIKE '%${this.keyword}%'`);
      tempFilterArr.push(`l.storeCode::text ILIKE '%${this.keyword}%'`);
      pushQueriesToMainArray();
    }

    // join all queries
    let whereClauseString = filterClauses[0] + filterClauses.slice(1).join(' AND ');
    whereClauseString += isFiltersApplied || this.keyword ? '' : '';
    return whereClauseString;
  }

  lazyLoadQuestions(event: LazyLoadEvent) {
    this.offset = event.first;
    this.pageSize = event.rows;
    if (event.sortField) {
      this.sortOrder = `${event.sortField}${event.sortOrder < 0 ? ' DESC' : ''}`;
    }
    return this.loadQuestions();
  }

  onChipsRefresh(filters: IFitersObject) {
    this.chipsArray = [].concat.apply([], Object.values(filters)); // merge all checkbox values into one array
    this.chipsArray = this.chipsArray.filter((e) => e); // remove all possible undefined

    this.filters = filters;
    this.loadQuestions();
  }

  ngOnDestroy(): void {
    this.tableFilterService.clearFilters(); // clear table filters
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
