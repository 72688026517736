import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class MapsInsightsConfigService {
  public stackOptions = {
    scales: {
      xAxes: {
        stacked: true,
        grid: {
          // hide vertical grid
          color: 'transparent',
        },
      },
      yAxes: {
        stacked: true,
        grace: '30%', // adds 30% value to top of chart (prevents label overflow)
        afterTickToLabelConversion(axis) {
          // converts Y axis labels to short numbers
          for (const tick of axis.ticks) {
            if (tick.value >= 1000 && tick.value <= 999999) {
              tick.label = tick.label.split(',')[0] + 'k';
            } else if (tick.value >= 1000000) {
              tick.label = tick.label.split(',')[0] + 'M';
            }
          }
        },
      },
    },
    layout: {
      padding: 10, // overall padding
    },
    responsive: true,
    plugins: {
      datalabels: {
        // totals displayed above each bar
        display: true,
        anchor: 'end',
        align: 'end',
        labels: {
          display: true,
          title: {
            font: {
              // weight: 'bold',
              size: 12,
            },
            color: '#333',
          },
        },
        // replaces default (broken down) datalabels with total
        formatter(value, context) {
          const datasets = context.chart.data.datasets;
          if (context.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.map((dataset) => {
              sum += dataset.data[context.dataIndex];
            });
            return sum.toLocaleString();
          } else {
            return '';
          }
        },
      },
      tooltip: {
        interaction: {
          mode: 'x', // displays broken down values
        },
        yAlign: 'top', // caret align
        xAlign: 'left', // caret align
        titleAlign: 'center', // inside tooltip
        // reverse tooltip items order (was being rendered in reverse)
        itemSort(a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
        backgroundColor: '#414042',
        callbacks: {
          // change color boxes to circles
          labelColor(tooltipItem) {
            const meta = tooltipItem.chart.getDatasetMeta(tooltipItem.datasetIndex);
            const options = meta.controller.getStyle(tooltipItem.dataIndex);
            return {
              borderColor: '#00000000',
              backgroundColor: options.backgroundColor,
              borderWidth: 0,
              borderRadius: 6,
            };
          },
        },
        // eliminate color boxes white border
        multiKeyBackground: '#00000000',
      },
      legend: {
        display: false,
      },
    },
  };

  public setBarThickness(range: number, showReviewsFlag: boolean): number {
    switch (range) {
      case 3:
        return showReviewsFlag ? 80 : 100;
      case 4:
        return showReviewsFlag ? 70 : 100;
      case 6:
        return showReviewsFlag ? 50 : 80;
      case 12:
        return showReviewsFlag ? 30 : 60;
      case 24:
        return showReviewsFlag ? 20 : 50;
      default:
        return showReviewsFlag ? 80 : 100;
    }
  }

  public setMaxBarThickness(range: number, showReviewsFlag: boolean): number {
    switch (range) {
      case 3:
        return showReviewsFlag ? 90 : 110;
      case 4:
        return showReviewsFlag ? 80 : 110;
      case 6:
        return showReviewsFlag ? 60 : 90;
      case 12:
        return showReviewsFlag ? 40 : 70;
      case 24:
        return showReviewsFlag ? 30 : 60;
      default:
        return showReviewsFlag ? 90 : 110;
    }
  }

}
