import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';
import { ProgressComponent } from 'src/app/core/feature-modules/progress/progress.component';
import { SuccessComponent } from 'src/app/gbp-management/gbp-tab-post/components/gbp-post-create/success/success.component';
import { GbpPostLocationsComponent } from 'src/app/gbp-management/gbp-tab-post/components/gbp-post-locations/gbp-post-locations.component';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-qa-add-question',
  templateUrl: './gbp-qa-add-question.component.html',
  styleUrls: ['./gbp-qa-add-question.component.scss'],
})
export class GbpQaAddQuestionComponent implements OnInit, OnDestroy {
  stepsLabels = ['Q&A', 'Schedule and Select Locations', 'Review and Submit'];
  qaForm = new FormGroup({
    qa: new FormGroup({
      question: new FormControl('', Validators.required),
      answer: new FormControl('', Validators.required),
      languageCode: new FormControl('', Validators.required),
    }),
    schedule: new FormGroup({
      isScheduled: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
      timeZone: new FormControl(''),
      location: new FormControl(''),
      isSelectedAll: new FormControl(''),
    }),
  });
  qAGroup: FormGroup;
  scheduleGroup: FormGroup;
  formGroupName = 'qa';
  selectedLocationsLength: number;
  activeIndex = 0;
  locations = [];
  timeZones = [];
  currentLocations = [];
  saveObj;
  languages = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'es', label: 'Español' }
  ];
  filtered = [];
  filterValue = '';
  // paginatorRows = 500;
  // paginatorLength = 0;
  buttonLabel = 'SAVE AND NEXT';
  unsubscribe$ = new Subject<void>();
  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {
    this.qAGroup = this.qaForm.controls.qa as FormGroup;
    this.scheduleGroup = this.qaForm.controls.schedule as FormGroup;
    this.timeZones = TIME_ZONES;

    // Get locations from service
    this.gbpService
      .getAllLocations(['_id', 'storeCode', 'businessName', 'city', 'state', 'tags', 'labels'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locations) => {
        this.locations = locations;
        this.currentLocations = this.locations; //.slice(0, this.paginatorRows);
        // this.paginatorLength = this.locations.length;
        this.filtered = this.locations;
      });

    this.scheduleGroup.controls.isSelectedAll.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      if (value) {
        this.scheduleGroup.controls.location.setValue(this.locations);
      } else {
        this.scheduleGroup.controls.location.setValue([]);
      }
    });

    this.scheduleGroup.controls.location.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.selectedLocationsLength = value.length;

      if (this.selectedLocationsLength !== this.locations.length) {
        this.scheduleGroup.controls.isSelectedAll.setValue(false, { emitEvent: false });
      }
    });
  }

  goNext(progress: ProgressComponent) {
    progress.next();
  }

  onStateChange(event) {
    if (event.status == 'completed') {
      // send data to backend

      this.saveObj = this.prepareQaSaveObject(this.qAGroup.value, this.scheduleGroup.value);

      this.gbpService.updateBulkOwnerQa(this.saveObj);
      this.ref.close();
      this.dialogService.open(SuccessComponent, {
        width: '20%',
      });
    }
    this.activeIndex = event.activeIndex;
    switch (this.activeIndex) {
      case 0: {
        this.formGroupName = 'qa';
        this.buttonLabel = 'SAVE AND NEXT';
        break;
      }
      case 1: {
        this.formGroupName = 'schedule';
        this.buttonLabel = 'SAVE AND NEXT';
        break;
      }
      case 2: {
        this.scheduleGroup.value?.isScheduled ? (this.buttonLabel = 'SCHEDULE') : (this.buttonLabel = 'PUBLISH NOW');
      }
    }
  }

  openLocations() {
    this.dialogService.open(GbpPostLocationsComponent, {
      width: '75%',
      height: '75%',
      data: {
        locations: [...this.scheduleGroup.value.location],
      },
    });
  }

  onPanelHide() {
    this.filterValue = '';
    this.currentLocations = this.locations; //.slice(0, this.paginatorRows);
  }

  onPageChange(event) {
    this.currentLocations = this.filtered; //.slice(event.first, +event.first + +event.rows);
  }

  onFilter(event) {
    const searchStr = event.target.value.toLowerCase();
    const pattern = new RegExp('\\b' + searchStr);
    this.filtered = [
      ...this.locations.filter(
        (elem) => elem.join(' ').toLowerCase().match(pattern)
      ),
    ];

    // this.paginatorLength = this.filtered.length;
    this.currentLocations = this.filtered; //.slice(0, this.paginatorRows);
  }

  closeDialog() {
    this.ref.close();
  }

  private prepareQaSaveObject(qAGroup, scheduleGroup) {
    const tempObj = {
      _status: '',
      account_id: 0,
      scheduleTime: new RZLib.Timestamp().setNow(),
      scheduleTimeZone: '',
      languageCode: '',
      questionText: '',
      answerText: '',
      location_ids: [],
    };
    tempObj._status = 'AP';
    tempObj.account_id = this.gbpService.account_id;
    tempObj.scheduleTime = scheduleGroup.isScheduled
      ? this.gbpService.prepareDate(scheduleGroup.date, tempObj.scheduleTime, scheduleGroup.time) : '';
    tempObj.scheduleTimeZone = scheduleGroup.isScheduled ? scheduleGroup.timeZone : '';
    tempObj.languageCode = qAGroup.languageCode;
    tempObj.questionText = qAGroup.question;
    tempObj.answerText = qAGroup.answer;
    tempObj.location_ids = scheduleGroup.isSelectedAll
      ? '*'
      : scheduleGroup.location?.length > 0
      ? scheduleGroup.location.map((l) => l[0])
      : [];
    return tempObj;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
