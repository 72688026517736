import { environment } from './environments/environment';

export interface Env {
  production: boolean;
  name: string;
  apiUrl: string;
  WSSUrl: string;
}

export const envName: string = environment.name;
export const API_URL: string = environment.apiUrl;
export const WSS_Url: string = environment.WSSUrl;
