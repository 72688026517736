import { Pipe, PipeTransform } from '@angular/core';
import { ACTIVITY_STATUS } from '../gbp-tabs.constants';

@Pipe({
  name: 'activityStatus',
})
export class ActivityStatusPipe implements PipeTransform {
  activityStatus: typeof ACTIVITY_STATUS = ACTIVITY_STATUS;

  transform(status: string): string {
    switch (status) {
      case this.activityStatus.PUBLISHED:
        return 'activity-button-published';
      case this.activityStatus.DRAFT:
        return 'activity-button-draft';
      case this.activityStatus.SCHEDULED:
        return 'activity-button-scheduled';
      case this.activityStatus.INACTIVE:
        return 'activity-button-inactive';
      case this.activityStatus.UNPUBLISHED:
        return 'activity-button-unpublished';
      case this.activityStatus.DELETE_SCHEDULED:
      case this.activityStatus.UNPUBLISH_SCHEDULED:
      case this.activityStatus.PROCESSING:
      case this.activityStatus.UNPUBLISH_PROCESSING:
      case this.activityStatus.DELETE_PROCESSING:
        return 'activity-button-processing';
      case this.activityStatus.FAILED:
        return 'activity-button-failed';
    }
  }
}
