import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from '@app/core/backend-adapter/session.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface MediaFileObj {
  status: string;
  account: string;
  fileName: string;
  internalName: string;
  internalURL: string;
}

@Component({
  selector: 'app-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss'],
})

export class UploadMediaComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: FileUpload;
  @ViewChild('uploadContainerRef', { static: false }) uploadContainerRef: ElementRef<HTMLElement>;
  @Input() multiple = false;
  @Input() isCustomUrl = false; // optional
  @Input() onlyCustomURL = false; // optional
  @Input() customURL = ''; // optional
  @Input() mediaCategory: string;
  @Input() mediaRequirements: string[] = [];
  @Input() mediaTitle: string;
  @Output() imagesUrlChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  private ngUnsubscribe$ = new Subject()

  selectedFiles: any[] = [];
  selectedImagesUrl: string[] = [];
  isScroll = false;
  tooltipText =
    '<u>Images</u></br>' +
    '<b>Format:</b>&nbsp;JPEG or PNG (no multi-frame)</br></br>' +
    '<b>Size:</b>&nbsp;5kB - 10MB</br></br>' +
    '<b>Resolution:</b>&nbsp;720&times;720px sugg.</br></br>' +
    '<b>Cover Image:</b>&nbsp;16:9 ratio (min. 420px &times; 270px)</br></br>' +
    '<b>Dimensions:</b>&nbsp;Min 250px; Max 10000px</br></br>' +
    '<u>Videos</u></br>' +
    '<b>Format:</b>&nbsp;MPEG4/MP4</br></br>' +
    '<b>Size:</b>&nbsp;75MB max</br></br>' +
    '<b>Resolution:</b>&nbsp;720px or higher</br></br>' +
    '<b>Duration:</b>&nbsp;30s max';
  public spinnerAnimationDuration: string = '0.8s';
  public isImageLoading: boolean = false;
  public errorLoadingImgMsg: string = '';
  public isFileTypeVideo: boolean = false;

  constructor(private http: HttpClient,
    private sessionService: SessionService,
    @Inject('API_URL') private API_URL: string) {
  }

  ngOnInit(): void {
    if (this.customURL) {
      this.selectedImagesUrl.push(this.customURL);
    }
  }

  public onSelect(event): void {
    //Validations for all photos less then 5MB.
    if (event['currentFiles']?.type === 'image/png' || event['currentFiles']?.type === 'image/jpeg' || event['currentFiles']?.type === 'image/jpg') {
      if (event['currentFiles'][0]?.size <= 5000000) {
        this.upload(event);
      } else {
        this.errorLoadingImgMsg = 'Error: Media size greater than 5MB; please select media less than 5MB.';
      }
    } else {
      // If video is selected size should be greater than 10KB and less then 75MB.
      if (event['currentFiles'][0]?.size >= 10000 && event['currentFiles'][0]?.size <= 75000000)  {
        this.upload(event);
      }  else {
        this.errorLoadingImgMsg = 'Error: Media size should be greater than 10KB and less than 75MB. Please adjust your media and try again.';
      }
    }
  }

  private upload(event): void {
    this.errorLoadingImgMsg = this.errorLoadingImgMsg.length ? '' : this.errorLoadingImgMsg;
    this.isImageLoading = true;
    const formData = new FormData();
    // handle binary upload/drag&drop
    if (event?.files) {
      for (const file of event.files) {
        if (this.selectedFiles.some((element) => element.name === file.name && element.size === file.size)) {
          // check if file already exist in array
          continue;
        }
        this.selectedFiles.push(file); // add file to array with selected files
        formData.append('imgFile', file);
        formData.append('imgCategory', this.mediaCategory);
      }
    }
    // handle URL
    else if (event) {
      formData.append('imgURL', event);
      formData.append('imgCategory', this.mediaCategory);
    }

    const headers = new HttpHeaders(
      { Authorization: `Bearer ${this.sessionService.AUTH_ID}` }
    );

    const upload$ = this.http.post<MediaFileObj>(
      `${this.API_URL}/upload/media-file`,
      formData,
      { headers }
    );

    upload$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (data: MediaFileObj) => {
          this.isImageLoading = false;
          if (data['status'] === 'err') {
            this.errorLoadingImgMsg = data['message'];
            this.selectedFiles = [];
          } else {
            this.selectedImagesUrl.push(data.internalURL);
            this.isFileTypeVideo =  new URL(this.selectedImagesUrl[0]).href.includes('.mp4');
            this.imagesUrlChanged.emit(this.selectedImagesUrl);
          }
        }, (error) => {
          this.isImageLoading = false;
          this.errorLoadingImgMsg = 'Error loading media, please verify media requirements.';
          this.selectedFiles = [];
        });

    this.setScroll();
  }

  public submitURL(): void {
    this.upload(this.customURL);
  }

  onDragOver(event) {
    this.uploadContainerRef.nativeElement.classList.add('dragged-over');
    this.uploadContainerRef.nativeElement.classList.remove('dragged-leave');
    event.preventDefault();
  }

  onDragLeave(event) {
    this.uploadContainerRef.nativeElement.classList.add('dragged-leave');
    this.uploadContainerRef.nativeElement.classList.remove('dragged-over');
    event.preventDefault();
  }

  // From drag and drop
  onDropSuccess(event) {
    event.preventDefault();
    this.multiple ? this.upload(event.dataTransfer) : this.upload(event.dataTransfer);
  }

  setScroll() {
    this.selectedFiles.length !== 0 ? (this.isScroll = true) : (this.isScroll = false);
  }

  chooseFile() {
    this.fileInput.choose();
  }

  cancelItem(event, index: number) {
    this.fileInput.remove(event, index);
    this.selectedFiles.splice(index, 1);
    this.selectedImagesUrl.splice(index, 1);
    this.imagesUrlChanged.emit(this.selectedImagesUrl);
    this.setScroll();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  openMediaGuidelines() {
    window.open('https://support.google.com/contributionpolicy/answer/7411351', '_blank');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
