import { NgModule } from '@angular/core';
import { PrimengModule } from '../primeng.module';
import { ProgressComponent } from './progress.component';
import { ProgressStepComponent } from './progress-step/progress-step.component';
import { ProgressStepDirective } from './progress-step.directive';

@NgModule({
  imports: [PrimengModule],
  declarations: [ProgressComponent, ProgressStepComponent, ProgressStepDirective],
  exports: [ProgressComponent, ProgressStepComponent, ProgressStepDirective],
})
export class ProgressrModule {}
