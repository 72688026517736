import { NgModule } from '@angular/core';
import { PrimengModule } from '../primeng.module';
import { LocationsListComponent } from './locations-list.component';
import { DateTimePipe } from '../../pipes/date-time.pipe';

@NgModule({
  imports: [PrimengModule],
  declarations: [LocationsListComponent, DateTimePipe],
  exports: [LocationsListComponent],
})
export class LocationsListModule {}
