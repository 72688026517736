import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading, ExtraOptions } from '@angular/router';
import { MenubarModule } from 'primeng/menubar';

import { ClientsComponent } from './clients/clients.component';
import { UsersComponent } from './users/users.component';

import { PartiesComponent } from './parties/parties.component';
import { SqlReportsComponent } from './reports/sql-reports/sql-reports.component';
import { LoginsComponent } from './admin/logins/logins.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { ReportsComponent } from './reports/reports.component';
import { GbpManagementComponent } from './gbp-management/gbp-management.component';
import { UtmManagementComponent } from './utm-management/utm-management.component';
import { ReviewManagementComponent } from './review-management/review-management.component';
import { SocialManagementComponent } from './social-management/social-management.component';
import { LocalAdsComponent } from './local-ads/local-ads.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';

import { NotFoundComponent } from './core/elements/pages/not-found/not-found.component';
import { IsAuthGuard } from './is-auth.guard';
import { PublicWrapperComponent } from './core/layouts/public-wrapper/public-wrapper.component';
import { PrivateWrapperComponent } from './core/layouts/private-wrapper/private-wrapper.component';
import { PaletteComponent } from './core/elements/pages/palette/palette.component';
import { AccuracyReportsComponent } from '@app/reports/accuracy-reports/accuracy-reports.component';
import { ManageNotificationsTabComponent } from './review-management/manage-notifications-tab/manage-notifications-tab.component';
import { ManageNotificationsGaurd } from './review-management/gaurds/manage-notifications.gaurd';
import { LocChangeLogComponent } from './loc-change-log/loc-change-log.component';

const routes: Routes = [
  {
    component: PublicWrapperComponent,
    path: '',
    children: [{ path: '', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule) }],
  },
  {
    component: PrivateWrapperComponent,
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },
  {
    component: PrivateWrapperComponent,
    path: '',
    canActivate: [IsAuthGuard],
    canLoad: [IsAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./acct-comps/accounts.module').then((m) => m.AccountsModule),
      },
    ],
  },
  {
    component: PrivateWrapperComponent,
    path: '',
    canActivate: [IsAuthGuard],
    canLoad: [IsAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./loc-comps/locations.module').then((m) => m.LocationsModule),
      },
    ],
  },
  {
    component: PrivateWrapperComponent,
    path: '',
    children: [
      {
        path: 'gbp-mgmt',
        component: GbpManagementComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'review-mgmt',
        component: ReviewManagementComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'social-mgmt',
        component: SocialManagementComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'utm-mgmt',
        component: UtmManagementComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'local-ads',
        component: LocalAdsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'sql-report',
        component: SqlReportsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'sql-report/:id',
        component: SqlReportsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'reports/accuracy',
        component: AccuracyReportsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
        canActivate: [IsAuthGuard],
      },
      // admin
      {
        path: 'logins',
        component: LoginsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'parties',
        component: PartiesComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'palette',
        component: PaletteComponent,
        canActivate: [IsAuthGuard],
      },
      {
        path: 'manage-notifications',
        component: ManageNotificationsTabComponent,
        canDeactivate: [ManageNotificationsGaurd]
      },
      {
        path: 'location-change-log',
        component: LocChangeLogComponent,
        canActivate: [IsAuthGuard],
      }
    ],
  },
  {
    component: PublicWrapperComponent,
    path: '',
    children: [
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // relativeLinkResolution: 'legacy',
      preloadingStrategy: NoPreloading,
      initialNavigation: 'enabled',
      // enableTracing: true,
      // initialNavigation: 'enabledNonBlocking'
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    } as unknown | ExtraOptions),
  ],
  exports: [RouterModule, MenubarModule],
})
export class AppRoutingModule {}
