import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { GetStatusPipe } from './get-status.pipe';

@NgModule({
  imports: [RSCommonModule],
  declarations: [GetStatusPipe],
  exports: [GetStatusPipe],
})
export class GetStatusModule {}
