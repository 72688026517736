import { Component, HostBinding, Input } from '@angular/core';
import { ICardInsightsNumbers } from './card-insights.interfaces';

@Component({
  selector: 'app-card-insights',
  templateUrl: './card-insights.component.html',
  styleUrls: ['./card-insights.component.scss'],
})
export class CardInsightsComponent {
  @Input() headerTitle: string;
  @Input() numbers: ICardInsightsNumbers[];
  @Input() subNumbers: ICardInsightsNumbers[];
  @HostBinding('class.rs-card-container') componentClass = true;
}
