import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { FormsModule } from '@angular/forms';
import { GbpUtmFormComponent } from './gbp-utm-form.component';
import { GbpUtmFieldChooserComponent } from './gbp-utm-field-chooser.component';

@NgModule({
  imports: [RSCommonModule, FormsModule],
  declarations: [GbpUtmFormComponent, GbpUtmFieldChooserComponent],
  exports: [GbpUtmFormComponent, GbpUtmFieldChooserComponent],
})
export class GbpUtmFormModule {}
