import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UTMService } from '../../../utm-management/services/utm.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gbp-utm-form',
  templateUrl: './gbp-utm-form.component.html',
  styleUrls: ['./gbp-utm-form.component.scss'],
})
export class GbpUtmFormComponent implements OnChanges {
  @Input() ctaURL: string;
  @Input() postInfoGroup: FormGroup;
  @Output() onCtaUrlWithUtmChange = new EventEmitter<string>();

  public showGbpUtmFieldChooserDialog: boolean;
  public currentUtmField: string;

  customFields: any[];
  selectedField: string; // To store the user's selection
  urlWithUtm: string;

  public localCtaUrl: string;

  public utmSource: string;
  public utmMedium: string;
  public utmCampaign: string;
  public utmTerm: string;
  public utmContent: string;
  public utmId: string;
  public utmSourcePlatform: string;
  constructor(private utmService: UTMService) {}

  ngOnInit(): void {
    // Access the customFields from UTMService
    this.customFields = this.utmService.customFields;
    if (this.postInfoGroup?.controls?.ctaURL?.value) {
      this.ctaURL = this.postInfoGroup?.controls?.ctaURL?.value;
    }
    this.refreshLocalFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ctaURL) {
      // The inputProperty has changed
      this.refreshLocalFields();
    }
  }

  refreshLocalFields() {
    this.localCtaUrl = this.ctaURL;
    this.utmService.parseGmbPostUrl(this.localCtaUrl);
    const queryParams = this.utmService.getGmbPostQueryParams();
    this.utmSource = queryParams['utm_source'];
    this.utmMedium = queryParams['utm_medium'];
    this.utmCampaign = queryParams['utm_campaign'];
    this.utmTerm = queryParams['utm_term'];
    this.utmContent = queryParams['utm_content'];
    this.utmId = queryParams['utm_id'];
    this.utmSourcePlatform = queryParams['utm_source_platform'];
    this.utmSourcePlatform = queryParams['utm_source_platform'];
  }

  onUtmSourceInputChange(event: Event) {
    this.utmSource = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_source', this.utmSource);
    this.refreshCtaUrl();
  }

  onUtmMediumInputChange(event: Event) {
    this.utmMedium = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_medium', this.utmMedium);
    this.refreshCtaUrl();
  }

  onUtmCampaignInputChange(event: Event) {
    this.utmCampaign = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_campaign', this.utmCampaign);
    this.refreshCtaUrl();
  }

  onUtmTermInputChange(event: Event) {
    this.utmTerm = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_term', this.utmTerm);
    this.refreshCtaUrl();
  }

  onUtmContentInputChange(event: Event) {
    this.utmContent = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_content', this.utmContent);
    this.refreshCtaUrl();
  }

  onUtmIdInputChange(event: Event) {
    this.utmId = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_id', this.utmId);
    this.refreshCtaUrl();
  }

  onUtmSourcePlatformInputChange(event: Event) {
    this.utmSourcePlatform = event as unknown as string;
    this.utmService.updateCtaUrlUtmCode('utm_source_platform', this.utmSourcePlatform);
    this.refreshCtaUrl();
  }

  refreshCtaUrl() {
    this.localCtaUrl = this.utmService.getGmbPostUrl();
    this.onCtaUrlWithUtmChange.emit(this.localCtaUrl);
  }

  handleSelectField(event: { utmField: string; value: string }): void {
    this.urlWithUtm = this.localCtaUrl + this.selectedField;

    // Depending on the utmField, we set the corresponding property:
    switch (event.utmField) {
      case 'utm_source':
        this.utmSource = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmSource);
        this.refreshCtaUrl();
        break;
      case 'utm_medium':
        this.utmMedium = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmMedium);
        this.refreshCtaUrl();
        break;
      case 'utm_campaign':
        this.utmCampaign = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmCampaign);
        this.refreshCtaUrl();
        break;
      case 'utm_term':
        this.utmTerm = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmTerm);
        this.refreshCtaUrl();
        break;
      case 'utm_content':
        this.utmContent = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmContent);
        this.refreshCtaUrl();
        break;
      case 'utm_id':
        this.utmId = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmId);
        this.refreshCtaUrl();
        break;
      case 'utm_source_platform':
        this.utmSourcePlatform = event.value;
        this.utmService.updateCtaUrlUtmCode(event.utmField, this.utmSourcePlatform);
        this.refreshCtaUrl();
        break;
      default:
        break;
    }
    // We close the field chooser:
    this.showGbpUtmFieldChooserDialog = false;
  }

  openFieldChooser(field: string) {
    this.currentUtmField = field;
    this.showGbpUtmFieldChooserDialog = true;
  }

  onClose() {
    this.showGbpUtmFieldChooserDialog = false;
  }
}
