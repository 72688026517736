<div class="review-cards">
  <div class="card first"></div>
  <div class="card second"></div>
  <div class="card third"></div>
  <div class="card fourth"></div>
  <div class="card fifth">
    <div class="button-container">
      <button pButton type="button" icon="pi pi-chevron-left" class="switch-card" (click)="switchCard(-1)"></button>
    </div>
    <div class="middle-content">
      <div class="review-number">
        <p class="bold small">REVIEW {{ selectedReview.pos }} OF {{ selectedReviews.length }}</p>
      </div>
      <div class="review-content">
        <div class="directory-store">
          <img src="assets/directories/round/middle.jpeg" alt="directory logo" />
          <span>
            <p class="small">STORE CODE</p>
            <p class="bold">{{ selectedReview.storeCode }}</p>
          </span>
        </div>
        <div class="review-details">
          <div class="full-review">
            <div class="full-review_comment">"{{ selectedReview.reviewerComment }}"</div>
          </div>
          <div class="review-footer">
            <hr />
            <div>
              <div class="poster-details">
                <p class="bold">{{ selectedReview.reviewerName }}&nbsp;</p>
                <p>rated this location&nbsp;</p>
                <p-rating [ngModel]="selectedReview.starRating" readonly="true" stars="5" [cancel]="false"></p-rating>
                <p>&nbsp;on&nbsp;</p>
                <p class="bold" *ngIf="selectedReview.reviewCreatedAt !== null">
                  {{
                    selectedReview.reviewCreatedAt._year_ +
                      '/' +
                      selectedReview.reviewCreatedAt._month_ +
                      '/' +
                      selectedReview.reviewCreatedAt.day
                  }}
                </p>
              </div>
              <!-- section hidden while we figure out what to link to -->
              <!-- <div class="external-review">
                        <p>SEE ORIGINAL REVIEW</p>
                        <button
                          pButton
                          type="button"
                          icon="pi pi-external-link"
                          class="open-review"
                        ></button>
                      </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button pButton type="button" icon="pi pi-chevron-right" class="switch-card" (click)="switchCard(+1)"></button>
    </div>
  </div>
</div>

<div class="reply">
  <textarea
    pInputTextarea
    [(ngModel)]="replyText"
    style="resize: none"
    maxlength="350"
    placeholder="Type your response here..."
  ></textarea>
  <div class="counter">{{ replyText.length }} of 350 characters</div>
  <div class="footer-content">
    <button pButton label="CANCEL" class="p-button-outlined p-button-secondary" (click)="cancel()"></button>
    <button
      pButton
      [disabled]="!replyText"
      [label]="'PUBLISH TO ALL (' + selectedReviews.length + ')'"
      (click)="confirmReply()"
    ></button>
  </div>
</div>
