import { Component, OnInit } from '@angular/core';
import { RsThemes } from 'src/app/core/feature-modules/whitelabel/style-changer/style-changer.interfaces';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { StyleChangerService } from './../../../feature-modules/whitelabel/style-changer/style-changer.service';

@Component({
  selector: 'app-palette',
  templateUrl: './palette.component.html',
  styleUrls: ['./palette.component.scss'],
})
export class PaletteComponent implements OnInit {
  RsThemes = RsThemes;

  obj = { name: 'asd' };
  form;

  constructor(
    public notifyService: NotifyService,
    private styleChangerService: StyleChangerService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      storecode: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  ngOnInit(): void {}

  changeTheme(theme: RsThemes) {
    this.styleChangerService.setCurrentTheme(theme);
  }
}
