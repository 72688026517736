import {Component, OnInit, SecurityContext} from '@angular/core';
import {SocketService} from '@app/core/backend-adapter/socket.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-social-management',
  templateUrl: './social-management.component.html',
  styleUrls: ['./social-management.component.scss']
})
export class SocialManagementComponent implements OnInit {
  public ssoUrl: SafeUrl;

  constructor(
    private socketService: SocketService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.getSocialUrl();
  }

  getSocialUrl() {
    this.socketService.sendRequest('get-rallio-sso-url', {}).then(
      (repl: any) => {
        this.ssoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(repl.ssoURL);
      }
    );
  }

}
