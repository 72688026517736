
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConvertCSVToJSONService } from '../shared/services/convert-csv-to-json.service';
import { ReviewsService } from '../../review-management/reviews.service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { Subject } from 'rxjs';
import { AccountsService } from 'src/app/acct-comps/accounts.service';
import { ICollectionWrapper } from 'src/app/core/backend-adapter/api/api.interfaces';
import { IDates } from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';
import { formatDate } from '@angular/common';
import { MapsInsightsConfigService } from '../shared/services/apple-maps-graph-config.service';
import { ViewGraphReportsService } from '../shared/services/view-graph-reports.service';

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-reports-tools',
  templateUrl: './reports-tools.component.html',
  styleUrls: ['./reports-tools.component.scss']
})

export class ReportsToolsComponent implements OnInit, OnDestroy {
  public reportSource = [
    {
      id: 1,
      btnName: 'Reviews',
      btnValue: 'reviews',
    },
    {
      id: 2,
      btnName: 'Insights: Google',
      btnValue: 'gmbinsights'
    },
    {
      id: 3,
      btnName: 'Insights: Apple',
      btnValue: 'aplinsights'
    },
    {
      id: 4,
      btnName: 'Insights: Yelp',
      btnValue: 'yelpinsights'
    },
    {
      id: 5,
      btnName: 'Insights: Bing',
      btnValue: 'binginsights'
    }
  ];
  public reportsType = [
    {
      id: 1,
      btnName: 'Per Account',
      btnValue: 'account'
    },
    {
      id: 2,
      btnName: 'Per Tag',
      btnValue: 'tag'
    },
    {
      id: 3,
      btnName: 'Per Location',
      btnValue: 'location'
    }
  ];
  public filteredData = [
    {
      label: 'Recent',
      value: 'recent',
      items: [
        { label: 'Yesterday', value: 'day_previous' },
        { label: 'Current week', value: 'week_current' },
        { label: 'Last week', value: 'week_previous' },
        { label: 'Current month', value: 'month_current' },
        { label: 'Last month', value: 'month_previous' },
        { label: 'Last 30 days (daily)', value: 'day_30' },
      ]
    },
    {
      label: 'By week',
      value: 'byWeek',
      items: [
        { label: 'Last 13 weeks (3 mo)', value: 'week_13' },
        { label: 'Last 26 weeks (6 mo)', value: 'week_26' },
      ]
    },
    {
      label: 'By month',
      value: 'byMonths',
      items: [
        { label: 'Last 3 months', value: 'month_3' },
        { label: 'Last 6 months', value: 'month_6' },
        { label: 'Last 12 months', value: 'month_12' },
        { label: 'Last 24 months', value: 'month_24' },
      ]
    },
    {
      label: 'By quarter',
      value: 'byQuarter',
      items: [
        { label: 'Last 4 quarters', value: 'quarter_4' },
        { label: 'Last 8 quarters', value: 'quarter_8' },
      ]
    },
    {
      label: 'All time',
      value: 'allTime',
      items: [
        { label: 'Annual', value: 'year_10' },
        { label: 'Total', value: 'alltime_current' },
      ]
    },
    {
      label: 'Date',
      value: 'date',
      items: [{ label: 'Custom date', value: 'period' }]
    },
  ];

  public actionItems = [
    {
      label: 'CSV',
      command: () => { this.downloadCSVFile('csv') },
      disabled: false,
    },
    {
      label: 'XLSX',
      command: () => { this.downloadXLSXFile('xls') },
      disabled: false,
    },
  ];
  public viewReportData: any[] = [];
  public sourceTab: string = 'reviews';
  public reportType: string = 'account';
  public selectedTimePeriod: string | undefined;
  public cols: Column[] = [];
  // public enablegoogleInsights: boolean = false;
  // public enableAppleInsights: boolean = false;
  // public enableYelpInsights: boolean = false;
  public first: number = 0;
  public pageSize: number = 10;
  public disableReviews: boolean = false
  public disableGoogleInsights: boolean = false;
  public disableAppleInsights: boolean = false;
  public disableYelpInsights: boolean = false;
  public disableBingInsights: boolean = false;
  public dateValue: IDates = {
    start: null,
    end: null,
  };
  public spinnerAnimationDuration: string = '0.8s';
  public loadingReportView: boolean = false;
  public graphData: any[] = [];
  public insightsGraphConfig: unknown;
  public totalClicks: any = {};
  public graphLabel: string[] = [];

  private ngUnsubscribe$ = new Subject();
  private customStartDate;
  private customEndDate;

  constructor(
    private convertCSVToJSONService: ConvertCSVToJSONService,
    private reviewsService: ReviewsService,
    private sessionService: SessionService,
    private accountsService: AccountsService,
    private mapsInsightsConfigService: MapsInsightsConfigService,
    private viewGraphReportsService: ViewGraphReportsService
  ) { }

  ngOnInit(): void {
    this.selectedTimePeriod = 'month_current';
    this.sessionService.getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: IAccount) => {
        const { gmb = undefined, appleMaps = undefined, yelp = undefined  } = typeof res?.global === 'string' ? JSON.parse(res?.global) : res?.global;
      });

    this.getAccountDetails();
  }

  public selectSourceType(sourceType: string): void {
    this.sourceTab = sourceType;
  }

  public setReportType(reportType: string): void {
    this.reportType = reportType;
  }

  public downloadCSVFile(fileType: string): void {
    let params: string;
    params = this.checkCustomDateSelection(params, fileType);
    const fileName = this.generateDynamicFileName();
    this.generateReport(params, fileType, fileName);
  }

  public downloadXLSXFile(fileType: string): void {
    let params: string;
    params = this.checkCustomDateSelection(params, 'csv');
    const fileName = this.generateDynamicFileName();
    this.generateReport(params, fileType, fileName);
  }

  private generateReport(params: string, fileType, fileName): void {
    this.loadingReportView = true;
    const reportData$ = this.reviewsService.getExportedReport(params);
    reportData$.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (response) => {
          const data = this.convertCSVToJSONService.csvToJson(response);
          if (fileType === 'csv') {
            this.reviewsService.genCSVReport(data['data'], fileType, fileName);

          } else {
            this.reviewsService.exportAsExcelFile(data['data'], fileName);
          }
          this.loadingReportView = false;
        },
        (err) => {
          this.loadingReportView = false;
        }
      );
  }

  public viewReport(viewType: string): void {
    this.resetData();

    let params;
    const fileType = 'csv';
    const config = {
      header: true
    };

    // Display loading spinner...
    this.loadingReportView = true;

    // Reset table if table already has data.
    if (this.cols.length) {
      this.cols = [];
    }
    // Check if custom date option is selected.
    params = this.checkCustomDateSelection(params, fileType);
    const viewReportData$ = this.reviewsService.getExportedReport(params);
    viewReportData$.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (response) => {
          const headers = this.convertCSVToJSONService.csvToJson(response)['data'][0];
          headers.forEach((header) => {
            const field = header
            const newHeader: string = header.replace(/_/g, ' ').replace(/__/g, ' - ');
            this.cols.push({
              field: field,
              header: `${newHeader.slice(0, 1).toUpperCase()}${newHeader.slice(1)}`
            });
          });
          if (viewType === 'tableView') {
            this.viewReportData = this.convertCSVToJSONService.csvToJson(response, config)['data'];
          }

          if (viewType === 'graphView') {
            this.insightsGraphConfig = this.mapsInsightsConfigService.stackOptions;
            const data = this.convertCSVToJSONService.csvToJson(response, config)['data'];
            if (this.sourceTab === 'gmbinsights') {
              this.graphData = this.viewGraphReportsService.mapGoogleInsightsData(data, this.graphLabel, this.selectedTimePeriod);
            }
            if (this.sourceTab === 'aplinsights') {
              this.graphData = this.viewGraphReportsService.mapAppleInsightsData(data, this.graphLabel, this.selectedTimePeriod);
            }
            if (this.sourceTab === 'reviews') {
              this.graphData = this.viewGraphReportsService.mapReviewsInsights(data, this.graphLabel, this.selectedTimePeriod);
            }
          }

          this.loadingReportView = false;
        },
        (err) => {
          this.loadingReportView = false;
        }
      );
  }

  public pageChange(event: any): void {
    this.first = event.first;
    this.pageSize = event.rows;
  }

  private getAccountDetails(): void {
    let allAccounts: IAccount[] = [];

    this.accountsService.getAccounts()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap((data: ICollectionWrapper<IAccount[]>) => {
          allAccounts = this.mapAccountsWithSubaccounts(data.collection);
          return this.sessionService.getSelectedAccount$();
        }))
      .subscribe((res: IAccount) => {
        const filteredAcc = allAccounts.find((acc: IAccount) => res._id === acc._id);
        this.setAccountDetails(filteredAcc);
      });
  }

  private setAccountDetails(res: IAccount): void {
    const { _subAccounts: subAccounts = undefined } = res;
    if (subAccounts) {
      const account: IAccount =
        subAccounts &&
        subAccounts?.length &&
        subAccounts.find((subAcc) => {
          const globalObject = this.parseGlobalObject(subAcc);
          const { gmb, appleMaps } = globalObject;
          if (gmb?.publish || appleMaps?.businessId) {
            return subAcc;
          }
        });

      if (account) {
        const globalObj = this.parseGlobalObject(account);
        (this.disableReviews = false),
          (this.disableGoogleInsights = (globalObj?.gmb && globalObj?.gmb?.publish) || false),
          (this.disableAppleInsights = (globalObj?.appleMaps && globalObj?.appleMaps?.businessId) || false);
        this.disableYelpInsights =
          (globalObj?.yelp && globalObj?.yelp?.publish && globalObj?.yelp?.subscribeToYK) || false;
        this.disableBingInsights = false;
      }
    } else {
      const newObject = this.parseGlobalObject(res);
      (this.disableReviews = false),
        (this.disableGoogleInsights = (newObject?.gmb && newObject?.gmb?.publish) || false),
        (this.disableAppleInsights = (newObject?.appleMaps && newObject?.appleMaps?.businessId) || false);
      this.disableYelpInsights =
        (newObject?.yelp && newObject?.yelp?.publish && newObject?.yelp?.subscribeToYK) || false;
      this.disableBingInsights = false;
    }
  }

  private parseGlobalObject(globalObject) {
    const result = typeof globalObject?.global === 'string' ? JSON.parse(globalObject?.global) : globalObject?.global;
    return result;
  };

  // Move this code to a service, is used in review-management.component.ts file--------
  private mapAccountsWithSubaccounts(collection): IAccount[] {
    const myCollection = collection.map(x => Object.assign({}, x));
    const accountsTree = myCollection.reduce((acc, v: IAccount, i, array) => {
      const hasParentAccount = v._parent_id;
      if (hasParentAccount) {
        const currentlyAdded = acc.find((a) => a._id === v._parent_id);
        const parentAccount = array.find((a) => a._id === v._parent_id) || {};
        if (!parentAccount?.hasOwnProperty('_subAccounts')) parentAccount['_subAccounts'] = [];

        if (currentlyAdded) {
          currentlyAdded._subAccounts.push(v);
          currentlyAdded.hasSubAccts = true;
        } else {
          parentAccount['_subAccounts'].push(v);
          parentAccount['hasSubAccts'] = true;
          acc.push(parentAccount);
        }
      } else {
        const currentlyAdded = acc.find((a) => a._id === v._id);
        if (!currentlyAdded) {
          acc.push(v);
        }
      }
      return acc;
    }, []);

    const flattenAccounts = accountsTree.reduce((acc, v) => {
      if (v.hasOwnProperty('_subAccounts')) {
        acc.push(v);
        acc.push(...v._subAccounts.sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        acc.push(v);
      }
      return acc;
    }, []);
    return flattenAccounts;
  }

  public setCustomDate(period: string): void {
    if (period === 'start') {
      this.customStartDate = formatDate(this.dateValue.start, 'yyyy-MM-dd', 'en-US').replace(/-/g, '');
    } else {
      var followingDay = new Date(this.dateValue.end.getTime() + 86400000);
      this.customEndDate = formatDate(followingDay.toLocaleDateString(), 'yyyy-MM-dd', 'en-US').replace(/-/g, '');
    };
  }

  private checkCustomDateSelection(params: string, fileType: string): string {
    if (this.selectedTimePeriod === 'period') {
      params = `${this.sourceTab}_by_${this.reportType}_${this.selectedTimePeriod}_${this.customStartDate}_${this.customEndDate}?format=${fileType}`;
    } else {
      params = `${this.sourceTab}_by_${this.reportType}_${this.selectedTimePeriod}?format=${fileType}`;
    }
    return params;
  };

  private generateDynamicFileName(): string {
    return `${this.sourceTab}_by_${this.reportType}_${this.selectedTimePeriod}`;
  };

  public handleChange(): void {
    this.filteredData.forEach(i => {
      i.items.forEach(j => {
        if ((this.selectedTimePeriod === j.value) && this.selectedTimePeriod !== 'period') {
          this.graphLabel = [j.label];
        }
        if ((this.selectedTimePeriod === j.value) && this.selectedTimePeriod === 'period') {
          this.graphLabel = [`
          ${this.dateValue.start.getDate()}/${this.dateValue.start.getMonth()}/${this.dateValue.start.getFullYear()} -
          ${this.dateValue.end.getDate()}/${this.dateValue.end.getMonth()}/${this.dateValue.end.getFullYear()}
          `];
        }
      })
    });
  }

  /**
   * @description To reset either reports data or graph data is already present in the view.
   * @returns void
   */
  private resetData(): void {
    if (this.graphData.length) {
      this.graphData = [];
    }

    if (this.viewReportData.length) {
      this.viewReportData = [];
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
