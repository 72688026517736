import { Injectable } from '@angular/core';
import { TableFilterComponent } from './table-filter.component';

@Injectable({
  providedIn: TableFilterComponent,
})
export class TableFilterService {
  private selectedFilters = {};
  constructor() {}

  get savedFilters() {
    return { ...this.selectedFilters };
  }

  set savedFilters(val) {
    this.selectedFilters = JSON.parse(JSON.stringify(val));
  }

  public clearFilters() {
    this.selectedFilters = {};
  }
}
