import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-gbp-post-edit',
  templateUrl: './gbp-post-edit.component.html',
  styleUrls: ['./gbp-post-edit.component.scss'],
})
export class GbpPostEditComponent implements OnInit {
  rowData; // post object

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.rowData = this.dynamicDialogConfig.data;
  }

  saveAndNext() {
    // call to backend for saving data will be here
  }

  closeDialog() {
    this.ref.close();
  }
}
