export const NOTIFS = [
  {
    title: 'New Review',
    content: 'You have 1 new review on Facebook',
    date: '20-12-2021',
    new: true,
  },
  {
    title: 'Scheduled Post',
    content: 'Your scheduled post for Location 33 was posted.',
    date: '15-12-2021',
    new: true,
  },
  {
    title: 'Listings Coverage',
    content: 'Your coverage has gone up 15%!',
    date: '03-07-2021',
    new: true,
  },
  {
    title: 'New Review',
    content: 'You have 1 new review on Facebook',
    date: '20-12-2021',
    new: false,
  },
  {
    title: 'Scheduled Post',
    content: 'Your scheduled post for Location 33 was posted.',
    date: '15-12-2021',
    new: false,
  },
  {
    title: 'Listings Coverage',
    content: 'Your coverage has gone up 15%!',
    date: '03-07-2021',
    new: false,
  },
];
