import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ImageOnErrorModule } from '../../directives/image-onerror.directive';
import { PrimengModule } from '../primeng.module';
import { DirectoryCardComponent } from './directory-card/directory-card.component';
import { DirectoryFormatCategoryPipe } from './directory-format-category.pipe';
import { DirectoryWrapperComponent } from './directory-wrapper/directory-wrapper.component';
import { DirectoryPageComponent } from './directory-page/directory-page.component';
import { ModalDirectoryListingsComponent } from './directory-listings/modal-directory-listings/modal-directory-listings.component';
import { RsElementsModule } from '../../elements/rs-elements.module';
import { JSONparsePipe } from './JSON.parse.pipe';
import { DirectoryService } from './directory.service';
import { SqlListingsAdapter } from './directory-listings/sql-listings/sql-listings.adapter';
import { DirectoryListingsService } from './directory-listings/directory-listings.service';
import { CheckTypeofPipe } from './checkTypeof.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DirectoryLocationListingsComponent } from './directory-listings/directory-location-listings/directory-location-listings.component';
import { DirectoryAccountListingsComponent } from './directory-listings/directory-account-listings/directory-account-listings.component';
import { DirectoryCardEditComponent } from './directory-card/directory-card-edit/directory-card-edit.component';
import { DirectoryFieldAvailabilityPipe } from './directory-card/directory-card-edit/directory-field-availability.pipe';

const MODULES = [CommonModule, FormsModule, PrimengModule, ImageOnErrorModule, RsElementsModule, ClipboardModule];
const COMPONENTS = [
  DirectoryCardComponent,
  DirectoryWrapperComponent,
  DirectoryPageComponent,
  DirectoryLocationListingsComponent,
  DirectoryAccountListingsComponent,
  ModalDirectoryListingsComponent,
];
@NgModule({
  imports: [...MODULES],
  declarations: [
    DirectoryFormatCategoryPipe,
    JSONparsePipe,
    CheckTypeofPipe,
    DirectoryFieldAvailabilityPipe,
    ...COMPONENTS,
    DirectoryCardEditComponent,
  ],
  providers: [DirectoryService, DirectoryListingsService, SqlListingsAdapter],
  exports: [...MODULES, ...COMPONENTS],
})
export class RSDirectoryPackagesModule {}
