export const TIME_ZONES = [
  {
    label: 'North America',
    items: [
      {
        name: 'Atlantic (CA)',
        value: 'Canada/Atlantic'
      },
      {
        name: 'Eastern (US/CA)',
        value: 'US/Eastern'
      },
      {
        name: 'Central (US/CA)',
        value: 'US/Central'
      },
      {
        name: 'Mountain (US/CA)',
        value: 'US/Mountain'
      },
      {
        name: 'Pacific (US/CA)',
        value: 'US/Pacific'
      },
      {
        name: 'Alaska (US)',
        value: 'US/Alaska'
      },
      {
        name: 'Hawaii (US)',
        value: 'US/Hawaii'
      },
    ]
  },
  {
    label: 'Australia/New Zealand',
    items: [
      {
        name: 'Eastern (AUS)',
        value: 'Australia/Brisbane'
      },
      {
        name: 'Central (AUS)',
        value: 'Australia/ACT'
      },
      {
        name: 'Western (AUS)',
        value: 'Australia/West'
      },
      {
        name: 'New Zealand',
        value: 'NZ'
      },
      {
        name: 'Chatham, NZ',
        value: 'NZ-CHAT'
      },
    ]
  },
  {
    label: 'Europe',
    items: [
      {
        name: 'Ireland',
        value: 'Europe/Dublin'
      },
      {
        name: 'Western Europe',
        value: 'WET'
      },
      {
        name: 'Central Europe',
        value: 'CET'
      },
      {
        name: 'Eastern Europe',
        value: 'EET'
      },
    ]
  },
  {
    label: 'Asia',
    items: [
      {
        name: 'Malaysia/Singapore',
        value: 'Singapore'
      },
      {
        name: 'Japan',
        value: 'Japan'
      }
    ]
  }
];
