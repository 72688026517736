export interface IFunctionInherited extends IDBLib {
  coll: any;
  findByCodeForVendor: (code, vendor) => any;
  fillCatsByVendor: () => any;
  codeWithName: (status) => any;
}

export abstract class IDBLib {
  Account: IFunctionInherited;
  Address: IFunctionInherited;
  AuthType: IFunctionInherited;
  CategoryType: IFunctionInherited;
  EMail: IFunctionInherited;
  Entity: IFunctionInherited;
  EnumType: IFunctionInherited;
  LocStatusType: IFunctionInherited;
  Location: IFunctionInherited;
  Login: IFunctionInherited;
  LoginSummary: IFunctionInherited;
  Party: IFunctionInherited;
  Permission: IFunctionInherited;
  Phone: IFunctionInherited;
  SqlReports: IFunctionInherited;
  UploadLog: IFunctionInherited;
  ReviewGMB: IFunctionInherited;
  LocalPostGMB: IFunctionInherited;
  LocalPostDetailGMB: IFunctionInherited;
  BulkOwnerQuestionGMB: IFunctionInherited;
  QuestionGMB: IFunctionInherited;
  AnswerDetailGMB: IFunctionInherited;
  MediaBatchGMB: IFunctionInherited;
  MediaDetailGMB: IFunctionInherited;
  MarkAsRead: IFunctionInherited;

  addSigHandler: () => any;
  applyExportMap: (val, m, key, mapKey) => any;
  applyExportMappings: (hash, obj, cls) => any;
  applyImportMap: (val, m, attr, mapKey) => any;
  applyImportMappings: (obj, hash, cls) => any;
  cbSignal: {};
  cleanTemps: (h) => any;
  clearAccountCaches: () => any;
  clearCache: () => any;
  clearPublicCaches: () => any;
  ensureIndexes: () => any;
  exportToHash: (obj) => any;
  findByCode: (key) => any;
  findById: (key) => any;
  findByIdent: (key) => any;
  findByLoginId: (key) => any;
  findByStoreCode: (key) => any;
  importCollection: (hashColl) => any;
  importFromHash: (hash) => any;
  indexCollection: (startIdx) => any;
  isEqual: (t1, t2, ignoreAttrs) => any;
  loadAll: () => any;
  loadObjects: (args?, postLoadedSig?) => any;
  makeLoadRequest: (reqId, args, postLoadedSig) => any;
  makeSaveRequest: (hItem, type) => any;
  offSignal: (s, cb) => any;
  onSignal: (s, cb) => any;
  postExportMapping: (hash, obj, cls) => any;
  postImportMapping: (obj, hash, cls) => any;
  postSignal: (sigName, sigArgs) => any;
  preExportMapping: (hash, obj, cls) => any;
  preImportMapping: (obj, hash, cls) => any;
  purgeAll: () => any;
  saveObject: (obj, args?) => any;
  signal: (sigName, sigArgs) => any;
  superClass: { isa: any; isMemberOf: any; isKindOf: any; clone: any };
  superConstructor: () => any;
  updateObject: (objHash) => any;
}
