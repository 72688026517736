import {Injectable} from '@angular/core';
import {defer, from, Observable} from 'rxjs';
import {ApiService} from '../core/backend-adapter/api/api.service';
import {DBService} from '../core/backend-adapter/db.service';
import {SessionService} from '../core/backend-adapter/session.service';
import {SocketService} from '../core/backend-adapter/socket.service';
import {NotifyService} from '../core/layouts/notifications/notify/notify.service';
import {ILocation} from './../loc-comps/locations.interfaces';

@Injectable({providedIn: 'root'})
export class GBPService {
  public account_id = 0;
  public currentBulkOwnerId = 0;

  constructor(
    private apiService: ApiService,
    private dbService: DBService,
    private socketService: SocketService,
    private notifyService: NotifyService,
    private sessionService: SessionService
  ) {
  }

  private isValidDate(dateObject) {
    return new Date(dateObject).toString() !== 'Invalid Date';
  }

  getTotalLocationsCount(): Observable<any> {
    return defer(() =>
      from(this.socketService.sendRequest('get-location-counts', {}).then((repl: any) => repl.collection[0]?.locsTotal))
    );
  }

  getAllLocations(cols): Observable<any> {
    return defer(() =>
      from(
        this.socketService.sendRequest( 'get-locations-summary', {
          columns: cols
        }).then(
          (repl: any) => {
            return repl.collection;
          },
          (err) => {
            console.warn('Error loading locations:', err);
          }
        )
      )
    );
  }

  getAllPosts(): Observable<any> {
    this.account_id = this.sessionService.effAccount_id;
    return defer(() =>
      from(
        this.dbService.LocalPostGMB.loadObjects({}).then(
          (repl) => {
            if (repl.collection.length > 0) {
              this.account_id = repl.collection?.account_id;
              return repl.collection;
            } else {
              return [];
            }
          },
          (err) => {
            console.warn('Error loading posts: %j', err);
          }
        )
      )
    );
  }

  getPostTrendsPast30Days(): Observable<any> {
    return defer(() =>
      from(
        this.socketService.sendRequest('get-trend-metrics', {}).then(
          (repl: any) => repl.collection[2],
          (err) => {
            console.log('Trend metrics error:', err);
          }
        )
      )
    );
  }

  getAllLocationDetails(locationId: number): Observable<any> {
    return defer(() =>
      from(
        this.dbService.LocalPostDetailGMB.loadObjects({
          where: `post_id = ${locationId}`,
        }).then(
          (repl) => repl.collection,
          (err) => {
            console.warn('Error loading location details:', err);
          }
        )
      )
    );
  }

  updateLocalPost(localPostObj: any) {
    this.dbService.LocalPostGMB.saveObject(localPostObj, {location_ids: localPostObj.location_ids}).then(
      (resp) => {
        console.log('Post updated successfully');
      },
      (err) => {
        console.log('Error saving local post', err);
      }
    );
  }

  getAllQALocations(id: number): Observable<any> {
    return defer(() =>
      from(
        this.dbService.QuestionGMB.loadObjects({
          where: `bulkOwner_id = ${id}`,
        }).then(
          (repl) => repl.collection,
          (err) => {
            console.warn('Error loading location details:', err);
          }
        )
      )
    );
  }

  prepareDate(dateString: string, scheduleTime: any, timeString?: string) {
    // const newDate = new RZLib.Timestamp().stringValue(dateString);
    const formDate = new Date(dateString);
    const formTime = new Date(timeString);

    if (this.isValidDate(formTime)) {
      scheduleTime.setDateTime(
        formDate.getFullYear(),
        formDate.getMonth() + 1,
        formDate.getDate(),
        formTime.getHours(),
        formTime.getMinutes(),
        0,
        0,
        0
      );
    } else {
      scheduleTime.setDateTime(formDate.getFullYear(), formDate.getMonth() + 1, formDate.getDate(), 0, 0, 0, 0, 0);
    }

    return scheduleTime;
  }

  prepareTime(scheduleTime: any, timeString: string) {
    const formTime = new Date(timeString);

    if (this.isValidDate(formTime)) {
      scheduleTime.setDateTime(0, 0, 0, formTime.getHours(), formTime.getMinutes(), 0, 0, 0);
    }
    return scheduleTime;
  }

  preparePostLanguage(value: string) {
    let tempValue = '';
    switch (value) {
      case 'en':
        tempValue = 'en-US';
        break;
      case 'en-US':
        tempValue = 'en';
        break;
      case 'fr':
        tempValue = 'fr-CA';
        break;
      case 'fr-CA':
        tempValue = 'fr';
        break;
      case 'es-ES':
        tempValue = 'es';
        break;
      case 'es':
        tempValue = 'es-ES';
        break;
      default:
        tempValue = 'en';
        break;
    }
    return tempValue;
  }

  getQuestions(where): Observable<any> {
    // this.account_id = this.sessionService.effAccount_id;
    return defer(() =>
      from(
        this.dbService.QuestionGMB.loadObjects(where).then(
          (repl) => {
            this.account_id = repl.collection[0]?.account_id;
            return repl.collection;
          },
          (err) => {
            console.warn('Error loading qa: %j', err);
          }
        )
      )
    );
  }

  updateAnswerToQA(answerObj: any) {
    this.dbService.AnswerDetailGMB.saveObject(answerObj).then(
      (resp) => {
        console.log('Answer updated successfully');
      },
      (err) => {
        console.log('Error saving answer for the question', err);
      }
    );
  }

  getAnswers(where: string): Observable<any> {
    return defer(() =>
      from(
        this.dbService.AnswerDetailGMB.loadObjects({
          where,
        }).then(
          (repl) => {
            // console.log(repl);
            return repl.collection;
          },
          (err) => {
            console.warn('Error loading qa: %j', err);
          }
        )
      )
    );
  }

  getBulkOwnerQuestions(): Observable<any> {
    // this.account_id = this.sessionService.effAccount_id;
    return defer(() =>
      from(
        this.dbService.BulkOwnerQuestionGMB.loadObjects({}).then(
          (repl) => {
            console.log(repl);
            return repl.collection;
          },
          (err) => {
            console.warn('Error loading qa: %j', err);
          }
        )
      )
    );
  }

  updateBulkOwnerQa(bulkOwnerQaObj: any) {
    this.dbService.BulkOwnerQuestionGMB.saveObject(bulkOwnerQaObj, {location_ids: bulkOwnerQaObj?.location_ids}).then(
      (resp) => {
        console.log('Question updated successfully');
      },
      (err) => {
        console.log('Error saving data', err);
      }
    );
  }

  getBulkOwnerQuestionById(id: number): Observable<any> {
    return defer(() =>
      from(
        this.dbService.BulkOwnerQuestionGMB.loadObjects({
          where: `_id = ${id}`,
        }).then(
          (repl) => {
            return repl.collection[0];
          },
          (err) => {
            console.warn('Error loading qa: %j', err);
          }
        )
      )
    );
  }

  getAllPhotos(): Observable<any> {
    this.account_id = this.sessionService.effAccount_id;
    return defer(() =>
      from(
        this.dbService.MediaBatchGMB.loadObjects({}).then(
          (repl) => {
            if (repl.collection.length > 0) {
              this.account_id = repl.collection?.account_id;
              return repl.collection;
            } else {
              return [];
            }
          },
          (err) => {
            console.warn('Error loading photos: %j', err);
          }
        )
      )
    );
  }

  updatePhotoBatch(mediaObj: any) {
    this.dbService.MediaBatchGMB.saveObject(mediaObj, {location_ids: mediaObj.location_ids}).then(
      (resp) => {
        console.log('Media saved successfully');
      },
      (err) => {
        console.log('Error saving media object', err);
      }
    );
  }

  deletePhotoBatch(batch) {
    batch._status = 'DP';

    this.dbService.MediaBatchGMB.saveObject(batch).then(
      (resp) => {
        console.log('Media deleted successfully');
      },
      (err) => {
        console.log('Error deleting media object', err);
      }
    );
  }

  getAllPhotoLocations(batchId): Observable<any> {
    return defer(() =>
      from(
        this.dbService.MediaDetailGMB.loadObjects({
          where: `batch_id = ${batchId}`
        }).then(
          (repl) => {
            if (repl.collection.length > 0) {
              console.log(repl.collection);
              return repl.collection;
            } else {
              return [];
            }
          },
          (err) => {
            console.warn('Error loading photo locations: %j', err);
          }
        )
      )
    );
  }

  // getLocStatusType(): Observable<any> {
  //   return this.apiService.get(LOCATION_ENDPOINTS.LocStatusType);
  // }

  public mapAllLocations(locations, preSelectedLocation = []): number[] {
    return [...locations.map(l => l[0]), ...preSelectedLocation.map(l => l._id)];
  }

}
