import {NgModule} from '@angular/core';
import {GbpTabPhotoComponent} from './gbp-tab-photo.component';
import {GbpPhotoUploadComponent} from './components/gbp-photo-upload/gbp-photo-upload.component';
import {RSCommonModule} from 'src/app/core/feature-modules/facade.module';
import {GbpPhotoExifComponent} from './components/gbp-photo-exif/gbp-photo-exif.component';
import {GbpPhotoLocationsComponent} from './components/gbp-photo-locations/gbp-photo-locations.component';
import {GbpPhotoDetailsComponent} from './components/gbp-photo-details/gbp-photo-details.component';
import {GbpPhotoOptimizeComponent} from './components/gbp-photo-optimize/gbp-photo-optimize.component';
import {GbpPhotoDeleteComponent} from './components/gbp-photo-delete/gbp-photo-delete.component';
import {GbpPhotoScheduledOptimizedComponent} from './components/gbp-photo-scheduled-optimized/gbp-photo-scheduled-optimized.component';
import {GbpSelectLocationsComponent} from './components/gbp-select-locations/gbp-select-locations.component';
import {GbpSelectLocationTableComponent} from './components/gbp-select-location-table/gbp-select-location-table.component';
import {TableFilterModule} from 'src/app/core/feature-modules/table-filter/table-filter.module';
import {LocationsListModule} from 'src/app/core/feature-modules/locations-list/locations-list.module';
import {UploadMediaModule} from 'src/app/core/feature-modules/upload-media/upload-media.module';
import {GbpDateLocationsFormModule} from '../components/gbp-date-locations-form/gbp-date-locations-form.module';
import {ActivityStatusModule} from '../pipes/activity-status.module';
import {GetStatusModule} from '../pipes/get-status.module';
import {ProgressrModule} from '@app/core/feature-modules/progress/progress.module';
import {GbpPostModule} from '@app/gbp-management/gbp-tab-post/gbp-tab-post.module';
import {GbpPhotoSubmitComponent} from '@app/gbp-management/gbp-tab-photo/components/gbp-photo-submit/gbp-photo-submit.component';
import {GbpPhotoAttributesComponent} from '@app/gbp-management/gbp-tab-photo/components/gbp-photo-attributes/gbp-photo-attributes.component';

const COMPONENTS = [
  GbpPhotoUploadComponent,
  GbpPhotoExifComponent,
  GbpPhotoLocationsComponent,
  GbpPhotoDetailsComponent,
  GbpPhotoOptimizeComponent,
  GbpPhotoDeleteComponent,
  GbpPhotoScheduledOptimizedComponent,
  GbpPhotoSubmitComponent,
  GbpSelectLocationsComponent,
  GbpSelectLocationTableComponent,
  GbpPhotoAttributesComponent
];

const MODULES = [
  TableFilterModule,
  LocationsListModule,
  UploadMediaModule,
  GbpDateLocationsFormModule,
  ActivityStatusModule,
  GetStatusModule,
];

@NgModule({
  imports: [RSCommonModule, ...MODULES, ProgressrModule, GbpPostModule],
  declarations: [GbpTabPhotoComponent, ...COMPONENTS],
  exports: [GbpTabPhotoComponent],
})
export class GbpPhotoModule {
}
