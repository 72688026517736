export enum RsFonts {
  Montserrat = 'Montserrat',
  AlbertSans = 'Albert Sans',
  Nunito = 'Nunito',
  AsapCondensed = 'AsapCondensed',
  LibreFranklin = 'Libre Franklin',
  Lato = 'Lato',
  Poppins = 'Poppins'
}

export enum RsThemes {
  default = 'default',
  whitelabeled = 'whitelabeled',
  custom = 'custom'
}
