import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldAvailability',
})
export class DirectoryFieldAvailabilityPipe implements PipeTransform {
  transform(value: string, field: 'utm' | 'categories' | 'smb' | 'chainfields'): boolean {
    const obj = {
      utm: () => ['bing', 'facebook', 'factual'].includes(value),
      categories: () => true, //  value !== 'bing',
      smb: () => value === 'yelp',
      chainfields: () => value === 'bing',
    };
    return obj[field]() ?? this.throwError();
  }

  throwError() {
    new Error('wrong pipe argument for fieldAvailability');
    return false;
  }
}
