import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UTMService } from '../../services/utm.service';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './utm-field-chooser.component.html',
  styleUrls: ['./utm-field-chooser.component.scss'],
})
export class UtmFieldChooserComponent {
  @Input() showUtmFieldChooser: boolean;
  @Input() providerName: string;
  @Input() campaignField: string;
  @Output() closeModal = new EventEmitter<void>();

  customFields: any[];
  selectedField: string; // To store the user's selection

  constructor(private utmService: UTMService) {}

  ngOnInit(): void {
    // Access the customFields from UTMService
    this.customFields = this.utmService.customFields;
  }

  onFieldSelect(field: string): void {
    this.selectedField = field;
  }

  onConfirm(): void {
    // Update the UTMService with the selected field
    if (this.selectedField) {
      this.utmService.setCampaignFieldValues(this.providerName, {
        [this.campaignField]: '{{' + this.selectedField + '}}',
      });
      this.utmService.notifyDataChange();
    }
    this.onClose();
  }

  onClose() {
    this.closeModal.emit();
  }
}
