import { Injectable } from "@angular/core";
import { PandioReviews } from "../model/pandio-reviews";
import { FILTER_TYPES, IFilterData } from "src/app/core/feature-modules/table-filter/table-filter.interfaces";

@Injectable({
  providedIn: 'root'
})

export class PandioReviewsConfigService {
  private static readonly DATE_FILTER_LIST: string[] = ['This month', 'Last 30 days', 'Last month', 'Year to date', 'Last 12 months', 'All time'];

  private stackedGraphData: unknown;

  public otherReviewsGlobalFilters: IFilterData[];
  public tableColsHeader = [
    { field: 'storeCode', header: 'STORE CODE' },
    { field: 'businessName', header: 'LOCATION NAME' },
    { field: 'addressLines', header: 'STREET ADDRESS' },
    { field: 'city', header: 'CITY' },
    { field: 'state', header: 'STATE' },
    { field: 'postalCode', header: 'POSTAL CODE' },
    { field: 'tags', header: 'TAGS'},
    { field: 'reviewCreatedAt', header: 'REVIEW DATE' },
    { field: 'reviewerComment', header: 'REVIEW' },
    { field: 'reviewerName', header: 'REVIEWER NAME' },
    { field: 'starRating', header: 'RATING' },
    { field: 'replyUpdatedAt', header: 'RESPONSE DATE' },
    { field: 'replyComment', header: 'RESPONSE' },
  ];

  constructor() { }

  /**
* @description Initializes the bar graph.
* @param void
* @returns set's the graph axes.
*/
  public initReviewsGraph(): any {
    const graphIndexis = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        title: {
          display: true,
          text: 'Review Count',
          color: '#000',
          font: {
            size: 20,
            weight: '400'
          }
        },
        legend: {
          // display: false,
          position: 'bottom',
          labels: {
            color: ''
          }
        }
      },
      scales: {
        x: {
          display: false,
          ticks: {
            color: '',
            font: {
              weight: 400
            }
          },
          grid: {
            color: '',
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: ''
          },
          grid: {
            color: '',
            drawBorder: false
          }
        }
      }
    };

    return graphIndexis;
  }

  /**
   * @description Returns the configuration details for Reviews Count graph.
   * @param void
   * @returns PandioReviews
   */
  public setReviewCountGraphConfig(): PandioReviews {
    return {
      labels: [],
      datasets: [
        {
          label: 'Total Reviews',
          backgroundColor: '#0324fc',
          borderColor: '#0324fc',
          data: []
        },
        {
          label: 'Last 30 days',
          backgroundColor: '#03fc35',
          borderColor: '#03fc35',
          data: []
        }
      ]
    };
  }

  /**
 * @description Return the configuration details for Rating Distribution graph.
 * @param void
 * @returns PandioReviews
 */
  public setRatingDistributionGraphConfig(): PandioReviews {
    return {
      labels: [],
      datasets: [
        {
          type: 'bar',
          label: 'Good',
          backgroundColor: '#0324fc',
          data: []
        },
        {
          type: 'bar',
          label: 'Neutral',
          backgroundColor: '#03fc35',
          data: []
        },
        {
          type: 'bar',
          label: 'Poor',
          backgroundColor: 'yellow',
          data: []
        },
      ]
    };
  };

  public generateTableHeaderData() {
    const tableHeaderData = [
      {
        label: 'SELECT',
        enableSorting: false,
        sortingLable: null,
        fieldName: null
      },
      {
        label: 'DIRECTORY',
        enableSorting: false,
        sortingLable: 'directory',
        fieldName: 'directory'
      },
      {
        label: 'STORE CODE',
        enableSorting: true,
        sortingLable: 'storeCode',
        fieldName: 'storeCode'
      },
      {
        label: 'LOCATION',
        enableSorting: true,
        sortingLable: 'businessName',
        fieldName: 'businessName'
      },
      {
        label: 'REVIEW',
        enableSorting: true,
        sortingLable: 'reviewCreatedAt',
        fieldName: 'reviewerComment'
      },
      {
        label: 'RATING',
        enableSorting: true,
        sortingLable: 'starRating',
        fieldName: 'starRating'
      },
      // {
      //   label: 'RESPONSE',
      //   enableSorting: true,
      //   sortingLable: 'replyUpdatedAt',
      //   fieldName: 'replyComment'
      // },
      {
        label: '',
        enableSorting: false,
        sortingLable: null,
        fieldName: null
      },
    ];

    return tableHeaderData;
  };

  /**
* @description list of filters for Pandio reviews
* @returns IFilterData[]
*/
  public setPandioReviewsFilters(activeDirectoriesList: string[]): void {
    this.otherReviewsGlobalFilters = [
      {
        type: FILTER_TYPES.MULTI,
        title: 'DIRECTORIES',
        options: [...activeDirectoriesList],
      },
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: [...PandioReviewsConfigService.DATE_FILTER_LIST],
      },
      {
        type: FILTER_TYPES.SINGLE,
        title: 'REVIEW TEXT',
        options: ['Review includes text', 'Review does not include text'],
      },
    ];
  };

  public generateStackedSentimentReportsGraph() {
    this.stackedGraphData = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        title: {
          display: true,
          text: 'Rating Distribution',
          color: '#000',
          font: {
            size: 20,
            weight: '400'
          }
        },
        legend: {
          // display: false,
          position: 'bottom',
          labels: {
            color: ''
          }
        }
      },
      scales: {
        x: {
          display: false,
          stacked: true,
          ticks: {
            color: ''
          },
          grid: {
            color: '',
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: ''
          },
          grid: {
            color: '',
            drawBorder: false
          }
        }
      }
    };

    return this.stackedGraphData;
  };

}
