<div class="event-info-wrapper" [formGroup]="postInfoGroup">
  <div class="event-info-row">
    <div class="event-info-title">Post Description&#x2a;</div>
    <div class="event-info-input">
      <textarea
        class="event-info-field"
        [rows]="3"
        pInputTextarea
        placeholder="100 - 1500 word summary about the post"
        formControlName="postDescription"
        #desc
        maxlength="1500"
      ></textarea>
      <span class="charac_limit" [class.highlight]="descriptionCharacterLimit === 1500">{{ descriptionCharacterLimit }}/1500</span>
      <!-- Temporary hide -->
      <!-- <div class="event-info-dynamic-attribute">
          <i
            class="pi pi-question-circle"
            pTooltip="Dynamic text replaces the corresponding information as listed in each location. E.g: Hello, city of {location city}. Will be replaced as 'Hello, city of {Toronto/Vancouver/Edmonton}' based on the information listed under each store location."
            tooltipPosition="left"
          ></i>
          <span>Add Dynamic Attribute</span>
        </div>
        <div class="event-info-chips-bar">
          <div class="event-info-chip" *ngFor="let chipText of chipsArray" (click)="onChipClick(chipText)">
            {{ chipText | uppercase }}
          </div>
        </div> -->
      <div *ngIf="!type" class="event-info-add-time">
        <span>Add time</span>
        <p-inputSwitch (onChange)="handleInputSwitchChange($event)" formControlName="isAddTime"></p-inputSwitch>
      </div>
    </div>
  </div>
  <div *ngIf="postInfoGroup.value?.isAddTime" class="event-info-row">
    <div class="event-info-title">Start Time&#x2a;</div>
    <div class="event-info-event-date">
      <p-calendar
        formControlName="startTime"
        [timeOnly]="true"
        [showIcon]="true"
        [hourFormat]="12"
        inputId="timeonly"
        appendTo="body"
        placeholder="00:00 PM"
      ></p-calendar>
      <span class="event-info-event-date-end">End Time&#x2a;</span>
      <p-calendar
        formControlName="endTime"
        [timeOnly]="true"
        [showIcon]="true"
        [hourFormat]="12"
        inputId="timeonly"
        appendTo="body"
        placeholder="00:00 PM"
      ></p-calendar>
    </div>
  </div>
  <div *ngIf="!type || type == 'offer'" class="event-info-row">
    <div class="event-info-title">{{ type == 'offer' ? 'Offer' : 'Event' }} Start Date&#x2a;</div>
    <div class="event-info-event-date">
      <p-calendar
        formControlName="startDate"
        [showIcon]="true"
        inputId="icon"
        appendTo="body"
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yy"
      ></p-calendar>
      <span class="event-info-event-date-end">{{ type == 'offer' ? 'Offer' : 'Event' }} End Date&#x2a;</span>
      <p-calendar
        formControlName="endDate"
        [showIcon]="true"
        inputId="icon"
        appendTo="body"
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yy"
        [minDate]="minDate"
      ></p-calendar>
      <!-- Set default date if the end date is in past. -->
    </div>
  </div>
  <div *ngIf="!type" class="event-info-row">
    <div class="event-info-title">
      Title&#x2a;<br />
      <div class="subtext">(Max 58 chars)</div>
    </div>
    <div class="event-info-input">
      <input
        class="event-info-field"
        maxlength="58"
        type="text"
        pInputText
        formControlName="title"
        placeholder="Type Here..."
      />
      <span class="charac_limit" [class.highlight]="titleCharacterLimit === 58">{{ titleCharacterLimit }} / 58</span>
    </div>
    <br />
  </div>
  <div *ngIf="(!type || type == 'update') && type != 'offer'" class="event-info-row">
    <div class="event-info-title">Add Button</div>
    <div class="event-info-input">
      <p-dropdown
        [style]="{ minWidth: '100%' }"
        [options]="buttons"
        appendTo="body"
        formControlName="addButton"
        placeholder="Select Button..."
        (onChange)="onDropdownChange()"
      >
        <ng-template pTemplate="item" let-option>
          <span>{{ option.label || '&nbsp;' }}</span>
        </ng-template></p-dropdown
      >
    </div>
  </div>
  <div *ngIf="showCtaURLField" class="event-info-row">
    <div class="event-info-title">Button URL&#x2a;
      <i class="pi pi-question-circle" [pTooltip]="urlToolTip" tooltipPosition="left"></i>
    </div>
    <div class="event-info-input">
      <div class="event-info-splitted-row">
        <input
          class="event-info-field"
          type="text"
          pInputText
          formControlName="ctaURL"
          (input)="handleInputCtaURLChange($event)"
          placeholder="Type Here..."
          [class.disabled_url]="showGbpUtmForm"
        />
        <button
          *ngIf="!showGbpUtmForm"
          pButton
          (click)="onAddUtmCodes($event)"
          class="event-info-cta"
          iconPos="right"
          icon="pi pi-angle-down"
        >
          UTM Codes
        </button>
        <button
          *ngIf="showGbpUtmForm"
          pButton
          (click)="onAddUtmCodes($event)"
          class="event-info-cta"
          iconPos="right"
          icon="pi pi-angle-up"
        >
          UTM Codes
        </button>
      </div>
      <span class="error_message" *ngIf="!postInfoGroup.controls['ctaURL'].valid">Please enter a valid URL.</span>
    </div>
  </div>
  <div class="event-info-row" *ngIf="showGbpUtmForm">
    <div class="event-info-title">UTM Codes</div>
    <gbp-utm-form
      class="event-info-input"
      [ctaURL]="this.ctaURL"
      (onCtaUrlWithUtmChange)="handleCtaUrlWithUtmChange($event)"
      [postInfoGroup]="postInfoGroup"
    ></gbp-utm-form>
  </div>
  <div class="event-info-row">
    <div class="event-info-title">Post Language&#x2a;</div>
    <div class="event-info-input">
      <p-dropdown
        [options]="languages"
        appendTo="body"
        formControlName="languageCode"
        placeholder="Select Language..."
      ></p-dropdown>
    </div>
  </div>
</div>
