import { RsFonts, RsThemes } from './style-changer.interfaces';
import { _PALETTE } from './styles/colors/colors.palette';
import { IRGBColorsType, RsThemeConfigObject } from './theme-config.interfaces';

export const ThemeColorsPalette: IRGBColorsType = {
  // violet: [238, 130, 238],
  // lime: [0, 255, 0],
  renderGreen: _PALETTE.$mainGreen.rgb,
  renderOrange: _PALETTE.$mainOrange.rgb,

  // default
  charcoalGrey: _PALETTE.$defaultThemeCharcoalGrey.rgb,
  parakeetGreen: _PALETTE.$defaultThemeParakeetGreen.rgb,

  // whitelabel
  deepBlue: _PALETTE.$whitelabelDeepBlue.rgb,
};

export let themeConfig: RsThemeConfigObject = {
  [RsThemes.default]: {
    name: RsThemes.default,
    font: RsFonts.Montserrat,
    orgName: 'RenderSEO',
    logos: {
      large: '',
      square: '',
      favicon: 'assets/favicon.png'
    },
    colors: {
      primary: ThemeColorsPalette.renderGreen.join(),
      secondary: ThemeColorsPalette.renderGreen.join(),
      button: ThemeColorsPalette.renderOrange.join(),
    },
  },
  [RsThemes.whitelabeled]: {
    name: RsThemes.whitelabeled,
    font: RsFonts.Nunito,
    orgName: '',
    logos: {
      large: '',
      square: '',
      favicon: ''
    },
    colors: {
      primary: [255, 147, 39].join(),
      secondary: [255, 147, 39].join(),
      button: [0, 152, 228].join(),
    },
  },
  [RsThemes.custom]: {
    name: RsThemes.custom,
    font: RsFonts.AlbertSans,
    orgName: '',
    logos: {
      large: '',
      square: '',
      favicon: ''
    },
    colors: {
      primary: ThemeColorsPalette.renderGreen.join(),
      secondary: ThemeColorsPalette.renderOrange.join(),
      button: ThemeColorsPalette.renderOrange.join(),
    },
  }
};
