<div class="confirmation-wrapper">
  <div class="top-content">
    <h2 *ngIf="publishObject.type == 'single'">Are you sure you want to publish this response?</h2>
    <h2 *ngIf="publishObject.type == 'multi'">
      Are you sure you want to publish these responses to all ({{ publishObject.data.length }}) reviews?
    </h2>
    <p>* These changes cannot be undone.</p>
  </div>
  <div class="buttons">
    <button pButton label="CANCEL" class="p-button-outlined p-button-secondary" (click)="cancelReply()"></button>
    <button pButton label="YES, I'M SURE" (click)="publishReply()"></button>
  </div>
</div>
