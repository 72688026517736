// truncate.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkTypeof',
})
export class CheckTypeofPipe implements PipeTransform {
  transform(value: string | Array<any>, type: 'string' | 'array' | 'object'): boolean {
    const obj = {
      string: () => typeof value === 'string',
      array: () => Array.isArray(value),
      object: () => typeof value === 'object',
    };
    return obj[type]() ?? false;
  }
}
