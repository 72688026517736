import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-loc-change-log',
  styleUrls: ['./loc-change-log.component.scss'],
  templateUrl: './loc-change-log.component.html'
})


export class LocChangeLogComponent implements OnInit {

  constructor() { }

  data: any[] = [];
  tableHeaderData: any[] = [
    {
      label: 'Account',
      enableSorting: false,
      sortingLable: 'account',
      fieldName: 'account',
      className: 'account',
    },
    {
      label: 'Storecode',
      enableSorting: true,
      sortingLable: 'Storecode',
      fieldName: 'Storecode',
      className: 'Storecode',
    },
    {
      label: 'Address line 1',
      enableSorting: false,
      sortingLable: 'addressLine',
      fieldName: 'addressLine',
      className: 'addressLine',
    },
    {
      label: 'City',
      enableSorting: false,
      sortingLable: 'city',
      fieldName: 'city',
      className: 'city',
    },
    {
      label: 'State',
      enableSorting: false,
      sortingLable: 'state',
      fieldName: 'state',
      className: 'state',
    },
    {
      label: 'Opening date',
      enableSorting: false,
      sortingLable: 'openingdate',
      fieldName: 'openingdate',
      className: 'openingdate',
    },
    {
      label: 'Updated at',
      enableSorting: true,
      sortingLable: 'updatedat',
      fieldName: 'updatedat',
      className: 'updatedat',
    },
    {
      label: 'Updated by',
      enableSorting: true,
      sortingLable: 'updatedby',
      fieldName: 'updatedby',
      className: 'updatedby',
    },
    {
      label: 'Field name',
      enableSorting: true,
      sortingLable: 'fieldname',
      fieldName: 'fieldname',
      className: 'fieldname',
    },
    {
      label: 'Old value',
      enableSorting: false,
      sortingLable: 'oldvalue',
      fieldName: 'oldvalue',
      className: 'oldvalue',
    },
    {
      label: 'New value',
      enableSorting: false,
      sortingLable: 'newvalue',
      fieldName: 'newvalue',
      className: 'newvalue',
    },
  ];
  selectedRange: number = 3;
  dateRanges = [
    { name: 'Last 30 days', code: 3, },
    { name: 'Last 90 days', code: 6, },
  ];
  public customDate: any;
  public displayDropdown: boolean = false;


  ngOnInit() {
  }

  pageChange(event) {

  }

  dateRangeChanged(event) {

  }

}
