import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { GbpPostLocationsComponent } from '../../gbp-post-locations/gbp-post-locations.component';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss'],
})
export class SubmitComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() preSelectedLocs: number = 0;
  uploadGroup: FormGroup;
  postDetailsGroup: FormGroup;
  scheduleGroup: FormGroup;
  offerInfoGroup: FormGroup;
  public selectedLocationsCount: number | string;
  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.uploadGroup = this.form.controls.upload as FormGroup;
    this.postDetailsGroup = this.form.controls.postDetails as FormGroup;
    this.offerInfoGroup = this.form.controls.offerInfo as FormGroup;
    this.scheduleGroup = this.form.controls.schedule as FormGroup;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isSelectedAll, location, totalLocationsCount } = changes?.form.currentValue['controls']['schedule']['controls'];
    const setLocValue: boolean = (location.value.length + this.preSelectedLocs) === totalLocationsCount.value;
    this.selectedLocationsCount = (isSelectedAll.value ||  setLocValue) ? 'All' : ( location.value.length + this.preSelectedLocs);
  }

  openLocations(): void {
    this.dialogService.open(GbpPostLocationsComponent, {
      width: '90%',
      height: '90%',
      data: {
        locations: [...this.scheduleGroup.value.location],
      },
    });
  }
}
