import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ACTIVITY_STATUS } from 'src/app/gbp-management/gbp-tabs.constants';
import { GbpPhotoLocationsComponent } from '../gbp-photo-locations/gbp-photo-locations.component';

@Component({
  selector: 'app-gbp-photo-scheduled-optimized',
  templateUrl: './gbp-photo-scheduled-optimized.component.html',
  styleUrls: ['./gbp-photo-scheduled-optimized.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GbpPhotoScheduledOptimizedComponent implements OnInit {
  @Input() edit: boolean;
  @Input() detailsOptimize: boolean;
  receivedData = {};
  activityStatus: typeof ACTIVITY_STATUS = ACTIVITY_STATUS;
  scheduleGroup = new FormGroup({
    date: new FormControl('', Validators.required),
    time: new FormControl('', Validators.required),
    timeZone: new FormControl('', Validators.required),
    location: new FormControl(''),
    isSelectedAll: new FormControl(''),
  });
  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    console.log(this.dynamicDialogConfig.data);
    this.receivedData = {
      details: [
        {
          name: 'Image Title',
          data: '',
        },
        {
          name: 'Image Description',
          data: '',
        },
        {
          name: 'User Comment',
          data: '',
        },
        {
          name: 'Keywords',
          data: '',
        },
        {
          name: 'Subject',
          data: '',
        },
      ],
      advanced: [
        {
          name: 'GPS Latitude Reference',
          data: '',
        },
        {
          name: 'GPS Latitude',
          data: '',
        },
        {
          name: 'GPS Longitude Reference',
          data: '',
        },
        {
          name: 'GPS Longitude',
          data: '',
        },
        {
          name: 'DateStamp',
          data: '',
        },
        {
          name: 'DateStamp Original',
          data: '',
        },
        {
          name: 'DateStamp Digital',
          data: '',
        },
        {
          name: 'Artist',
          data: '',
        },
        {
          name: 'Author/Owner Name',
          data: '',
        },
        {
          name: 'Copyright',
          data: '',
        },
        {
          name: 'Camera Make',
          data: '',
        },
        {
          name: 'Camera Model',
          data: '',
        },
        {
          name: 'Software',
          data: '',
        },
      ],
      tooltipsText: [
        {
          text: 'Use the primary keyword that best describes the image.',
        },
        {
          text: 'A brief description (255 characters or shorter) using the primary keyword that explains what the image is.',
        },
        {
          text: 'Based on your reviews, explain how customers describe this.',
        },
        {
          text: 'Use commas to separate the keywords for the image. (Max 3 keywords)',
        },
        {
          text: 'Give a one sentence description of the image.',
        },
      ],
    };
  }

  openLocations(): void {
    this.ref.close();
    this.dialogService.open(GbpPhotoLocationsComponent, {
      width: '80%',
      data: { ...this.dynamicDialogConfig.data },
    });
  }
}
