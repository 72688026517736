<div class="container" (clickOutside)="handleClickOutside($event)" *ngIf="user">
  <div class="acc-selector-polygon"></div>
  <div class="acc-selector-close" (click)="onCloseSelector()"><i class="pi pi-times rs-pi-icon-sized c-p"></i></div>
  <div class="acc-selector-header">
    <div class="account-info">
      <span class="account-prefix">Logged in as...</span>
      <span class="account-name">
        <span class="lh-0">{{ ((selectedAccount$ | async)?.name | truncate: '30') || 'Account not selected' }}</span>
        <ng-container *ngIf="!('isUserSingleAccounted' | hasPerm | async)">
          <div
            *ngIf="selectedAccount$ | async as acc"
            class="lh-0 account-settings-icon"
            (click)="handleAccountNavigation(acc, true)"
          >
            <ng-template *ngTemplateOutlet="accountSettingsIcon"></ng-template>
          </div>
        </ng-container>
      </span>
    </div>

    <div class="user-info">
      <div class="user-image">
        <img class="logo_image" src="{{ user?.logoUrl }}" (error)="handleMissingImage($event)" alt="user-image" />
      </div>
      <div class="user-creds">
        <div>
          <b>{{ user.login.loginId || 'john@doe.com' }}</b>
        </div>
        <div>{{ user.login.note || 'John Doe' }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="!isUserSingleAccounted" class="acc-selector-content">
    <div class="acct-search">
      <span class="p-input-icon-right">
        <i class="pi pi-search"></i>
        <span class="p-inputgroup">
          <input
            #searchRef
            type="text"
            class="search-input"
            pInputText
            placeholder="Search by account or sub-account name..."
            (keyup)="onSearch($event)"
          />
          <button class="filter-button" pButton type="button" icon="pi pi-search"></button>
        </span>
      </span>
    </div>

    <br />
    <!-- Hide See all accounts button -->
    <!-- <button
      class="p-button-primary rs-pi-btn"
      label="see all accounts"
      pButton
      (click)="handleNavigationClick('/accounts')">
    </button> -->

    <div *ngIf="!isSearching" class="flex-row heading">
      <h3>Recent accounts<span class="text-green">.</span></h3>
      <i
        *ngIf="filteredAccounts?.length"
        (click)="clearRecentAccounts($event)"
        class="pi pi-times rs-pi-icon-sized c-p"
        pTooltip="Clear recent accounts list"
      ></i>
    </div>

    <ul class="projects-list">
      <app-rs-spinner *ngIf="filteredAccounts === null"></app-rs-spinner>

      <div *ngIf="filteredAccounts?.length === 0" class="flex-center m-t-10">No results</div>
      <div *ngIf="!isSearching" class="recent-accounts">
        <ng-template *ngTemplateOutlet="accountsList; context: { $implicit: filteredAccounts }"></ng-template>
        <hr #recentsAccDividerRef />
      </div>
      <ng-container *ngIf="!isSearching">
        <div class="flex-row heading">
          <h3>All accounts<span class="text-green">.</span></h3>
          <span>{{ originAccounts?.length }}</span>
        </div>
        <div #allAccountsRef class="all-accounts">
          <ng-template
            *ngTemplateOutlet="accountsList; context: { $implicit: originAccounts, all: true }"
          ></ng-template>
        </div>
      </ng-container>

      <div *ngIf="isSearching" class="searching-accounts">
        <ng-template *ngTemplateOutlet="accountsList; context: { $implicit: filteredAccounts }"></ng-template>
      </div>
    </ul>
  </div>
</div>

<!-- templates -->
<ng-template #accountSettingsIcon>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 12.965a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM10.67 13.985c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53v2.65h9.26a6.963 6.963 0 0 1-.59-6.98zM20.75 16.965c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63l-.3-1.49h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63 0 .22.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63l.3 1.49h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zm-3.75 2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
      fill="#73C24B"
    />
  </svg>
</ng-template>

<ng-template #accountsList let-filteredAccounts let-all="all">
  <ng-container *ngFor="let a of filteredAccounts">
    <li (click)="handleAccountNavigation(a)">
      <div class="project-image">
        <img class="logo_image" src="{{ a?.logoUrl }}" (error)="handleMissingImage($event)" alt="project-image" />
      </div>
      <div *ngIf="a._parent_id && !all" class="project-subaccount">
        <i class="pi pi-chevron-right rs-pi-icon-sized text-gray-i c-p"></i>
      </div>
      <div *ngIf="a._parent_id && all" class="flex-center">
        <span class="ws-nw">{{ a._parent_id | getParentName: filteredAccounts | truncate: '7' }}</span>
        <i class="pi pi-chevron-right rs-pi-icon-sized text-gray-i"></i>
      </div>
      <div class="project-name">{{ a.name }}</div>
      <div class="subaccounts-container">
        <button *ngIf="a.properties?.hasSubAccts && !all" type="button" (click)="handleSubaccountsList($event, a)">
          <i class="pi pi-chevron-right rs-pi-icon-sized c-p"></i>
        </button>
        <ul *ngIf="a._subAccountsToggle" class="subaccounts-list">
          <ng-container *ngFor="let a of a._subAccounts">
            <li (click)="handleAccountNavigation(a)">
              <div class="project-image">
                <img class="logo_image" src="../assets/renderseo.png" alt="project-image" />
              </div>
              <div class="project-name">{{ a.name }}</div>
            </li>
          </ng-container>
        </ul>
      </div>
    </li>
  </ng-container>
</ng-template>
