import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UTMService } from '../../../utm-management/services/utm.service';

@Component({
  selector: 'gbp-utm-field-chooser',
  templateUrl: './gbp-utm-field-chooser.component.html',
  styleUrls: ['./gbp-utm-field-chooser.component.scss'],
})
export class GbpUtmFieldChooserComponent {
  @Input() utmField: string;
  @Output() onSelectField = new EventEmitter<{ utmField: string; value: string }>();

  customFields: any[];
  selectedField: string; // To store the user's selection

  constructor(private utmService: UTMService) {}

  ngOnInit(): void {
    // Access the customFields from UTMService
    this.customFields = this.utmService.customFields;
  }

  onFieldSelect(field: string): void {
    this.selectedField = field;
  }

  onConfirm(): void {
    this.onSelectField.emit({ utmField: this.utmField, value: this.selectedField });
  }

  onClose() {
    this.onSelectField.emit({ utmField: '', value: '' });
  }
}
