<!-- @format -->

<div class="main">
  <div class="flex">
    <div class="title-settings">
      <div class="title-settings-child">{{ login.note }}</div>
    </div>
  </div>
  <p-tabMenu [model]="tabItems" [activeItem]="activeItem"></p-tabMenu>
  <div class="card-account-info" *ngIf="activeItem.label == TABS.accountInfo">
    <div class="green-area"></div>
    <span class="space-apart">
      <div class="flex">
        <div class="header-parent-title">
          <div class="header-child-title">Account Information</div>
          <div class="header-child-title">.</div>
        </div>
      </div>
      <button *ngIf="!editingInfo" pButton label="EDIT" class="p-button-primary" (click)="editing()"></button>
      <button *ngIf="editingInfo" pButton label="SAVE" class="p-button-primary" (click)="save()"></button
    ></span>
    <div class="info">
      <div class="header">PERSONAL INFORMATION</div>
      <div class="divider"></div>
      <span class="row"
        ><div class="input-text">Name</div>
        <div *ngIf="!editingInfo">{{ login.note }}</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.note"
          type="text"
          pInputText
          placeholder="Name"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
      <span class="row"
        ><div class="input-text">Email</div>
        <div *ngIf="!editingInfo">{{ login.attrs.prefs.email }}</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.attrs.prefs.email"
          type="text"
          pInputText
          placeholder="Email"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
      <span class="row"
        ><div class="input-text">Office</div>
        <div *ngIf="!editingInfo">{{ login.attrs.prefs.office }}</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.attrs.prefs.office"
          type="text"
          pInputText
          placeholder="Office"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
      <span class="row"
        ><div class="input-text">Mobile</div>
        <div *ngIf="!editingInfo">{{ login.attrs.prefs.mobile }}</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.attrs.prefs.mobile"
          type="text"
          pInputText
          placeholder="Mobile"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
    </div>
    <div class="info">
      <div class="header">PROFESSIONAL INFORMATION</div>
      <div *ngIf="!editingInfo">{{ login.attrs.prefs.position }}</div>
      <div class="divider"></div>
      <span class="row"
        ><div class="input-text">Position</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.attrs.prefs.position"
          type="text"
          pInputText
          placeholder="Position"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
      <span class="row"
        ><div class="input-text">Permission Level</div>
        <div *ngIf="!editingInfo">{{ login.attrs.prefs.permission }}</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.attrs.prefs.permission"
          type="text"
          pInputText
          placeholder="Permission Level"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
      <span class="row"
        ><div class="input-text">Location</div>
        <div *ngIf="!editingInfo">{{ login.attrs.prefs.location }}</div>
        <input
          *ngIf="editingInfo"
          [(ngModel)]="login.attrs.prefs.location"
          type="text"
          pInputText
          placeholder="Location"
          [style]="{ width: '610px', fontSize: '16px' }"
      /></span>
    </div>
    <div class="info">
      <div class="header">PASSWORD CHANGE</div>
      <div class="divider"></div>

      <span class="row">
        <span class="space-apart">
          <span class="left-elements">
            <span class="pw-flex">
              <div class="input-text">Password</div>
              <div *ngIf="!editingPassword">*********</div></span
            >
            <span *ngIf="editingPassword" class="pw-inputs">
              <span class="p-input-icon-right">
                <i class="pi pi-eye" (click)="toggleShowHide('old')"></i>
                <input
                  [(ngModel)]="oldPass"
                  (input)="resetErr()"
                  autocapitalize="off"
                  [type]="passTypeOld"
                  pInputText
                  placeholder="Current"
                  [style]="{ width: '220px', fontSize: '16px', flex: 1 }"
                />
              </span>
              <span class="p-input-icon-right">
                <i class="pi pi-eye" (click)="toggleShowHide('new')"></i>
                <input
                  [(ngModel)]="newPass"
                  (input)="resetErr()"
                  autocapitalize="off"
                  [type]="passTypeNew"
                  pInputText
                  (input)="validateInput($event.target.value)"
                  placeholder="New Password"
                  [style]="{ width: '220px', marginLeft: '40px', fontSize: '16px', flex: 1 }"
                />
              </span>
              <span class="p-input-icon-right">
                <i class="pi pi-eye" (click)="toggleShowHide('confirm')"></i>
                <!-- [(ngModel)]="confirmPass" -->
                <input
                  (input)="resetErr()"
                  autocapitalize="off"
                  [type]="passTypeConfirm"
                  pInputText
                  placeholder="Confirm Password"
                  [style]="{ width: '220px', marginLeft: '40px', fontSize: '16px', flex: 1 }"
                  (input)="validatePasswordMatch($event.target.value)"
                />
              </span>
              <!-- <button
                class="p-inputgroup-addon"
                pButton
                type="button"
                [icon]="eyeIconOld"
                class="p-button-secondary p-button-text eye"
                (click)="toggleShowHide('old')"
                style="box-shadow: none"
              ></button> -->
              <!-- <button
                  pButton
                  type="button"
                  [icon]="eyeIconNew"
                  class="p-button-secondary p-button-text eye"
                  (click)="toggleShowHide('new')"
                  style="box-shadow: none"
                ></button> -->
            </span>
          </span>
          <button
            *ngIf="!editingPassword"
            pButton
            label="CHANGE"
            class="p-button-primary"
            (click)="editingPw()"
          ></button>
          <button
            [disabled]="buttonDisabled"
            *ngIf="editingPassword"
            pButton
            label="SAVE"
            class="p-button-primary"
            (click)="changePassword()"
          ></button> </span
      ></span>
      <div class="rules-flex" *ngIf="editingPassword">
        <span class="rules">
          <fa-icon
            [ngClass]="styleForRule(hasRequiredLength, newPass)"
            [icon]="iconForRule(hasRequiredLength, newPass)"
          ></fa-icon>
          <label>Must have at least 8 characters</label>
        </span>

        <span class="rules">
          <fa-icon
            [ngClass]="styleForRule(requiresLetter, newPass)"
            [icon]="iconForRule(requiresLetter, newPass)"
          ></fa-icon>
          <label>Must have at least 1 letter</label>
        </span>
        <span class="rules">
          <fa-icon
            [ngClass]="styleForRule(requiresDigit, newPass)"
            [icon]="iconForRule(requiresDigit, newPass)"
          ></fa-icon>
          <label>Must have at least 1 digit</label>
        </span>
        <span class="rules err" *ngIf="errMsg">
          <fa-icon [ngClass]="styleForRule(false)" [icon]="iconForRule(false)"></fa-icon>
          <label>{{ errMsg }}</label>
        </span>
      </div>
    </div>
  </div>
  <div class="card-preferences" *ngIf="activeItem.label == TABS.preferences">
    <div class="flex">
      <div class="header-parent-title">
        <div class="header-child-title">Time & Date</div>
        <div class="header-child-title">.</div>
      </div>
    </div>
    <div class="header">TIME FORMAT</div>
    <div class="divider"></div>
    <div class="flex-clocks">
      <div class="flex-zone-clock">
        <span class="header2">Time Zone</span>
        <p-dropdown
          [options]="timeZones"
          [group]="true"
          optionLabel="name"
          optionValue="value"
          [(ngModel)]="login.attrs.prefs.timezone"
          [style]="{ width: '43em', marginRight: '100px' }"
        ></p-dropdown>
      </div>
      <div class="flex-zone-clock">
        <span class="header2">Clock System</span>
        <p-dropdown
          [options]="clock"
          [(ngModel)]="login.attrs.prefs.clock"
          optionLabel="hours"
          [style]="{ width: '200px' }"
        ></p-dropdown>
      </div>
    </div>
    <div class="flex">
      <div class="header-parent-title">
        <div class="header-child-title">Notifications</div>
        <div class="header-child-title">.</div>
      </div>
    </div>
    <div class="checkbox-flex">
      <div class="header">BOARD ACTIVITY AND CONVERSATION</div>
      <div class="divider"></div>
      <span class="row-flex">
        <p-checkbox
          name="board activity and conversation"
          value="val1"
          [(ngModel)]="selectedValues"
          [style]="{ marginRight: '100px' }"
        ></p-checkbox>
        <div class="labels">When a report is generated</div></span
      >
      <span class="row-flex">
        <p-checkbox
          name="board activity and conversation"
          value="val2"
          [(ngModel)]="selectedValues"
          [style]="{ marginRight: '100px' }"
        ></p-checkbox>
        <div class="labels">When changes are made to a locations' details</div></span
      >
    </div>
    <div class="checkbox-flex">
      <div class="header">TEAM ACTIVITY</div>
      <div class="divider"></div>
      <span class="row-flex">
        <p-checkbox
          name="board activity and conversation"
          value="val1"
          [(ngModel)]="selectedValues"
          [style]="{ marginRight: '100px' }"
        ></p-checkbox>
        <div class="labels">When invites have been accepted</div></span
      >
      <span class="row-flex">
        <p-checkbox
          name="board activity and conversation"
          value="val2"
          [(ngModel)]="selectedValues"
          [style]="{ marginRight: '100px' }"
        ></p-checkbox>
        <div class="labels">When a report is downloaded</div></span
      >
    </div>
    <div class="checkbox-flex">
      <div class="header">OTHER UPDATES</div>
      <div class="divider"></div>
      <span class="row-flex">
        <p-checkbox
          name="board activity and conversation"
          value="val1"
          [(ngModel)]="selectedValues"
          [style]="{ marginRight: '100px' }"
        ></p-checkbox>
        <div class="labels">
          Tips on collaboration and board usage, case studies, tutorials and many insightful articles
        </div></span
      >
      <span class="row-flex">
        <p-checkbox
          name="board activity and conversation"
          value="val2"
          [(ngModel)]="selectedValues"
          [style]="{ marginRight: '100px' }"
        ></p-checkbox>
        <div class="labels">Auto generate monthly repost</div></span
      >
    </div>
  </div>
</div>
