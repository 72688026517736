import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { GBPService } from 'src/app/gbp-management/gbp.service';
import {RsThemes} from '@app/core/feature-modules/whitelabel/style-changer/style-changer.interfaces';
import {themeConfig} from '@app/core/feature-modules/whitelabel/style-changer/theme-config';
import {StyleChangerService} from '@app/core/feature-modules/whitelabel/style-changer/style-changer.service';

@Component({
  selector: 'app-gbp-qa-reply',
  templateUrl: './gbp-qa-reply.component.html',
  styleUrls: ['./gbp-qa-reply.component.scss'],
})
export class GbpQaReplyComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  data: any;
  answer: string | null;
  isSuccess = false;
  isWhitelabeled = false;
  squareLogo = '';

  constructor(
    public ref: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    public sessionService: SessionService,
    private gbpService: GBPService,
    private styleChangerService: StyleChangerService
  ) {}

  ngOnInit(): void {
    this.data = this.dynamicDialogConfig.data;
    this.gbpService
      .getAnswers(`(authorType='MERCHANT') AND question_id='${this.data._id}'`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res?.length > 0) {
          this.answer = res[0].answerText;
        }
      });

    this.styleChangerService.getCurrentTheme().subscribe((theme: RsThemes) => {
      this.isWhitelabeled = (theme == RsThemes.whitelabeled);
      this.squareLogo = themeConfig[theme].logos.square;
    });
  }

  getActiveLogo(): string {
    if (this.isWhitelabeled) {
      return this.squareLogo;
    } else if (this.sessionService.loginObj) {
      if (
        this.sessionService.permissions._roles.includes('cust-admin') ||
        this.sessionService.permissions._roles.includes('cust-ro-admin')
      ) {
        let account = this.sessionService.currAccount;
        if (account.includes('-')) {
          account = account.split('-')[0];
        }
        return `assets/logo/${account}.png`;
      } else {
        return 'assets/renderseo.png';
      }
    } else {
      return 'assets/blank.png';
    }
  }

  saveAnswer() {
    // call to backend will be here
    const tempObj = {
      account_id: this.data.account_id,
      answerText: this.answer,
      question_id: this.data ? this.data._id : '',
      _status: 'AP',
    };

    this.gbpService.updateAnswerToQA(tempObj);
    this.isSuccess = true;
  }

  closeDialog(): void {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
