<div class="directory-edit">
  <ng-template *ngTemplateOutlet="dirForm"></ng-template>

  <section class="submit flex-row">
    <button pButton class="rs-pi-no-label rs-btn-small p-button-secondary" (click)="handleCancel()">cancel</button>
    <button pButton class="rs-pi-no-label rs-btn-small" (click)="handleSave()">save changes</button>
  </section>
</div>

<ng-template #dirForm>
  <section>
    <h1 class="ttc">{{ d._vendorName }}</h1>
  </section>
  <section class="dir-form">
    <div class="form-row">
      <p-checkbox
        class="ttu p-10"
        binary="true"
        name="publish"
        label="publish to directory"
        [(ngModel)]="d.publish"
      ></p-checkbox>
    </div>

    <ng-container *ngIf="d.vendor | fieldAvailability: 'chainfields'">
      <div class="form-row">
        <span class="input-field">
          <div class="input-label">Chain name</div>
          <input [(ngModel)]="d.chainName" type="text" pInputText />
        </span>
        <span class="input-field">
          <div class="input-label">Contact name</div>
          <input [(ngModel)]="d.clientContactName" type="text" pInputText />
        </span>
      </div>
      <div class="form-row half">
        <span class="input-field">
          <div class="input-label">Contact email</div>
          <input [(ngModel)]="d.clientContactEmail" type="text" pInputText />
        </span>
      </div>
      <div class="form-row fullWidth">
        <span class="input-field radio">
          <p-radioButton name="catMapLevelgroup" value="acctLevel" [(ngModel)]="d.catMapLevel"></p-radioButton>
          <div class="input-label-radio">Use identical Categories for all Locations</div>
        </span>
      </div>

      <div class="form-row fullWidth">
        <span class="input-field radio">
          <p-radioButton name="catMapLevelgroup" value="locLevel" [(ngModel)]="d.catMapLevel"></p-radioButton>
          <div class="input-label-radio">Use Categories from Location Database<br /></div>
        </span>
      </div>
      <span>Go to Locations > Manage > View and set categories for each individual Location</span>
      <hr />
      <div class="form-row">
        <span class="input-field radio">
          <p-radioButton name="chainnamegroup" value="partial" [(ngModel)]="d.chainFlag"></p-radioButton>
          <div class="input-label-radio">Partial</div>
        </span>
        <span class="input-field radio">
          <p-radioButton name="chainnamegroup" value="full" [(ngModel)]="d.chainFlag"></p-radioButton>
          <div class="input-label-radio">Full</div>
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="d.vendor | fieldAvailability: 'smb'">
      <div class="form-row fullWidth">
        <p-checkbox
          class="ttu p-10"
          binary="true"
          name="isSMB"
          label="Account is SMB"
          [(ngModel)]="d.isSMB"
        ></p-checkbox>
      </div>
    </ng-container>

    <ng-container *ngIf="d.vendor | fieldAvailability: 'utm'">
      <div class="form-row fullWidth">
        <span class="input-field">
          <div class="input-label">
            UTM parameters
            <i class="pi pi-question-circle c-p" [pTooltip]="tooltipUTMText" tooltipStyleClass="appFixTooltipWidth"></i>
          </div>
          <input [(ngModel)]="d.utm" type="text" pInputText />
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="d.vendor | fieldAvailability: 'categories'">
      <div class="categories-edit">
        <div class="categories-title">
          <h5>Categories</h5>
          <span>Select up to 6</span>
          <i *ngIf="d.catMapLevel !== 'locLevel'" class="pi pi-plus-circle c-p" (click)="showAutocomplete = true"></i>
        </div>
        <div class="categories">
          <p-autoComplete
            *ngIf="showAutocomplete"
            [(ngModel)]="selectedCategory"
            valueOption="name"
            field="name"
            appendTo="body"
            scrollHeight="400px"
            placeholder="search for categories..."
            [suggestions]="categoryMatches"
            [dropdown]="true"
            [showEmptyMessage]="true"
            [autofocus]="true"
            (onSelect)="handleCategorySelection($event, d)"
            (completeMethod)="handleSearchCategories($event, d.vendor)"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </p-autoComplete>

          <ng-container *ngFor="let c of categoriesSlots">
            <div
              *ngIf="c"
              class="category-badge ws-nw"
              [class.disabled]="d.catMapLevel === 'locLevel'"
              [pTooltip]="c"
              [tooltipPosition]="'top'"
            >
              {{ c | formatCategory }}&nbsp;<i class="pi pi-times-circle c-p" (click)="handleRemoveCategory(c)"></i>
            </div>
            <div *ngIf="c === undefined" class="category-badge-stub"></div>
          </ng-container>

          <!-- <div class="no-categories-text" *ngIf="d.categories.length === 0">Categories not set</div> -->
        </div>
        <!-- <div *ngIf="d.catMapLevel">Categories</div> -->
        <!-- catMapLevel? -->
      </div>
    </ng-container>
  </section>
</ng-template>
