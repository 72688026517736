import { Injectable } from "@angular/core";
import { DateRange } from '../models/date-range';

@Injectable({
  providedIn: 'root',
})
export class GraphConfigService {
  public dateRanges: DateRange[] = [
    { name: 'Last 3 months', code: 3 },
    { name: 'Last 6 months', code: 6 },
    { name: 'Quarterly comparison', code: 4 },
    { name: 'Last 12 months', code: 12 },
    { name: 'Last 24 months', code: 24 },
  ];

  public applePopupData = [
    {
      id: 'searchCard',
      label: 'Search Card',
      value: 'searchCard',
    },
    {
      id: 'views',
      label: 'Views',
      value: 'views',
    },
    {
      id: 'directions',
      label: 'Directions',
      value: 'directions',
    },
    {
      id: 'websiteClicks',
      label: 'Website clicks',
      value: 'websiteClicks',
    },
    {
      id: 'calls',
      label: 'Calls',
      value: 'calls',
    },
    {
      id: 'shares',
      label: 'Shares',
      value: 'shares',
    },
    {
      id: 'showCaseClicks',
      label: 'Showcase Clicks',
      value: 'showCaseClicks',
    },
    {
      id: 'appointments',
      label: 'Appointments',
      value: 'appointments',
    },
    {
      id: 'menuCard',
      label: 'Menu',
      value: 'menuCard',
    },
    {
      id: 'ordering',
      label: 'Ordering',
      value: 'ordering',
    },
    {
      id: 'reservations',
      label: 'Reservations',
      value: 'reservations',
    },
  ];

  public yelpPopupConfig = [
    {
      header: 'Business metrics',
      config: [
        {
          id: 'totalPageViews',
          label: 'Total User Views',
          value: 'totalPageViews',
        },
        {
          id: 'directionsAndMapViews',
          label: 'Directions & Map Views',
          value: 'directionsAndMapViews',
        },
        {
          id: 'urlClicks',
          label: 'Clicks to Website',
          value: 'urlClicks',
        },
        {
          id: 'checkIns',
          label: 'Mobile Check-ins',
          value: 'checkIns',
        },
        {
          id: 'userPhotos',
          label: 'User Uploaded Photos',
          value: 'userPhotos',
        },
        {
          id: 'bookmarks',
          label: 'Yelp Bookmarks',
          value: 'bookmarks',
        },
        {
          id: 'desktopCTAClicks',
          label: 'Desktop Call to Action Clicks',
          value: 'desktopCTAClicks',
        },
        {
          id: 'mobileCTAClicks',
          label: 'Mobile Call to Action Clicks',
          value: 'mobileCTAClicks',
        },
        {
          id: 'messagesToBusiness',
          label: 'Request a Quote - messages',
          value: 'messagesToBusiness',
        },
        {
          id: 'desktopSearchAppearances',
          label: 'Desktop Appearances in Search',
          value: 'desktopSearchAppearances',
        },
        {
          id: 'desktopPageViews',
          label: 'Desktop User Views',
          value: 'desktopPageViews',
        },
        {
          id: 'mobilePageViews',
          label: 'Mobile User Views',
          value: 'mobilePageViews',
        },
        {
          id: 'trackingCalls',
          label: 'Calls Tracked',
          value: 'trackingCalls',
        },
        {
          id: 'dealsSold',
          label: 'Deals Sold',
          value: 'dealsSold',
        },
        {
          id: 'onlineOrders',
          label: 'Online Orders',
          value: 'onlineOrders',
        },
        {
          id: 'onlineBookings',
          label: 'Online Bookings',
          value: 'onlineBookings',
        },
        {
          id: 'checkInOfferRedemptions',
          label: 'Check In Offer Redemptions',
          value: 'checkInOfferRedemptions',
        },
        {
          id: 'collectionItemAdded',
          label: 'Collections',
          value: 'collectionItemAdded',
        },
        {
          id: 'rapcInitiated',
          label: 'RaPC Initiated',
          value: 'rapcInitiated',
        },
        {
          id: 'waitlistWisitCreated',
          label: 'Waitlist Visit Created',
          value: 'waitlistWisitCreated',
        },
        {
          id: 'medianResponseTimeInSec',
          label: 'Median response time (secs)',
          value: 'medianResponseTimeInSec',
        },
        {
          id: 'replyRate',
          label: 'Reply rate',
          value: 'replyRate',
        },
        {
          id: 'organicBizPageViews',
          label: 'Organic Page Visits',
          value: 'organicBizPageViews',
        },
        {
          id: 'organicBizPageViewsPercentage',
          label: '% Biz Page Views Organic',
          value: 'organicBizPageViewsPercentage',
        },
        {
          id: 'totalLeads',
          label: 'Total # of leads',
          value: 'totalLeads',
        },
      ],
    },
    {
      header: 'Advertiser metrics',
      config: [
        {
          id: 'billedImpressions',
          label: 'Billed Ad Impressions',
          value: 'billedImpressions',
        },
        {
          id: 'billedClicks',
          label: 'Billed Ad Clicks',
          value: 'billedClicks',
        },
        {
          id: 'adCost',
          label: 'Ad Cost',
          value: 'adCost',
        },
        {
          id: 'adDrivenBookmarks',
          label: 'Ad Driven Yelp Bookmarks',
          value: 'adDrivenBookmarks',
        },
        {
          id: 'adDrivenCalls',
          label: 'Ad Driven Mobile Calls',
          value: 'adDrivenCalls',
        },
        {
          id: 'adDrivenCTAClicks',
          label: 'Ad Driven Total Call to Action Clicks',
          value: 'adDrivenCTAClicks',
        },
        {
          id: 'adDrivenCheckIns',
          label: 'Ad Driven Mobile Check-ins',
          value: 'adDrivenCheckIns',
        },
        {
          id: 'adDrivenDealsSold',
          label: 'Ad Driven Deals Sold',
          value: 'adDrivenDealsSold',
        },
        {
          id: 'adDrivenDirectionsAndMapViews',
          label: 'Ad Driven Directions & Map Views',
          value: 'adDrivenDirectionsAndMapViews',
        },
        {
          id: 'adDrivenUserPhotos',
          label: 'Ad Driven User Uploaded Photos',
          value: 'adDrivenUserPhotos',
        },
        {
          id: 'adDrivenOnlineReservations',
          label: 'Ad Driven Online Reservations',
          value: 'adDrivenOnlineReservations',
        },
        {
          id: 'adDrivenUrlClicks',
          label: 'Ad Driven Clicks to Website',
          value: 'adDrivenUrlClicks',
        },
        {
          id: 'adClickThroughRate',
          label: 'Ad Click Through Rate',
          value: 'adClickThroughRate',
        },
        {
          id: 'averageCostPerClick',
          label: 'Average Cost Per Click',
          value: 'averageCostPerClick',
        },
        {
          id: 'billableAdClicks',
          label: 'Billable Ad Clicks',
          value: 'billableAdClicks',
        },
        {
          id: 'billableAdImpressions',
          label: 'Billable Ad Impressions',
          value: 'billableAdImpressions',
        },
        {
          id: 'adDrivenBizPageViews',
          label: 'Ad Driven Page Visits',
          value: 'adDrivenBizPageViews',
        },
        {
          id: 'adDrivenCallsTracked',
          label: 'Ad Driven Calls Tracked',
          value: 'adDrivenCallsTracked',
        },
        {
          id: 'adDrivenRapcInitiated',
          label: 'Ad Driven RaPC Initiated',
          value: 'adDrivenRapcInitiated',
        },
        {
          id: 'adDrivenWaitlistVisitCreated',
          label: 'Ad Driven Waitlist Visit Created',
          value: 'adDrivenWaitlistVisitCreated',
        },
        {
          id: 'adDrivenTotalLeads',
          label: 'Total # of ad driven leads',
          value: 'adDrivenTotalLeads',
        },
        {
          id: 'adDrivenPlatformPurchaseMade',
          label: 'Ad Driven Platform Purchases',
          value: 'adDrivenPlatformPurchaseMade',
        },
        {
          id: 'adDrivenBizPageViewsPercentage',
          label: '% Biz Page Views Ad Driven',
          value: 'adDrivenBizPageViewsPercentage',
        },
      ],
    },
  ];

  public bingPopupData = [
    {
      id: 'impressions',
      label: 'Impressions',
      value: 'impressions',
    },
    {
      id: 'clicks',
      label: 'Clicks',
      value: 'clicks',
    },
    {
      id: 'clickswebsite',
      label: 'Clicks: Website',
      value: 'clickswebsite',
    },
    {
      id: 'clicksdirections',
      label: 'Clicks: Directions',
      value: 'clicksdirections',
    },
    {
      id: 'clicksphone',
      label: 'Clicks: Phone',
      value: 'clicksphone',
    },
    {
      id: 'clicksphotos',
      label: 'Clicks: Photos',
      value: 'clicksphotos',
    },
    {
      id: 'clickslocatedat',
      label: 'Clicks: Located at',
      value: 'clickslocatedat',
    },
    {
      id: 'clicksreview',
      label: 'Clicks: Review',
      value: 'clicksreview',
    },
    {
      id: 'clickssuggestanedit',
      label: 'Clicks: Suggest an Edit',
      value: 'clickssuggestanedit',
    },
    {
      id: 'clicksorderonline',
      label: 'Clicks: Order Online',
      value: 'clicksorderonline',
    },
    {
      id: 'clicksmenu',
      label: 'Clicks: Menu',
      value: 'clicksmenu',
    },
  ];
}
