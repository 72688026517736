import { NgModule } from '@angular/core';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { UtmTabComponent } from './utm-tab.component';
import { LocationsListModule } from 'src/app/core/feature-modules/locations-list/locations-list.module';
import { FormsModule } from '@angular/forms';
import { UTMService } from '../services/utm.service';
import { UtmFieldChooserComponent } from './components/utm-field-chooser.component';
import { ObjectKeysCountPipe } from '../pipes/object-keys-count.pipe';

export const MODULES = [LocationsListModule, FormsModule];

export const COMPONENTS = [UtmFieldChooserComponent, ObjectKeysCountPipe];

@NgModule({
  imports: [RSCommonModule, ...MODULES],
  declarations: [UtmTabComponent, ...COMPONENTS],
  exports: [UtmTabComponent],
  providers: [UTMService],
})
export class UtmTabModule {}
