import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NotFoundComponent } from './pages/not-found/not-found.component';

import { RsTitleComponent } from './rs-title.component';
import { RsTableBehaviorsComponent } from './table-behaviors.component';
import { PrimengModule } from './../feature-modules/primeng.module';
import { RsSpinnerComponent } from './rs-spinner.component';
import { AtPipe } from '../pipes/at.pipe';
import { TruncatePipe } from './../pipes/truncate.pipe';
import { KeyPipe } from './../pipes/key.pipe';
import { SafeHtmlPipe } from './../pipes/safeHtml.pipe';
import { SearchInputComponent } from './search-input/search-input.component';
import { PaletteComponent } from './pages/palette/palette.component';
import { CardGraphComponent } from './card-graph/card-graph.component';
import { CardInsightsComponent } from './card-insights/card-insights.component';
import { CountUpModule } from 'ngx-countup';
import { CardListComponent } from './card-list/card-list.component';
import { RsPageTabsComponent } from './page-tabs/page-tabs.component';
import { RsQuestionMarkComponent } from './inputs/rs-question-mark.component';
import { RsInputBtnDeleteComponent } from './inputs/rs-input-btn-delete.component';
import { ExportHandlerComponent } from './export-handler/export-handler.component';
import { HasPermModule } from '../permissions/permissions.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const COMPONENTS = [
  RsTitleComponent,
  RsSpinnerComponent,
  RsTableBehaviorsComponent,
  RsPageTabsComponent,
  RsQuestionMarkComponent,
  RsInputBtnDeleteComponent,
  SearchInputComponent,
  CardGraphComponent,
  CardInsightsComponent,
  CardListComponent,
  ExportHandlerComponent,
];

const PAGES = [NotFoundComponent];

const PIPES = [AtPipe, TruncatePipe, KeyPipe, SafeHtmlPipe];
@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, PrimengModule, CountUpModule, HasPermModule],
  declarations: [...COMPONENTS, ...PAGES, ...PIPES, PaletteComponent],
  exports: [...COMPONENTS, ...PAGES, ...PIPES, CountUpModule, HasPermModule],
})
export class RsElementsModule {}
