import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPublish } from '../../review-management.interfaces';
import { ReviewsService } from '../../reviews.service';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
})
export class DeletePopupComponent implements OnInit {
  publishObject: IPublish<any>;

  constructor(
    public ref: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private reviewsService: ReviewsService
  ) {}

  ngOnInit(): void {
    this.publishObject = this.dynamicDialogConfig?.data;
  }

  deleteItem() {
    if (this.publishObject['actionType'] === 'Response') {
      this.reviewsService.deleteReviewResponse(this.publishObject);
      this.ref.close(); // delete the review response
    } else {
      // Delete a review.
      this.reviewsService.deleteReview(this.publishObject).subscribe(res => {
        this.ref.close(res);
      })
    }
  }

  closeDialog() {
    this.ref.close();
  }
}
