import { Inject, Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { sprintf } from 'sprintf-js';
import { IAuthenticateData } from './session.interfaces';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { CookieService } from './cookie.service';
import { from, Observable } from 'rxjs';

interface ISocketMessage {
  status: 'ok' | 'err';
  category: string | 'input' | 'DB';
  message: string;
  type: string | 'invalid';
}

declare var window: any;

type StateChangeCallback = (newState: number, oldState: number, args?: object) => void;
type StateCallback = (args?: object) => void;
type SignalCallback = (sigName: string, sigArgs: object) => void;

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  static ERROR = -1;
  static DISCONNECTED = 0;
  static CONNECTING = 1;
  static CONNECTED = 2;
  static AUTHENTICATED = 3;
  static DISCONNECTING = 4;

  keepAliveInterval: number = 180 * 1000;

  connectionState = 0;
  nextSeqNum = Math.floor(Math.random() * 9999) + 1;
  authId: string;
  loginId: string;
  //  postponedRequests: any[] = [];
  pendingRequests: any = {};

  cbState: {
    stateChanged: StateChangeCallback[];
    connected: StateCallback[];
    reconnected: StateCallback[];
    authenticated: StateCallback[];
    deauthenticated: StateCallback[];
  } = {
    stateChanged: [],
    connected: [],
    reconnected: [],
    authenticated: [],
    deauthenticated: [],
  };

  cbSignal: any = {};

  ws$$: WebSocketSubject<ArrayBuffer>;
  wsSubscription;

  statistics: null;
  keepAlive: any;

  retry: {
    count?: number;
    promise?: any;
    resolve?: any;
    reject?: any;
    timeoutId?: any;
  };

  constructor(
    @Inject('WSS_Url') private WSS_Url: string,
    private notifyService: NotifyService,
    private cookieService: CookieService
  ) {
    window.SocketService = this;
  }

  //
  // Connection management
  //

  // disconnect: explicitly distconnect (don't try to reconnect)
  //
  disconnect() {
    console.log('changeConnState:- DISCONNECTING');
    this.changeConnState(SocketService.DISCONNECTING);
    if (this.wsSubscription) {
      this.wsSubscription.unsubscribe(); // close observer
      this.wsSubscription = null;
    }
    this.ws$$.complete(); // close socket by completing the observable
  }

  // reconnect: try to reconnect using the auth token stored in the cookie
  //
  reconnect() {
    // if no retry object, this is the first time in.  come back in 2 seconds.
    if (!this.retry) {
      this.retry = {
        count: 0,
        timeoutId: setTimeout(() => {
          this.reconnect();
        }, 2000),
      };
      const p = new Promise((resolve, reject) => {
        this.retry.resolve = resolve;
        this.retry.reject = reject;
      });
      console.log('socket closed.  initiating reconnect');
      return p;
    }

    // we have a retry object.  if we tried too many times, give up and reject the promise.
    if (this.retry.timeoutId && ++this.retry.count > 30) {
      clearTimeout(this.retry.timeoutId);
      this.retry.reject();
      // this.retry = null;
      return;
    }

    console.log('SocketService.reconnect : attempting reconnect #%d', this.retry.count);
    this.connect();
    this.retry.timeoutId = setTimeout(() => {
      this.reconnect();
    }, 1000); // and try again in 1 second, if not opened
  }

  // connect: establish a websocket connection and set up observers
  //
  connect(url?: string): void {
    if (!url) {
      url = this.WSS_Url;
    }

    this.ws$$ = webSocket({
      url,
      serializer: (data) => data,
      deserializer: (evt) => evt.data,
      openObserver: { next: (evt) => this.onOpen(evt) },
      closingObserver: { next: () => this.onClosing() },
      closeObserver: { next: (closeEvt) => this.onClose(closeEvt) },
    });
    console.log('changeConnState:- CONNECTING');
    this.changeConnState(SocketService.CONNECTING);

    this.wsSubscription = this.ws$$.subscribe(
      (msg) => {
        this.onMessage(msg);
      }, // Called whenever there is a message from the server
      (err) => {
        this.onError(err);
      } // Called if WebSocket API signals some kind of error
      //      () =>  { this.onSubClose()   }   // Called when connection is closed (for whatever reason)
    );
  }

  onOpen(evt) {
    if (this.retry) {
      clearTimeout(this.retry.timeoutId);
      console.log(sprintf('socket.connect : reconnect succeeded after %d retries', this.retry.count));
      this.retry.resolve();
      delete this.retry;
    }

    // update connection state and inform via callbacks
    console.log('changeConnState:- CONNECTED 1');
    this.changeConnState(SocketService.CONNECTED);
    if (Array.isArray(this.cbState.connected)) {
      this.cbState.connected.forEach((cb) => cb);
    }
  }

  onError(err: string) {
    console.log('changeConnState:- ERROR');
    this.changeConnState(SocketService.ERROR);
    this.stopKeepAlive('onWsError');
  }

  onClosing() {}

  onClose(closeEvt) {
    this.stopKeepAlive('onWsClose');
    this.ws$$ = null;
    this.wsSubscription = null;
    console.log('changeConnState:- DISCONNECTED');
    const oldState = this.changeConnState(SocketService.DISCONNECTED);

    // don't try to reconnect if we explicitly called disconnect()
    // or we're already retrying.
    if (oldState != SocketService.DISCONNECTING && !this.retry) {
      this.reconnect();
    }
  }

  stopKeepAlive(n) {
    if (this.keepAlive) {
      clearTimeout(this.keepAlive);
    }
    this.keepAlive = null;
    // console.log("this.stopKeepalive(" + n + ")");
  }

  startKeepAlive(n) {
    if (this.keepAlive) {
      clearTimeout(this.keepAlive);
    }

    if (this.connectionState == SocketService.AUTHENTICATED) {
      this.keepAlive = setTimeout(() => {
        this.sendRequest('ping', this.statistics).catch((errArgs) => {
          if (errArgs.category == 'auth') {
            console.log('changeConnState:- CONNECTED 2');
            this.changeConnState(SocketService.CONNECTED, errArgs);
          }
        });
      }, this.keepAliveInterval);
      // console.log(sprintf("socket.startKeepAlive(%s, id=%s)", n, this.keepAlive));
    }
  }

  //
  // Message processing
  //

  private onMessage(message): void {
    const t1 = message.indexOf(' ');

    if (t1 > 0) {
      const m1 = message.substr(0, t1);
      const payload = message.substr(t1 + 1);
      const args: ISocketMessage = JSON.parse(payload);

      if (m1.match(/^\d+$/)) {
        // m1 is a request sequence number
        const req = this.pendingRequests[m1];

        if (args.status === 'err' && args.category !== 'DB') {
          if (args.category === 'auth') {
            // TODO: need to reload auth. as a fast trick to reload() everything on sensitive errors
            // this.authenticating();
            // debugger;
            // this.notifyService.error('This action is forbidden');
            // this.notifyService.error('Wait for a while...');
            // location.reload();

            if (args.message === 'Invalid token') {
              this.cookieService.delete(this.cookieService.COOKIE_ID_CONSTANT);
              location.reload();
            }
          } else {
            // this.notifyService.error('Warning or Error: ' + args.message);
          }
        }

        console.log(
          sprintf(
            'received response[%db, %dms] %s %%j',
            message.length,
            // tslint:disable-next-line: new-parens
            new Date().valueOf() - req.startTime,
            m1
          ),
          args
        );

        if (args.status == 'ok') {
          req.resolve(args);
        } else {
          req.reject(args);
        }

        delete this.pendingRequests[m1];
      } // m1 is an asynchronous signal name
      else {
        console.log(sprintf('received signal[%db] %s %%j', message.length, m1), args);

        if (this.cbSignal[m1]) {
          if (Array.isArray(this.cbSignal[m1])) {
            this.cbSignal[m1].forEach((cb: SignalCallback) => {
              cb(m1, args);
            });
          }
        }
      }
      this.startKeepAlive('onMessage');
    }
  }
  // end of SocketService

  sendRequest(reqId: string, args?: any) {
    // debugger;
    if (!this.ws$$) {
      console.log('sendRequest: auto-connecting...');
      this.connect();
    }

    this.stopKeepAlive('sendRequest');

    const payload = JSON.stringify(args || {});
    ++this.nextSeqNum;
    this.nextSeqNum %= 10000;
    const reqSeq = this.nextSeqNum;
    let authId = '';

    if (this.authId) {
      authId = ':' + this.authId;
    }
    // debugger

    const message = reqSeq + authId + ' ' + reqId + ' ' + payload;
    console.log(sprintf('sending message[%db] %s %%j', message.length, reqSeq + authId + ' ' + reqId), args);

    this.ws$$.next(message as unknown as ArrayBuffer);

    return new Promise((resolve, reject) => {
      this.pendingRequests[reqSeq] = { resolve, reject, startTime: new Date().valueOf() };
    });
  }

  //
  // Authentication
  //

  authenticating(credentials: any) {
    if (credentials.authId) {
      let reqAuthId = credentials.authId;

      return this.sendRequest('authenticate', credentials).then((args: IAuthenticateData) => {
        if (args.authId != reqAuthId) {
          debugger;
          return null;
        }

        this.loginId = args.login.loginId;
        this.authId = args.authId;
        console.log('changeConnState:- AUTHENTICATED');
        this.changeConnState(SocketService.AUTHENTICATED, args);
        this.startKeepAlive('authenticated');
        if (Array.isArray(this.cbState.authenticated)) {
          this.cbState.authenticated.forEach((cb) => cb(args));
        }
        return args;
      });
    }
  }

  deauthenticate() {
    return this.sendRequest('deauthenticate', { authId: this.authId }).then((args: any) => {
      console.log('changeConnState:- CONNECTED 3');
      this.changeConnState(SocketService.CONNECTED, args);
      if (Array.isArray(this.cbState.deauthenticated)) {
        this.cbState.deauthenticated.forEach((cb: (args: any) => void) => {
          cb(args);
        });
      }
      this.authId = null;
      this.loginId = null;
      return args;
    });
  }

  //
  // Low-level messages
  //

  signal(sigId, args) {
    return this.sendRequest('signal', { sigId, sigArgs: args });
  }

  ping() {
    return this.sendRequest('ping');
  }

  // changeAccount1(account: string) {
  //   return this.sendRequest('change-account', { account });
  // }

  changeAccount(account: string): Observable<any> {
    return from(this.sendRequest('change-account', { account }));
  }

  changeIdentity(partyId: number, account?: string) {
    return this.sendRequest('change-identity', { partyId, account });
  }

  //
  // Utility functions
  //

  on(stateName: string, cb: any): void {
    let cbList = this.cbState[stateName];
    if (!cbList) {
      cbList = this.cbState[stateName] = [];
    }

    for (let i = 0; i < cbList.length; ++i) {
      // don't add an existing callback again
      if (cbList[i] == cb) {
        return;
      }
    }

    cbList.push(cb);
  }

  off(s: string, cb: () => void): void {
    const cbList = this.cbState[s];
    if (!cbList) {
      return;
    }

    for (let i = 0; i < cbList.length; ++i) {
      if (cbList[i] == cb) {
        cbList.splice(i, 1);
        return;
      }
    }
  }

  onSignal(sigName: string, cb: SignalCallback): void {
    let cbList = this.cbSignal[sigName];
    if (!cbList) {
      cbList = this.cbSignal[sigName] = [];
    }

    for (let i = 0; i < cbList.length; ++i) {
      // don't add an existing callback again
      if (cbList[i] == cb) {
        return;
      }
    }

    cbList.push(cb);
  }

  getStateName(n: number) {
    if (n < 0) {
      return 'ERROR';
    }
    if (n > 4) {
      return '?';
    }
    return ['DISCONNECTED', 'CONNECTING', 'CONNECTED', 'AUTHENTICATED', 'DISCONNECTING'][n];
  }

  changeConnState(newState: number, args?: any): number {
    if (!args) {
      args = {};
    }

    const oldState = this.connectionState;
    this.connectionState = newState;
    console.log('socket state: %s -> %s', this.getStateName(oldState), this.getStateName(newState));

    if (Array.isArray(this.cbState.stateChanged)) {
      for (const cb of this.cbState.stateChanged) {
        cb(newState, oldState, args);
      }
    }

    return oldState;
  }
}
