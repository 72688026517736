import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { capitalize } from 'src/app/core/helpers/global.utils';
import { IBreadcrumbItem } from './breadcrumbs.interfaces';

@Component({
  selector: 'breadcrumbs-base',
  template: '',
})
export class BreadcrumbsBase implements OnDestroy {
  protected subs = new Subscription();
  private params: Params;
  private routerEvent: any;
  private crumbsValuesConfig: IBreadcrumbItem[]; // : Array<Promise<any>>;
  config;
  private symbols = { arrowRight: '&#62;', arrowRightBold: '<b>&#62;</b>', roundDot: '&#8226' };
  public separator = this.symbols.arrowRight;

  constructor(protected router: Router, protected route: ActivatedRoute) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public checkSeparatorInserting(i: number): string {
    const s = i <= this.config.length - 1 ? this.separator : '';
    return s;
  }

  protected buildBreadcrumbsConfig(PARAMS: Params, routerEvent: RouterEvent, cb) {
    if (!routerEvent) {
      return;
    }
    if (!this.routerEvent || this.routerEvent.url !== routerEvent.url) {
      this.params = PARAMS;
      this.routerEvent = routerEvent;
    }

    const arr = this.routerEvent.urlAfterRedirects.split('#');
    const hash = `${arr[1] ? `${arr[1]}` : ''}`;
    const urlSegmentsArr = [...arr[0].split('/'), hash].filter((v) => !!v);

    const fullUrlToCurrentSegmentName = [''];

    this.crumbsValuesConfig = urlSegmentsArr.map((urlSegment: string) => {
      fullUrlToCurrentSegmentName.push(urlSegment);
      let crumb: IBreadcrumbItem = { subj: null };

      for (const paramKey in this.params) {
        if (this.params[paramKey] === urlSegment) {
          if (paramKey === 'accountId') {
            urlSegment = urlSegment; // get param of account and replace with name
          }

          if (paramKey === 'locationId') {
            urlSegment = urlSegment; // get param of location and replace with name
          }

          crumb = {
            subj: { text: capitalize(urlSegment), link: fullUrlToCurrentSegmentName.join('/') },
          } as IBreadcrumbItem;
          break;
        }
      }

      if (!crumb.subj) {
        crumb.subj = { text: capitalize(urlSegment), link: fullUrlToCurrentSegmentName.join('/') };
      }
      return crumb as IBreadcrumbItem;
    });
    this.crumbsValuesConfig[this.crumbsValuesConfig.length - 1].subj.link = '';

    this.config = this.crumbsValuesConfig.map((v) => ({ label: v.subj.text, routerLink: [v.subj.link] }));
    delete this.config[this.config.length - 1].routerLink;

    cb(this.config);

    // Promise.all(transformPromises).then((transformedUrlData) => {
    //   Promise.all(this.crumbsValuesConfig).then((data: IBreadcrumbItem[]) => {
    //     for (const item of data) {
    //       // Case: Event Name
    //       if (item.subj.text === 'event' && transformedUrlData[0]) {
    //         item.name.text = transformedUrlData[0];
    //       }

    //       // Case: Participant
    //       if (item.subj.text === 'participant' && transformedUrlData[1]) {
    //         item.name.text = transformedUrlData[1];
    //       }

    //       // Case: customurl
    //       if (item.subj.text === 'customurl' && transformedUrlData[2]) {
    //         item.name.text = transformedUrlData[2];
    //       }
    //     }

    //     this.config = data;

    //   });
    // });
  }
}
