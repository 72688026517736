<p-dialog id="chng-pwd-dialog" [modal]="true" [closeOnEscape]="true" [(visible)]="showDialog" *ngIf="makeDialog">
  <ng-template pTemplate="header">
    <h3 class="pwd-header">Change password for {{ login?.note }}</h3>
  </ng-template>
  <div>
    <p><span class="login-id">Login ID: </span>{{ currentUser }}</p>

    <span>
      <label for="old-pass" style="font-weight: bold">Old password:</label>
      <div class="relative">
        <input id="old-pass" [(ngModel)]="oldPass" [type]="passTypeOld" autocapitalize="off" (input)="resetErr()" />
        <button
          pButton
          type="button"
          [icon]="eyeIconOld"
          class="p-button-secondary p-button-text eye"
          (click)="toggleShowHide('old')"
          style="box-shadow: none"
        ></button>
      </div>
    </span>

    <br />

    <span>
      <label for="new-pass" style="font-weight: bold">New password:</label>
      <div class="relative">
        <input
          id="new-pass"
          [(ngModel)]="newPass"
          [type]="passTypeNew"
          autocapitalize="off"
          autocomplete="off"
          (input)="validateInput($event.target.value)"
        />
        <button
          pButton
          type="button"
          [icon]="eyeIconNew"
          class="p-button-secondary p-button-text eye"
          (click)="toggleShowHide('new')"
          style="box-shadow: none"
        ></button>
      </div>
      <div>
        <span class="rules">
          <fa-icon
            [ngClass]="styleForRule(hasRequiredLength, newPass)"
            [icon]="iconForRule(hasRequiredLength, newPass)"
          ></fa-icon>
          <label>Must have at least 8 characters</label>
        </span>

        <span class="rules">
          <fa-icon
            [ngClass]="styleForRule(requiresLetter, newPass)"
            [icon]="iconForRule(requiresLetter, newPass)"
          ></fa-icon>
          <label>Must have at least 1 letter</label>
        </span>
        <span class="rules">
          <fa-icon
            [ngClass]="styleForRule(requiresDigit, newPass)"
            [icon]="iconForRule(requiresDigit, newPass)"
          ></fa-icon>
          <label>Must have at least 1 digit</label>
        </span>
        <span class="rules err" *ngIf="errMsg">
          <fa-icon [ngClass]="styleForRule(false)" [icon]="iconForRule(false)"></fa-icon>
          <label>{{ errMsg }}</label>
        </span>
      </div>
    </span>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      label="Cancel"
      class="p-button-outlined p-button-secondary dialog"
      (click)="closeChangePwdDialog()"
    ></button>
    <button pButton label="Change" class="dialog" (click)="changePassword()"></button>
  </ng-template>
</p-dialog>

<p-toast onClose="clearToast()"></p-toast>
