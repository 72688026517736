import { Directive, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Directive({
  selector: '[appFixTooltipWidth]',
})
export class FixTooltipWidthDirective implements OnInit {
  @ViewChild(Tooltip) tooltip: Tooltip;

  constructor(private host: ElementRef) {}

  ngOnInit() {
    (this.host.nativeElement as HTMLElement).setAttribute('tooltipStyleClass', 'appFixTooltipWidth');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule],
  declarations: [FixTooltipWidthDirective],
  exports: [FixTooltipWidthDirective],
})
export class FixTooltipWidthModule {}
