<div
  [ngClass]="{
    'sidebar-menu': true,
    collapsed: collapsed,
    expanded: !collapsed
  }"
  *ngIf="sessionService.getCurrentUser$() | async"
>
  <div
    [ngClass]="{
      'side-menu': true,
      collapsed: collapsed,
      expanded: !collapsed
    }"
  >
    <div
      [ngClass]="{
        identity: true,
        collapsed: collapsed,
        expanded: !collapsed
      }"
    >
      <div class="header-menu">
        <div class="agency-logo" (click)="toggleAccountSelector = !toggleAccountSelector">
          <img class="logo-avatar" [src]="activeLogo" alt="logo" />
          <div class="logo-hover-stub"></div>
        </div>

        <app-sidebar-menu-account-selector
          *ngIf="toggleAccountSelector"
          (closeSelector)="toggleAccountSelector = false"
        ></app-sidebar-menu-account-selector>

        <p *ngIf="!collapsed">{{ mainAccount }}</p>
      </div>
    </div>

    <!-- <div class="search">
    <div #autocompleteWrap>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon" *ngIf="!collapsed && activeAcct">
          <img src="../assets/logo/{{ topAcct?.ident }}.png" />
        </span>
        <p-autoComplete
          #acctAutoComplete
          [(ngModel)]="activeAcct"
          [suggestions]="accountMatches"
          [dropdown]="true"
          placeholder="Search or select account..."
          field="label"
          [ngClass]="{
            'accts-menu': true,
            full: !collapsed && !activeAcct,
            partial: !collapsed && activeAcct
          }"
          scrollHeight="0em"
          (completeMethod)="searchAccts($event)"
          (onKeyUp)="accountSearchKeyup($event)"
          (click)="onClick($event, autocompleteWrap)"
          *ngIf="!collapsed"
        >
        </p-autoComplete>
      </div>
      <p-overlayPanel
        #op
        class="resultsOverlay"
        [dismissable]="true"
        [appendTo]="autocompleteWrap"
        [style]="{
          width: '300px',
          'max-height': '750px',
          overflow: 'auto',
          'margin-left': '32px',
          'margin-top': '5px',
          'border-top-left-radius': '0'
        }"
        (onShow)="showOP()"
        (onHide)="hideOP()"
        (onKeyUp)="accountSearchKeyup($event)"
      >
        <ng-template pTemplate>
          <p-tree
            #accts
            id="accts"
            [value]="treeAccounts"
            selectionMode="single"
            indentation="5"
            [(selection)]="selectedNode"
            (onNodeSelect)="nodeSelected($event)"
            (onNodeExpand)="expandNode($event)"
          >
            <ng-template let-node pTemplate="default">
              <img
                src="../assets/logo/{{ node.data }}.png"
                *ngIf="!node.parent"
              />
              <span [ngClass]="{ subacct: node.parent }">{{
                node.label
              }}</span>
            </ng-template>
          </p-tree>
        </ng-template>
      </p-overlayPanel>
    </div>
  </div> -->

    <p-panelMenu
      [model]="menuItemsLeft"
      [ngClass]="{ collapsed: collapsed, expanded: !collapsed }"
      [multiple]="false"
    ></p-panelMenu>
  </div>
  <div class="menu-toggle">
    <button
      pButton
      type="button"
      [ngClass]="{
        'p-button-text': true,
        hover: toggleHover,
        expanded: !collapsed
      }"
      [icon]="collapsed ? 'pi pi-angle-right' : 'pi pi-angle-left'"
      (click)="toggleSideMenu(menuItemsLeft[0])"
      (mouseenter)="onToggleHover()"
      (mouseleave)="onToggleLeave()"
    ></button>
  </div>
</div>
