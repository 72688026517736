import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-gbp-photo-exif',
  templateUrl: './gbp-photo-exif.component.html',
  styleUrls: ['./gbp-photo-exif.component.scss'],
})
export class GbpPhotoExifComponent implements OnInit {
  exifData = {};
  src: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.exifData = {
      'GPS Latitude Reference': 'N',
      'GPS Latitude': 42.0753,
      'GPS Longitude Reference': 'W',
      'GPS Longitude': -88.289929,
      Artist: 'Toys"R"Us (4115 N)',
      Owner: 'Toys"R"Us',
      Copyright: 'Copyright (c )2019 Toys"R"Us',
      DateStamp: '07/09/2021 4:30 PM',
    };

    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://maps.google.com/maps?q=${this.exifData['GPS Latitude']},${this.exifData['GPS Longitude']}&output=embed&z=14`
    );
  }

  public keepOriginalOrder = (a, b) => a.key;
}
