<div class="utm-page-container">
  <!-- Main page header -->
  <div class="flex">
    <div class="header-parent-title">
      <div class="header-child-title">UTM Codes</div>
      <div class="header-child-title">.</div>
    </div>
    <div>
      <span
        >Set up UTM parameters to be attached to the Website URL that is sent to select Publishers. Enter a value for at
        least one parameter. Click the database icon to use location-specific values from your locations.
      </span>
    </div>
  </div>
  <!-- Tab menu-->
  <ng-container *ngIf="hasAccount">
    <p-tabMenu class="tabs-menu" [model]="tabItems" [activeItem]="activeItem">
      <ng-template pTemplate="item" let-item let-i="index">
        <div class="tabs-style">{{ item.label.toUpperCase() }}</div>
      </ng-template>
    </p-tabMenu>
    <ng-container *ngIf="tabItems.length">
      <div class="utm-tab">
        <app-utm-tab [providerName]="activeItem.id" [providerLabel]="activeItem.label"></app-utm-tab>
      </div>
    </ng-container>
  </ng-container>
</div>
