import { Directive, ElementRef, Output, EventEmitter, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[clickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  subs = new Subscription();
  // tslint:disable-next-line: variable-name
  constructor(private host: ElementRef, @Inject(DOCUMENT) private _document: Document) {}

  @Output() public clickOutside = new EventEmitter<MouseEvent>();

  ngOnInit() {
    this.listenForOutsideClick();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private listenForOutsideClick(): void {
    this.subs.add(
      fromEvent(this._document, 'click').subscribe((event: MouseEvent) => {
        const targetElement = event.target;

        // tslint:disable-next-line: curly
        if (!targetElement) return;

        const clickedInside = this.host.nativeElement.contains(targetElement);
        if (!clickedInside) {
          this.clickOutside.emit(event);
        }
      })
    );
  }
}
