import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit, OnDestroy {
  public search$$ = new Subject<string>();
  public searching: boolean;
  @Input() placeholder = 'Search...';
  @Output() changeSearch = new EventEmitter<string>();
  @Output() startSearch = new EventEmitter<string>();

  subs = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.listenSearch();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private listenSearch(): void {
    this.subs.add(this.search$$.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((v) => this.changeSearch.emit(v)));
  }
}
