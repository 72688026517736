import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gbp-tab-qa',
  templateUrl: './gbp-tab-qa.component.html',
  styleUrls: ['./gbp-tab-qa.component.scss'],
})
export class GbpTabQaComponent implements OnInit {
  qaRole = 'Customer';
  stateOptions = ['Customer', 'Owner'];
  constructor() {}

  ngOnInit(): void {}
}
