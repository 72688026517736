<div class="bing-reports_wrapper">
  <div class="dashboard">
    <div
      class="card"
      [ngClass]="{
        card: true,
        insights: true,
        'half-height': showReviews,
        'full-height': !showReviews
      }">
      <div class="card-header insights">
        <h3>BING INSIGHTS</h3>
        <div class="header-right">
          <p-dropdown
            [options]="dateRanges"
            [(ngModel)]="selectedRange"
            optionLabel="name"
            optionValue="code"
            (onChange)="dateRangeChanged($event)">
          </p-dropdown>
          <p-splitButton class="advance_filters" label="Filter by" [model]="tags" (onClick)="openFilters()"
            styleClass="p-button-sm">
          </p-splitButton>
          <button pButton class="edit p-button-text" icon="fa fa-pen" (click)="editInsights()"></button>
        </div>
      </div>
      <hr />

      <div class="card_holder" [class.full_width]="fullWidth" [class.half_width]="!fullWidth">
        <p>No data to display.</p>
        <ng-container *ngIf="!insightsErr; else noInsights">
          <ng-container *ngFor="let data of demoData">
            <div class="chart" *ngIf="data.visibility" [class.full_width__chart]="fullWidth"
              [class.half_width__chart]="!fullWidth">
              <div class="chart_block">
                <h2 class="chart_block_title">{{ data.title }}</h2>
                <p-chart type="bar" [data]="data.graphData" [options]="stackOptions" width="100%"
                  [height]="showReviews ? '18vh' : '22vh'">
                </p-chart>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noInsights>
          <h2>No data to display</h2>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- Edit card preference pop-up -->
<p-dialog id="insights" header="Customize Insights" [(visible)]="showEditPopup" [modal]="true" [dismissableMask]="true"
  (onHide)="cancelEditPopup()">
  <div class="insights_container">
    <ng-container *ngFor="let data of popupData">
      <div id="{{ data.id }}" class="insights_list">
        <label class="insights_list_label">{{ data.label }}</label>
        <p-checkbox name="googleInsights" value="{{ data.value }}" [(ngModel)]="selectedInsightPrefs"
          (onChange)="prefsChanged()">
        </p-checkbox>
      </div>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div>
      <button pButton label="CANCEL" class="p-button-outlined p-button-secondary" (click)="cancelEditPopup()"></button>
      <button pButton label="SAVE" (click)="savePrefs()"></button>
    </div>
  </ng-template>
</p-dialog>
