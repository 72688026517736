import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { SocketService } from './socket.service';
import { BroadcastService } from './broadcast.service';
import { DB_initialize } from './db2/db2-lib';
import { IDBLib } from './db2/db2-lib.interfaces';

@Injectable({ providedIn: 'root' })
export class DBService extends IDBLib {
  public DB: IDBLib;

  constructor(
    public socketService: SocketService,
    public sessionService: SessionService,
    public broadcastService: BroadcastService
  ) {
    super();
    this.DB = DB_initialize(this.sessionService, this.socketService, this.broadcastService);
    // INFO: merge unused DBInstance variable to the service directly to use it as an singleton directly
    Object.assign(this, this.DB);
  }
}
