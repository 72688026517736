import { NgModule } from '@angular/core';
import { RSCommonModule } from '../core/feature-modules/facade.module';
import { ReportsComponent } from './reports.component';
import { SqlReportsComponent } from './sql-reports/sql-reports.component';
import { GoogleDashboardTabComponent } from './google-dashboard-tab/google-dashboard-tab.component';
import { YelpDashboardTabComponent } from './yelp-dashboard-tab/yelp-dashboard-tab.component';
import { AccuracyReportsComponent } from './accuracy-reports/accuracy-reports.component';
import {ImageOnErrorModule} from '@app/core/directives/image-onerror.directive';
import { AppAppleDashboardMapsTabComponent } from './apple-dashboard-tab/apple-dashboard-tab.component';
import { InsightsTableCardComponent } from './shared/components/insights-table-card/insights-table-card.component';
import { FilterButtonComponent } from './shared/components/filter-buttons/filter-buttons.component';
import { ReportsToolsComponent } from  './reports-tools/reports-tools.component';
import { BingInsightsComponent } from './bing-insights/bing-insights.component';

const Components = [
  ReportsComponent,
  SqlReportsComponent,
  GoogleDashboardTabComponent,
  YelpDashboardTabComponent,
  AccuracyReportsComponent,
  AppAppleDashboardMapsTabComponent,
  InsightsTableCardComponent,
  FilterButtonComponent,
  ReportsToolsComponent,
  BingInsightsComponent,
];

@NgModule({
  declarations: [...Components],
  exports: [],
    imports: [RSCommonModule, ImageOnErrorModule],
  providers: [],
})
export class ReportsModule {}
