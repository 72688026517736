import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, Observable } from 'rxjs';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-qa-owner-view-answers',
  templateUrl: './gbp-qa-owner-view-answers.component.html',
  styleUrls: ['./gbp-qa-owner-view-answers.component.scss'],
})
export class GbpQaOwnerViewAnswersComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  data: any;
  errorImg = '';
  question;
  type: string;
  answers$: Observable<any>;

  constructor(
    public ref: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private gbpService: GBPService
  ) {}

  ngOnInit(): void {
    this.question = this.dynamicDialogConfig.data.question;
    // this.type = this.dynamicDialogConfig.data.type;

    this.answers$ = this.gbpService.getQuestions({
      where: `authorType!='MERCHANT' AND bulkOwner_id='${this.question._id}'`,
    }); //  AND bulkOwner_id='${this.question._id}'
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
