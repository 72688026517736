import { Component } from '@angular/core';

@Component({
  selector: 'app-rs-spinner',
  template: `<span class="rs-spinner fz-25 p-20"><i class="pi rs-pi-icon-sized pi-spin pi-spinner"></i></span>`,
  styles: [
    `
      .rs-spinner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class RsSpinnerComponent {}
