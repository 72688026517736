<div class="header-parent-title">
  <div class="header-child-title">Location EXIF</div>
  <div class="header-child-title">.</div>
</div>
<div class="gbp-location-exif-wrapper">
  <div class="gbp-location-exif-data">
    <table class="table">
      <tr *ngFor="let item of exifData | keyvalue: keepOriginalOrder">
        <td class="list-key">{{ item.key }}</td>
        <td class="list-value">{{ item.value }}</td>
      </tr>
    </table>
  </div>
  <iframe [src]="src" width="350" allowfullscreen></iframe>
</div>
