import {Component, HostListener, OnInit} from '@angular/core';
import {BroadcastService} from '../core/backend-adapter/broadcast.service';
import {DBService} from '../core/backend-adapter/db.service';
import {SessionService} from '../core/backend-adapter/session.service';
import {SocketService} from '../core/backend-adapter/socket.service';
import RZLib from '../core/backend-adapter/db2/RZ-lib.helpers';

@Component({
  selector: 'app-parties',
  templateUrl: './parties.component.html',
  styleUrls: ['./parties.component.scss'],
})
export class PartiesComponent implements OnInit {
  account: any;
  accounts: any[];
  parties: any[];
  subAccCopy: any;
  flatGlobal: any = {};
  makeDialog: boolean;
  showDialog: boolean;

  selectedParty: any = null;
  accChange = false;

  tableHeight = '900px';
  tableWidth = '1100px';
  loading = true;

  dbStatusTypes = [
    {code: 'A', name: 'Active', descr: 'Active'},
    {code: 'I', name: 'Inactive', descr: 'Inactive'},
  ];

  categoryMatches: any[];
  haveErr = false;
  message: string;

  vendors = [
    'gbp',
    'appleMaps',
    'amazonalexa',
    'bing',
    'factual',
    'here',
    'infogroup',
    'localeze',
    'nextdoor',
    'pandio',
    'tomtom',
    'uber',
    'yelp',
  ];

  constructor(
    private broadcastService: BroadcastService,
    public dbService: DBService,
    public sessionService: SessionService,
    private socketService: SocketService
  ) {
  }

  ngOnInit(): void {
    this.account = this.dbService.Account.findByIdent(this.sessionService.currAccount);

    this.dbService.Party.loadObjects().then((resp) => {
      this.loading = false;
      this.parties = resp.collection;
      this.accounts = this.dbService.Account.coll;
    });

    this.broadcastService.subscribe('accountChanged', (resp) => {
      this.accChange = true;
      this.account = this.dbService.Account.findByIdent(resp.account);

      this.dbService.Party.loadObjects().then((resp) => {
        this.parties = resp.collection;
      });
    });
  }

  buildTooltipText(subaccount) {
    return `ID: ${subaccount._id}`;
  }

  selectParty(acct) {
    this.selectedParty = acct.ident;
  }

  getAndEdit(ident) {
    let fullAccount = {};

    for (const party of this.parties) {
      if (party.ident === ident) {
        fullAccount = party;
      }
    }

    this.editParty(fullAccount);
  }

  buildWhereClause() {
    const whereClauses = [];

    for (const vendor of this.vendors) {
      const codes = this.flatGlobal[`${vendor}.categories`];

      if (codes && codes.length > 0) {
        const catsWquotes = [];

        for (const code of codes) {
          catsWquotes.push(`'${code}'`);
        }

        whereClauses.push(`vendorIdent = '${vendor}' AND code IN (${catsWquotes.join(',')})`);
      }
    }
    const whereClause = whereClauses.join(' OR ');

    return whereClause;
  }

  editParty(acct) {
    this.subAccCopy = this.dbService.Party.importFromHash(this.dbService.Party.exportToHash(acct));

    this.flatGlobal = RZLib.flattenHash(this.subAccCopy.global);

    this.dbService.CategoryType.loadObjects({where: this.buildWhereClause()}).then(
      () => {
        this.dbService.CategoryType.fillCatsByVendor();

        for (const vendor of this.vendors) {
          if (vendor == 'localeze' || vendor == 'here') {
            continue;
          }

          const key = `${vendor}.categories`;
          if (!Array.isArray(this.flatGlobal[key])) {
            this.flatGlobal[key] = [];
          }

          for (const i of Object.keys(this.flatGlobal[key])) {
            const code = this.flatGlobal[key][i];
            this.flatGlobal[key][i] = this.dbService.CategoryType.findByCodeForVendor(code, vendor);
          }
        }

        this.makeDialog = true;
        this.showDialog = true;
      },
      (err) => {
        console.log('Categ objects load fail');
      }
    );

    if (!this.subAccCopy.properties.customColumnDefs) {
      this.subAccCopy.properties.customColumnDefs = [];
    }
  }

  addParty() {
    this.subAccCopy = this.dbService.Party.importFromHash({
      ident: '',
      name: '',
      properties: {defaultLang: 'en_US', enabledAttrs: {}, customColumnDefs: []},
      global: {},
      hashKey: '',
    });
    this.flatGlobal = RZLib.flattenHash(this.subAccCopy.global);

    for (const vendor of this.vendors) {
      this.flatGlobal[`${vendor}.categories`] = [];
    }

    this.makeDialog = true;
    this.showDialog = true;
  }

  saveParty() {
    for (const vendor of this.vendors) {
      if (vendor == 'localeze' || vendor == 'here') {
        continue;
      }

      const cats = this.flatGlobal[`${vendor}.categories`];

      for (const i in cats) {
        if (cats[i] == null) {
          continue;
        }
        cats[i] = cats[i].code;
      }
    }

    this.subAccCopy.global = RZLib.expandHash(this.flatGlobal);

    this.dbService.Party.saveObject(this.subAccCopy)
      .then((resp) => {
        this.showDialog = false;
        this.makeDialog = false;
        this.parties = this.dbService.Party.coll.slice();
      })
      .catch((err) => {
        this.haveErr = true;
        this.message = err.message;
      });
  }

  // searchCats(event, cats) {
  //   this.categoryMatches = [];
  //   for (let cat of cats) {
  //     let re = RegExp(event.query, 'i');
  //     if (cat.name.match(re) || cat.descr && cat.descr.match(re) || cat.code.match(re))
  //       this.categoryMatches.push(cat);
  //   }
  //   return this.categoryMatches;
  // }

  searchCats(event, ident) {
    const q = event.query;
    const where = ['-and', `vendorIdent = '${ident}'`];
    const words = q.trim().split(/\s+/);

    for (const word of words) {
      if (word.length >= 3) {
        where.push(`name ~* '\\y${word}' or code ~* '\\y${word}'`);
      }
    }

    this.socketService
      .sendRequest('get-vendor-cat-coll', {
        where,
        limit: 50,
        order: `case when (name ~* '\\y${words[0]}\\y') then 0 else 1 end`,
      })
      .then((v: any) => {
        this.categoryMatches = v.collection;
      })
      .catch((e) => console.warn(e));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let wid = event.target.innerWidth;
    if (wid > 1115) {
      wid = 1115;
    } else if (wid < 400) {
      wid = 400;
    }
    this.tableWidth = wid - 115 + 'px';

    const hgt = event.target.innerHeight;
    this.tableHeight = hgt - 165 + 'px';

    // this.zone.run(() => {});
  }
}

function addParty() {
  throw new Error('Function not implemented.');
}
