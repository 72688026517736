<div class="accuracy-reports-wrapper">
  <div class="dashboard">
    <div class="card half-height">
      <div class="card-header">
        <h3>ACCURACY REPORT</h3>
      </div>
      <hr/>
      <div *ngIf="publisherStatistics">
        <div *ngFor="let publisher of publishers;" class="card-body">
          <div class="directory-card" *ngIf="publisherStatistics.hasOwnProperty(publisher)">
            <section class="dir-logo">
              <div class="dir-vendor">
                <img class="vendor-image" [src]="VENDOR_IMAGES[publisher]" appImageOnError/>
                <div class="dir-title ttc">{{ publisherMap[publisher] }}</div>
              </div>
            </section>
            <section *ngIf="publisherStatistics[publisher].length" class="accuracy-fields">
              <div *ngFor="let field of fields;" class="accuracy-field">
                <div class="accuracy-field-name">{{ fieldMap[field] }}</div>
                <div class="accuracy-field-percentage"
                     [pTooltip]="getPublisherFieldTooltip(publisher, field)">{{ getPublisherFieldMatchPercentage(publisher, field) | number: '1.0-0' }}%</div>
              </div>
              <div>
                <a href="/download/sql-report/{{ publisher }}_accuracy?format=csv"><i
                  class="fa fa-file-download download-report"></i></a><br>
                Download
              </div>
            </section>

            <section *ngIf="publisherStatistics[publisher].length == 0" class="accuracy-fields-empty">
              <div>Coming soon...</div>
            </section>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>


