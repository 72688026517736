<div class="gbp-page-container">
  <!-- Main page header -->
  <div class="flex">
    <div class="header-parent-title">
      <div class="header-child-title">Google Management</div>
      <div class="header-child-title">.</div>
    </div>
  </div>

  <!-- Tab menu-->
  <!-- <p-tabMenu class="tabs-menu" [model]="tabItems" [activeItem]="activeItem">
    <ng-template pTemplate="item" let-item let-i="index">
      <div class="tabs-style">{{ item.label }}</div>
    </ng-template>
  </p-tabMenu> -->
  <rs-page-tabs [tabItems]="tabItems" (tabSelected)="activeItem = $event"> </rs-page-tabs>

  <!-- Photo Tab -->
  <div class="gbp-photo" *ngIf="activeItem.label == gbpTabs.PHOTO">
    <app-gbp-tab-photo></app-gbp-tab-photo>
  </div>

  <!-- Post Tab -->
  <div class="gbp-post" *ngIf="activeItem.label == gbpTabs.POST">
    <app-gbp-post></app-gbp-post>
  </div>

  <!-- Q&A Tab -->
  <div class="gbp-qa" *ngIf="activeItem.label == gbpTabs.QA">
    <app-gbp-tab-qa></app-gbp-tab-qa>
  </div>
</div>
