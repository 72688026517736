import { Component, OnInit } from "@angular/core";
import { MapsInsightsConfigService } from "../shared/services/apple-maps-graph-config.service";
import { GraphConfigService } from "../shared/services/graph-config.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { FilterButtonComponent } from "../shared/components/filter-buttons/filter-buttons.component";
import { AppleMapsInsightsDataService } from "../shared/services/apple-maps-insights-data.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'bing-insights',
  templateUrl: './bing-insights.component.html',
  styleUrls: ['./bing-insights.component.scss'],
})
export class BingInsightsComponent implements OnInit {

  public showReviews: boolean = true;
  public dateRanges = [
    { name: 'Last 3 months', code: 3 },
    { name: 'Last 6 months', code: 6 },
    { name: 'Quarterly comparison', code: 4 },
    { name: 'Last 12 months', code: 12 },
    { name: 'Last 24 months', code: 24 },
  ];
  public selectedRange: number = 3;
  public fullWidth: boolean = false;
  public insightsErr: boolean = false;
  public demoData: any[] = [];
  public stackOptions;
  public showEditPopup: boolean = false;
  public popupData: any[] = [];
  public selectedInsightPrefs: string[] = [];
  public tags: any[] = [
    {
      label: 'Store code filter',
      disabled: false,
      command: () => {
        this.primaryFilters();
      }
    },
    {
      label: 'Advanced filters',
      disabled: false,
      command: () => {
        this.advanceFilters();
      }
    }
  ];
  public componentRef: DynamicDialogRef | undefined;

  private allAdvanceFilters: any[] = [];
  private ngUnsubscribe$ = new Subject();

  constructor(
    private graphConfigService: GraphConfigService,
    private mapsInsightsConfigService: MapsInsightsConfigService,
    private dialogService: DialogService,
    private mapsInsightsDataService: AppleMapsInsightsDataService,
  ) {}

  ngOnInit(): void {
    this.stackOptions = this.mapsInsightsConfigService.stackOptions;
    this.popupData = this.graphConfigService.bingPopupData;
    this.selectedInsightPrefs = [
      'impressions',
      'clicks',
      'clickswebsite',
      'clicksdirections',
      'clicksphone'
    ];
    this.setFilters();
  }

  public dateRangeChanged(event): void {
    this.fullWidth = this.selectedRange === 12 || this.selectedRange === 24 ? true : false;
    // this.loadInsightsData(this.selectedRange);
    // this.loadTrends();
  }

  public editInsights(): void {
    // this.prevInsightPrefs = [...this.selectedInsightPrefs];
    // this.disabledField = this.getDisabledField();
    this.showEditPopup = true;
  }

  public cancelEditPopup(): void {
    // this.setInsightsCardVisibility();
    this.showEditPopup = false;
  }

  public savePrefs(): void {
    this.showEditPopup = false;
  }

  public prefsChanged(): void {
    this.setInsightsCardVisibility();
  }

  private setInsightsCardVisibility(): void {
    this.demoData.forEach((card) => {
      card['visibility'] = this.selectedInsightPrefs.includes(card.id) ? true : false;
    });
  }

  public primaryFilters(): void {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Filter by Store Code',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.mapsInsightsDataService.setBasicFiltersData()], // Add filter data here.....
        isAdvanceFilterActive: false
      }
    });
    this.mapsInsightsDataService.getDialogRef(this.componentRef);
  };

  public advanceFilters(): void {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Advance filters',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.allAdvanceFilters], // Add filter data here.....
        isAdvanceFilterActive: true
      }
    });

    this.mapsInsightsDataService.getDialogRef(this.componentRef);
  };

  private setFilters(): void {
    const advanceFiltersObs$ = this.mapsInsightsDataService.getDynamicFilters();
    advanceFiltersObs$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([tags, googleLabels, businessCategories]) => {
        this.allAdvanceFilters = this.mapsInsightsDataService.setAdvanceFiltersData()
          .map(filter => {
            if (filter.label === 'Tag') {
              tags.collection?.forEach(tag => {
                filter.checkboxList.push({
                  label: tag.tagName?.replace(/_/g, ' ')
                })
              });
              return filter;
            };

            if (filter.label === 'Google Label') {
              googleLabels?.collection.forEach(tag => {
                filter.checkboxList.push({
                  label: tag.labelName?.replace(/_/g, ' ')
                })
              });
              return filter;
            }

            if (filter.label === 'Business Category') {
              businessCategories?.collection.forEach(tag => {
                filter.checkboxList.push({
                  label: tag.categoryName?.replace(/_/g, ' ')
                })
              });
              return filter;
            }

            // if (filter.label === 'Google Attributes') {
            //   googleAttributes?.collection.forEach(tag => {
            //     filter.checkboxList.push({
            //       label: tag.labelName?.replace(/_/g, ' ')
            //     })
            //   });
            //   return filter;
            // }

            return filter;
          });
      });
  }

  public openFilters(): void {
    console.log('filter opened');
  }


  /**
   * NOTES
   * Create a service that will keep/ track instance of all dynamic filters and will be shared
   * amongst all directories in Insights page. Eg: Google, Yelp, Bing, Apple Maps etc.
   */

}
