import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { ManageNotificationsTabComponent } from '../manage-notifications-tab/manage-notifications-tab.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ManageNotificationsGaurd implements CanDeactivate<ManageNotificationsTabComponent> {
  canDeactivate(component: ManageNotificationsTabComponent): boolean | Observable<boolean> {
    if (component.hasUnsavedData) {
      component.openDialog();
      return false;
    }
    return true;
  }
}
