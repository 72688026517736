<div class="header-parent-title">
  <div *ngIf="showSteps" class="header-child-title">Create {{ selectedType }} Post</div>
  <div *ngIf="!showSteps" class="header-child-title">Select Post Type</div>
  <div class="header-child-title">.</div>
</div>

<div class="post-create-wrapper">
  <div *ngIf="!showSteps; else router" class="post-create-type">
    <div *ngIf="false" class="post-create-col">
      <div class="post-create-row">
        <p-radioButton
          class="radio-style"
          name="postType"
          value="Alert"
          inputId="Alert"
          [(ngModel)]="selectedType"
        ></p-radioButton>
        <label class="post-create-label" for="Alert">Alert</label>
      </div>
      <div class="post-create-row">
        Provide an update about changes related to COVID-19 and how the location manages safety and hygiene.
      </div>
    </div>
    <div class="post-create-col">
      <div class="post-create-row">
        <p-radioButton name="postType" value="Standard" inputId="Standard" [(ngModel)]="selectedType"></p-radioButton>
        <label class="post-create-label" for="Standard">Standard</label>
      </div>
      <div class="post-create-row">
        Give any general information about your business, like photos, videos, descriptions, or sharing new services.
      </div>
    </div>
    <div class="post-create-col">
      <div class="post-create-row">
        <p-radioButton name="postType" value="Event" inputId="Event" [(ngModel)]="selectedType"></p-radioButton>
        <label class="post-create-label" for="Event">Event</label>
      </div>
      <div class="post-create-row">
        Promote an event taking place at your business. Details can include text, photo or video and an action button.
      </div>
    </div>
    <div class="post-create-col">
      <div class="post-create-row">
        <p-radioButton name="postType" value="Offer" inputId="Offer" [(ngModel)]="selectedType"></p-radioButton>
        <label class="post-create-label" for="Offer">Offer</label>
      </div>
      <div class="post-create-row">
        Provide promotional sales or offers from your business. Include details like a photo, video, description, coupon
        code, link, and terms and conditions.
      </div>
    </div>
  </div>
  <ng-template #router [ngSwitch]="selectedType">
    <app-event-post *ngSwitchCase="'Event'"></app-event-post>
    <app-offer-post *ngSwitchCase="'Offer'"></app-offer-post>
    <app-covid19-update-post *ngSwitchCase="'Alert'"></app-covid19-update-post>
    <app-covid19-update-post *ngSwitchCase="'Standard'" [isStandard]="true"></app-covid19-update-post>
  </ng-template>
</div>

<div *ngIf="!showSteps" class="bottom-buttons">
  <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
  <button
    pButton
    label="SAVE AND NEXT"
    [disabled]="!selectedType"
    class="p-button-primary"
    (click)="saveAndNext()"
  ></button>
</div>
