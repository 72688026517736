import { Injectable } from '@angular/core';
import { DirectoryWrapperComponent } from '../directory-wrapper/directory-wrapper.component';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SqlListingsAdapter } from './sql-listings/sql-listings.adapter';
import { DirectoryFlatListingsRow, DirectoryListingsRow } from './directory-listings.interfaces';
import { fixVendorName } from '../directory.helpers';
import { VendorKeysType } from '../directory.constants';

@Injectable()
export class DirectoryListingsService {
  constructor(private dlAdapter: SqlListingsAdapter) {}

  public initListings() {
    this.dlAdapter.retrieveAllSqlListings();
  }

  public getListingsByVendor(vendor: VendorKeysType): Observable<DirectoryListingsRow[]> {
    return this.dlAdapter.getAllListings$().pipe(
      filter((v) => !!v),
      map((rows) => this.filterByVendor(rows, vendor))
    );
  }

  public getListingsByLocation(locationId: number): Observable<DirectoryListingsRow[]> {
    return this.dlAdapter.getAllListings$().pipe(
      filter((v) => !!v),
      map((rows) => rows.filter((v) => v.locationId === locationId))
    );
  }

  public getListings(): Observable<DirectoryFlatListingsRow[]> {
    return this.dlAdapter.getAllListings$().pipe(
      filter((v) => !!v),
      // map((rows) => rows)
      map((rows) => {
        return this.dlAdapter.mapAllListingsTableData();
      })
    );
  }

  private filterByVendor(rows, vendor: VendorKeysType): DirectoryListingsRow[] {
    vendor = fixVendorName(vendor);
    const res = rows.filter((v) => v.vendor === vendor);
    return res;
  }
}
