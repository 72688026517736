import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DBService } from '../../core/backend-adapter/db.service';
import { SocketService } from '../../core/backend-adapter/socket.service';
import { SessionService } from '../../core/backend-adapter/session.service';
import { TypeDefs } from '../../acct-comps/type-defs';
import { Subject, from } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { AccountsService } from 'src/app/acct-comps/accounts.service';

declare var window: any;

@Component({
  selector: 'app-logins',
  templateUrl: './logins.component.html',
  styleUrls: ['./logins.component.scss'],
})
export class LoginsComponent implements OnInit, OnDestroy {
  // table variables
  tableWidth: string;
  tableHeight: string;
  logins: any[];
  selLogin: any;

  // popup variables
  showDialog: boolean;
  makeDialog: boolean;
  newLogin: boolean;
  loginCopy: any;
  errMsg: string;
  authList: any[] = [];
  statuses: any[];
  nullAuth: any = {
    code: 'null-entry',
    name: '- Select option -',
    descr: 'To replace null entries and provide default',
    inactive: true,
  };
  selectedTags: string[] = [];
  private ngUnsubscribe$: Subject<any> = new Subject();
  public tableHeaderData = [
    {
      label: 'ID',
      enableSorting: true,
      sortingLable: '_id',
      fieldName: '_id',
      className: 'db-id',
    },
    {
      label: 'Login ID',
      enableSorting: true,
      sortingLable: 'loginId',
      fieldName: 'loginId',
      className: 'login-id',
    },
    {
      label: 'Password',
      enableSorting: true,
      sortingLable: 'lastPwReset',
      fieldName: 'lastPwReset',
      className: 'password',
    },
    {
      label: ' Last reset',
      enableSorting: true,
      sortingLable: 'lastPwReset',
      fieldName: 'lastPwReset',
      className: 'pwd-reset',
    },
    {
      label: 'Name',
      enableSorting: true,
      sortingLable: 'note',
      fieldName: 'note',
      className: 'notes',
    },
    {
      label: 'Account',
      enableSorting: true,
      sortingLable: 'account',
      fieldName: 'account',
      className: 'account',
    },
    {
      label: 'Auth Roles',
      enableSorting: true,
      sortingLable: 'authTypes',
      fieldName: 'authTypes',
      className: 'auth-types',
    },
    {
      label: 'Tags',
      enableSorting: true,
      sortingLable: 'tags',
      fieldName: 'tags',
      className: 'tags',
    },
    {
      label: 'Status',
      enableSorting: true,
      sortingLable: '_status',
      fieldName: '_status',
      className: 'status',
    },
  ];
  public loading: boolean = true;

  accounts: any[] = [];
  authAccount: any[] = [];
  // tags: any[] = [];
  // allLocations: any[] = [];
  public authAccountAttributes: any[] = [];
  // authAccountDropdownValues: any[] = [];
  // reportType: string;
  public filterValue: string = '';
  private globalAccAuthAttributes: any[] = [];
  public activeTab: string = '';
  private nullAcctAuth = {
    _id: '',
    ident: '',
    locations: [],
    tags: [],
    name: '',
    activeTab: '',
    allTags: [],
    allLocs: [],
  };
  public ctaActionType: string;
  public editAuthAccountData: any[] = [];
  public first: number = 0;
  public rows: number = 10;
  public totalRecords: number;

  constructor(
    public dbService: DBService,
    public socketService: SocketService,
    public sessionService: SessionService,
    private typeDefs: TypeDefs,
    private notifyService: NotifyService,
    private accountsService: AccountsService
  ) {
    this.statuses = this.typeDefs.dbStatusTypes;
    for (const s of this.statuses) {
      this.statuses[s.code] = s;
    }

    window.LoginsComponent = this;
  }

  ngOnInit(): void {
    this.getAuthRoles();
    this.getAllLoginsData();
    this.onResize({ target: window });
    this.getAllAccounts();
  }

  private getAllAccounts(): void {
    this.accountsService
      .getAccounts()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(({ collection }) => {
        this.accounts = this.accountsService.mapAccountsWithSubaccounts(collection).map((el) => ({
          _id: el?._id,
          ident: el?.ident,
          name: el?.ident,
          locations: [],
          tags: [],
          activeTab: '',
          allTags: [],
          allLocs: [],
        }));
      });
  }

  private getLocs(index): void {
    const locs$ = from(
      this.socketService.sendRequest('get-locations-summary', {
        columns: ['_id', 'storeCode'], // add _owner_id
        where: ['-and'],
      })
    );

    locs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((location) => {
      this.globalAccAuthAttributes =
        location &&
        location['collection'] &&
        location['collection'].length &&
        location['collection'].map((el) => ({
          _id: el[0],
          storeCode: el[1],
          name: el[1],
        }));
      this.authAccount.forEach((acc, i) => {
        if (index === i) {
          this.authAccount[i]['allLocs'] = this.globalAccAuthAttributes;
        }
      });
    });
  }

  private getTags(index): void {
    const tags$ = from(
      this.socketService.sendRequest('get-location-tags-and-counts', {
        where: ['-and'],
      })
    );
    tags$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.globalAccAuthAttributes =
        res['collection'] &&
        res['collection'] &&
        res['collection'].length &&
        res['collection'].map((t) => {
          return {
            name: t['tagName'],
            _id: t['tagName'],
            storeCode: t['tagName'],
          };
        });

      this.authAccount.forEach((acc, i) => {
        if (index === i) {
          this.authAccount[i]['allTags'] = this.globalAccAuthAttributes;
        }
      });
    });
  }

  private getAuthRoles(): void {
    const authRoles$ = from(this.dbService.AuthType.loadObjects());
    authRoles$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.authList = res['collection'] || [];
      this.authList.splice(0, 0, this.nullAuth);
    });
  }

  private getAllLoginsData(): void {
    const data$ = from(this.dbService.Login.loadObjects());
    data$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
      (login: unknown) => {
        this.logins = login['collection'];
        this.totalRecords = this.logins.length;
        if (this.logins.length) {
          this.loading = false;
          this.notifyService.success('User Logins loaded successfully');
        }
      },
      (error) => {
        this.logins = [];
        this.loading = false;
        this.notifyService.warning('Error loading User Logins');
      },
      () => {
        this.loading = false;
      }
    );
  }

  public suggestPassword(): void {
    const suggestPswd$ = from(this.socketService.sendRequest('suggest-password'));
    suggestPswd$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((repl: unknown) => {
      this.loginCopy.password = repl['suggestions'][0];
    });
  }

  authTypeList(authTypes) {
    return authTypes.map((a) => (a ? a.name : '*')).join(', ');
  }

  removeAuth(i) {
    if (this.loginCopy.authTypes.length > 1) {
      this.loginCopy.authTypes.splice(i, 1);
    }
  }

  addAuth(i) {
    this.loginCopy.authTypes.splice(i + 1, 0, this.nullAuth);
  }

  trackIndex(index, item) {
    return index;
  }

  addLogin(ctaType: string) {
    this.getAllAccounts(); // To reset accounts prop, as it's caching data, while re-adding a new user login.
    this.selectedTags = ['console'];
    this.ctaActionType = ctaType;
    this.loginCopy = {
      _status: 'A',
      loginId: '',
      password: '',
      note: '',
      account: '',
      authTypes: [this.nullAuth],
      tags: [],
      authz: {},
    };
    this.authAccount.splice(0, 0, this.nullAcctAuth);
    this.newLogin = true;
    this.showDialog = true;
    this.makeDialog = true;
  }

  editLogin(login, ctaType: string) {
    this.getAllAccounts(); // To reset accounts prop, as it's caching data, while re-adding a new user login.

    this.ctaActionType = ctaType;
    this.loginCopy = this.dbService.Login.importFromHash(this.dbService.Login.exportToHash(login));
    if (Object.keys(this.loginCopy['authz']).length) {
      this.loginCopy['authz']['accounts'].forEach((acc, i) => {
        this.accounts.forEach((el, i) => {
          if (acc._id === el._id) {
            this.editAuthAccountData.push({
              ...el,
            });
          }
        });
      });

      this.authAccount = this.loginCopy['authz']['accounts'].map((acc, i) => {
        if (acc['locations']?.length) {
          setTimeout(() => {
            this.fireAccChange(acc['ident']);
            this.getLocs(i);
          }, i * 1000);
        } else {
          setTimeout(() => {
            this.fireAccChange(acc['ident']);
            this.getTags(i);
          }, i * 1000);
        }

        return {
          _id: acc['_id'],
          ident: acc['ident'],
          name: acc['ident'],
          activeTab: acc['locations']?.length ? 'locations' : 'tags',
          locations: acc['locations']?.length
            ? [...acc['locations']?.map((el) => ({ ...el, name: el.storeCode }))]
            : [],
          tags: acc['tags']?.length
            ? [
                ...acc['tags']?.map((el) => ({
                  name: el,
                  _id: el,
                  storeCode: el,
                })),
              ]
            : [],
          allLocs: [],
          allTags: [],
        };
      });
    }

    this.selectedTags = this.loginCopy.tags;
    this.showDialog = true;
    this.makeDialog = true;
  }

  closePopup() {
    this.newLogin = false;
    this.errMsg = null;
    this.showDialog = false;
    this.makeDialog = false;
    this.authAccount = [];
    if (this.editAuthAccountData.length) {
      this.editAuthAccountData = [];
    }
  }

  public saveLogin(): void {
    this.loading = true;
    if (this.loginCopy.account === '') {
      this.loginCopy.account = null;
    }

    const auths = this.loginCopy.authTypes;
    for (const i of Object.keys(auths)) {
      if (auths[i] && auths[i].code == 'null-entry') {
        auths.splice(i, 1);
      }

      if (!auths[i]) {
        continue;
      }
    }

    this.loginCopy.tags = [...this.selectedTags];
    let accounts = [];
    this.authAccount.forEach((acc, i) => {
      if (acc._id) {
        accounts.push({
          _id: acc['_id'],
          ident: acc['ident'],
          [acc.activeTab]:
            acc.activeTab === 'locations'
              ? acc['locations'].map((el) => ({ _id: el['_id'], storeCode: el['storeCode'] }))
              : acc['tags'].map((el) => el['_id']),
        });
      }
    });

    this.loginCopy.authz = accounts.length ? { accounts } : {};
    const {_lastUpdatedAt, ...loginObj} = this.loginCopy;
    const saveUserLogin$ = from(this.dbService.Login.saveObject(loginObj));
    saveUserLogin$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(() => from(this.dbService.Login.loadObjects()))
      )
      .subscribe(
        (res) => {
          this.logins = res['collection'];
          this.errMsg = null;
          this.newLogin = false;
          this.selectedTags = [];
          this.showDialog = false;
          this.makeDialog = false;
          this.loading = false;
          this.notifyService.success('User Login saved successfully');
          this.authAccount = [];
          this.editAuthAccountData = [];
        },
        (error) => {
          this.errMsg = error.message;
          this.loading = false;
          this.notifyService.warning('Error saving User Login');
          this.authAccount = [];
          this.editAuthAccountData = [];
        }
      );
  }

  public selectAuthAttribute(type: string, selectedAcc, index): void {
    this.activeTab = type;
    if (type === 'locations') {
      this.getLocs(index);
    } else {
      this.getTags(index);
    }
    this.authAccount.forEach((acc, i) => {
      if (acc._id === selectedAcc._id) {
        this.authAccount[i].activeTab = type;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let wid = event.target.innerWidth;
    if (wid > 1900) {
      wid = 1900;
    }
    this.tableWidth = wid - 20 + 'px';

    const hgt = event.target.innerHeight;
    this.tableHeight = hgt - 230 + 'px';
  }

  private fireAccChange(ident): void {
    this.sessionService
      .changeAccount(ident)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {});
  }

  public accountChanged(event): void {
    if (this.ctaActionType === 'editUser') {
      this.authAccount[this.authAccount.length - 1] = event.value;
    }

    this.sessionService
      .changeAccount(event.value.ident)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {});
  }

  selectLocOrTag(event, i) {
    if (this.authAccount[i].activeTab === 'locations') {
      this.authAccount[i].locations = event.value;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  addAccountAuth(i): void {
    this.authAccount.splice(i + 1, 0, this.nullAcctAuth);
  }

  removeAccountAuth(i): void {
    if (this.authAccount.length > 1) {
      this.authAccount.splice(i, 1);
    }
  }

  selectedUserLogin(event) {}

  public pageChange(event): void {
    this.first = event.first;
    this.rows = event.rows;
  }
}
