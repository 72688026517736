import { capitalize } from '../../helpers/global.utils';
import { VendorKeysType } from './directory.constants';

export const getVendorName = (v: VendorKeysType): string => {
  switch (v) {
    case 'appleMaps':
      return 'Apple Maps';
    case 'amazonalexa':
      return 'Amazon Alexa';
    case 'tomtom':
      return 'TomTom';
    case 'pandio':
      return 'YP Package';
    case 'gmb':
      return 'Google Maps';
    case 'ypca':
      return 'YP Canada';

    default:
      return capitalize(v);
  }
};

export const mapVendorNames = (vendors): {} => {
  return vendors.reduce((acc, v: VendorKeysType) => {
    switch (v) {
      case 'gmb':
        acc[v] = 'Google Maps';
        break;
      case 'pandio':
        acc[v] = 'YP Package';
        break;
      case 'appleMaps':
        acc[v] = 'Apple Maps';
        break;
      case 'amazonalexa':
        acc[v] = 'Amazon Alexa';
        break;
      case 'tomtom':
        acc[v] = 'TomTom';
        break;
      case 'ypca':
        acc[v] = 'YP Canada';
        break;
      default:
        acc[v] = v.toLowerCase();
    }
    return acc;
  }, {});
};

export const fixVendorName = (vendor: VendorKeysType): VendorKeysType => {
  // if (vendor === 'gmb') {
  //   vendor = 'gbp';
  // }
  if (vendor === ('applemaps' as VendorKeysType)) {
    vendor = 'appleMaps';
  } else if (vendor === ('dba' as VendorKeysType)) {
    vendor = null;
  }
  return vendor;
};
