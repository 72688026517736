import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TIME_ZONES } from 'src/app/account-settings/account-settings.data';
import { GBPService } from 'src/app/gbp-management/gbp.service';

@Component({
  selector: 'app-gbp-select-location-table',
  templateUrl: './gbp-select-location-table.component.html',
  styleUrls: ['./gbp-select-location-table.component.scss'],
})
export class GbpSelectLocationTableComponent implements OnInit, OnDestroy {
  timeZones = [];
  locations = [];
  isSelectedAll: boolean;
  current = 0;
  currentLocations = [];
  paginatorRows = 5;
  paginatorLength = 0;
  filterValue = '';
  filtered = [];
  unsubscribe$ = new Subject<void>();

  constructor(private gbpService: GBPService) {}

  ngOnInit(): void {
    // Get locations from service
    this.gbpService
      .getAllLocations(['_id', 'storeCode', 'businessName', 'city', 'state', 'tags', 'labels'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locations) => {
        this.locations = locations;
        this.timeZones = TIME_ZONES;
        this.currentLocations = this.locations.slice(0, this.paginatorRows);
        this.paginatorLength = this.locations.length;
        this.filtered = locations;
      });
  }

  onPageChange(event) {
    this.currentLocations = this.filtered.slice(event.first, +event.first + +event.rows);
  }

  onPanelHide() {
    this.filterValue = '';
    this.currentLocations = this.locations.slice(0, this.paginatorRows);
  }

  onFilter(event) {
    this.filtered = [...this.locations.filter((obj) => obj?.businessName.includes(event.target.value))];
    this.paginatorLength = this.filtered.length;
    this.currentLocations = this.filtered.slice(0, this.paginatorRows);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
