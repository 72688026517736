<div class="listings-table-container">
  <h1 class="m-0">All listings</h1>
  <p-table
    #sqlTbl
    [value]="filteredListings"
    dataKey="_id"
    scrollable="true"
    autoLayout="true"
    responsive="true"
    [resizableColumns]="true"
    [first]="first"
    [currentPageReportTemplate]="'Showing {first} to {last} of {totalRecords} locations'"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [rows]="pageSize"
    [totalRecords]="totalEntries"
  >
    <ng-template pTemplate="caption">
      <div class="flex-center">
        <app-search-input
          [placeholder]="'Search listings by location name or store code...'"
          (changeSearch)="handleSearch($event)"
        ></app-search-input>
        <span class="flex-center">({{ filteredListings?.length }})</span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th class="storeCode" pResizableColumn pSortableColumn="storeCode">
          Storecode
          <p-sortIcon field="storeCode"></p-sortIcon>
        </th>
        <th class="location" pResizableColumn pSortableColumn="location">
          Location
          <p-sortIcon field="location"></p-sortIcon>
        </th>
        <!-- 7 vendors -->
        <th class="yelp" pResizableColumn pSortableColumn="yelp">
          yelp
          <p-sortIcon field="yelp"></p-sortIcon>
        </th>
        <th class="mapquest" pResizableColumn pSortableColumn="mapquest">
          mapquest
          <p-sortIcon field="mapquest"></p-sortIcon>
        </th>
        <th class="pandio" pResizableColumn pSortableColumn="pandio">
          {{ getVendorName('pandio') }}
          <p-sortIcon field="pandio"></p-sortIcon>
        </th>
        <th class="appleMaps" pResizableColumn pSortableColumn="appleMaps">
          appleMaps
          <p-sortIcon field="appleMaps"></p-sortIcon>
        </th>
        <th class="bing" pResizableColumn pSortableColumn="bing">
          bing
          <p-sortIcon field="bing"></p-sortIcon>
        </th>
        <th class="gmb" pResizableColumn pSortableColumn="gmb">
          Google
          <p-sortIcon field="gmb"></p-sortIcon>
        </th>
        <th class="nextdoor" pResizableColumn pSortableColumn="nextdoor">
          nextdoor
          <p-sortIcon field="nextdoor"></p-sortIcon>
        </th>
      </tr>
      <app-rs-table-behaviors [searching]="loading" [items]="filteredListings"></app-rs-table-behaviors>
    </ng-template>

    <ng-template pTemplate="body" let-r>
      <tr>
        <td class="storeCode">{{ r.storeCode }}</td>
        <td class="location">{{ r.location }}</td>

        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.yelp }"></ng-template>
        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.mapquest }"></ng-template>
        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.pandio }"></ng-template>
        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.appleMaps }"></ng-template>
        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.bing }"></ng-template>
        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.gmb }"></ng-template>
        <ng-template *ngTemplateOutlet="link; context: { $implicit: r.nextdoor }"></ng-template>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #noLink> - no link - </ng-template>

<ng-template #link let-link>
  <td class="link">
    <ng-container *ngIf="link; else noLink">
      <div class="link-row" *ngIf="link | checkTypeof: 'string'">
        <a target="_blank" [href]="link | safeHtml: 'url'"> {{ link }}&nbsp;<i class="pi pi-external-link"></i> </a>
        <button
          pButton
          pTooltip="Copy to clipboard"
          class="p-button-text"
          icon="pi pi-copy rs-pi-icon-sized"
          (mouseup)="handleCopyToClipboard(link)"
          [cdkCopyToClipboard]="copyToClipboard"
        ></button>
      </div>
      <ng-container *ngIf="link | checkTypeof: 'array'">
        <div class="link-row" *ngFor="let l of link">
          <a target="_blank" [href]="l | safeHtml: 'url'"> {{ l }}&nbsp;<i class="pi pi-external-link"></i> </a>
          <button
            pButton
            pTooltip="Copy to clipboard"
            class="p-button-text"
            icon="pi pi-copy rs-pi-icon-sized"
            (mouseup)="handleCopyToClipboard(l)"
            [cdkCopyToClipboard]="copyToClipboard"
          ></button>
        </div>
      </ng-container>
    </ng-container>
  </td>
</ng-template>
