<app-progress #progress (stateChange)="onStateChange($event)" [stepsLabels]="stepsLabels">
  <app-progress-step>
    <app-upload-media
      [multiple]="false"
      [isCustomUrl]="true"
      [onlyCustomURL]="false"
      [customURL]="uploadGroup.controls['imageUrl'].value"
      (imagesUrlChanged)="imageChanged($event)"
      [mediaRequirements]="photoRequirements"
      [mediaTitle]="photoRequirementsTitle"
    ></app-upload-media>
  </app-progress-step>
  <app-progress-step [formGroup]="eventForm">
    <app-post-info [postInfoGroup]="postInfoGroup" [closeGbpUtmFormEvent]="closeGbpUtmFormEvent"></app-post-info>
  </app-progress-step>
  <app-progress-step>
    <app-gbp-date-locations-form
      [displayScheduleLaterSwitch]="true"
      [scheduleGroup]="scheduleGroup"
      [rows]="2"
      [width]="'87vw'"
    ></app-gbp-date-locations-form>
  </app-progress-step>
  <app-progress-step>
    <app-submit *ngIf="activeIndex === 3" [form]="eventForm" [preSelectedLocs]="preSelectedLocation.length"></app-submit>
  </app-progress-step>
</app-progress>

<div class="bottom-buttons">
  <button *ngIf="activeIndex == 0" pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
  <button *ngIf="activeIndex > 0" pButton label="BACK" class="p-button-outlined" progressStepPrev></button>
  <button
    pButton
    label="SAVE AND NEXT"
    class="p-button-primary"
    (click)="goNext(progress)"
    [disabled]="!eventForm.controls[formGroupName].valid || checkDateAndLocStatus || checkAddButtonStatus"
  ></button>
  <button pButton *ngIf="activeIndex === 0" (click)="goNext(progress)" label="SKIP"></button>
</div>
