export enum FILTER_TYPES {
  SINGLE = 'singleselect',
  MULTI = 'multiselect',
  DATE = 'date',
}

export interface IFilterData {
  type: FILTER_TYPES;
  title: string;
  options: string[];
}

export interface IDates {
  start: Date;
  end: Date;
}

export interface GraphCount {
  [key: string]: number
}
