import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { IPublish, IReviewData, IReviewMulti } from '../../review-management.interfaces';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-multi-reply',
  templateUrl: './multi-reply.component.html',
  styleUrls: ['./multi-reply.component.scss'],
})
export class MultiReplyComponent implements OnInit {
  @Input() selectedReviews: IReviewMulti[] = [];
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
  selectedReview: IReviewMulti;
  replyText = '';
  updatedReviwes: IReviewData[];
  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    this.selectedReview = this.selectedReviews[0];
    this.selectedReview.pos = 1;
  }

  switchCard(step: -1 | 1) {
    const idx = this.selectedReviews.indexOf(this.selectedReview);
    if (idx == 0 && step == -1) {
      this.selectedReview = this.selectedReviews[this.selectedReviews.length - 1];
      this.selectedReview.pos = this.selectedReviews.length;
    } else {
      this.selectedReview = this.selectedReviews[(idx + step) % this.selectedReviews.length];
      this.selectedReview.pos = ((idx + step) % this.selectedReviews.length) + 1;
    }
  }

  cancel() {
    this.closeDialog.emit(false);
  }

  confirmReply() {
    const type = this.selectedReviews.length == 1 ? 'single' : 'multi';
    this.updatedReviwes = this.selectedReviews.map((review) => {
      return {
        ...review,
        replyComment: this.replyText,
      };
    });
    const publishObject: IPublish<IReviewMulti[]> = {
      type,
      data: this.updatedReviwes,
    };
    this.closeDialog.emit(false);
    this.dialogService.open(ConfirmationPopupComponent, {
      data: { ...publishObject },
      closable: false,
      width: '800px',
      height: '300px',
    });
  }
}
