import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { DBService } from '../../core/backend-adapter/db.service';
import { SessionService } from '../../core/backend-adapter/session.service';
import { SocketService } from '../../core/backend-adapter/socket.service';
import { BroadcastService } from '../../core/backend-adapter/broadcast.service';
import { Observable } from 'rxjs';

declare var document: any;
declare var window: any;

@Component({
  selector: 'app-sql-reports',
  templateUrl: './sql-reports.component.html',
  styleUrls: ['./sql-reports.component.scss'],
})
export class SqlReportsComponent implements OnInit {
  tableWidth: string;
  initialHeight: string;
  tableHeight: string;
  custTblHeight: string;

  reportIdent: string;
  sqlReportItems: any;
  selectedReportItem: any;
  reportTitle: string;
  rowCount: number;
  dataRows: any;
  columnDefs: any;
  showTableContent = true;
  viewAdhocSQL = false;
  custQuery: string;
  errMsg: string;
  loading = false;
  newAdhoc = false;

  // download
  downloadFormats = [
    { value: 'csv', label: 'CSV' },
    { value: 'xls', label: 'XLS' },
    { value: 'xlsx', label: 'XLSX' },
  ];
  downloadFormat = 'csv';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sessionService: SessionService,
    public socketService: SocketService,
    public broadcastService: BroadcastService,
    public dbService: DBService
  ) {
    window.SqlReportsComp = this;
  }

  ngOnInit(): void {
    //    const queryIdent = this.actRoute.snapshot.params.id;
    this.onResize({ target: window });

    this.route.paramMap.subscribe((params: ParamMap) => this.runQuery(params.get('id')));

    this.viewAdhocSQL = false;
    this.showTableContent = true;
    this.tableHeight = this.initialHeight;

    this.dbService
      .loadAll()
      .then(() => {
        return this.dbService.SqlReports.loadObjects();
      })
      .then((coll) => {
        this.sqlReportItems = coll;
        if (this.sessionService.hasPerm('report.sql-adhoc', 'r') && this.sqlReportItems[0].ident !== 'adhoc') {
          this.sqlReportItems.unshift({ ident: 'adhoc', name: 'Ad-hoc SQL report', descr: 'Run your own SQL report.' });
        }
      });

    if (document.URL.includes('sql-report/')) {
      this.showTableContent = false;
    }

    if (document.URL.includes('adhoc')) {
      this.reportTitle = 'Ad-hoc SQL report';
      this.showTableContent = false;
      this.viewAdhocSQL = true;
      this.tableHeight = this.custTblHeight;
      this.custQuery = '';
      this.dataRows = [];
    }
  }

  toggleTextarea() {
    if (!this.viewAdhocSQL) {
      this.viewAdhocSQL = true;
      this.tableHeight = this.custTblHeight;
    } else {
      this.viewAdhocSQL = false;
      this.tableHeight = this.initialHeight;
    }
  }

  clearQuery() {
    this.custQuery = '';
    this.dataRows = [];
    this.columnDefs = [];
    this.rowCount = 0;
    this.errMsg = null;
  }

  alignStyle(code: string) {
    const label = { l: 'left', r: 'right', c: 'center' }[code];
    return label || code;
  }

  runQuery(ident: string, sql?: string): Observable<any> {
    // if (!ident)
    //   return;

    this.reportIdent = ident;

    const queryArgs: any = {};
    queryArgs.queryIdent = ident == 'adhoc' ? '' : ident;
    if (sql && this.sessionService.hasPerm('report.sql-adhoc', 'r')) {
      queryArgs.querySql = sql;
    }

    if (!queryArgs.queryIdent && !queryArgs.querySql) {
      return;
    }

    this.loading = true;
    this.errMsg = null;

    this.socketService.sendRequest('run-sql-query', queryArgs).then(
      (repl: any) => {
        this.reportTitle = repl.name + ' - ' + (repl.flags?.all_accounts ? 'All Accounts' : repl.accountName);
        this.dataRows = repl.rows;
        this.columnDefs = repl.colDefs;
        this.rowCount = repl.rowCount;
        this.custQuery = repl.sql;
        let totalWidth = 0;

        for (const c of this.columnDefs) {
          c.width = c.name.length * 10;
        }

        for (const r of this.dataRows) {
          for (const c of this.columnDefs) {
            if (!r) {
              continue;
            }

            let val;
            if (c.isArray) {
              val = r[c.index][0];
              if (val == null) {
                r[c.index][0] = val = '-NULL-';
              }
            } else {
              val = r[c.index];
              if (val == null) {
                r[c.index] = val = '-NULL-';
              }
            }

            let rWid = val.length * 10;
            if (rWid > 400) {
              rWid = 400;
            }
            if (c.width < rWid) {
              c.width = rWid;
            }
          }
        }

        for (const c of this.columnDefs) {
          totalWidth += c.width;
        }
        this.tableWidth = totalWidth + 'px';

        this.loading = false;
      },
      (err) => {
        this.loading = false;
        if (err.category == 'DB') {
          this.errMsg = err.message;
        } else {
          this.errMsg = 'Could not run report: ' + err.message;
        }
      }
    );

    return;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let wid = event.target.innerWidth;
    if (wid > 1900) {
      wid = 1900;
    }
    // this.tableWidth = (wid - 100) + 'px';

    const hgt = event.target.innerHeight;
    this.tableHeight = hgt - 240 + 'px';
    this.initialHeight = hgt - 240 + 'px';
    this.custTblHeight = hgt - 380 + 'px';
  }
}
