import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DBService } from '../../../backend-adapter/db.service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { StyleChangerService } from '@app/core/feature-modules/whitelabel/style-changer/style-changer.service';
import { RsThemes } from '@app/core/feature-modules/whitelabel/style-changer/style-changer.interfaces';
import { themeConfig } from '@app/core/feature-modules/whitelabel/style-changer/theme-config';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarMenuComponent implements OnInit {
  @Input() mainAccount: any;
  @Input() account: IAccount;
  @Input() collapsed: boolean;

  @Output() refreshBreadcrumb = new EventEmitter<void>();
  @Output() changeCollapsed = new EventEmitter<boolean>();

  // Sidebar menu vars
  selectedItem: any;

  toggleHover = false;
  menuItemsLeft: MenuItem[];
  reportMenuItems: MenuItem[];
  sqlReports: MenuItem[];
  sqlReportItems: any[];
  acctReports: MenuItem[] = [];
  allAcctReports: MenuItem[] = [];

  public toggleAccountSelector: boolean;
  public isWhitelabeled = true;
  private squareLogo: string;
  public activeLogo: string;
  private checkIfWhiteLabelled: string[] = [
    // GPO
    "gpo",
    "gpo-cortevents",
    "gpo-cortfurnoutlet",
    "gpo-cortfurnrental",
    "gpo-firestone",
    "gpo-firestonedirect",
    "gpo-hibdontiresplus",
    "gpo-paintingwithatwist",
    "gpo-rentacentermexico",
    "gpo-tiresplus",
    "gpo-wheelworks",

    // Digital Kairoi
    "digitalkairoi",
    "digitalkairoi-athletesfoot",

    "advantixdigital",
    "advantixdigital-smb",

    "bucketmedia",
    "bucketmedia-boonehealth",

    "adplorer",
    "adplorer-showhomes",
    "adplorer-vitalitybowls",

    "truemedia",
    "truemedia-capitalregion",
    "truemedia-capitalregionprof",
    "truemedia-capitalregionprov",
    "truemedia-centralbank",
    "truemedia-centralinvestadv",
    "truemedia-centraltrust",
    "truemedia-coegi",
    "truemedia-missourifarmbureau",
    "truemedia-radaranalytics",
    "truemedia-runnings",
    "truemedia-tmoffices",

    "ccm",
    "ccm-sab",
    "ccm-smb",

    "wehco",
    "wehco-AdvancedDentalCenter",

    "welliq",
    "welliq-welliq",

    "travelmedia",

  ];

  abc: string[] = [];

  constructor(
    public sessionService: SessionService,
    private dbService: DBService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private styleChangerService: StyleChangerService
  ) {}

  ngOnInit(): void {
    this.collapsed = true;
    this.setActiveMenuOnNavigation();
    this.getActiveLogo();

    this.styleChangerService.getCurrentTheme().subscribe((theme: RsThemes) => {
      this.isWhitelabeled = theme == RsThemes.whitelabeled;
      this.squareLogo = themeConfig[theme].logos.square;
      this.getActiveLogo();
    });

    // if(this.account.ident)

  }

  navigateAndTrigger(event) {
    this.menuClicked(event);
    // send signal for popup/button press
  }

  onToggleHover() {
    this.toggleHover = true;
  }

  onToggleLeave() {
    this.toggleHover = false;
  }

  setActiveMenuOnNavigation() {
    this.initMenus();
    this.refreshBreadcrumb.emit();

    // TODO: delete after tesing. no accouns in menu already
    // if (document.URL.includes('accounts')) {
    //   this.menuItemsLeft[0].styleClass = 'active-menu';
    //   return;
    // }
    if (document.URL.includes('locations')) {
      if (this.collapsed) {
        this.menuItemsLeft[1].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[1].expanded = true;
        this.menuItemsLeft[1].items[0].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('gbp-mgmt')) {
      if (this.collapsed) {
        this.menuItemsLeft[1].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[1].expanded = true;
        this.menuItemsLeft[1].items[1].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('location-change-log')) {
      if (this.collapsed) {
        this.menuItemsLeft[1].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[1].expanded = true;
        this.menuItemsLeft[1].items[2].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('utm-mgmt')) {
      if (this.collapsed) {
        this.menuItemsLeft[1].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[1].expanded = true;
        this.menuItemsLeft[1].items[3].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('review-mgmt')) {
      this.menuItemsLeft[2].styleClass = 'active-menu';
      return;
    }
    if (document.URL.includes('social-mgmt')) {
      this.menuItemsLeft[3].styleClass = 'active-menu';
      return;
    }
    if (document.URL.includes('local-ads')) {
      this.menuItemsLeft[4].styleClass = 'active-menu';
      return;
    }
    if (document.URL.includes('reports')) {
      if (this.collapsed) {
        this.menuItemsLeft[5].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[5].expanded = true;
        this.menuItemsLeft[5].items[0].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('sql-report')) {
      if (this.collapsed) {
        this.menuItemsLeft[5].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[5].expanded = true;
        this.menuItemsLeft[5].items[1].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('account-settings')) {
      this.menuItemsLeft[6].styleClass = 'active-menu';
      return;
    }
    if (document.URL.includes('finance')) {
      this.menuItemsLeft[7].styleClass = 'active-menu';
      return;
    }
    if (document.URL.includes('logins')) {
      if (this.collapsed) {
        this.menuItemsLeft[8].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[8].expanded = true;
        this.menuItemsLeft[8].items[0].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('permissions')) {
      if (this.collapsed) {
        this.menuItemsLeft[8].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[8].expanded = true;
        this.menuItemsLeft[8].items[1].styleClass = 'active-menu';
      }
      return;
    }
    if (document.URL.includes('parties')) {
      if (this.collapsed) {
        this.menuItemsLeft[8].styleClass = 'active-menu';
      } else {
        this.menuItemsLeft[8].expanded = true;
        this.menuItemsLeft[8].items[2].styleClass = 'active-menu';
      }
      return;
    }
    this.cdr.markForCheck();
  }

  menuClicked(event) {
    console.log('event :', event);

    // when clicking on a collapsed menu item, expand menu
    if (this.collapsed) {
      this.collapsed = false;
      this.initMenus();
    }

    this.setActiveMenu(event.item);

    setTimeout(() => {
      this.refreshBreadcrumb.emit();
    }, 0);
  }

  toggleSideMenu(item) {
    this.collapsed = !this.collapsed;
    this.initMenus();
    this.setActiveMenuOnNavigation();
  }

  setActiveMenu(clickedItem) {
    console.log('clickedItem:', clickedItem);

    // reset pre-existing active menu classes
    for (const item of this.menuItemsLeft) {
      if (item.styleClass == 'active-menu') {
        item.styleClass = '';
      }

      if (item.items) {
        item.items.forEach((elem) => {
          if (elem.styleClass) {
            elem.styleClass = '';
          }
        });
      }
    }

    // clicked item no label means collapsed menu was clicked
    if (!clickedItem.label) {
      if (
        [
          'accounts-icon',
          'reviews-icon',
          'social-icon',
          'ads-icon',
          'settings-icon',
          'finance-icon',
          'new-acct-icon',
          'gen-rept-icon',
        ].includes(clickedItem.icon)
      ) {
        this.menuItemsLeft.find((item) => item.icon == clickedItem.icon).styleClass = 'active-menu';
        return;
      }

      // collapsed menu has no subitems
      // expand menu and activate first subitem of clicked menu
      if (clickedItem.icon == 'locations-icon') {
        this.menuItemsLeft[1].expanded = true;
        if (document.URL.includes('locations')) {
          this.menuItemsLeft[1].expanded = true;
          this.menuItemsLeft[1].items[0].styleClass = 'active-menu';
          return;
        }
        if (document.URL.includes('gbp-mgmt')) {
          this.menuItemsLeft[1].expanded = true;
          this.menuItemsLeft[1].items[1].styleClass = 'active-menu';
          return;
        }
        if (document.URL.includes('location-change-log')) {
          this.menuItemsLeft[1].expanded = true;
          this.menuItemsLeft[1].items[2].styleClass = 'active-menu';
          return;
        }
        if (document.URL.includes('utm-mgmt')) {
          this.menuItemsLeft[1].expanded = true;
          this.menuItemsLeft[1].items[3].styleClass = 'active-menu';
          return;
        }
      }

      if (clickedItem.icon == 'reporting-icon') {
        this.menuItemsLeft[5].expanded = true;
        if (document.URL.includes('reports')) {
          this.menuItemsLeft[5].expanded = true;
          this.menuItemsLeft[5].items[0].styleClass = 'active-menu';
          return;
        }
        if (document.URL.includes('sql-report')) {
          this.menuItemsLeft[5].expanded = true;
          this.menuItemsLeft[5].items[1].styleClass = 'active-menu';
          return;
        }
      }

      if (clickedItem.icon == 'admin-icon') {
        this.menuItemsLeft[8].expanded = true;
        if (document.URL.includes('logins')) {
          this.menuItemsLeft[8].expanded = true;
          this.menuItemsLeft[8].items[0].styleClass = 'active-menu';
          return;
        }
        if (document.URL.includes('permissions')) {
          this.menuItemsLeft[8].expanded = true;
          this.menuItemsLeft[8].items[1].styleClass = 'active-menu';
          return;
        }
        if (document.URL.includes('parties')) {
          this.menuItemsLeft[8].expanded = true;
          this.menuItemsLeft[8].items[2].styleClass = 'active-menu';
          return;
        }
      }
    } else {
      // expanded menu was clicked
      clickedItem.styleClass = 'active-menu';
      if (clickedItem.routerLink?.includes('/sql-report')) {
        this.menuItemsLeft[5].expanded = true;
      }
    }
  }

  initMenus() {
    if (this.collapsed) {
      this.menuItemsLeft = [
        {
          icon: 'accounts-icon',
          id: '0',
          routerLink: ['/dashboard'],
          visible: this.sessionService.hasPerm('dashboard', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          icon: 'locations-icon',
          id: '1',
          // routerLink: ['/locations'],
          visible: this.sessionService.hasPerm('location', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          icon: 'reviews-icon',
          id: '2',
          routerLink: ['/review-mgmt'],
          visible: this.sessionService.hasPerm('review', 'r') && !this.sessionService.hasPerm('reputation', 'S'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          icon: 'social-icon',
          id: '3',
          routerLink: ['/social-mgmt'],
          visible: this.sessionService.hasPerm('social', 'S') || this.sessionService.hasPerm('reputation', 'S'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        // {
        //   icon: 'ads-icon',
        //   id: '4',
        //   routerLink: ['/local-ads'],
        //   disabled: true,
        //   visible: this.sessionService.hasPerm('location', 'r'),
        //   command: (event) => {
        //     this.menuClicked(event);
        //   },
        // },
        {
          icon: 'reporting-icon',
          id: '5',
          routerLink: ['/reports'],
          visible: this.sessionService.hasPerm('report', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          icon: 'settings-icon',
          id: '6',
          routerLink: ['/account-settings'],
          disabled: true,
          visible: this.sessionService.hasPerm('location', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          icon: 'finance-icon',
          id: '7',
          routerLink: ['/finance'],
          disabled: true,
          visible: this.sessionService.hasPerm('location', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          icon: 'admin-icon',
          id: '8',
          visible: this.sessionService.hasPerm('report', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        { separator: true, disabled: true, styleClass: 'divider' },

        // {
        //   icon: 'new-acct-icon',
        //   id: '9',
        //   routerLink: ['/accounts'],
        //   disabled: true,
        //   visible: this.sessionService.hasPerm('location', 'r'),
        //   command: (event) => {
        //     this.menuClicked(event);
        //   },
        // },
        {
          icon: 'gen-rept-icon',
          id: '10',
          routerLink: ['/reports'],
          disabled: true,
          visible: this.sessionService.hasPerm('location', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
      ];
    } else {
      this.menuItemsLeft = [
        {
          label: 'Dashboard',
          icon: 'accounts-icon',
          id: '0',
          routerLink: ['/dashboard'],
          visible: this.sessionService.hasPerm('dashboard', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Listings Management',
          icon: 'locations-icon',
          items: [
            {
              label: 'Locations',
              routerLink: ['/locations'],
              visible: this.sessionService.hasPerm('location', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'Google Management',
              routerLink: ['/gbp-mgmt'],
              visible:
                this.sessionService.hasPerm('photo', 'r') ||
                this.sessionService.hasPerm('post', 'r') ||
                this.sessionService.hasPerm('qanda', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'Location Change Log',
              routerLink: ['/location-change-log'],
              visible: this.sessionService.hasPerm('location', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'UTM Codes',
              routerLink: ['/utm-mgmt'],
              visible: this.sessionService.hasPerm('location', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
          ],
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Review Management',
          icon: 'reviews-icon',
          items : [
            {
              label: 'Reviews',
              routerLink: ['/review-mgmt'],
              visible: this.sessionService.hasPerm('review', 'r') && !this.sessionService.hasPerm('reputation', 'S'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'Notifications',
              routerLink: ['/manage-notifications'],
              visible: !this.checkIfWhiteLabelled.includes(this.account.ident),
              command: (event) => {
                this.menuClicked(event);
              },
            }
          ],
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: this.sessionService.hasPerm('social', 'S')
            ? 'Social Media Management'
            : this.sessionService.hasPerm('reputation', 'S')
            ? 'Reputation Management'
            : 'Social/Reputation Management',
          icon: 'social-icon',
          routerLink: ['/social-mgmt'],
          visible: this.sessionService.hasPerm('social', 'S') || this.sessionService.hasPerm('reputation', 'S'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Local Ads',
          icon: 'ads-icon',
          routerLink: ['/local-ads'],
          visible: true,
          disabled: true,
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Reporting',
          icon: 'reporting-icon',
          items: (this.reportMenuItems = [
            {
              label: 'Reports',
              routerLink: ['/reports'],
              visible: this.sessionService.hasPerm('report', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'SQL Reports',
              routerLink: ['/sql-report'],
              visible: this.sessionService.hasPerm('report.sql.*', 'r'),
              items: (this.sqlReports = [
                { label: 'Account specific', items: (this.acctReports = []) },
                { label: 'All accounts', items: (this.allAcctReports = []) },
              ]),
              command: (event) => {
                this.menuClicked(event);
              },
            },
          ]),
          visible: this.sessionService.hasPerm('report', 'r'),
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Account Settings',
          icon: 'settings-icon',
          routerLink: ['/account-settings'],
          visible: true,
          disabled: true,
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Finance',
          icon: 'finance-icon',
          routerLink: ['/finance'],
          visible: true,
          disabled: true,
          command: (event) => {
            this.menuClicked(event);
          },
        },
        {
          label: 'Admin',
          visible: this.sessionService.hasPerm('admin', 'r'),
          icon: 'admin-icon',
          items: [
            {
              label: 'Logins',
              routerLink: ['/logins'],
              disabled: !this.sessionService.hasPerm('admin.logins', 'r'),
              visible: this.sessionService.hasPerm('admin.logins', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'Permissions',
              routerLink: ['/permissions'],
              disabled: !this.sessionService.hasPerm('admin.perms', 'r'),
              visible: this.sessionService.hasPerm('admin.perms', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
            {
              label: 'Parties',
              routerLink: ['/parties'],
              disabled: !this.account?.properties.hasParties,
              visible: this.sessionService.hasPerm('admin.party', 'r'),
              command: (event) => {
                this.menuClicked(event);
              },
            },
          ],
          command: (event) => {
            this.menuClicked(event);
          },
        },

        { label: 'SHORTCUTS', icon: 'shortcut-icon', disabled: true, styleClass: 'divider-text' },

        // {
        //   label: 'Create new account',
        //   icon: 'new-acct-icon',
        //   routerLink: ['/accounts'],
        //   visible: true,
        //   disabled: true,
        //   command: (event) => {
        //     this.navigateAndTrigger(event);
        //   },
        // },
        {
          label: 'Generate Report',
          icon: 'gen-rept-icon',
          visible: true,
          disabled: true,
          command: (event) => {
            this.navigateAndTrigger(event);
          },
        },
        // { label: 'Dashboard', routerLink: ['/dashboard'], badge: '4', visible: this.sessionService.hasPerm('dashboard', 'r') },
      ];
    }

    this.dbService.SqlReports.loadObjects().then((reports) => {
      for (const rpt of reports) {
        // this.sqlReports.push({ label: rpt.name, routerLink: ['/sql-report/' + rpt.ident], visible: this.sessionService.hasPerm('report.sql.*', 'r') });
        if (rpt.flags?.all_accounts) {
          this.allAcctReports.push({
            label: rpt.name,
            routerLink: ['/sql-report/' + rpt.ident],
            visible: this.sessionService.hasPerm('report.sql.*', 'r'),
          });
        } else {
          this.acctReports.push({
            label: rpt.name,
            routerLink: ['/sql-report/' + rpt.ident],
            visible: this.sessionService.hasPerm('report.sql.*', 'r'),
          });
        }
      }
      this.sqlReportItems = this.allAcctReports.concat(this.acctReports);
    });
  }

  getActiveLogo() {
    if (this.sessionService.loginObj) {
      if (this.isWhitelabeled) {
        this.activeLogo = this.squareLogo;
      } else if (
        this.sessionService.permissions._roles.includes('cust-admin') ||
        this.sessionService.permissions._roles.includes('cust-ro-admin')
      ) {
        this.mainAccount = this.dbService.Account.findByIdent(this.sessionService.currAccount).name;
        let account = this.sessionService.currAccount;
        if (account.includes('-')) {
          account = account.split('-')[0];
        }
        this.activeLogo = `../assets/logo/${account}.png`;
      } else {
        this.mainAccount = 'RenderSEO';
        this.activeLogo = '../assets/renderseo.png';
      }
    } else {
      this.activeLogo = '../assets/blank.png';
    }
  }
}
