import { Component, OnInit, OnDestroy } from '@angular/core';
import { NoticebarService } from './noticebar.service';
import { BehaviorSubject, from, Observable, of, Subscription, timer } from 'rxjs';
import { delay, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'app-noticebar',
  templateUrl: './noticebar.component.html',
  styleUrls: ['./noticebar.component.scss'],
})
export class NoticebarComponent implements OnInit, OnDestroy {
  public text: string;
  private subs = new Subscription();
  show$$ = new BehaviorSubject<boolean>(false);

  constructor(private noticebarService: NoticebarService) {}

  ngOnInit(): void {
    this.subs.add(
      this.noticebarService.message$().subscribe(({ text, delay }) => {
        this.text = text;

        this.showMessage(delay);
      })
    );
  }

  showMessage(delay): void {
    // this.subs.add(
    timer(0, delay)
      .pipe(take(2))
      .subscribe((i) => {
        i === 0 ? this.show$$.next(true) : this.show$$.next(false);
      });
    // );
  }

  handleClose() {
    this.show$$.next(false);
  }

  ngOnDestroy() {
    this.show$$.complete();
    this.subs.unsubscribe();
  }
}
