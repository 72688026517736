import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';

export enum STORAGE_KEYS {
  recentAccounts = 'recentAccounts',
  wasRecentlyLogged = 'wasRecentlyLogged',
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  hasLS: boolean;

  constructor(private cookieService: CookieService) {
    this.checkLocalStorage();
  }

  public checkLocalStorage(): boolean {
    this.hasLS = false;
    if (localStorage) {
      try {
        const x = 'storageTest';
        localStorage.setItem(x, x);
        localStorage.removeItem(x);
        this.hasLS = true;
        return this.hasLS;
      } catch (e) {
        this.hasLS = false;
        console.warn('not found LS');
        return this.hasLS;
      }
    } else {
      return this.hasLS;
    }
  }

  public set(itemKeyName: string, value: any): void {
    if (value != null) {
      this.lsSet(itemKeyName, value);
    }
  }

  public get(itemKeyName: string): any | null | {} | any[] {
    const item = this.lsGet(itemKeyName);
    return item != null && item !== 'undefined' ? this.getParsedValue(item) : null;
  }

  public remove(itemKeyName: string): boolean {
    try {
      this.lsRemove(itemKeyName);
      // return this.lsGet(itemKeyName) ? false : true; fires earlier
      return true;
    } catch (e) {
      return false;
    }
  }

  private lsRemove(itemKeyName: string): void {
    if (this.hasLS) {
      localStorage.removeItem(itemKeyName) as void;
    } else {
      this.cookieService.delete(itemKeyName) as void;
    }
  }

  private lsGet(itemKeyName: string): string | null {
    if (this.hasLS) {
      return localStorage.getItem(itemKeyName);
    } else {
      const cookieValue = this.cookieService.get(itemKeyName) as string;
      return cookieValue;
    }
  }

  private stringify(value: any | object): string {
    if (typeof value === 'string') {
      return value;
    } else {
      return JSON.stringify(value);
    }
  }

  private lsSet(itemKeyName: string, value: any | object): void {
    if (this.hasLS) {
      localStorage.setItem(itemKeyName, this.stringify(value)) as void;
    } else {
      this.cookieService.set(
        itemKeyName,
        this.stringify(value),
        365
        // {
        //   expires: 365,
        //   // secure: true, // dont saves cookies somewhy
        //   // domain: location.host, // not work on localhost:4200 and not set cookies
        //   // path: ,
        //   sameSite: 'Lax',
        // }
      ) as void;
    }
  }
  //////////////////////

  private getParsedValue(item: string): any {
    // can be "asdasd", or '"asdasdasd"' or "{object}"
    try {
      // INFO: "error identifier position 0" tells that it is string tried to parse. JSON.parse("asd67876asd") cause this error
      const obj = JSON.parse(item);
      return obj;
    } catch (e) {
      return item;
    }
  }
}
