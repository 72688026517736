import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '../primeng.module';
import { TableFilterComponent } from './table-filter.component';
import { TableFilterService } from './table-filter.service';

@NgModule({
  imports: [PrimengModule, FormsModule],
  declarations: [TableFilterComponent],
  exports: [TableFilterComponent],
  providers: [TableFilterService],
})
export class TableFilterModule {}
