import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from './core/backend-adapter/session.service';
import {DBService} from '@app/core/backend-adapter/db.service';

export function authLoaderFactory(v: AuthLoaderService) {
  return (): Promise<any> => {
    return v.loaderTryRefreshAuth();
  };
}

@Injectable({providedIn: 'root'})
export class AuthLoaderService {
  constructor(private sessionService: SessionService,
              private dbService: DBService,
              private router: Router) {
  }

  loaderTryRefreshAuth() {
    return new Promise<void>((resolve, reject) => {
      try {
        this.dbService.loadAll();
      } catch (e) {
        this.router.navigate(['/login']);
      }

      setTimeout(() => {
        console.log('AuthLoaderService Finished');
        this.sessionService.setSelectedAccount(this.dbService.Account.findByIdent(this.sessionService.currAccount));
        resolve();
      }, 2500);
    });
  }
}
