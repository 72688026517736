import { Component } from '@angular/core';

@Component({
  selector: 'rs-input-btn-delete',
  template: `<button pButton icon="pi pi-times" class="input-remove p-button-text p-button-info"></button> `,
  styles: [
    `
      :host {
        position: absolute;
        right: -23px;
        top: 8px;
      }
      ::ng-deep button.input-remove {
        height: 20px !important;
        width: 20px !important;
        position: relative !important;
        right: 35px !important;
        background: #fb2424 !important;
        color: white !important;
      }
      ::ng-deep button.input-remove .p-button-icon {
        margin-top: 1px !important;
        font-weight: bold !important;
      }
    `,
  ],
})
export class RsInputBtnDeleteComponent {}
