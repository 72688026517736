import { Injectable } from "@angular/core";
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';

@Injectable({
  providedIn: 'root'
})

export class GBPFiltersDateService {

  constructor () {}

  private firstOfThisMonth(): string {
    let date = RZLib.Date.newWithToday();
    date.day = 1;
    return date.toString();
  }

  private firstOfLastMonth(): string {
    let date = RZLib.Date.newWithToday();
    date.day = 1;
    --date.month;
    return date.toString();
  }

  private firstOfNextMonth(): string {
    let date = RZLib.Date.newWithToday();
    date.day = 1;
    ++date.month;
    return date.toString();
  }

  public inThisMonth(name: string): string {
    return `${name} >= '${this.firstOfThisMonth()}' AND ${name} < '${this.firstOfNextMonth()}'`;
  }

  public inLastMonth(name: string): string {
    return `${name} >= '${this.firstOfLastMonth()}' AND ${name} < '${this.firstOfThisMonth()}'`;
  }

}
