import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NotifyService } from './notify.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { NotifyComponent } from './notify.component';

@NgModule({
  imports: [CommonModule, ToastModule],
  declarations: [NotifyComponent],
  exports: [ToastModule, NotifyComponent],
})
export class NotifyModule {
  static forRoot(): ModuleWithProviders<NotifyModule> {
    return {
      ngModule: NotifyModule,
      providers: [NotifyService, MessageService],
    };
  }
}
