import { _PALETTE } from './colors.palette';

export const SYSTEM_COLORS = {
  $systemGreen: _PALETTE.$mainGreen.hex,
  $systemYellow: _PALETTE.$mainOrange.hex,
  $systemRed: _PALETTE.$red.hex,
  $systemLightGreen: _PALETTE.$lightGreen.hex,
};

export const TWO_YELLOW_CHART_PALETTE = [SYSTEM_COLORS.$systemRed, SYSTEM_COLORS.$systemGreen];
export const TWO_RED_CHART_PALETTE = [SYSTEM_COLORS.$systemRed, SYSTEM_COLORS.$systemGreen];
export const THREE_CHART_PALETTE = [SYSTEM_COLORS.$systemRed, SYSTEM_COLORS.$systemYellow, SYSTEM_COLORS.$systemGreen];
export const THREE_CHART_PALETTE_REVERSE = [
  SYSTEM_COLORS.$systemGreen,
  SYSTEM_COLORS.$systemYellow,
  SYSTEM_COLORS.$systemRed,
];
