import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import RZLib from 'src/app/core/backend-adapter/db2/RZ-lib.helpers';
import { ProgressComponent } from 'src/app/core/feature-modules/progress/progress.component';
import { GBPService } from 'src/app/gbp-management/gbp.service';
import { GBPPhotoConfigService } from 'src/app/gbp-management/services/gbp-photo-config-service/gbp-photo-config-service';
import { SuccessComponent } from '../success/success.component';
import { SocketService } from '../../../../../../app/core/backend-adapter/socket.service';

export interface ITempObj {
  account_id: number;
  summary: string;
  languageCode: string;
  topicType: string;
  alertType: string;
  sourceURL: string;
  location_ids: number[] | string;
  scheduleTime: any;
  scheduleTimeZone: string;
  ctaURL: string;
  _status: string;
  ctaActionType?: string;
  _id?: number;
}

@Component({
  selector: 'app-covid19-update-post',
  templateUrl: './covid19-update-post.component.html',
  styleUrls: ['./covid19-update-post.component.scss'],
})
export class Covid19UpdatePostComponent implements OnInit {
  @Input() isStandard = false;
  @Input() rowData;

  stepsLabels = ['Details', 'Schedule', 'Review and Submit'];
  covid19UpdateForm = new FormGroup({
    upload: new FormGroup({
      imageUrl: new FormControl(''),
    }),
    postDetails: new FormGroup({
      postDescription: new FormControl('', Validators.required),
      addButton: new FormControl(''),
      ctaURL: new FormControl(
        '',
        Validators.pattern(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]{}@!\$&'\(\)\*\+,;=]*(\{\{[A-Za-z0-9.]+\}\}|[\w\-\._~:/?#[\]{}@!\$&'\(\)\*\+,;=])*\/?$/
        )
      ),
      languageCode: new FormControl('', Validators.required),
    }),
    schedule: new FormGroup({
      checked: new FormControl(),
      date: new FormControl(''),
      time: new FormControl(''),
      timeZone: new FormControl(''),
      location: new FormControl([]),
      isSelectedAll: new FormControl(''),
    }),
  });
  postDetailsGroup: FormGroup;
  scheduleGroup: FormGroup;
  uploadGroup: FormGroup;
  formGroupName = 'upload';
  activeIndex = 0;
  saveObj;
  preSelectedLocation: any[] = [];
  /**
   * @description To display validations rules to upload an Image.
   */
  public photoRequirements: string[] = [];
  /**
   * @description Validation rules Title.
   */
  public photoRequirementsTitle: string;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private gbpService: GBPService,
    private gbpPhotoConfigService: GBPPhotoConfigService,
    private socketService: SocketService,
  ) {}

  ngOnInit(): void {
    this.postDetailsGroup = this.covid19UpdateForm.controls.postDetails as FormGroup;
    this.scheduleGroup = this.covid19UpdateForm.controls.schedule as FormGroup;
    this.uploadGroup = this.covid19UpdateForm.controls.upload as FormGroup;
    this.setPhotoValidationRules();

    if (this.isStandard) {
      this.stepsLabels = ['Add Photo', ...this.stepsLabels];
    }

    if (this.rowData) {
      this.setInitialFormValues();
    }
  }

  /**
   * @description Method set's validation rules on Add Photo screen for Media type PHOTO.
   * @returns void
   */
  private setPhotoValidationRules(): void {
    this.photoRequirements = this.gbpPhotoConfigService.setGBPMediaRequirements('PHOTO').description;
    this.photoRequirementsTitle = this.gbpPhotoConfigService.setGBPMediaRequirements('PHOTO').title;
  }

  private setInitialFormValues() {
    this.uploadGroup.setValue({
      imageUrl: this.rowData?.internalURL || this.rowData?.sourceURL,
    });

    this.postDetailsGroup.patchValue({
      postDescription: this.rowData?.summary,
      addButton: this.rowData?.ctaActionType || '',
      ctaURL: this.rowData?.ctaURL,
      languageCode: this.gbpService.preparePostLanguage(this.rowData?.languageCode),
    });
    const scheduledTime = this.rowData.scheduleTime !== null ?
      (new Date(new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), this.rowData.scheduleTime?._hour_, this.rowData.scheduleTime?._minute_, this.rowData.scheduleTime?._second_)) : '';
    const scheduledDate = this.rowData.scheduleTime !== null ?
      (new Date(`${this.rowData.scheduleTime?._month_}/${this.rowData.scheduleTime?._day_}/${this.rowData.scheduleTime?._year_}`)) : '';
    this.scheduleGroup.patchValue({
      date: scheduledDate,
      time: scheduledTime,
      timeZone: this.rowData?.scheduleTimeZone,
    });

    // Add a new control if Post status === A (Active).
    if(this.rowData._status === 'A') {
      this.scheduleGroup.addControl('postStatus', new FormControl(true));
    }


    this.socketService.sendRequest('get-localpost-detail-coll', { 'detailLevel': 3, 'where': ['-and', `post_id=${this.rowData._id}`] })
      .then(res => {
        res['collection'].forEach(l => {
          this.preSelectedLocation.push({
            _id: l.location_id,
            storeCode: l.storeCode,
            businessName: l.businessName,
            city: l.city,
            state: l.state,
            disabled: true
          });
        })
        this.scheduleGroup.addControl('preSelectedLocation', new FormControl(
          [...this.preSelectedLocation]
        ));
      })

  }

  private prepareAlertSaveObject(postDetailsGroup, scheduleGroup, uploadGroup) {
    const tempObj: ITempObj = {
      account_id: 0,
      summary: '',
      languageCode: '',
      topicType: '',
      alertType: '',
      sourceURL: '',
      location_ids: [],
      scheduleTime: new RZLib.Timestamp().setNow(),
      scheduleTimeZone: '',
      ctaURL: '',
      _status: '',
    };
    tempObj.account_id = this.gbpService.account_id;
    tempObj.summary = postDetailsGroup.postDescription;
    tempObj.languageCode = postDetailsGroup.languageCode;
    tempObj.topicType = 'ALERT';
    tempObj.alertType = 'COVID_19';
    const urlFieldName = new RegExp(/^https:\/\/rseo-platform(-dev)?.s3.amazonaws.com\/media\/.+/i).test(
      uploadGroup.imageUrl
    )
      ? 'internalURL'
      : 'sourceURL';
    tempObj[urlFieldName] = uploadGroup.imageUrl;
    tempObj.location_ids = scheduleGroup.isSelectedAll
      ? '*'
      : scheduleGroup.location?.length > 0
      ? this.gbpService.mapAllLocations(scheduleGroup.location, this.preSelectedLocation)
      : null;
    tempObj.scheduleTime = scheduleGroup.checked ? this.gbpService.prepareDate(scheduleGroup.date, tempObj.scheduleTime, scheduleGroup.time) : "";
    tempObj.scheduleTimeZone = scheduleGroup.checked ? scheduleGroup.timeZone : null;
    tempObj.ctaURL = postDetailsGroup.ctaURL || '';
    tempObj._status = 'AP';

    // Edit mode
    if (this.rowData) {
      tempObj._id = this.rowData._id;
    }

    return tempObj;
  }

  private prepareStandartSaveObject(postDetailsGroup, scheduleGroup, uploadGroup) {
    const tempObj: ITempObj = {
      account_id: 0,
      summary: '',
      languageCode: '',
      topicType: '',
      alertType: '',
      sourceURL: '',
      location_ids: [],
      scheduleTime: new RZLib.Timestamp().setNow(),
      scheduleTimeZone: '',
      ctaActionType: '',
      ctaURL: '',
      _status: '',
    };
    tempObj.account_id = this.gbpService.account_id;
    tempObj.summary = postDetailsGroup.postDescription;
    tempObj.languageCode = postDetailsGroup.languageCode;
    tempObj.topicType = 'STANDARD';
    const urlFieldName = new RegExp(/^https:\/\/rseo-platform(-dev)?.s3.amazonaws.com\/media\/.+/i).test(
      uploadGroup.imageUrl
    )
      ? 'internalURL'
      : 'sourceURL';
    tempObj[urlFieldName] = uploadGroup.imageUrl;
    tempObj.location_ids = scheduleGroup.isSelectedAll
      ? '*'
      : scheduleGroup.location?.length > 0
      ? this.gbpService.mapAllLocations(scheduleGroup.location, this.preSelectedLocation)
      : null;
    tempObj.scheduleTime = scheduleGroup.checked ? this.gbpService.prepareDate(scheduleGroup.date, tempObj.scheduleTime, scheduleGroup.time) : "";
    tempObj.scheduleTimeZone = scheduleGroup.checked ? scheduleGroup.timeZone : null;
    tempObj.ctaActionType = postDetailsGroup.addButton.toUpperCase();
    tempObj.ctaURL = postDetailsGroup.ctaURL || '';
    tempObj._status = 'AP';

    // Edit mode
    if (this.rowData) {
      tempObj._id = this.rowData._id;
    }

    return tempObj;
  }

  public goNext(progress: ProgressComponent): void {
    progress.next();
  }

  onStateChange(event) {
    if (event.status == 'completed') {
      // send data to backend

      if (this.isStandard) {
        this.saveObj = this.prepareStandartSaveObject(
          this.postDetailsGroup.value,
          this.scheduleGroup.value,
          this.uploadGroup.value
        );
      } else {
        this.saveObj = this.prepareAlertSaveObject(
          this.postDetailsGroup.value,
          this.scheduleGroup.value,
          this.uploadGroup.value
        );
      }

      this.gbpService.updateLocalPost(this.saveObj);
      this.ref.close();
      this.dialogService.open(SuccessComponent, {
        width: '20%',
      });
    }
    this.activeIndex = event.activeIndex;
    switch (this.activeIndex) {
      case 0: {
        this.formGroupName = 'upload';
        break;
      }
      case 1: {
        this.formGroupName = 'postDetails';
        break;
      }
      case 2: {
        this.formGroupName = 'schedule';
        break;
      }
    }
  }

  imageChanged(url) {
    this.covid19UpdateForm.patchValue({ upload: { imageUrl: url[0] } });
  }

  closeDialog() {
    this.ref.close();
  }

  public get checkAddButtonStatus(): boolean {
    let status: boolean = false;
    if (this.postDetailsGroup.controls['addButton'].value && this.postDetailsGroup.controls['addButton'].value !== 'CALL') {
      if (!this.postDetailsGroup.controls['ctaURL'].value) {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  }

  public get checkDateAndLocStatus(): boolean {
    const isChecked = this.scheduleGroup.controls;
    let dateSelection: boolean;
    if (this.formGroupName === 'schedule') {
      if (!isChecked['checked'].value) {
        return (dateSelection =
          !isChecked['checked'].value && (isChecked['location'].value.length || isChecked['isSelectedAll'].value || isChecked['preSelectedLocation']?.value?.length)
            ? false
            : true);
      } else {
        return (dateSelection =
          isChecked['date'].value &&
          isChecked['time'].value &&
          isChecked['timeZone'].value &&
          (isChecked['location'].value.length || isChecked['isSelectedAll'].value || isChecked['preSelectedLocation']?.value?.length)
            ? false
            : true);
      }
    } else {
      false;
    }
  }

}
