// tslint:disable: curly
import { Component, DoCheck, ViewChild } from '@angular/core';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'app-notify',
  template: `<p-toast position="bottom-center" key="bc" appendTo="body"></p-toast>`,
  styles: [``],
})
export class NotifyComponent implements DoCheck {
  @ViewChild(Toast) toast: Toast;

  constructor() {}

  ngDoCheck() {
    // if (!(this.toast && this.toast.containerViewChild)) return;
    // const requiredZIndex = '100000'; // `${DomHandler.zindex}`;
    // const el = this.toast.containerViewChild.nativeElement as HTMLElement;
    // if (el.style.zIndex !== requiredZIndex) el.style.zIndex = requiredZIndex;
  }
}
