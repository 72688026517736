<p-table
  #locationList
  [value]="tableData"
  [lazy]="true"
  responsiveLayout="scroll"
  [globalFilterFields]="['_id', 'businessName', 'storeCode', 'postalCode', 'city', 'state']"
  (onLazyLoad)="lazyLoadLocations($event)"
>
  <ng-template pTemplate="caption">
    <div class="flex-search-bar">
      <div class="search-bar-buttons">
        <div class="acct-search">
          <span class="p-input-icon-right">
            <i class="pi pi-search"></i>
            <span class="p-inputgroup">
              <input
                class="input"
                pInputText
                type="text"
                placeholder="Search by Store code or Business name"
                (input)="locationList.filterGlobal($event.target.value, 'contains')"
                [(ngModel)]="keyword"
              />
            </span>
          </span>
        </div>
        <button
          *ngIf="addLocation"
          pButton
          label="ADD LOCATION"
          class="p-button-primary button-div-flex"
          iconPos="left"
          icon="pi pi-plus-circle"
          (click)="openAddLocation()"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Store Code</th>
      <th>Business Name</th>
      <th>Date and Time</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-location>
    <tr>
      <td>{{ location.storeCode }}</td>
      <td>{{ location.businessName }}</td>
      <td>
        {{ location._lastUpdatedAt | dateTimePipe }}
      </td>
    </tr>
  </ng-template>
</p-table>
