import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-offer-info',
  templateUrl: './offer-info.component.html',
  styleUrls: ['./offer-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfferInfoComponent implements OnInit, OnDestroy {
  @Input() offerInfoGroup: FormGroup;
  @Input() closeGbpUtmFormEvent: EventEmitter<void>;
  buttons = [
    { value: '', label: '' },
    { value: 'BOOK', label: 'BOOK' },
    { value: 'ORDER', label: 'ORDER' },
    { value: 'SHOP', label: 'SHOP' },
    { value: 'LEARN_MORE', label: 'LEARN MORE' },
    { value: 'SIGN_UP', label: 'SIGN UP' },
    { value: 'CALL', label: 'CALL' },
  ];

  showGbpUtmForm = false;
  public linkToRedeemOfferValue: string;
  constructor() {}

  ngOnInit(): void {
    this.showGbpUtmForm = false;
    if (this.closeGbpUtmFormEvent) this.closeGbpUtmFormEvent.subscribe(() => this.onCloseGbpUtmForm());
    this.offerInfoGroup.controls['linkToRedeemOffer'].enable();
    this.linkToRedeemOfferValue = this.offerInfoGroup.controls['linkToRedeemOffer'].value;
  }

  onCloseGbpUtmForm(): void {
    this.showGbpUtmForm = false;
    this.offerInfoGroup.controls['linkToRedeemOffer'].enable();
  }

  public get offerTitleCharLimit(): number {
    return this.offerInfoGroup.controls['offerTitle']?.value?.length;
  }

  public get termsConditionCharLimit(): number {
    return this.offerInfoGroup.controls['termsAndConditions']?.value?.length || 0;
  }

  ngOnDestroy(): void {
    this.offerInfoGroup.controls['linkToRedeemOffer'].enable();
  }

  //----------------------------------------------------------------------------
  // UTM management
  //----------------------------------------------------------------------------

  handleInputLinkToRedeemOfferValueChange(event): void {
    const value = event.target.value;
    this.linkToRedeemOfferValue = value;
    this.offerInfoGroup.patchValue({ linkToRedeemOffer: value });
  }

  onAddUtmCodes(event: Event): void {
    event.preventDefault(); // This prevents the default link navigation
    this.linkToRedeemOfferValue = this.offerInfoGroup.controls['linkToRedeemOffer'].value;
    this.showGbpUtmForm = !this.showGbpUtmForm;
    if (this.showGbpUtmForm) {
      this.offerInfoGroup.controls['linkToRedeemOffer'].disable();
    } else {
      this.offerInfoGroup.controls['linkToRedeemOffer'].enable();
    }
  }

  handleLinkToRedeemOfferValueWithUtmChange(event: Event): void {
    this.offerInfoGroup.patchValue({
      linkToRedeemOffer: event,
    });
    this.linkToRedeemOfferValue = event as unknown as string;
  }
}
