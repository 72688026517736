<ng-container *ngIf="!isSuccess; else success">
  <div class="header-parent-title">
    <div class="header-child-title">{{ data.ownerAnswerText ? 'Edit Reply' : 'Reply' }}</div>
    <div class="header-child-title">.</div>
  </div>
  <div class="qa-reply-wrapper">
    <div class="qa-reply-row">
      <div class="qa-reply-image"><img class="qa-reply-photo" [src]="'http:' + data.authorPhotoURL" /></div>
      <div class="qa-reply-question">
        <div class="qa-reply-question-info">{{ data.authorName }} on {{ data.updateTime?.strftime('%m/%d/%Y') }}</div>
        <span class="qa-reply-question-title">{{ data.questionText }}</span>
      </div>
    </div>
    <p-divider class="qa-reply-divider"></p-divider>
    <div class="qa-reply-row">
      <div class="qa-reply-image"><img [src]="getActiveLogo()" width="40px" /></div>
      <div class="qa-reply-answer">
        <span>Your Answer</span>
        <textarea
          [rows]="3"
          placeholder="Type Here..."
          pInputTextarea
          autoResize="autoResize"
          [(ngModel)]="answer"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="bottom-buttons">
    <button pButton label="CANCEL" class="p-button-outlined" (click)="closeDialog()"></button>
    <button
      pButton
      [label]="data.ownerAnswerText ? 'SAVE' : 'PUBLISH'"
      [disabled]="!answer"
      (click)="saveAnswer()"
      class="p-button-primary"
    ></button>
  </div>
</ng-container>
<ng-template #success>
  <div class="qa-reply-success">
    <img src="assets/loc-add-edit-widget-logos/check.png" width="100px" height="100px" />
    <div class="header-parent-title">
      <div class="header-child-title">Success</div>
      <div class="header-child-title">!</div>
    </div>
    <div>Your answer has been posted successfully.</div>
  </div>
</ng-template>
