import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'app-reviews-overview-card',
  templateUrl: './reviews-overview-card.component.html',
  styleUrls: ['./reviews-overview-card.component.scss'],
})

export class ReviewsOverviewCardComponent {
  @Output() hideReviewsCard: EventEmitter<boolean> = new EventEmitter();
  public hideCards: boolean = false;

  constructor() { }

  public hideOverview(): void {
    this.hideCards = !this.hideCards;
    this.hideReviewsCard.emit();
  }

}
