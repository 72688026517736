<div class="pandio_reviews">

<div class="search">
  <input class="search_input" pInputText type="text" placeholder="Search for ratings by review and response..."
    [(ngModel)]="keyword" (keyup)="userSearch()" />

  <button pButton type="button" icon="fa fa-filter" class="search_filter" (click)="op.toggle($event)">
  </button>

  <p-overlayPanel #op>
    <ng-template pTemplate>
      <div class="search-filter">
        <app-table-filter [data]="filterData" (filterData)="filterTableData($event)"></app-table-filter>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>

<p class="reviews_date">
  <b>Reviews from {{ startDate }} to {{ endDate }}</b>
</p>

<div class="chips-bar" *ngIf="chipsArray.length">
  <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
</div>

  <app-reviews-overview-card (hideReviewsCard)="toggleReviewsCardView()">
    <div class="pandio_reviews_card" *ngIf="displayReviewsCard" reviewsGraph>
      <span class="graphs">
        <div class="graph-container">
          <div class="pandio_reviews__graph">
            <div class="total_reviews">
              <h2 class="total_reviews__number">{{ totalReviews }}</h2>
              <p class="total_reviews__title">Total Reviews</p>
            </div>
            <p-chart type="bar" [data]="reviewsCountGraphData" [options]="options" height="300px"></p-chart>
          </div>
        </div>
      </span>

      <span class="graphs">
        <div class="graph-container">
          <div class="pandio_reviews__graph sentiments_graph">
            <div class="sentiments_graph__rating">
              <p>Average Rating</p>
              <ng-container *ngFor="let avgRatings of averageDirectoriesRating">
                <p *ngIf="avgRatings.name">{{ avgRatings.name }} : {{ avgRatings.rating }}</p>
              </ng-container>
            </div>
            <p-chart type="bar" [data]="sentimentReportsData" [options]="stackedGraphOptions"></p-chart>
          </div>
        </div>
      </span>
    </div>
  </app-reviews-overview-card>

  <div class="table-container">
    <p-table #reviewTbl class="reviews-table" [value]="reviewData" dataKey="_id"
      [globalFilterFields]="['reviewerComment', 'businessName', 'addressLines', 'city', 'state', 'postalCode', 'storeCode', 'replyComment']"
      [(selection)]="selectedReviews" [lazy]="true" [paginator]="true" sortField="reviewCreatedAt" [sortOrder]="-1"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} " (onLazyLoad)="lazyLoadLocations($event)"
      [rows]="pageSize" [totalRecords]="totalReviewsCount" [showCurrentPageReport]="true" [columns]="cols"
      [lazyLoadOnInit]="true">

      <!-- Table filters and to export report/s-->
      <ng-template pTemplate="caption">
        <div class="flex filters">
          <span class="p-input-icon-right ml-auto">
            <!-- Search field to filter table data -->
            <i class="pi pi-search"></i>
            <input class="filters_search" pInputText type="text"
              (input)="reviewTbl.filterGlobal($event.target.value, 'contains')"
              [(ngModel)]="keyword"
              placeholder="Search for ratings by review and response..." />
            <!-- Filter's icon to filter table data -->
            <button class="filters_icon" pButton type="button" icon="fa fa-filter" (click)="op.toggle($event)"></button>
          </span>
        </div>
        <!-- To export table data -->
        <div class="button_container">
          <p-splitButton #actions label="EXPORT" styleClass="export-button" [model]="actionItems"
            (onClick)="actions.onDropdownButtonClick(null)"></p-splitButton>
        </div>
        <div class="chips-bar">
          <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
        </div>
      </ng-template>

      <!-- Table header -->
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of tableColumnsHeader" pSortableColumn="{{col.enableSorting ? col.sortingLable : null}}">
            <!-- <p-checkbox *ngIf="col.label === 'SELECT'" [(ngModel)]="selectAllReviews" [binary]="true" (onChange)="handleSelectAllReviews($event)"></p-checkbox> -->
            {{col.label}}
            <p-sortIcon *ngIf="col.enableSorting" field="col.fieldName"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <!-- Table data -->
      <ng-template pTemplate="body" let-rowData>
        <tr *ngIf="rowData">
          <td class="select">
            <p-checkbox class="review-table" name="reviews" [value]="rowData" [(ngModel)]="selectedReviews"
              (onChange)="onRowSelect($event)"></p-checkbox>
          </td>
          <td class="directory">{{ rowData?.directory }}</td>
          <td class="store-code">{{ rowData?.storeCode }}</td>
          <td class="location-name">
            {{ rowData?.businessName }}<br />
            {{ rowData?.addressLines }}<br />
            {{ rowData?.city }}, {{ rowData?.state }} {{ rowData?.postalCode }}
          </td>
          <td class="review review-row">
            <p *ngIf="rowData.date || rowData.date !== null; else dash">
                  On {{
              rowData?.date._year_ +
              '/' +
              rowData?.date._month_ +
              '/' +
              rowData?.date._day_
              }}
            </p>
            <p
              [pTooltip]="rowData.reviewerComment ? rowData.reviewTooltip : null"
              tooltipPosition="bottom"
              tooltipStyleClass="review-tooltip"
              hideDelay="250"
              *ngIf="rowData.reviewerComment; else dash"
              class="review-response">
              {{ rowData?.reviewerComment | truncate:300 }}
            </p>
          </td>
          <td class="rating">
            <span class="rating-digit">{{ rowData.starRating }} </span>
            <span class="fa fa-star"></span>
          </td>

          <!-- <td class="response response-row">
            <p *ngIf="rowData.replyUpdatedAt || rowData.replyUpdatedAt !== null; else dash">
              On {{
              rowData.replyUpdatedAt._month_ +
              '/' +
              rowData.replyUpdatedAt.day +
              '/' +
              rowData.replyUpdatedAt._year_
              }}
            </p>
            <p
              [pTooltip]="rowData.replyComment ? rowData.replyTooltip : null"
              tooltipPosition="bottom"
              tooltipStyleClass="review-tooltip"
              hideDelay="250"
              *ngIf="rowData.replyComment; else dash"
              class="review-response">
              {{ rowData.replyComment | truncate:300 }}
            </p>
          </td> -->

          <td class="table-actions">
            <ul class="table-actions-list">
              <li *ngIf="rowData.attrs?.url && sessionService.hasPerm('review', 're')" class="edit-reply"
                (click)="openExternalResponseLink(rowData)"><i class="pi pi-comments" [pTooltip]="'Respond'"
                  tooltipPosition="left">
                </i>
              </li>
              <li class="delete-reply" (click)="deleteReview(rowData)"><i class="pi pi-trash action_icon"
                  [pTooltip]="'Delete Review'" tooltipPosition="left"></i>
              </li>
            </ul>
          </td>

          <ng-template #dash>
            <img src="assets/horizontal_rule.png" width="16px" />
          </ng-template>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>


<!-- Filters modal -->
<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="search-filter">
      <app-table-filter [data]="filterData" (filterData)="filterTableData($event)"></app-table-filter>
    </div>
  </ng-template>
</p-overlayPanel>
