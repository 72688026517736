import { Injectable } from '@angular/core';
import { SocketService } from '../../backend-adapter/socket.service';
import { DirectoryWrapperComponent } from './directory-wrapper/directory-wrapper.component';

@Injectable({
  providedIn: DirectoryWrapperComponent,
})
export class DirectoryService {
  constructor(private socketService: SocketService) {}

  searchCats(event, vendor: string, callback: (res) => void): void {
    const q = event.query;
    const where = ['-and', `vendorIdent = '${vendor}'`];
    const words = q.trim().split(/\s+/);

    for (const word of words) {
      if (word.length >= 3) {
        where.push(`name ~* '\\y${word}' or code ~* '\\y${word}'`);
      }
    }

    this.socketService
      .sendRequest('get-vendor-cat-coll', {
        where,
        limit: 50,
        order: `case when (name ~* '\\y${words[0]}\\y') then 0 else 1 end`,
      })
      .then((res: any) => callback(res))
      .catch((err) => console.log('category search error: ', err));
  }
}
